import React from 'react';
import './lastwish.css';
import aboutLeft from '../../../assets/images/about.svg';
import {Col, Row, Container, Card} from "react-bootstrap";
import {Link} from "react-router-dom";


const LastWish = () => {
    return (
        <>
            <section className='last-wish'>
                <Container>
                    <Row>
                    <Col lg={5} md={5} sm={12} className="">
                        <img
                            src={aboutLeft}
                            className="img-fluid"
                            alt="logo"
                        />
                    </Col>

                    <Col lg={7} md={7} sm={12} className="about-right">
                        <h3 className='mb-4'>What if no one is able to continue your legacy or know your <span className='grediant-text'>last wish </span>?</h3>
                        <p className='mb-4'>Many people box up important documents in various places; safety deposit boxes, cabinets, lockers, or scattered across digital platforms. Accessing this information becomes impossible when away or when the location is lost.
                            <br/>
                            <br/>
                            Now, picture this scenario: if they were to pass away, their families face hurdles in retrieving information. They might bear hefty fees from estate lawyers, deal with complex bank procedures, and face courtroom drama.
                            <br/>
                            <br/>
                            Here is when we step in. We organize your data, making your legacy easily accessible at your fingertips. Our service includes regular check-ins to ensure your well-being and the simple transition of data to your chosen ones when the moment arrives.</p>
                        <Link to="/how-to" type="button" className="grediant-btn btn"><span>   Learn More </span></Link>
                    </Col>
                    </Row>
                </Container>
            </section>
        </>

    );
};

export default LastWish;