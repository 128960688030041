import React from 'react';
import './ourstory.css';
import { Col, Container, Row} from "react-bootstrap";
import ourStory from "../../../assets/images/earth.svg";
import {Link} from "react-router-dom";

const OurStory = () => {
    return (
        <>
            <section className='our_story'>
                <Container>
                    <Row className='justify-content-center m-0' >


                        <Col lg={7} md={7} sm={12} className="about-right">
                            <div className='section-title text-start'>
                                <h2>Our Story</h2>
                            </div>
                            <p className='mb-4'>Over four decades ago, Randall Arnwine began his career as a software developer. His focus was to deliver exceptional results for his clients, but life had other plans for him.
                                <br/>
                                <br/>
                                The sudden loss of his brother exposed the challenge of locating crucial documents like wills and insurance papers. This experience inspired Randall to create Keys to My Wishes, offering a simple and user-friendly solution for families to find important documents when the time comes.
                                <br/>
                                <br/>
                                In the years that followed, Keys to My Wishes recognized the need for individuals living alone to have a system for regular check-ins, ensuring their well-being. It led to the creation of the Keep in Touch check-in service, allowing users to signal their well-being with a click. Today, we remain committed to providing comfort by securing user data and serving as regular well-wishers we wish we had in the beginning. </p>
                            <Link to="/how-to" type="button" className="grediant-btn btn"><span>   Learn More </span></Link>
                        </Col>
                        <Col lg={5} md={5} sm={12} className="">
                            <img
                                src={ourStory}
                                className="img-fluid"
                                alt="ourStory"
                            />
                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    );
};

export default OurStory;