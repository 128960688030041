import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Modal} from "react-bootstrap";
import {Box, Checkbox, Grid, styled} from "@material-ui/core";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import HandleConfirmation from "../../../screens/handleConfirmation";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import {wish} from "../../../utils/wishes.utils";

const StyledBox = styled(Box)({
    border: "1px solid #c3c3c3",
    padding: "10px",
    borderRadius: "10px",
});

const MuiLabel = styled(Typography)({
    width: "40%",
    fontWeight: "500"
});

const MuiTypography = styled(Typography)({
    overflow:"hidden",
    textOverflow:"ellipsis",
    maxWidth:"60%"
});

const StyledControlLabel = styled(FormControlLabel)({
    margin: 0
});


const PermissionModal = (props) => {
    const {
        isOpenPermissions,
        togglePermissionModal,
        selectedData,
        shareRequestOwnerGet
    } = props;

    const initialValue = selectedData ? selectedData.statusid === 2 : false;
    const [selected, setSelected] = useState(initialValue);
    const [isSubmitting, setSubmitting] = useState(false);
    const [currentRequest, setCurrentRequest] = useState({});
    const [toastMessage, setToastMessage] = useState({
        isOpen: false,
        message: ""
    });

    useEffect(()=> {
        setSelected(initialValue);
    },[initialValue]);


    useEffect(()=> {
        if (isOpenPermissions && selectedData){
            shareRequestAccessGet();
        }
    },[isOpenPermissions]);

    const shareRequestAccessGet = () => {
        const token = localStorage.getItem("token");
        const data = {
            params:{
                nUserID:localStorage.getItem('userid'),
                nID:selectedData["AccessApprovalID"]
            },
            headers: {Authorization: `Bearer ${token}`}
        };
        return axios.get(process.env.REACT_APP_SERVER + `inventory/shareRequestAccessGet`,data)
            .then((res) => {
                setCurrentRequest(res.data ? res.data[0] : {})
            }).catch((err) => {
                console.log(err)
            })
    }

    const getReadMe = () => {
        const userName = localStorage.getItem("name");
        const contactName = `${selectedData.fname} ${selectedData.lname}`;
        return axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=62`)
            .then((res) => {
                return res.data[0].thetext.replace("&lt;username&gt;", userName).replace("&lt;contact name&gt;", contactName);
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleApprovedStatus = (response) => {
        if (response) {
            getReadMe().then((message) => {
                axios.post(process.env.REACT_APP_SERVER + 'usersendsms', {
                    ssubject: 'keys to my wishes Verification Code',
                    nUserID: localStorage.getItem('userid'),
                    smessage: message.replace("&lt;contactname&gt;",response.swhoto),
                    smessageSMS:  wish.removeHtml(message.replace("&lt;contactname&gt;",response.swhoto)),
                    nContactID: 0,
                    sphonenumber:response.sphonenumber,
                    semailaddress:response.semailaddress,
                    bEmailSent:!!response.semailaddress,
                    bTextSent: !!response.sphonenumber,
                    swhoto:response.swhoto,
                    otpParam:0
                }).then(()=>{
                    shareRequestOwnerGet();
                }).catch((Err)=>console.log(Err))
            })
        }
    }

    const handleSubmitRequest = () => {
        const token = localStorage.getItem("token");

        const data = {};

        data.nUserID = localStorage.getItem("userid");
        data.nID = selectedData["AccessApprovalID"];
        data.nApprovalType = selected ? 2 : 3;

        setSubmitting(true);
        axios.post(`${process.env.REACT_APP_SERVICE_PLAN_API}inventory/shareRequestAccessApproval`, {},
            {params: data, headers: {Authorization: `Bearer ${token}`}}
        ).then((response) => {
            const contactName = `${selectedData.fname} ${selectedData.lname}`;
            const message = wish.accessApproved.replace("{username}",contactName);
            if (selected) {
                handleApprovedStatus(response.data[0]);
            } else {
                shareRequestOwnerGet();
            }
            setSubmitting(false);
            togglePermissionModal();
            setToastMessage({isOpen: true, message: message});
        })
            .catch((err) => {
                setSubmitting(false);
                setToastMessage({isOpen: true, message: err.response.data.message});
            })
    }

    return (
        <>
            <Modal
                show={isOpenPermissions}
                onHide={() => togglePermissionModal()}
                centered
                size="xl"
            >
                <Modal.Header closeButton className="ShareRelease-Modal-Header">
                    <Modal.Title>Access Approval</Modal.Title>
                </Modal.Header>
                {selectedData && (
                    <Modal.Body>
                        <StyledBox mb={2}>
                            Please be sure of who you are Sharing with.
                            This is your personal and confidential information and it is your responsibility
                            to be sure you have sent your request to the correct person.
                        </StyledBox>
                        <Grid
                            container
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item xs={12} md={5}>
                                <StyledBox>
                                    <Typography variant="h6" align="center" gutterBottom>
                                        Your Contact's Information
                                    </Typography>
                                    <Box display="flex" mb={1}>
                                        <MuiLabel variant="body1">Name</MuiLabel>
                                        <MuiTypography>
                                            {currentRequest["myContctsName"] ? currentRequest["myContctsName"] : "-"}
                                        </MuiTypography>
                                    </Box>
                                    <Box display="flex" mb={1}>
                                        <MuiLabel variant="body1">Email</MuiLabel>
                                        <MuiTypography>
                                            {currentRequest["myContactsEmail"] ? currentRequest["myContactsEmail"] : "-"}
                                        </MuiTypography>
                                    </Box>
                                    <Box display="flex" mb={1}>
                                        <MuiLabel variant="body1">Phone #</MuiLabel>
                                        <MuiTypography>
                                            {currentRequest["myContactsPhoneNumber"] ? currentRequest["myContactsPhoneNumber"] : "-"}
                                        </MuiTypography>
                                    </Box>
                                    <Box display="flex" mb={1}>
                                        <MuiLabel variant="body1">Date of Request</MuiLabel>
                                        <MuiTypography>
                                            {selectedData["Date Created"] ? moment(selectedData["Date Created"]).format("ll") : "-"}
                                        </MuiTypography>
                                    </Box>
                                </StyledBox>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                {(currentRequest && selectedData) && (
                                    <StyledBox>
                                        <FormGroup row style={{display: "flex", justifyContent: 'center', gap: 5}}>
                                            <StyledControlLabel
                                                value="top"
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={selected}
                                                        disabled={
                                                            !(currentRequest["RequestorsEmail"] ||
                                                            currentRequest["RequestorsPhoneNumber"]) ||
                                                            selectedData.statusid === 2
                                                        }
                                                        onChange={() => setSelected(true)}
                                                    />
                                                }
                                                label="Accept"
                                                labelPlacement="top"
                                            />
                                            <StyledControlLabel
                                                value="top"
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={!selected}
                                                        onChange={() => setSelected(false)}
                                                    />
                                                }
                                                label={
                                                    !(currentRequest["RequestorsEmail"] || currentRequest["RequestorsPhoneNumber"])
                                                        ? "Revoke"
                                                        : "Deny"
                                                }
                                                labelPlacement="top"
                                            />
                                        </FormGroup>
                                    </StyledBox>
                                )}
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <StyledBox>
                                    <Typography variant="h6" align="center" gutterBottom>
                                        Requestor's Information
                                    </Typography>
                                    <Box display="flex" mb={1}>
                                        <MuiLabel variant="body1">Name</MuiLabel>
                                        <MuiTypography>
                                            {currentRequest["RequestorsName"] ? currentRequest["RequestorsName"] : "-"}
                                        </MuiTypography>
                                    </Box>
                                    <Box display="flex" mb={1}>
                                        <MuiLabel variant="body1">Email</MuiLabel>
                                        <MuiTypography>
                                            {currentRequest["RequestorsEmail"] ? currentRequest["RequestorsEmail"] : "-"}
                                        </MuiTypography>
                                    </Box>
                                    <Box display="flex" mb={1}>
                                        <MuiLabel variant="body1">Phone #</MuiLabel>
                                        <MuiTypography>
                                            {currentRequest["RequestorsPhoneNumber"] ? currentRequest["RequestorsPhoneNumber"] : "-"}
                                        </MuiTypography>
                                    </Box>
                                    <Box display="flex" mb={1}>
                                        <MuiLabel variant="body1">Date of Request</MuiLabel>
                                        <MuiTypography>
                                            {selectedData["Date of Request"] ? moment(selectedData["Date of Request"]).format("ll") : "-"}
                                        </MuiTypography>
                                    </Box>
                                </StyledBox>
                            </Grid>
                        </Grid>
                    </Modal.Body>
                )}
                <Modal.Footer className="d-flex justify-content-end">
                    <Button color="success" disabled={isSubmitting} className="btn-secondary"
                            onClick={() => togglePermissionModal()}>
                        Cancel
                    </Button>
                    <Button className="btngenie" disabled={isSubmitting} onClick={handleSubmitRequest}>
                        Submit {isSubmitting && <Spinner animation="border" size="sm"/>}
                    </Button>
                </Modal.Footer>
            </Modal>
            <HandleConfirmation
                isOpen={toastMessage.isOpen}
                handleClose={() => setToastMessage({isOpen: false, message: ""})}
                onConfirm={() => setToastMessage({isOpen: false, message: ""})}
                question={toastMessage.message}
                yesText="Close"
                noText=""
            />
        </>
    );
};

export default PermissionModal;