import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Checkbox, styled,
    TextField,
} from "@material-ui/core";
import "./AdditemDiscriptionWizard.css";
import Typography from "@material-ui/core/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            width: "50px",
            minWidth: "50px",
            textAlign: "center",
            border: "solid 2px",
            height: "22px",
        },
    },
    datePicker: {
        width: '130px',
        height: '25px',
        marginRight:'5px',
        "& .MuiStack-root": {
            paddingTop: 0,
            overflow: 'hidden',
        },
        "& .MuiInputBase-root": {
            width: '130px',
            height: '25px'
        },
        "& .MuiInputBase-input": {
             textAlign:'left',
             paddingLeft: '6px'
        },
        "& .MuiButtonBase-root": {
            padding:"0px 5px 0px 0px"
        },
    },
    error: {
        color: "red",
        fontSize: "13px",
    }

}));

const MuiLabel = styled(Typography)({});

const AddItemIncapacitatedDeceasedWizard = ({
                                                nIncapacitated,
                                                nDeceased,
                                                handleNIncapacitatedChange,
                                                handleNDeceasedChange,
                                                handleChange1,
                                                handleChange2,
                                                handleDaysIncapacitateChange,
                                                handleDaysPassedAwayChange,
                                                Immediatly1,
                                                Immediatly2,
                                                option1,
                                                option2,
                                                nIncapDays,
                                                nDeceasedDays,
                                                setOnVacation,
                                                OnVacation,
                                                setVacationDate,
                                                VacationDate,
                                                setIsError,
                                                isError,
                                                handleletstart,
                                                setTime,
                                                time
                                            }) => {
    const classes = useStyles();
    const styles = {
        containerFirst: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
        },
        RadioBtn: {
            display: "flex",
            flexDirection: "row",
        },
    };
    const tomorrow = dayjs().add(1, 'day');
    const year = dayjs().add(1, 'year');
    const handleOnVacationChange = (e) => {
        setOnVacation(e.target.checked ? 1 : 0)
    }
    return (
        <div>
            <div style={{border: "1px solid grey", padding: "5px"}}>
                <b>
                    This Screen allows you to be specific in which case you want the wish
                    item to be Released to one or more of your loved once and when it
                    shoud be released.
                </b>
            </div>
            <div className="mr-2 font-weight-bold mt-3">
                This Wish Item is used:{" "}
            </div>
      {/*      <div className="d-flex align-items-center justify-content-between section1-custom-checkbox" style={{minHeight:"31px"}}>*/}
      {/*          <span style={styles.containerFirst}>*/}
      {/*            <div className="d-flex">*/}
      {/*              <p style={{width: "135px"}}>On Vacation</p>*/}
      {/*              <Checkbox*/}
      {/*                  checked={OnVacation === 1}*/}
      {/*                  onChange={handleOnVacationChange}*/}
      {/*                  color="primary"*/}
      {/*                  inputProps={{*/}
      {/*                      "aria-label": "secondary checkbox",*/}
      {/*                  }}*/}
      {/*              />*/}
      {/*              </div>*/}

      {/*              <div className="d-flex align-items-center flex-wrap mb-3 mb-sm-0">*/}
      {/*                   <span className={"marginLeft"}>Release this Wish:</span>*/}
      {/*            <div className="d-flex align-items-center ml-2 mr-2 newReleaseMyInfo-container">*/}
      {/*              <form className={"mt-2 d-flex align-items-center"}>*/}


      {/*                      <span className={classes.datePicker}>*/}
      {/*                         <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
      {/*<DemoContainer components={['DatePicker', 'DatePicker']}>*/}
      {/*  <DatePicker*/}
      {/*      onChange={(newValue) => setVacationDate(dayjs(newValue).format('YYYY-MM-DD'))}*/}
      {/*      minDate={tomorrow}*/}
      {/*      maxDate={year}*/}
      {/*      onError={(newError) => {*/}
      {/*          if(VacationDate && OnVacation == 1) {*/}
      {/*              setIsError(newError ? true : false)*/}
      {/*          }*/}
      {/*      }}*/}
      {/*      disabled={OnVacation == 0}*/}
      {/*      disablePast*/}
      {/*      views={['year', 'month', 'day']}*/}
      {/*      value={(VacationDate && OnVacation == 1) ? dayjs(VacationDate) : ''}*/}
      {/*  />*/}
      {/*</DemoContainer>*/}
      {/*  </LocalizationProvider>*/}
      {/*                          {isError && (*/}
      {/*                              <span className={classes.error}>*/}
      {/*                                      Please select a date between in 1 year range*/}
      {/*                                </span>*/}
      {/*                          )}*/}
      {/*                      </span>*/}
      {/*                  <select style={{width: '60px'}} className={'selectOption'} value={time ? time : 4} onChange={(e)=>setTime(e.target.value)} disabled={OnVacation == 0}>*/}
      {/*                          <option value={2}>2PM</option>*/}
      {/*                            <option value={3}>3PM</option>*/}
      {/*                            <option value={4}>4PM</option>*/}
      {/*                            <option value={5}>5PM</option>*/}
      {/*                            <option value={6}>6PM</option>*/}
      {/*                            <option value={7}>7PM</option>*/}
      {/*                            <option value={8}>8PM</option>*/}
      {/*                  </select><span>(PST)</span>*/}
      {/*                  <ErrorOutlineOutlinedIcon onClick={()=>handleletstart(72)} className={"ml-2 cursor-pointer"} style={{color:'#007bff'}}/>*/}
      {/*              </form>*/}
      {/*            </div>*/}
      {/*              </div>*/}
      {/*          </span>*/}
      {/*      </div>*/}
            <div className="d-flex align-items-center justify-content-between section1-custom-checkbox">
                <span style={styles.containerFirst}>
                  <div className="d-flex">
                    <p style={{width: "135px"}}>Incapacitated</p>
                    <Checkbox
                        checked={nIncapacitated === 1}
                        onChange={handleNIncapacitatedChange}
                        color="primary"
                        inputProps={{
                            "aria-label": "secondary checkbox",
                        }}
                    />
                    </div>

                    <div className="d-flex align-items-center flex-wrap mb-3 mb-sm-0">
                  <span className={"marginLeft"}>Release this Wish:</span>

                  <div className="align-items-center ml-2 mr-2 newReleaseMyInfo-container">
                    <form className={"mt-2 d-flex align-items-center"}>
                      <select
                          style={{width: "231px"}}
                          className={"selectOption"}
                          value={option1}
                          onChange={handleChange1}
                      >
                        <option value={0}>Immediately</option>
                        <option value={"after"}>After # of Days</option>
                      </select>
                       <ErrorOutlineOutlinedIcon className={"ml-2 cursor-pointer"} onClick={()=>handleletstart(73)} style={{color:'#007bff'}}/>
                        {option1 === "after" && (
                            <span className={classes.root}>
                              <TextField
                                  style={{border: "none", marginLeft: "10px"}}
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={
                                      Immediatly1 === "Immediately" || nIncapDays === 0
                                          ? ""
                                          : nIncapDays
                                  }
                                  type="number"
                                  onChange={handleDaysIncapacitateChange}
                                  disabled={Immediatly1 === "Immediately"}
                              />
                              <span className={"ml-1"}>
                                  days
                              </span>
                              <span className="ml-1 text-danger max_days_label">(max 30 days)</span>
                            </span>
                        )}

                    </form>
                  </div>
                    </div>
                </span>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-between section1-custom-checkbox">
        <span style={styles.containerFirst}>
            <div className="d-flex">
          <span style={{width: "135px"}}>Deceased</span>
           <Checkbox
               checked={nDeceased === 1}
               onChange={handleNDeceasedChange}
               color="primary"
               inputProps={{
                   "aria-label": "secondary checkbox",
               }}
           />
            </div>

            <div className="d-flex align-items-center flex-wrap ">
          <span className={"marginLeft"}>Release this Wish:</span>
          <div className="align-items-center ml-2 mr-2 newReleaseMyInfo-container">
            <form className={"mt-2 d-flex align-items-center"}>
              <select
                  style={{width: "231px"}}
                  className={"selectOption"}
                  value={option2}
                  onChange={handleChange2}
              >
                <option value={0}>Immediately</option>
                <option value={"after"}>After # of Days</option>
                <option value={100}>Receipt of Death Certificate</option>
              </select>
                <ErrorOutlineOutlinedIcon className={"ml-2 cursor-pointer"} onClick={()=>handleletstart(74)} style={{color:'#007bff'}}/>
                {option2 === "after" && (
                    <span className={classes.root}>
                      <TextField
                          style={{border: "none", marginLeft: "10px"}}
                          id="outlined-basic"
                          variant="outlined"
                          value={
                              Immediatly2 === "Immediately" || nDeceasedDays === 0
                                  ? ""
                                  : nDeceasedDays
                          }
                          type="number"
                          onChange={handleDaysPassedAwayChange}
                          disabled={Immediatly2 === "Immediately"}
                      />
                      <span className={"ml-1"}> days</span>
                      <span className="ml-1 text-danger max_days_label">(max 30 days)</span>
                    </span>
                )}
            </form>
          </div>
            </div>
        </span>
            </div>
        </div>
    );
};

export default AddItemIncapacitatedDeceasedWizard;
