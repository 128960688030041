import React from 'react';
import keysLogoImage from '../../../PrivatePeople3LandingNew/assest/images/logo.png';
import { Button, Navbar, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';




const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});



const Header = () => {
    const history = useHistory();
    const classes = useStyles();
    const anchor = 'right';
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="mobile_menu">
                <div className='header_logo d-flex gap-2'>
                    <Link to='/'>
                        <img
                            src={keysLogoImage}
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Link>
                    <CloseIcon />
                </div>
                <Link to="/">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/home_webcopy" className="btn">Home</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/shop">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/shop_webcopy" className="btn">Shop</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/about">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/about_webcopy" className="btn">About</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/contact">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/contact_webcopy" className="btn">Contact</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-signup">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-signup" className="btn Landing_signup_btn">Sign Up</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-login">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-login" className="btn Landing_login_btn">Login</ListItemText>
                    </ListItem>
                </Link>
            </List>
        </div>
    );


    return (
        <>
            <div className='home_webcopy_section'>
                <Navbar className='main-header webcopy_main_header'>
                    <Navbar.Brand>
                        <Link to='/'>
                            <img
                                src={keysLogoImage}
                                className="d-inline-block align-top"
                                alt="logo"
                                style={{ width: "221px" }}
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse expand="md" className="justify-content-end">
                        <ul className='main_menu_list'>
                            <li className='menu_item '>
                                <Link to="/">Home</Link>
                            </li>
                            <li className='menu_item active'>
                                <Link to="/shop">Shop</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/about">About</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                        <div className='header_menu_button'>
                            <Button variant="" className='header_menu_outlinebtn' onClick={() => history.push('/new-login')}>Login</Button>
                            <Button variant="" className='header_menu_btn ml-2' onClick={() => history.push('/new-signup')}>
                                Start Your Free 14-Day Trial
                            </Button>
                        </div>
                    </Navbar.Collapse>
                    <div className="menu_bar">
                        <MenuIcon onClick={toggleDrawer(anchor, true)} />
                    </div>
                </Navbar>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                </Drawer>
            </div>
        </>
    )
};
export default Header;