import React from 'react';
import './location.css';
import { Col, Container, Row } from 'react-bootstrap';

const Location = () => {
    return (
        <>
            <section className='location_introduction_section'>
                <Container>
                    <Row>
                        <Col lg={5} md={5} sm={12} className="location_introduction_left">
                            <h3> <a href='https://keystomywishes.com/'>KeysToMyWishes.com</a></h3>
                            <p>
                                21550 W Oxnard St Suite #300 <br/>
                                Woodland Hills, CA 91367, US
                            </p>
                        </Col>
                        <Col lg={7} md={7} sm={12}>
                            <div className="introduction_map_content">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d476.6881429760869!2d-118.59982335750263!3d34.178868630341725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c29ea173fd8371%3A0x56b0a4b1470948b7!2s21550%20W%20Oxnard%20St%20Suite%20%23300%2C%20Woodland%20Hills%2C%20CA%2091367%2C%20USA!5e0!3m2!1sen!2sin!4v1644304651070!5m2!1sen!2sin"
                                    width="100%"
                                    height="100%"
                                    style={{ border: "0" }}
                                    allowFullScreen=""
                                    loading="lazy"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Location;