import React from 'react';
import {TextField} from "@material-ui/core";
import {Col, Row} from "react-bootstrap";

const RealEstate = [
    {
        property: "Property #1",
        ownership: "Ownership",
        address: "Address",
        city: "City",
        state: "State",
        zip: "Zip",
        mortgage: "Mortgage",
        account: "Account #",
        contact: "Contact",
        phone: "Phone",
        note: "Note",

    }, {
        property: "Property #2",
        ownership: "Ownership",
        address: "Address",
        city: "City",
        state: "State",
        zip: "Zip",
        mortgage: "Mortgage",
        account: "Account #",
        contact: "Contact",
        phone: "Phone",
        note: "Note",

    },
]

const labelStyle = {
    minWidth: "100px"
}
const RealEstatePage = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Real Estate Information</h3>
            </div>
            {RealEstate.map((estate, index) => (
                <Row key={index}>
                    <Col md={12} className="d-flex mt-3">
                        <div className="pr-1" style={labelStyle}>{estate.property}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={12} className="d-flex">
                        <div className="pr-1" style={labelStyle}>{estate.ownership}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={12} className="d-flex">
                        <div className="pr-1" style={labelStyle}>{estate.address}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={6} className="d-flex">
                        <div className="pr-1" style={labelStyle}>{estate.city}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={3} className="d-flex pl-0">
                        <div className="pr-1">{estate.state}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={3} className="d-flex pl-0">
                        <div className="pr-1">{estate.zip}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={12} className="d-flex">
                        <div className="pr-1" style={labelStyle}>{estate.mortgage}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={12} className="d-flex">
                        <div className="pr-1" style={labelStyle}>{estate.address}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={6} className="d-flex">
                        <div className="pr-1" style={labelStyle}>{estate.city}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={3} className="d-flex pl-0">
                        <div className="pr-1">{estate.state}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={3} className="d-flex pl-0">
                        <div className="pr-1">{estate.zip}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={12} className="d-flex">
                        <div className="pr-1" style={labelStyle}>{estate.account}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={6} className="d-flex">
                        <div className="pr-1" style={labelStyle}>{estate.contact}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={6} className="d-flex">
                        <div className="pr-1" style={labelStyle}>{estate.phone}:</div>
                        <TextField className="w-100 mb-2"/>
                    </Col>
                    <Col md={12} className="d-flex mb-3">
                        <div className="pr-1" style={labelStyle}>{estate.note}:</div>
                        <TextField
                            variant="filled"
                            rows={3}
                            fullWidth={true}
                            multiline={true}
                            className="w-100 mb-2"
                        />
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default RealEstatePage;