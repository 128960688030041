import React, {createContext, useState, useEffect} from "react";
import axios from "axios";

export const BooksContext = createContext({
    bookList: [],
    cart: []
});

export default function ContextProvider(props) {
    const [state, setState] = useState({
        bookList: [],
        cart: JSON.parse(localStorage.getItem("cartItems")) || []
    });
    const [color, setColor] = useState('black');

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER + `getProducts?nAll=1`)
            .then((response) => {
                let data = !response.data.status ? response.data : [];
                data[0].selectedColor = "black";
                setState({
                    bookList: !response.data.status ? response.data : [],
                    cart: JSON.parse(localStorage.getItem("cartItems")) || []
                })
            })
    }, []);

    const addToCart = (book) => {
        const bookId = book.colorOptions ? `${book.id}_${color}` : `${book.id}`;
        const cardData =
            state.cart.find((cartItem) => cartItem.id === bookId)
                ? state.cart.map((cartItem) =>
                    cartItem.id === bookId
                        ? {...cartItem, count: cartItem.count + 1, color: color}
                        : cartItem
                )
                : [...state.cart, {...book, id: bookId, count: 1, color: color}]
        setState({
            ...state,
            cart: cardData
        });
        localStorage.setItem("cartItems", JSON.stringify(cardData));
    };

    const removeFromCart = (id) => {
        const filteredCart = state.cart.filter((cartItem) => cartItem.id !== id);
        setState({
            ...state,
            cart: filteredCart
        });
        localStorage.setItem("cartItems", JSON.stringify(filteredCart));
    }

    const EmptyCard = (id) => {
        setState({
            ...state,
            cart: []
        });
        localStorage.removeItem("cartItems");
    }

    const increase = (id) => {
        const cardData = state.cart.map((cartItem) =>
            cartItem.id === id
                ? {...cartItem, count: cartItem.count + 1}
                : cartItem)
        setState({
            ...state,
            cart: cardData
        });
        localStorage.setItem("cartItems", JSON.stringify(cardData));
    };

    const decrease = (id) => {
        try {
            const cardData = state.cart.map((cartItem) =>
                cartItem.id === id
                    ? {...cartItem, count: cartItem.count > 1 ? cartItem.count - 1 : 1}
                    : cartItem
            )
            setState({
                ...state,
                cart: cardData
            });
            localStorage.setItem("cartItems", JSON.stringify(cardData));
        } catch (e){
            console.log(e)
        }
    };

    return (
        <BooksContext.Provider value={{state, addToCart, increase, decrease, removeFromCart, setColor, EmptyCard}}>
            {props.children}
        </BooksContext.Provider>
    );
}
