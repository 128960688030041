import React, { useEffect, useState } from 'react';
import {Row, Col, Container, Button} from 'react-bootstrap';
import './faq.css';
import axios from 'axios';
import '../../components/NewHeader/header.css';
import Header from '../../components/NewHeader/Header'
import {Link, useHistory} from "react-router-dom";
import Back from "../../assets/images/Back_white.png";
import FaqSection1 from "../NewLandingPage/FaqSaction";
import pattern from "../../assets/images/LandingPage/patterns/pattern.jpg";
import pattern_1 from "../../assets/images/LandingPage/patterns/pattern_1.jpg";
import "../NewLandingPage/NewLandingPage.css"

const FAQ = () => {
    const [faqGet, setFaqGet] = useState([])
    const history = useHistory()
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_GMTP_API}/faq`)
            .then(function (response) {
                setFaqGet(response.data)
            })
    }, [])

    const handleBack = () => {
        history.goBack()
    }

    return (
        <>
            <div className="privacy-policy-section position-relative">
                <header>
                    <div className="header-section">
                        <h1 className="text-center">FREQUENTLY ASKED QUESTIONS</h1>
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <div className="background-pattern-img background-loop"
                             style={{backgroundImage: `url(${pattern})`}}/>
                        <div className="background-pattern-gradient"/>
                        <div className="background-pattern-bottom">
                            <div className="image"
                                 style={{backgroundImage: `url(${pattern_1})`}}/>
                        </div>
                    </div>
                </header>
                <div className="themeBackgroundImage">
                </div>

                    <Row className="mx-auto text-center mobile-button back-logout-btn">
                        <Col
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            className="p-2 text-start profile-left-btn d-flex btnall"
                        >
                            <Button onClick={handleBack} className="btn custom-link-btn btn-primary"
                                    style={{backgroundColor: "#007BFF"}}>
                                <img src={Back} alt="Back" className="w-5"/> Back
                            </Button>
                        </Col>
                    </Row>
                    <FaqSection1 faqList={faqGet}/>
            </div>
        </>
    )
}

export default FAQ
