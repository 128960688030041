import React, {useEffect, useRef} from "react";
import "./productPdf.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logo from "../../screens/PrivatePeople3LandingNew/assest/images/logo.png";
import axios from "axios";

const ProductReceipt = () => {
    const pdfRef = useRef();
    const tableRef = useRef();
    const getData = localStorage.getItem("receiptData");
    const receiptData = JSON.parse(getData);
    const todayDate = new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    useEffect(() => {
        sendPurchaseInformation();
    }, []);

    const headerStyle = {
        textAlign: "start",
        padding: "8px",
    };
    const emailStyle ={
        textAlign: "center",
        padding: "8px"
    }

    const sendPurchaseInformation = () => {
        const pdfHtml = tableRef.current.outerHTML;
        let subjectData = receiptData.name  + " $" + receiptData.payableAmount;
        axios.post(process.env.REACT_APP_SERVER + 'sendemail/send-purchase-information', {emailbody:pdfHtml,subject:subjectData}, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    };

    const handleDownloadPdf = async () => {
        const element = pdfRef.current;
        const canvas = await html2canvas(element, {scale: 2});
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "mm", "a4");

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = pdfWidth - 20;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const positionX = (pdfWidth - imgWidth) / 2;
        const positionY = (pdfHeight - imgHeight) / 14;

        pdf.addImage(imgData, "PNG", positionX, positionY, imgWidth, imgHeight);
        pdf.save("order-receipt.pdf");
    };

    return (
        <>
            <div className="d-flex align-items-center justify-content-center custom_recipt">
                <div className="receipt-container">
                    <div ref={pdfRef} className="main_container">
                        <div>
                            <div className='d-flex justify-content-center'>
                                <img src={logo} alt="" className='w-25'/>
                            </div>
                            <div className='mt-4'>
                                <h3 className='mb-0'>Hello {receiptData.name},</h3>
                                <p className='m-0'>Thank you for your order — and welcome to the KeysToMyWishes family.
                                    From
                                    this point, a KeysToMyWishes team member will make sure your order gets to you
                                    quickly
                                    and efficiently, and we'll keep you in the loop each step of the way with shipping
                                    and
                                    delivery confirmation. If you have any questions at any time, you can reach us at
                                    (747)
                                    774-0966 or billing@KeysToMyWishes.com.</p>
                            </div>
                            <h1 className='mt-5 font-weight-bold'>Order Confirmation</h1>
                            <div className="receipt-header">
                                <div>
                                    <strong>Order Number:</strong> {receiptData.ConfirmationNumber}
                                </div>
                                <div>
                                    <strong>Date:</strong> {todayDate}
                                </div>
                            </div>
                            <div className="customer-info">
                                <strong>Customer Details</strong>
                                <p>
                                    <span className="font-weight-bold">Name: </span>
                                    {receiptData.name}
                                </p>
                                <p>
                                    <span className="font-weight-bold">Email: </span>{" "}
                                    {receiptData.email}
                                </p>
                                <p className='mb-0'>
                                    <span className="font-weight-bold">Shipping Address :</span> <br/>
                                    {receiptData.ship} <br/>
                                    {receiptData.line1} <br/>
                                    {receiptData.city}, {receiptData.state} {receiptData.postal_code}
                                </p>
                            </div>
                            <div className="order-details">
                                <strong>Order Details</strong>
                                <table className="table table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th style={headerStyle}>Product</th>
                                        <th style={headerStyle}>Quantity</th>
                                        <th style={headerStyle}>Color</th>
                                        <th style={headerStyle}>Price</th>
                                        <th style={headerStyle}>Shipping</th>
                                        <th className='text-right'>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {receiptData.cartData.map((item, index) => (
                                        <tr key={index}>
                                            <td style={headerStyle}>{item.name}</td>
                                            <td className='text-center'>{item.count}</td>
                                            <td className='text-center text-capitalize'>{item.color}</td>
                                            <td className='text-center'>${item.yearlyrate}</td>
                                            <td className='text-center'>{item.shippingCost ? `$${item.shippingCost}` : ""}</td>
                                            <td className='text-right'>
                                                ${item.shippingCost
                                                ? (+item.yearlyrate * +item.count + +item.shippingCost).toFixed(2)
                                                : (+item.yearlyrate * +item.count).toFixed(2)}
                                            </td>
                                        </tr>
                                    ))}
                                    <tr className="total-row">
                                        <td colSpan="5">
                                            <strong>Total Amount:</strong>
                                        </td>
                                        <td className='text-right'>
                                            <strong>
                                                ${receiptData.totalAmount}
                                            </strong>
                                        </td>
                                    </tr>
                                    {receiptData.discountAmount > 0 && (
                                        <tr className="total-row">
                                            <td colSpan="5">
                                                <strong> Discount Amount:</strong>
                                            </td>
                                            <td className='text-right'>
                                                <strong>${receiptData.discountAmount}</strong>
                                            </td>
                                        </tr>
                                    )}
                                    {receiptData.discountAmount > 0 && (
                                        <tr className="total-row">
                                            <td colSpan="5">
                                                <strong>Order Amount:</strong>
                                            </td>
                                            <td className='text-right'>
                                                <strong>${receiptData.payableAmount}</strong>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="email-details" ref={tableRef}>
                                <strong>Order Details</strong>
                                <div className="for_email">
                                    <b>Customer name:</b> {receiptData.name}
                                </div>
                                <div className="for_email">
                                    <b>Email:</b> {receiptData.email}
                                </div>
                                <div className="for_email" style={{marginBottom:"10px"}}>
                                    <b>Date:</b> {todayDate}
                                </div>
                                <table border="1" style={{ borderCollapse: 'collapse' }}>
                                    <thead>
                                    <tr>
                                        <th style={headerStyle}>Product</th>
                                        <th style={emailStyle}>Quantity</th>
                                        <th style={emailStyle}>Color</th>
                                        <th style={emailStyle}>Price</th>
                                        <th style={emailStyle}>Shipping</th>
                                        <th style={{textAlign: "right",padding:"8px"}}>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {receiptData.cartData.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{textAlign: "start",padding:"8px"}}>{item.name}</td>
                                            <td style={emailStyle}>{item.count}</td>
                                            <td style={{textAlign: "center",
                                                padding: "8px",textTransform:"capitalize"}}>{item.color}</td>
                                            <td style={emailStyle}>${item.yearlyrate}</td>
                                            <td style={emailStyle}>{item.shippingCost ? `$${item.shippingCost}` : ""}</td>
                                            <td style={{textAlign: "right",padding:"8px"}}>
                                                ${item.shippingCost
                                                ? (+item.yearlyrate * +item.count + +item.shippingCost).toFixed(2)
                                                : (+item.yearlyrate * +item.count).toFixed(2)}
                                            </td>
                                        </tr>
                                    ))}
                                    <tr className="total-row">
                                        <td colSpan="5" style={{textAlign: "left",padding:"8px"}}>
                                            <strong>Total Amount:</strong>
                                        </td>
                                        <td style={{textAlign: "right",padding:"8px"}}>
                                            <strong>
                                                ${receiptData.totalAmount}
                                            </strong>
                                        </td>
                                    </tr>
                                    {receiptData.discountAmount > 0 && (
                                        <tr className="total-row">
                                            <td colSpan="5" style={{textAlign: "left",padding:"8px"}}>
                                                <strong> Discount Amount:</strong>
                                            </td>
                                            <td style={{textAlign: "right",padding:"8px"}}>
                                                <strong>${receiptData.discountAmount}</strong>
                                            </td>
                                        </tr>
                                    )}
                                    {receiptData.discountAmount > 0 && (
                                        <tr className="total-row">
                                            <td colSpan="5" style={{textAlign: "left",padding:"8px"}}>
                                                <strong>Order Amount:</strong>
                                            </td>
                                            <td style={{textAlign: "right",padding:"8px"}}>
                                                <strong>${receiptData.payableAmount}</strong>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/*<div className='info'>*/}
                        {/*    <h3>Contact Information:</h3>*/}
                        {/*    <p className='mb-0'>Email: billing@KeysToMyWishes.com</p>*/}
                        {/*    <p>Phone: 747-774-0966</p>*/}
                        {/*</div>*/}
                    </div>
                    <div className='mt-5'>
                        <button onClick={handleDownloadPdf} className="btn btn-primary">
                            Download Pdf
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductReceipt;