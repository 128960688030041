import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import genie_img from "../../assets/images/genie_image.jpg";
import fileAttach from "../../assets/images/ic_attatch.png";
import Add from "../../assets/images/Add.png";
import ic_users from "../../assets/images/ic_users.png";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "./Layout/ShareReleaseModel.css";
import axios from "axios";

const headCells = [
  {
    id: "sharedwith",
    numeric: true,
    disablePadding: false,
    label: "Shared With",
    align: "left",
  },
  {
    id: "releaseto",
    numeric: true,
    disablePadding: false,
    label: "Released To",
    align: "left",
  },
  {
    id: "fname",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    align: "left",
  },
  {
    id: "lname",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
    align: "left",
  },
];

function EnhancedTableHead({ isShowShareColumn, isShowReleaseColumn }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) =>
          (headCell.id === "sharedwith" && isShowShareColumn) ||
          (headCell.id === "releaseto" && isShowReleaseColumn) ||
          (headCell.id !== "sharedwith" && headCell.id !== "releaseto") ? (
            <TableCell key={index} align={headCell.align}>
              {headCell.label}
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  container: {
    maxHeight: 500,
    overflowX: "scroll",
  },
  customCell: {
    padding: "8px 16px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  customHeaderCell: {
    fontSize: "16px",
  },
}));

const ShareReleaseModal = ({
  ShareReleaseModalShow,
  closeShareReleaseModalShow,
  itemName,
  existingSharedReleaseWithTags,
  handleIsShareToChange,
  handleIsReleaseToChange,
  saveIsShareToIsReleaseChange,
  isShowShareColumn = true,
  isShowReleaseColumn = true,
  isSaveButtonDisabled,
}) => {
  const classes = useStyles();

  const [LegendData, setLegendData] = React.useState("");

  const [showLegend, setShowLegend] = useState(false);

  const handleLegendDailog = () => {
    getLegendData();
    setShowLegend(true);
  };

  const handleCloseLegend = () => {
    setShowLegend(false);
  };

  const getLegendData = () => {
    if (isShowShareColumn === true) {
      axios
        .get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=9`)
        .then((res) => {
          if (res.data){
            setLegendData(res.data[0]);
          } else {
            return null
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (isShowReleaseColumn === true) {
      axios
        .get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=10`)
        .then((res) => {
          setLegendData(res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <Modal show={showLegend} onHide={handleCloseLegend}>
        <Modal.Header closeButton className={"modelHeader"}>
          <Modal.Title>{LegendData && LegendData.theheader ? LegendData.theheader : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="model-genie"
            dangerouslySetInnerHTML={{
              __html: LegendData && LegendData.thetext
                ?.toString()
                .replaceAll("\n", "<p>\n</p>"),
            }}
          ></div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngenie" onClick={handleCloseLegend}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={ShareReleaseModalShow}
        onHide={() => {
          closeShareReleaseModalShow();
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ShareRelease-Modal-Container"
      >
        <Modal.Header closeButton className="ShareRelease-Modal-Header">
          <Modal.Title>
            {isShowShareColumn && isShowReleaseColumn
              ? "Shared With - Release To"
              : isShowShareColumn
              ? "Shared With"
              : "Release To"}
          </Modal.Title>
        </Modal.Header>
        <div className={"modelLegend"}>
          <img
            src={genie_img}
            className="genie ml-2 mt-2"
            onClick={handleLegendDailog}
          />
        </div>
        <Modal.Body>
          <div className="text-left">
            <div className="pb-3">
              <b>
                {isShowShareColumn && isShowReleaseColumn
                  ? "Wish will be Shared With and/or be Released To"
                  : isShowShareColumn
                  ? "Wish will be Shared With"
                  : "Wish will be Released To"}{" "}
              </b>
            </div>
            {itemName ? (
              <div className="ShareRelease-Container">{itemName}</div>
            ) : null}
          </div>
          <div className={"sharedRelease"}>
            <Paper className={classes.paper}>
              <TableContainer className={classes.container}>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                  stickyHeader
                >
                  <EnhancedTableHead
                    isShowShareColumn={isShowShareColumn}
                    isShowReleaseColumn={isShowReleaseColumn}
                  />
                  <TableBody>
                    {existingSharedReleaseWithTags
                      /*.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/
                      .map((row, index) => {
                        return (
                          <TableRow
                            style={{cursor: 'pointer'}}
                            hover
                            tabIndex={-1}
                            key={index}
                            onClick={
                              isShowShareColumn
                                ? () =>
                                    handleIsShareToChange(
                                      row.contactid.toString(),
                                      !row.editsharedwith
                                    )
                                : () =>
                                      handleIsReleaseToChange(
                                      row.contactid.toString(),
                                      !row.editreleaseto
                                    )
                            }
                          >
                            {isShowShareColumn ? (
                                <TableCell align="left">
                                  <Checkbox
                                      id={row.contactid.toString()}
                                      checked={!!row.editsharedwith}
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "secondary checkbox",
                                      }}
                                  />
                                </TableCell>
                            ) : null}

                            {isShowReleaseColumn ? (
                                <TableCell align="left">
                                  <Checkbox
                                      id={row.contactid.toString()}
                                      checked={!!row.editreleaseto}
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "secondary checkbox",
                                      }}
                                  />
                                </TableCell>
                            ) : null}
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              id={index}
                            >
                              {row.fname}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              id={index}
                            >
                              {row.lname}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="primary"
            className="custom-btn"
            onClick={() => saveIsShareToIsReleaseChange()}
            disabled={isSaveButtonDisabled()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShareReleaseModal;
