import React, { useState } from 'react';
import { Modal, ModalBody, Col, Container, Form, Row } from "react-bootstrap";
import sendIcon from "../../PrivatePeople3LandingNew/assest/images/send-icon.svg";
import axios from "axios";
import mauntainsImg from '../../../assets/images/contactWeb/mauntains_img.svg';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './contact-web.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {ModalHeader} from "reactstrap";

const ContactPage = () => {

    const history = useHistory();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isRequired, setIsRequired] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleCloseModel = () => {
        setIsOpen(false);
    }
    const handleSubmitReport = (e) => {
        if (!name || !email || !message) {
            setIsRequired(true)
        } else {
            e.preventDefault();

            const data = {
                sfullname: name,
                semailaddress: email,
                smessage: message,
            };
            axios
                .post(process.env.REACT_APP_SERVER + "usergetandsendemail", data)
                .then((res) => {
                    setName("");
                    setEmail("");
                    setMessage("");
                })
                .catch((err) => {
                });
        }
    };
    return (
        <div>
            <section className='contact_home_section'>
                <Container fluid>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <div>
                                <h2 className='main_title'>Ready to secure your <br /> last wishes?</h2>
                                <p className='sub_title'>
                                    You deserve peace of mind when it comes to protecting <br /> your private and personal
                                    information. <span className='strong_title'>KeysToMyWishes</span> is ready to give you that.
                                </p>
                                <p className='sub_title'>
                                    Simplify the process of informing your loved ones about your  important information. <br /> See
                                    how easy it is to store your last wishes in one place with <span className='strong_title'>KeysToMyWishes</span>. Take advantage of
                                    our
                                    free 14-day trial today.
                                </p>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='text-center'>
                                <img src={mauntainsImg} alt='mauntainsImg' className='mauntains_img img-fluid' />
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section_container trial_section'>
                <Container>
                    <Row className='justify-content-center align-items-center'>
                        <button className='button_primary' onClick={()=>history.push("/new-signup")}>
                            Secure Your Wishes Now
                        </button>
                    </Row>
                </Container>
            </section>

            <section className='contact_web_section'>
                <Container fluid>
                    <Row className='justify-content-end '>
                        <Col lg={4} className='d-flex justify-content-end'>
                            <div className='contact_info_container'>
                                <div className='contact_info_box'>
                                    <CallIcon />
                                    <div className='info_inner_box'>
                                        <h6>Phone</h6>
                                        <p>747-774-0966</p>
                                    </div>
                                </div>
                                <div className='contact_info_box'>
                                    <EmailIcon />
                                    <div className='info_inner_box'>
                                        <h6>Email</h6>
                                        <p>support@keystomywishes.com</p>
                                    </div>
                                </div>
                                <div className='contact_info_box'>
                                    <LocationOnIcon />
                                    <div className='info_inner_box'>
                                        <h6>Location</h6>
                                        <p>21550 W Oxnard St <br/> Suite #300 <br/> Woodland Hills, CA 91367</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <Form className='contact_form'>
                                <Row>
                                    <Col sm={12} className='mb-3'>
                                        <h3>Get In Touch</h3>
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <Form.Control
                                            placeholder="Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        {(isRequired && name.trim() === "") &&
                                            <span className="field-error">Name is required</span>}
                                    </Col>
                                    {/*<Col md={6} className='mb-3'>*/}
                                    {/*    <Form.Control placeholder="Phone" />*/}
                                    {/*</Col>*/}
                                    <Col sm={12} className='mb-3'>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {(isRequired && email.trim() === "") && (
                                            <span className="field-error">Email is required</span>
                                        )}
                                    </Col>
                                    <Col sm={12} className='mb-3'>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Enter Message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                        {(isRequired && message.trim() === "") && (
                                            <span className="field-error">Message is required</span>
                                        )}
                                    </Col>
                                    <Col>
                                        <p className='sub_title my-0'>
                                            If you have questions or comments, fill out the contact form below. We’ll get back to you
                                            within 2
                                            days.
                                        </p>
                                    </Col>
                                    <Col sm={12} className='mb-3'>
                                        <div className='contact_form_btn '>
                                            <button
                                                type="button"
                                                className="grediant-btn btn mt-4"
                                                onClick={(e) => {
                                                    handleSubmitReport(e);
                                                    setIsOpen(true);
                                                }}
                                            >
                                                <img
                                                    src={sendIcon}
                                                    className="img-fluid mr-2"
                                                    alt="logo"
                                                />
                                                Send Message
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Modal
                show={isOpen}
                onHide={handleCloseModel}
                centered
            >
                <Modal.Header style={{backgroundColor:"#54a0f3",color:"white"}} closeButton>
                   <Modal.Title>Thanks!</Modal.Title>
                </Modal.Header>
                <ModalBody className='helper_video p-2'>
                    <div className={'px-2 py-3 text-center'}>
                        Thank you for your message. We will be in touch within 2 business days.
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ContactPage;