import React, { Component } from "react";
import "./HelpingSection.css";
import banner_right_image from "../../../assets/images/LandingPageNew/banner_right_image.png";
import KeysToMyWishes from "../../../assets/images/KeysToMyWishes.mp4";
import app_store from "../../../assets/images/LandingPageNew/app_store.svg";
import google_play from "../../../assets/images/LandingPageNew/google_play.svg";
import main_circle from "../../../assets/images/LandingPageNew/BannerBgImage/main_circle.svg";
import circle1 from "../../../assets/images/LandingPageNew/BannerBgImage/circle_1.svg";
import circle2 from "../../../assets/images/LandingPageNew/BannerBgImage/circle_2.svg";
import triangle1 from "../../../assets/images/LandingPageNew/BannerBgImage/triangle_1.svg";
import triangle2 from "../../../assets/images/LandingPageNew/BannerBgImage/triangle_2.svg";
import triangle3 from "../../../assets/images/LandingPageNew/BannerBgImage/triangle_3.svg";
import arrow_line from "../../../assets/images/LandingPageNew/BannerBgImage/arrow_line.svg";
import key from "../../../assets/images/LandingPageNew/key.svg";
import RepresentativesContentBg from "../../../assets/images/LandingPageNew/representatives_content_bg.svg";
import RepresentativesLeftBg from "../../../assets/images/LandingPageNew/representatives_left_bg.svg";
import { Link } from "react-router-dom";
import {Button, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";

class NewHelpingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      email: "",
      Message: "",
      pricingType: "yearly",
      sentSuccess: false, 
      massagemodel: true,
      toggleModal:false,
    };
  }
  handlecloseMassageModel = () => {
    this.setState({ fname: "" });
    this.setState({ email: "" });
    this.setState({ message: "" });
    this.setState({ massagemodel: false });
  };

  handlecloseDownloadModal =()=>{
      this.setState({ toggleModal: false });
  }
  handleShowModal = () =>{
      this.setState({ toggleModal: true });
  }

  render() {
    const {
      sentSuccess,
      massagemodel,
      toggleModal
    } = this.state;
    return (
      <>
        {sentSuccess && (
          <Modal show={massagemodel} onHide={this.handlecloseMassageModel}>
            <Modal.Header
              closeButton
              style={{ backgroundColor: "#7030a0", color: "white" }}
            >
              <Modal.Title>Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>
                Thank you. Your email was sent to Keys To My Wishes Support
                Staff and we will get back to you soon!
              </span>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btngenie"
                onClick={this.handlecloseMassageModel}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}

          <Modal show={toggleModal} onHide={this.handlecloseDownloadModal}>
              <Modal.Header
                  closeButton
                  style={{ backgroundColor: "#7030a0", color: "white" }}
              >
                    <Modal.Title>Coming Soon</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  You will be able to download our Mobile app very soon.<br/>
                   Check back with us.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button className="btngenie" onClick={this.handlecloseDownloadModal}>
                  Close
                </Button>
              </Modal.Footer>
          </Modal>
        <div className="banner_section representatives_section">
          <div className="banner_content representatives_content">
            <div className="row justify-content-center mb-4">
                <h1 className="Newheading">Helping Individuals and Families <br/> <span className="representHeding">Prepare for the Unexpected</span></h1>
            </div>
            <div className="row m-0 justify-content-center">
              <div className="col-xl-6 col-lg-6 mb-3 align-self-center">
                <div className="banner_details">
                  <h1 className="banner_title"> We've got your back!</h1>
                  <div className="your-info-section">
                  <video style={{ width: '-webkit-fill-available' }} controls>
                        <source src={KeysToMyWishes} type="video/mp4" />
                  </video>
                  </div>
                  <p className="banner_description">
                    If you were in an accident today would your loved ones know
                    where to find your private important information/documents?
                  </p>
                  <div className="download_btn_group">
                    <a>
                      <img src={app_store} alt="app_store" onClick={this.handleShowModal} />
                    </a>
                    <a>
                      <img src={google_play} alt="google_play" onClick={this.handleShowModal} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="banner_right_img text-center">
                  <img src={banner_right_image} alt="banner_right_image"/>
                </div>
              </div>
            </div>
          </div>
          <div className="banner_bg_image">
            <img
              src={main_circle}
              className="bg_img main_circle"
              alt="main_circle"
            />
            <img src={circle1} className="bg_img circle1" alt="circle1" />
            <img
              src={circle2}
              className="bg_img circle1 circle2"
              alt="circle2"
            />
            <img src={triangle1} className="bg_img triangle1" alt="triangle1" />
            <img
              src={triangle1}
              className="bg_img triangle1_1"
              alt="triangle1"
            />
            <img src={triangle2} className="bg_img triangle2" alt="triangle2" />
            <img src={triangle3} className="bg_img triangle3" alt="triangle3" />
            <img
              src={arrow_line}
              className="bg_img arrow_line"
              alt="arrow_line"
            />
          </div>
        </div>


        <div className="representatives_section">
          <div className="representatives_content d-flex flex-column justify-content-center">
            <h3 className={"text-center representatives_content_header"}>
              OUR GOAL IS TO CONVEY YOUR FINAL WISHES TO YOUR LOVED ONES - IN YOUR WORDS AND IN YOUR WAY!
            </h3>
            <p className="representatives_label text-center my-5">
              Real-Life Representatives to Keep In Touch verifying your Health
            </p>
            <div className=" m-auto p-0 m-0 representatives_label_main">
              <div className="row h-100 m-0 justify-content-between representatives_section_list">
                <div className="tab_content d-flex align-items-center">
                  <img src={key} className="mr-3" alt="key" />
                  <Link to="/keep-in-touch">Keep In Touch/CHECK-IN!</Link>
                </div>
                <div className="tab_content d-flex align-items-center">
                  <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-top`} style={{zIndex:'999999999999'}}>
                          Break the Glass is the feature that takes place no matter which plan you choose. At anytime something happens to the account holder our process is triggered to verify that you are ok or not. If not we will release your Wishes based on your settings.
                        </Tooltip>
                      }
                  >
                    <div>
                      <img src={key} className="mr-3" alt="key" />
                        <Link to="/break-the-glass">
                        Break the Glass!
                      </Link>
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="tab_content d-flex align-items-center">
                  <img src={key} className="mr-3" alt="key" />
                  <Link to="/how-it-can-help-me">How it can Help Me?</Link>
                </div>
                <div className="tab_content d-flex align-items-center">
                  <img src={key} className="mr-3" alt="key" />
                  <Link to="/why-it-matters">Why it Matters?</Link>
                </div>
                <div className="tab_content d-flex align-items-center">
                  <img src={key} className="mr-3" alt="key" />
                  <Link to="/Confidential">Confidential</Link>
                </div>
                <div className="tab_content d-flex align-items-center">
                  <img src={key} className="mr-3" alt="key" />
                  <Link to="/easy-steps">4 Easy Steps!</Link>
                </div>
              </div>
              <img
                src={RepresentativesContentBg}
                className="RepresentativesContentBg"
                alt="RepresentativesContentBg"
              />
            </div>
            <h2 className="representatives_title text-center my-5">
              We Convey Your Wishes When You Cannot!!!
            </h2>
          </div>
          <img
            src={RepresentativesLeftBg}
            className="representatives_bg"
            alt="RepresentativesLeftBg"
          />
        </div>
      </>
    );
  }
}

export default NewHelpingSection;
