import React from 'react';
import BGHeader from "../Common/BGHeader";
import Footer from "../Home/Footer";
import PlanList from "./Plans";
import FeaturesList from "./FeaturesList";

const Features = () => {
    return (
        <div>
            <BGHeader
                title={'More Organized = More Time'}
                description={'Whether you want to protect your information, organize your data, or ensure a smooth transfer to loved ones – Keys To My Wishes takes the headache off your plate.'}
            />
            <PlanList/>
            <FeaturesList/>
            <Footer/>
        </div>
    );
};

export default Features;