import React from 'react';
import BGHeader from "../Common/BGHeader";
import Footer from "../Home/Footer";
import PricingList from "./PricingList";
import FindMyPlan from "./FindMyPlan";
import FAQ from "./FAQ";
import MultipalReviews from "../../../Landing/MultipalReviews";

const Pricing = () => {
    return (
        <div>
            <BGHeader
                title={`Protect your vital information and precious memories in one secure place`}
                description={'With our one-of-a-kind Check-In feature, you check in with KeysToMyWishes on a quarterly, monthly, or weekly basis. This lets us know that things are still okay on your end.'}
            />
            <PricingList/>
            {/*<FindMyPlan/>*/}
            <MultipalReviews buttonNeed={true}/>
            <FAQ/>
            <Footer/>
        </div>
    );
};

export default Pricing;