import React from 'react';
import Header from './Header';
import Introduction from './Introduction';
import LearnMore from './LearnMore';
import WhyChoose from '../../WhyChoose';
import EasySteps from './EasySteps';
import Reviews from '../../Reviews';
import FreeTrial from './FreeTrial';
import Footer from './Footer';


const PrivatePeopleLandingNew = () => {
    return (
        <div>
            <Header/>
            <Introduction/>
            <LearnMore/>
            <WhyChoose/>
            <EasySteps/>
            <Reviews/>
            <FreeTrial/>
            <Footer/>
        </div>
    );
};

export default PrivatePeopleLandingNew;