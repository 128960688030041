import React, {useEffect} from "react";
import {Checkbox, TextField} from "@material-ui/core";
import "@pathofdev/react-tag-input/build/index.css";
import {makeStyles} from "@material-ui/core/styles";
import './Layout/Section1.css'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {Modal} from "react-bootstrap";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            width: "50px",
            minWidth: "50px",
            textAlign: "center",
            border: "solid 2px",
            height: "22px",
        },
    },
    datePicker: {
        width: '130px',
        height: '25px',
        marginRight:'5px',
        "& .MuiStack-root": {
            paddingTop: 0,
            overflow: 'hidden',
        },
        "& .MuiInputBase-root": {
            width: '130px',
            height: '25px'
        },
        "& .MuiInputBase-input": {
            textAlign:'left',
            paddingLeft: '6px'
        },
        "& .MuiButtonBase-root": {
            padding:"0px 5px 0px 0px"
        },
    },
    error:{
        color:"red",
        fontSize:"13px",
    }

}));


const Section1 = ({
                      isLabelExist,
                      nIncapacitated,
                      nIncapDays,
                      nDeceasedDays,
                      Immediatly1,
                      Immediatly2,
                      setImmediatly1,
                      setImmediatly2,
                      handleNIncapacitatedChange,
                      nDeceased,
                      handleNDeceasedChange,
                      handleDaysIncapacitateChange,
                      handleDaysPassedAwayChange,
                      sortOrder,
                      setSortOrder,
                      OnVacation,
                      handleOnVacationChange,
                      setVacationDate,
                      VacationDate,
                      setIsError,
                      isError,
    setTime,
    Time
                  }) => {
    const styles = {
        containerFirst: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
        },
        RadioBtn: {
            display: "flex",
            flexDirection: "row",
        },
    };
    const [option1, setOption1] = React.useState(Immediatly1)
    const [option2, setOption2] = React.useState(Immediatly2)
    const [showInfoModal,setShowInfoModal] = React.useState(false);
    const [getstartData, setgetstartData] = React.useState("");
    useEffect(() => {
        setOption1(Immediatly1);
    }, [Immediatly1]);

    useEffect(() => {
        setOption2(Immediatly2);
    }, [Immediatly2]);

    const classes = useStyles();
    const openInfoModal = (id) =>{
        setShowInfoModal(true)
        handleletstart(id)
    }
    const handleletstart = (id) => {
        axios
            .get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=${id}`)
            .then((res) => {
                setgetstartData(res.data[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleChange1 = (event) => {
        setOption1(event.target.value);
        setImmediatly1(event.target.value);
    };
    const handleChange2 = (event) => {
        setOption2(event.target.value);
        setImmediatly2(event.target.value);
    };
    const tomorrow = dayjs().add(1, 'day');
    const year = dayjs().add(1, 'year');

    return (
        <>
        <div className="box align-items-center section1">
            <div className={'float-right'}>
                <span>Sort Order{' '}</span>
                <TextField
                    className={'sort-order'}
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    value={sortOrder?.length > 3 ? '' : sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                />
            </div>
            {isLabelExist ? <div className="mr-2">*Section 1</div> : null}
            <div className="mr-2 font-weight-bold">
                This Wish Item is used:{" "}
            </div>
    {/*        <div className="d-flex align-items-center justify-content-between section1-custom-checkbox w-100" style={{minHeight:"31px"}}>*/}
    {/*    <span style={styles.containerFirst}>*/}
    {/*        <div className="d-flex release_items">*/}
    {/*                <p style={{width: "60px", color: 'red'}}>If I am : </p>*/}
    {/*              <p style={{width: "135px"}}>On Vacation</p>*/}
    {/*              <Checkbox*/}
    {/*                  checked={OnVacation === 1}*/}
    {/*                  onChange={handleOnVacationChange}*/}
    {/*                  color="primary"*/}
    {/*                  inputProps={{*/}
    {/*                      "aria-label": "secondary checkbox",*/}
    {/*                  }}*/}
    {/*                  disabled={isLabelExist}*/}
    {/*              />{" "}*/}
    {/*      </div>*/}
    {/*        <div className="d-flex release_items align-items-center">*/}
    {/*             <span className={"marginLeft"}>Release this Wish:</span>*/}
    {/*            <div className="align-items-center ml-2 mr-2 newReleaseMyInfo-container">*/}
    {/*            <form className={'mt-1 d-flex align-items-center'}>*/}


    {/*                   <span className={classes.datePicker}>*/}
    {/*                        <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
    {/*  <DemoContainer components={['DatePicker', 'DatePicker']}>*/}
    {/*    <DatePicker*/}
    {/*        onChange={(newValue) => setVacationDate(dayjs(newValue).format('YYYY-MM-DD'))}*/}
    {/*        minDate={tomorrow}*/}
    {/*        maxDate={year}*/}
    {/*        onError={(newError) => {*/}
    {/*            if(VacationDate && OnVacation == 1) {*/}
    {/*                setIsError(newError ? true : false)*/}
    {/*            }*/}
    {/*        }}*/}
    {/*        disabled={OnVacation == 0}*/}
    {/*        disablePast*/}
    {/*        value={(VacationDate && OnVacation == 1) ? dayjs(VacationDate) : ''}*/}
    {/*        views={['year','month','day']}*/}
    {/*    />*/}
    {/*  </DemoContainer>*/}
    {/*</LocalizationProvider>*/}
    {/*               </span>*/}
    {/*                <select style={{width: '60px'}} className={'selectOption'} value={Time ? Time : 4} onChange={(e)=>setTime(e.target.value)} disabled={OnVacation == 0} >*/}
    {/*                              <option value={2}>2PM</option>*/}
    {/*                              <option value={3}>3PM</option>*/}
    {/*                              <option value={4}>4PM</option>*/}
    {/*                              <option value={5}>5PM</option>*/}
    {/*                              <option value={6}>6PM</option>*/}
    {/*                              <option value={7}>7PM</option>*/}
    {/*                              <option value={8}>8PM</option>*/}

    {/*                    </select><span>(PST)</span>*/}
    {/*                <ErrorOutlineOutlinedIcon onClick={()=>openInfoModal(72)} className={"ml-2 cursor-pointer"} style={{color:'#007bff'}}/>*/}
    {/*        </form>*/}
    {/*                {isError && (*/}
    {/*                    <span className={classes.error}>*/}
    {/*                      Please select a date between in 1 year range*/}
    {/*                    </span>*/}
    {/*                )}*/}
    {/*      </div>*/}
    {/*        </div>*/}
    {/*    </span>*/}
    {/*        </div>*/}
            <div className="d-flex align-items-center justify-content-between section1-custom-checkbox w-100">
        <span style={styles.containerFirst}>
            <div className="d-flex release_items">
                    <p style={{width: "60px", color: 'red'}}>If I am : </p>
                  <p style={{width: "135px"}}>Incapacitated</p>
                  <Checkbox
                      checked={nIncapacitated === 1}
                      onChange={handleNIncapacitatedChange}
                      color="primary"
                      inputProps={{
                          "aria-label": "secondary checkbox",
                      }}
                      disabled={isLabelExist}
                  />{" "}
          </div>
            <div className="d-flex release_items marginLeft align-items-center">
                <span>Release this Wish:</span>
                <div className="align-items-center ml-2 mr-2 newReleaseMyInfo-container">
                <form className={'mt-1 d-flex align-items-center'}>
                <select style={{width: '231px'}} className={'selectOption'} value={option1} onChange={handleChange1}>
                   <option value={0}>Immediately</option>
                   <option value={'after'}>After # of Days</option>
               </select>
                <ErrorOutlineOutlinedIcon onClick={()=>openInfoModal(73)} className={"ml-2 cursor-pointer"} style={{color:'#007bff'}}/>
                    {
                        option1 === 'after' &&
                        <span className={classes.root}>
                            <TextField
                                style={{border: "none", marginLeft: '10px'}}
                                id="outlined-basic"
                                variant="outlined"
                                value={Immediatly1 === "Immediately" || nIncapDays === 0 ? '' : nIncapDays}
                                type="number"
                                onChange={handleDaysIncapacitateChange}
                                disabled={Immediatly1 === "Immediately"}
                            />
                    <span className={'ml-1'}>{' '}days</span>
                   </span>
                    }
            </form>
          </div>
            </div>
        </span>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-between section1-custom-checkbox w-100">
        <span style={styles.containerFirst}>
            <div className="d-flex release_items">
                <span style={{width: "60px", color: 'red'}}>If I am : </span>
          <span style={{width: "135px"}}>Deceased</span>
          <Checkbox
              checked={nDeceased === 1}
              onChange={handleNDeceasedChange}
              color="primary"
              inputProps={{
                  "aria-label": "secondary checkbox",
              }}
              disabled={isLabelExist}
          />{" "}
          </div>
            <div className="d-flex release_items marginLeft align-items-center">
                <span>Release this Wish:</span>
                <div className="align-items-center ml-2 mr-2 newReleaseMyInfo-container">
                <form className={'mt-2 d-flex align-items-center'}>
                <select style={{width: '231px'}} className={'selectOption'} value={option2} onChange={handleChange2}>
                   <option value={0}>Immediately</option>
                   <option value={'after'}>After # of Days</option>
                     <option value={100}>Receipt of Death Certificate</option>
               </select>
                    <ErrorOutlineOutlinedIcon onClick={()=>openInfoModal(74)} className={"ml-2 cursor-pointer"} style={{color:'#007bff'}}/>
                    {
                        option2 === 'after'
                        && <span className={classes.root}>
                      <TextField
                          style={{border: "none", marginLeft: '10px'}}
                          id="outlined-basic"
                          variant="outlined"
                          value={Immediatly2 === "Immediately" || nDeceasedDays === 0 ? '' : nDeceasedDays}
                          type="number"
                          onChange={handleDaysPassedAwayChange}
                          disabled={Immediatly2 === "Immediately"}
                      />
                <span className={'ml-1'}>{' '}days</span>
                </span>
                    }

            </form>
          </div>
           </div>
        </span>
            </div>
        </div>
            <Modal show={showInfoModal} onHide={()=>setShowInfoModal(false)} centered >
                <Modal.Header className={"modelHeader"}>
                    <Modal.Title><div dangerouslySetInnerHTML={{ __html: getstartData?.theheader }} className={"text-center"}/></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className={"ml-2 pt-2 dataMain"}
                        dangerouslySetInnerHTML={{ __html: getstartData?.thetext }}
                    />
                </Modal.Body>
            </Modal>
            </>
    );
};

export default Section1;
