import React from 'react';
import  './Header/header.css';
import Header from './Header';
import Introduction from './Introduction';
import Helping from './Helping';
import Features from './Features';
import WhyChoose from './WhyChoose';
import Reviews from './Reviews';
import HowWorks from './HowWorks';
import Trial from './Trial';
import Contact from './Contact';
import Footer from './Footer';
import EasySteps from "../PrivatePeople3LandingNew/Pages/Home/EasySteps";
import Plans from './Plans';
import NewContactSection from "../LandingPageNew/helpingSection/NewContactSection";
import FooterMyPrivacyPage from "../MyPrivacy/FooterMyPrivacyPage/FooterMyPrivacyPage";
import LearnMore from "../PrivatePeople3LandingNew/Pages/Home/LearnMore";
import MultipalReviews from "./MultipalReviews";
import FAQ from "../PrivatePeople3LandingNew/Pages/Pricing/FAQ";
import PrivetPeopleFAQ from "./PrivetPeopleFAQ";

const LandingNewuneexpacted = () => {
    return (
        <div className="new_landing_page">
            <div className="introduction_section pb-5">
                <Header/>
                <Introduction/>
                <Helping/>
            </div>
            <LearnMore/>
            {/*<Features/>*/}
            {/*<Plans/>*/}
            <WhyChoose/>
            <MultipalReviews/>
            {/*<Reviews/>*/}
            {/*<HowWorks/>*/}
            <EasySteps buttonTitle="Start 14-Day Free Trial"/>
            <PrivetPeopleFAQ/>
            <NewContactSection isShowHeader={false}/>
            <FooterMyPrivacyPage/>
        </div>
    );
};

export default LandingNewuneexpacted;