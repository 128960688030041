
export const indexLists = [
    { title: "WHY THIS ORGANIZER IS IMPORTANT?", number: 4 },
    { title: "RECOMMENDATION TO PROTECT LOVED ONES", number: 5 },
    { title: "IN CASE OF EMERGENCY", number: 6 },
    { title: "MY INFORMATION", number: 7 },
    { title: "CHILDREN", number: 8 },
    { title: "PETS", number: 9 },
    { title: "UPON MY DEATH DO THIS", number: 10 },
    { title: "EMPLOYMENT INFORMATION", number: 11 },
    { title: "TRUST", number: 12 },
    { title: "WILL", number: 13 },
    { title: "POWER OF ATTORNEY", number: 14 },
    { title: "ATTORNEY INFORMATION", number: 15 },
    { title: "INSURANCE", number: 16 },
    { title: "BANK ACCOUNTS", number: 20 },
    { title: "DIGITAL ACCOUNTS", number: 21 },
    { title: "CREDIT CARDS", number: 22 },
    { title: "AUTOMOBILES", number: 23 },
    { title: "BUSINESS OWNED", number: 24 },
    { title: "JEWELRY", number: 25 },
    { title: "COLLECTIBLES", number: 27 },
    { title: "REAL ESTATE INFORMATION", number: 28 },
    { title: "INCAPACITATION", number: 29 },
    { title: "FRIENDS AND FAMILY", number: 30 },
    { title: "CHERISHED MEMORIES WITH", number: 31 },
    { title: "OBITUARY", number: 32 },
    { title: "MY FINAL WISHES", number: 33 }
]