import React from 'react';

const WhyOrganizerImportant = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Why this Organizer is Important?</h3>
            </div>
            <div className="content">
                <p>
                    Are all your important documents in order? Are they organized? Are they all in
                    the same convenient location? If not, do you know where they’re all located? If
                    a family member needed to know if you even have a certain document, would
                    they be able to find that document? Would a family member even know where
                    to begin to look?
                </p>
                <p>
                    The information contained in this organizer will be of untold value to both
                    you and assistance to your family and personal representatives. You will feel
                    a sense of comfort, pride, accomplishment and organization that all your
                    important and personal information is laid out in a simple and convenient
                    journal. Between you and the help of an attorney, this organizer may
                    also assist you in identifying what other documents are needed for your
                    circumstances.
                </p>
                <p>
                    If you were to become incapacitated and not be able to communicate to
                    your family and when you are no longer here, your family and personal
                    representatives will be thankful you had the foresight and thoughtfulness to
                    have your affairs in order. This will save them the stress and frustration of
                    trying to determine what important documents you do have and where they
                    are located during a devastating time.
                </p>
                <p>
                    This organizer will not determine the disposition of your property in the event
                    of your death. Only your Last Will and Testament can do that.
                </p>
                <p>
                    If you do not have a Will at the present time, you may wish to consult your
                    attorney.
                </p>
                <p>
                    Please refer to our website for additional information at www.Keystomywishes.
                    com.
                </p>
            </div>
        </div>
    );
};

export default WhyOrganizerImportant;