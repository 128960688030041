import React, { Component, useState } from "react";
import "./HelpingSection.css";
import ClarityEmailLine from "../../../assets/images/LandingPageNew/clarity_email-line.svg";
import CarbonPhone from "../../../assets/images/LandingPageNew/carbon_phone.svg";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Col, Row, Button} from 'react-bootstrap';
import Back from '../../../assets/images/Back_white.png';



class NewContactSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      email: "",
      Message: "",
      sentSuccess: false,
    };
  }

  handleSubmitContact = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { fname, email, message } = this.state;
    axios
      .post(process.env.REACT_APP_SERVER + "usergetandsendemail", {
        sfullname: fname,
        semailaddress: email,
        smessage: message,
      })
      .then((res) => {
        this.setState({
          fname: null,
          email: null,
          message: null,
          sentSuccess: true,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

   handleBack = () => {
    this.props.history.goBack();
  }

  render() {
    const {
      message,
      email,
      fname,
      isLoading,
    } = this.state;
    return (
      <>
        {this.props.isShowHeader && (
            <Row className="mx-auto text-center mobile-button back-logout-btn">
              <Col
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  className="p-2 text-start profile-left-btn d-flex btnall"
              >
                <Button onClick={this.handleBack} className="btn custom-link-btn btn-primary">
                  <img src={Back} alt="Back" className="w-5"/> Back
                </Button>
              </Col>
            </Row>
        )}
        <div className="contactUs_section">
          <div className="title text-center">
            <h2>Contact Us</h2>
          </div>
          <div className="contactUs_content">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="map_content">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d476.6881429760869!2d-118.59982335750263!3d34.178868630341725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c29ea173fd8371%3A0x56b0a4b1470948b7!2s21550%20W%20Oxnard%20St%20Suite%20%23300%2C%20Woodland%20Hills%2C%20CA%2091367%2C%20USA!5e0!3m2!1sen!2sin!4v1644304651070!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 pl-3">
                <div className="contactUs_form">
                  <form action="" onSubmit={this.handleSubmitContact}>
                    <div className="form_content">
                      <div className="d-flex tag">
                        <div className="email_tag">
                          <img src={ClarityEmailLine} alt="ClarityEmailLine" />
                          <p className="email_text m-0">Contact Us</p>
                        </div>
                        <div className="phone_tag">
                          <img src={CarbonPhone} alt="CarbonPhone" />
                          <p className="email_text m-0">
                            Call Us At: 747-774-0966
                          </p>
                        </div>
                      </div>
                      <h2 className="form_label">Leave us a Message</h2>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Full Name"
                          value={fname}
                          onChange={(e) =>
                            this.setState({ fname: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          value={email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="form-group textArea">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder="Write Your Message"
                          rows="5"
                          value={message}
                          onChange={(e) =>
                            this.setState({ message: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={!this.state.fname || !this.state.message || !this.state.email ||  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email) }>
                      {isLoading ? <CircularProgress /> : "Submit Reply"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

NewContactSection.defaultProps = {
  isShowHeader:true
};

export default NewContactSection;
