import React from 'react';
import BGHeader from "../Common/BGHeader";
import Footer from "../Home/Footer";
import Introduction from "./Introduction";
import GetInTouch from "./GetInTouch";
import Location from "./Location";

const Contact = () => {
    return (
        <div>
            <BGHeader
                title={'Helping Great People Get Organized'}
                description={'Your job is to live a stress-free life. Our job is to help you organize, protect, and optimize your family information. Accessibility, sharing, check-ins, and passing legacy.'}
            />
            <Introduction/>
            <GetInTouch/>
            <Location/>
            <Footer/>
        </div>
    );
};
    
export default Contact;