import React, {useEffect, useState} from 'react';
import {Row, Col, Modal, Tab, Form, Button, Card} from 'react-bootstrap';
import axios from 'axios';
import './changepassword.css';
import Header from '../../components/NewHeader/Header';
import {Link, useHistory} from 'react-router-dom';
import Logout from '../../components/logout/Logout';
import CircularProgress from '@material-ui/core/CircularProgress';
import genie_img from '../../assets/images/genie_image.jpg'
import Back from '../../assets/images/Back_white.png';
import SessionExpired from '../../components/SessionExpired/SessionExpired'
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {profileDetailGet} from "../myprofile/myprofile";
import DraggebleModal from "../../components/DraggebleModal";

var validpassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&^_~+-.])[A-Za-z\d!@#$%&^-_~+-.]{8,}$/;


const Changepassword = () => {
    const history = useHistory()
    const LocalStorageEmail = localStorage.getItem('email')
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [verifyPassword, setVerifyPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [NewPasswordError, setNewPasswordError] = useState("")
    const [ServerResponse, setResponseFromServer] = useState(false);
    const [TokenExpired, setTokenExpired] = useState(false);
    const [hidden, setHidden] = useState(true);
    const [hiddenConfirm1, setHiddenConfirm1] = useState(true);
    const [hiddenConfirm2, setHiddenConfirm2] = useState(true);
    const [showPasswordErrorModal, setShowPasswordErrorModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [showLegend, setShowLegend] = useState(false);

    const handleLegendDailog = () => {
        setShowLegend(true)
    };


    useEffect(() => {
        if (newPassword.length >= 8) {
            setNewPasswordError("")
        }
    }, [newPassword])

    useEffect(() => {
        if (newPassword !== '' && verifyPassword !== '') {
            if (newPassword === verifyPassword) {
                setErrorMessage("");
            }
        }
    }, [verifyPassword]);

    const submitePassowrdHandler = (e) => {
        setIsLoading(true)
        e.preventDefault();
        if (!validpassword.test(newPassword)) {
            handleAleart()
            setIsLoading(false)
        } else if (newPassword.length < 6) {
            setNewPasswordError("**Password length must be atleast 6 characters");
            setIsLoading(false)
        } else if (!validpassword.test(newPassword)) {
            setNewPasswordError("enter valid password ")
            setIsLoading(false)
        } else if (newPassword !== verifyPassword) {
            setErrorMessage("password don't match");
            setIsLoading(false)
        } else {
            setPasswordError("");
            setNewPasswordError("")
            setErrorMessage("")
            // post User change password Data
            axios.post(`${process.env.REACT_APP_SERVER}auth/changepasswordbefore`, {
                email: LocalStorageEmail,
                password: oldPassword,
                new_password: newPassword
            })
                .then(function (response) {
                    if (response.data.status) {
                        setTokenExpired(true)
                    } else {
                        setResponseFromServer(true)
                        setIsLoading(false)
                        setShow(true)
                    }
                }).catch(function (error) {
                setShow(true)
                setResponseFromServer(false)
                setIsLoading(false)
            });
        }
    };
    function onCancelHandler() {
        history.push('/maindashboard')
    }


    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
        history.push('/maindashboard')
    };

    //password
    const handleAleart = () => {
        setShows(true)
    }

    const [shows, setShows] = useState(true);

    const handleCloses = () => setShows(false);


    return (
        <div className={'changePasswordData'}>
            {TokenExpired && <SessionExpired/>}
            <Modal show={showPasswordErrorModal} onHide={() => setShowPasswordErrorModal(false)}>
                <Modal.Header closeButton style={{backgroundColor: '#0069d9', color: 'white'}}>
                    <Modal.Title>Password Rule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={'font-weight-bold'}>Your password must be at least 8 characters long.</Row>
                    <div className="model-genie">
                        <Row>It cannot begin with a number or special character</Row>
                        <Row>It must contain at least 1 upper case letter</Row>
                        <Row>It must contain at least 1 lower case letter</Row>
                        <Row>It must contain at least 1 number</Row>
                        <Row>It must contain at least 1 special character</Row>
                        <Row>Valid Special Characters are:</Row>
                        <strong className={'ml-4'}>! @ # $ % ^ - _ ~ + .</strong>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btngenie" onClick={() => setShowPasswordErrorModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show}>
                <Modal.Header closeButton style={{backgroundColor: '#0069d9', color: 'white'}}>
                    <Modal.Title>
                        {ServerResponse
                                ? <p>Done!!</p>
                                : <p>Password Not Changed!</p>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ServerResponse
                            ? <p>Your Password has been Changed SuccessFully.</p>
                            : <p>The Password you entered as Old Password is not correct.</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{backgroundColor: '#0069d9', color: 'white'}} onClick={handleClose}>
                        close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="change-password-section">
                <Header title="CHANGE PASSWORD" />
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maindashboard" className="btn hoy-to-link-btn">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <Logout/>
                    </Col>
                </Row>
                <div>
                    <img src={genie_img} alt="genie_img" className="genie ml-5" onClick={handleLegendDailog}/>
                </div>
                <div>
                    <Card className="mx-auto mt-3 mb-3">
                        <Card.Header className="pt-4 pb-4 hederpassword ">Change Password</Card.Header>
                        <Card.Body>
                            <Form
                                onSubmit={(e) => submitePassowrdHandler(e)}>
                                <Form.Group controlId="formBasicEmail" className={'position-relative'}>
                                    <Form.Label>Current Password</Form.Label>
                                    <Form.Control type={hidden ? "password" : "text"} placeholder="Current Password"
                                                  onChange={(e) => setOldPassword(e.target.value)} required/>
                                    {hidden ? (
                                        <VisibilityOffIcon
                                            className={'PasswordHandleIcon'}
                                            onClick={() => setHidden(!hidden)}
                                        />
                                    ) : (
                                        <VisibilityIcon
                                            className={'PasswordHandleIcon'}
                                            onClick={() => setHidden(!hidden)}
                                        />
                                    )}
                                    <p className="error">{passwordError}</p>
                                </Form.Group>
                                <div className="text-right">
                                    <Link to={'/new-password'}>Forgot Password?</Link>
                                </div>
                                <Form.Group controlId="formBasicPassword" className={'position-relative'}>
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control type={hiddenConfirm1 ? "password" : "text"} placeholder="New password"
                                                  onChange={(e) => setNewPassword(e.target.value)} required/>
                                    {hiddenConfirm1 ? (
                                        <VisibilityOffIcon
                                            className={'PasswordHandleIcon'}
                                            onClick={() => setHiddenConfirm1(!hiddenConfirm1)}
                                        />
                                    ) : (
                                        <VisibilityIcon
                                            className={'PasswordHandleIcon'}
                                            onClick={() => setHiddenConfirm1(!hiddenConfirm1)}
                                        />
                                    )}
                                    <ErrorOutlineIcon
                                        className={'PasswordInfoIcon'}
                                        onClick={() => setShowPasswordErrorModal(true)}
                                    />
                                    <p className="error">{NewPasswordError}</p>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword" className={'position-relative'}>
                                    <Form.Label>Validate new password</Form.Label>
                                    <Form.Control type={hiddenConfirm2 ? "password" : "text"} placeholder="Validate new password"
                                                  onChange={(e) => setVerifyPassword(e.target.value)} required/>
                                    {hiddenConfirm2 ? (
                                        <VisibilityOffIcon
                                            className={'PasswordHandleIcon'}
                                            onClick={() => setHiddenConfirm2(!hiddenConfirm2)}
                                        />
                                    ) : (
                                        <VisibilityIcon
                                            className={'PasswordHandleIcon'}
                                            onClick={() => setHiddenConfirm2(!hiddenConfirm2)}
                                        />
                                    )}
                                    <p className="error paswword-not-match">{errorMessage}</p>
                                </Form.Group>
                                <div className="text-right">
                                    <Button variant="primary" type="reset" className="cancel-btn mt-3 "
                                            onClick={() => onCancelHandler()}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" className="submit-btn mt-3 isLoading" onClick={() => submitePassowrdHandler()} >
                                        {
                                            isLoading
                                                ? <CircularProgress/>
                                                : <span>Submit</span>
                                        }
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                    {shows && (
                        <div className="modal_pass">
                            <Modal.Dialog className="pass_dialog">
                                <Modal.Header closeButton className={'modelHeader'} onHide={handleCloses}>
                                    <Modal.Title>Password Rule</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className={'font-weight-bold'}>
                                        Your password must be at least 8 characters long.
                                    </Row>
                                    <Row>It cannot begin with a number or special character</Row>
                                    <Row>It must contain at least 1 upper case letter</Row>
                                    <Row>It must contain at least 1 lower case letter</Row>
                                    <Row>It must contain at least 1 number</Row>
                                    <Row>It must contain at least 1 special character</Row>
                                    <Row>Valid Special Characters are:</Row>
                                    <strong className={'ml-4'}>! @ # $ % ^ - _ ~ + .</strong>
                                </Modal.Body>
                            </Modal.Dialog>
                        </div>
                    )}
                </div>
            </div>
            <DraggebleModal
                screen={5}
                isOpen={showLegend}
                setOpenModal={setShowLegend}
            />
        </div>
    )
}

export default Changepassword
