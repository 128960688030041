import React from 'react';
import './features.css';
import { Card, Col, Container, Row } from 'react-bootstrap';
import organizedIcon from "../assets/images/organized.svg";
import safetyIcon from "../assets/images/safety.svg";
import checkinsIcon from "../assets/images/check-Ins.svg";
import accessibilityIcon from "../assets/images/accessibility.svg";

const Features = () => {
    return (
        <Container fluid className='features_container'>
            <div className='features_content'>
                <Row className='justify-content-center mb-2 m-0'>
                    <h1 className='features_heading'>
                        This System Is All About Securing <br />
                        Your Legacy
                    </h1>
                </Row>
            </div>


            <div className='features_card_container'>
                <div className='featured_first'>
                    <Card className='features_card'>
                        <Card.Body>
                            <div className='features_img_content'>
                                <div className='features_img'>
                                    <img src={organizedIcon} alt='organizedIcon' />
                                </div>
                            </div>
                            <Card.Title>
                                <h1 className='features_title'>Getting organized</h1>
                            </Card.Title>
                            <Card.Text className='features_description'>
                                    Organize your documents in one place accessible to you at all times
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className='features_card'>
                        <Card.Body>
                            <div className='features_img_content'>
                                <div className='features_img'>
                                    <img src={safetyIcon} alt='organizedIcon' />
                                </div>
                            </div>
                            <Card.Title>
                                <h1 className='features_title'>Safe pair of hands</h1>
                            </Card.Title>
                            <Card.Text className='features_description'>
                                    With 256-bit encryption, two-factor verification,
                                    and biometric login eliminate the fear of
                                    losing documents
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='featured_second'>
                    <Card className='features_card'>
                        <Card.Body>
                            <div className='features_img_content'>
                                <div className='features_img'>
                                    <img src={checkinsIcon} alt='checkinsIcon' />
                                </div>
                            </div>
                            <Card.Title>
                                <h1 className='features_title'>Check-Ins</h1>
                            </Card.Title>
                            <Card.Text className='features_description'>
                                    We regularly check your well-being, and if the <br />
                                    time comes seamlessly transfer your wishes <br />
                                    and legacy to your loved ones
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className='features_card'>
                        <Card.Body>
                            <div className='features_img_content'>
                                <div className='features_img'>
                                    <img src={accessibilityIcon} alt='accessibilityIcon' />
                                </div>
                            </div>
                            <Card.Title>
                                <h1 className='features_title'>Accessibility</h1>
                            </Card.Title>
                            <Card.Text className='features_description'>
                                    With top-notch security features, not even the gods' eye can <br />
                                    see your data. It is accessible only to you anywhere, <br />
                                    anytime.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Container>
    );
};

export default Features;