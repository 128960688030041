import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const BusinessOwned = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Business Owned</h3>
            </div>
            {Array(3).fill(1).map((value, index) => (
                <div className="content mt-3 mb-5" key={index}>
                    {["Business Name:", "Address:","City:","State: Zip:","Phone #:"].map((title) => (
                        <Row className="mx-0" key={title}>
                            <Col md="3 pl-0">
                                {title}
                            </Col>
                            <Col md="5 pr-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                        </Row>
                    ))}
                    <Row className="my-2 mx-0">
                        <Col md="12 pl-0">
                            Notes:
                        </Col>
                        <Col md="12 px-0">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                                multiline={true}
                                rows={5}
                            />
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    );
};

export default BusinessOwned;