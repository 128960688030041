import React, {useEffect} from 'react';
import './footer.css';
import { Col, Container, Row } from "react-bootstrap";
import keysLogoImage from '../../../assest/images/logo.png'
import instagram from '../../../assest/images/instagram.svg'
import facebook from '../../../assest/images/facebook.svg'
import twitter from '../../../assest/images/twitter.svg'
import linkedIn from '../../../assest/images/linkedIn.svg'
import {Link} from "react-router-dom";
import {wish} from "../../../../../utils/wishes.utils";

const socialLinks = [
    { SocialIcon:instagram, link: "https://www.instagram.com/keystomywishes/" },
    { SocialIcon:facebook, link: "https://www.facebook.com/profile.php?id=61558068633226" },
    { SocialIcon:twitter, link: "https://twitter.com/KeysToMyWishes" },
    { SocialIcon:linkedIn, link: "https://www.linkedin.com/company/keystomywishes/" },
];

const Footer = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const {showMenu = true} = props
    return (
        <Container className='footer_section'>
            <Row className="m-0 footer_details_section">
                <Col md={5}>
                    <div className='footer_details'>
                        <Link to='/'>
                            <img
                                src={keysLogoImage}
                                className="d-inline-block align-top"
                                alt="logo"
                            />
                        </Link>
                        <p>
                            {showMenu ? (
                                <p>
                                    Our goal is to have a one-stop storage vault that allows you to keep your information
                                    private and confidential.
                                    Giving you peace of mind that should something happen to you we will convey
                                    your important information to those you have designated to receive your information.
                                </p>
                            ) : ""}
                        </p>
                        <div className="social_icon">
                            {socialLinks.map(({link,SocialIcon})=> (
                                <img
                                    key={SocialIcon}
                                    src={SocialIcon}
                                    className="d-inline-block align-top mr-2 cursor-pointer"
                                    alt="logo"
                                    onClick={()=>wish.newTab(link)}
                                />
                            ))}
                        </div>
                    </div>
                </Col>
                {showMenu && (
                    <>
                        <Col md={2}>
                            <ul className='footer-menu-list'>
                                <li className='menu-list_item'>
                                    <Link to="/about" className='menu-list_item'>About</Link>
                                </li>
                                <li className='menu-list_item'>
                                    <Link to="/pricing" className='menu-list_item'>Pricing</Link>
                                </li>
                                <li className='menu-list_item'>
                                    <Link to="/faq" className='menu-list_item'>FAQ</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md={2}>
                            <ul className='footer-menu-list'>

                                <li className='menu-list_item'>
                                    <Link to="/support" className='menu-list_item'>Help support</Link>
                                </li>
                                <li className='menu-list_item'>
                                    <Link to="/contact" className='menu-list_item'>Contact</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <ul className='footer-menu-list'>
                                <li className='menu-list-title'>Contact Info</li>
                                <li className='menu-list_item'>21550 W Oxnard St Suite #300 Woodland Hills, CA 91367</li>
                                <li className='menu-list_item'>747-774-0966</li>
                                <li className='menu-list_item'>info@keystomywishes.com</li>
                            </ul>
                        </Col>
                    </>
                )}
            </Row>

            <div className='footer_bottom'>
                <Row className="m-0">
                    <Col md={6} >
                        <p>© 2024 <span>Keystomywishes</span> All Right Reserved</p>
                    </Col>
                    <Col Col md={6}>
                       <div className='footer_bottom_link'>
                       <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms-of-use">Terms and Conditions</Link>
                       </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default Footer;