import React from 'react';
import {TextField} from "@material-ui/core";

const IncapacitationPage = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>INCAPACITATION</h3>
            </div>
            <div className="content mt-3 mb-2">
                Should I become incapacitated, these are my wishes:
            </div>
            <div className="content mb-5">
                <div className="content mb-2">Notes:</div>
                <TextField
                    variant="filled"
                    fullWidth={true}
                    size="small"
                    multiline={true}
                    rows={40}
                />
            </div>
        </div>
    );
};

export default IncapacitationPage;