import React from 'react';
import './introduction.css';
import securityLogo from '../../../assets/images/security-logo.svg';


const Introduction = () => {
    return (
        <>
            <section className='security_section'>
                <div className='security_banner'>
                    <div>
                        <h4>Built Upon Global Security Standards</h4>
                    </div>
                    <div className='security_banner_image'>
                        <img
                            src={securityLogo}
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Introduction;