import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const Collectibles = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Collectibles</h3>
            </div>
            <div className="content mt-3 mb-5">
                <span>
                    These are usually items that have value and can be sold for a large amount of money
                    such as “baseball cards”, “stamp collection”, “vintage dolls”, etc.
                </span>
                {Array(3).fill(1).map((value, index) => (
                    <div className="content mt-3 mb-5" key={index}>
                        {["Item:", "Description:", "Cost:","Value:","Receiver:"].map((title) => (
                            <Row className="mx-0" key={title}>
                                <Col md="2 pl-0">
                                    {title}
                                </Col>
                                <Col md="5 pr-0">
                                    <TextField
                                        variant="filled"
                                        fullWidth={true}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Row className="my-2 mx-0">
                            <Col md="12 pl-0">
                                Notes:
                            </Col>
                            <Col md="12 px-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                    multiline={true}
                                    rows={5}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Collectibles;