import React from 'react';
import logo from "../../.././screens/PrivatePeople3LandingNew/assest/images/logo.png";
import LastPageCover from "../../.././screens/PrivatePeople3LandingNew/assest/images/LastPageCover.png";

const LastCoverPage = () => {
    return (
        <div className="personal_affairs_container_last_cover p-0">
            <div className="mt-3 last_page_logo_image mb-5">
                <img src={logo} alt="Logo"/>
            </div>
            <div className="mt-3 last_page_cover_image mb-5">
                <img src={LastPageCover} alt="Last Page Cover"/>
            </div>
        </div>
    );
};

export default LastCoverPage;