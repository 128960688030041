import React from 'react';
import './helping.css';
import { Button, Card, Container, Row } from 'react-bootstrap';
import dotsImage from '../assets/images/dot.svg';
import linesImage from '../assets/images/lines.svg';
import WishesVideo from '../../../assets/images/KeysToMyWishes.mp4'

const Helping = () => {
    return (
        <Container fluid className='helper_container' style={{padding: "0px"}}>
            <div className='helper__video_content'>
                <Row className='justify-content-center m-0'>
                    <Card className='helper_video_container'>
                        <Card.Body className='p-0'>
                            <div className='helper_video_header'>
                                <div className='d-flex justify-content-between'>
                                    <img
                                        src={dotsImage}
                                        className="d-inline-block align-top"
                                        alt="images"
                                    />
                                    <img
                                        src={linesImage}
                                        className="d-inline-block align-top"
                                        alt="images"
                                    />
                                </div>
                            </div>
                            <div className='helper_video_frame'>
                                <div className='helper_video'>
                                    <video width="914" height="503" controls>
                                        <source src={WishesVideo} type="video/mp4" />
                                    </video>
                                </div>

                            </div>
                        </Card.Body>
                    </Card>
                </Row>
            </div>
        </Container>
    );
};

export default Helping;