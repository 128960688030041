import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import blackImg from '../../../../assets/images/black-book.png';
import pinkImg from '../../../../assets/images/pink-book.png';
import purpleImg from '../../../../assets/images/purple-book.png';
import whiteImg from '../../../../assets/images/white-book.png';
import './estimate-planning-book.css';
import '../../common.css';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Card from 'react-bootstrap/Card';
import {useHistory} from "react-router-dom";
import {wish} from "../../../../utils/wishes.utils";
import '../../../Landing/Reviews/reviews.css';
import Slider from "react-slick";

const EstatePlanning = () => {
    const history = useHistory();

    return (
        <Container fluid className="p-0 home_book_section">
            <section className='section_container pb-3'>
                <Container>
                    <Row>
                        <Col lg={12} className='p-0 side_box_img'>
                            <div className="d-flex justify-content-center">
                                <img
                                    src={pinkImg}
                                    alt={"Organizer"}
                                    className="img-fluid inner-image"
                                    width={320}
                                    height={320}
                                />
                            </div>
                            <div className="d-flex gap-2 justify-content-center">
                                <button
                                    onClick={() => wish.newTab("https://www.amazon.com/dp/B0D7V33HFM?ref_=pe_93986420_774957520")}
                                    className="button_primary ml-3">
                                    Buy on Amazon
                                </button>
                                <button onClick={() => history.push('/products')} className="button_primary">
                                    Buy Here!
                                </button>
                            </div>
                        </Col>
                        <Col lg={12} className='side_box_section text-center'>
                            <div className='text-center'>
                                <h2 className='main_title'>Estate Planning Support</h2>
                                <p className='sub_title'>
                                    <span className='strong_title'>KeysToMyWishes</span> provides <b>all-in-one print
                                    and digital storage solutions</b> for your private
                                    information and <b>release of your last wishes</b>.
                                    Rest assured knowing your loved ones will be prepared to settle your affairs.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mb-3 quote_section'>
                <Container fluid className='quote_box_container'>
                    <div className='quote_box_content text-center'>
                        <div className='avtar-img'>
                            <span>
                                GA
                            </span>
                        </div>
                        <div className='mt-4'>
                            <h4>
                                ...I am single and somewhat of a private person. Especially as it
                                pertains to my
                                financial
                                affairs. I love the fact that I can simply make an entry into Keys with
                                the location of
                                my
                                important documents without having to give this information to someone.
                            </h4>
                        </div>
                        <h6>-Greg A., KeysToMyWishes user</h6>
                        <FormatQuoteIcon className='quote_icon'/>
                    </div>
                </Container>
            </section>
            <section className="book_section">
                <Col md={6} className='book_main'>
                    <p className='single_img'>
                        <img src={blackImg} alt={"Organizer"} height={200} width={200}/>
                    </p>
                    <p className='single_img'>
                        <img src={pinkImg} alt={"Organizer"} height={200} width={200}/>
                    </p>
                    <p className='single_img'>
                        <img src={purpleImg} alt={"Organizer"} height={200} width={200}/>
                    </p>
                    <p className='single_img'>
                        <img src={whiteImg} alt={"Organizer"} height={200} width={200}/>
                    </p>
                </Col>
            </section>
            <section className='section_container storage_section_container'>
                <Container>
                    <Row className="justify-content-center">
                        <div className='text-center'>
                            <h2 className='main_title mb-4'>KeysToMyWishes Personal Affairs Organizer</h2>
                        </div>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Storage Peace of Mind</h4>
                                    <div className='mt-4'>
                                        <p>
                                            Have a hard copy of your private information on hand, whenever you need it.
                                        </p>
                                        <p>
                                            Chuck the hesitation about uploading your private information online
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Organizational Bliss</h4>

                                    <div className='mt-4'>
                                        <p>
                                            With 25 fillable templates, know exactly what documents you need to have in
                                            order for your loved ones to settle your affairs.
                                        </p>
                                        <p>
                                            Keep everything your loved ones will need after you pass in one place.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Retirement Relaxation</h4>
                                    <div className='mt-4'>
                                        <p>
                                            Feel accomplished that you’ve done what you can to prepare your loved ones
                                            for your passing.
                                        </p>
                                        <p>
                                            Enjoy your retirement doing what you want instead of worrying about what
                                            will happen after you pass.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={12} className="d-flex justify-content-center mt-3">
                            <button
                                className="button_primary ml-2"
                                onClick={() => history.push('/products')}
                            > Buy Now
                            </button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section_container support_section_container'>
                <Container>
                    <div className='text-center'>
                        <h2 className='main_title'>Your KeysToMyWishes Digital Storage Solution</h2>
                        <p className='sub_title mb-5'>
                            Upload the location of your KTMW Personal Affairs Organizer to our highly secure <br/>
                            platform. Select the loved ones you’d like us to release this information to after
                            you’ve <br/>
                            become incapacitated or have passed. We’ll handle the rest.
                        </p>
                    </div>
                    <Row>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Online Safety</h4>
                                    <div className='mt-4'>
                                        <p>
                                            Optional two-factor authentication for a safe log-in.
                                        </p>
                                        <p>
                                            256-bit encryption for airtight digital storage. <span
                                            className='strong_title'>KeysToMyWishes</span> won’t be able to see your
                                            private information.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card className='support_card danger_card'>
                                <Card.Body>
                                    <h4>Ease of Use</h4>
                                    <div className='mt-4'>
                                        <p>
                                            A user-friendly app with simple upload of your private information.
                                        </p>
                                        <p>
                                            Biometric scan log-in. Say goodbye to remembering another password.
                                        </p>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Comfort</h4>
                                    <div className='mt-4'>
                                        <p>
                                            2GB of storage space for all your important files and private information.
                                        </p>
                                        <p>
                                            Choice in how you want to store your information.
                                            Store the location of your KTMW Personal Affairs Organizer with us,
                                            or upload your private information directly to our encrypted platform.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={12} className="d-flex justify-content-center mt-3">
                            <button
                                className="button_primary ml-2"
                                onClick={() => history.push('/products')}
                            > Buy Now
                            </button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="text-center mb-5">
                <h3>
                    Ready to make sure your last wishes are organized and secure? Shop our <br/>
                    print and digital storage solutions today.
                </h3>
            </section>
        </Container>
    );
};

export default EstatePlanning;