import React, {useEffect, useRef, useState} from "react";
import Header from "../../components/NewHeader/Header";
import {Button, Col, Modal, Row, Form} from "react-bootstrap";
import {Link, useHistory, useParams} from "react-router-dom";
import Back from "../../assets/images/Back_white.png";
import back_arrow from "../../assets/images/arrow.png"
import disable_arrow from "../../assets/images/arrowdisable.png"
import axios from "axios";
import Download_icon from "../../assets/images/download_icon.png"
import DeleteImage from "../../assets/images/DeleteIcon.png";
import moment from "moment";
import defaultProfile from "../../assets/images/default-profile-icon.jpg"

const baseURL = 'https://reactnotes.s3.us-east-2.amazonaws.com/';
const ImageDownload = () => {
    const {id} = useParams()
    const {mediaid} = useParams()
    const {s3fileName} = useParams()
    const {index} = useParams()
    const history = useHistory()
    const [showModel, setshowModel] = React.useState(false);
    const [comment, setComment] = React.useState('');
    const [AlbumData, setaAllAlbums] = React.useState('');
    const [List, setList] = React.useState([]);
    const [commentID, setCommentID] = React.useState('');
    var token = localStorage.getItem("token");
    var userId = localStorage.getItem("userid");
    // const defaultImage = 'https://reactnotes.s3.us-east-2.amazonaws.com/a_defaultbucket/Blueguysmall_2.jpg';
    const defaultImage = '';
    const handleClose = () => {
        setCommentID("");
        setshowModel(false)
        setComment("");
    }

    const handleOpen = (e) => {
        setCommentID(e.id)
        setshowModel(true)
        setComment(e.comments);
    }
    const handlesubmitComment = () => {
        var userId = localStorage.getItem("userid");
        var token = localStorage.getItem("token");
        const dataComment = {
            nUserID: userId,
            nAlbumID: id,
            nMediaID: mediaid,
            nCommentID: 0,
            nCommentorID: userId,
            sComments: comment,
        }
        axios.post(process.env.REACT_APP_SERVER + `user/albumscommentsave`, dataComment, {
            headers: {Authorization: `Bearer ${token}`},
        }).then((res) => {
            handleClose()
        }).catch((err) => {
            console.log(err);
        });
    };

    const handlesubmitCommentEdit = () => {
        var userId = localStorage.getItem("userid");
        var token = localStorage.getItem("token");
        const dataComment = {
            nUserID: userId,
            nAlbumID: id,
            nMediaID: mediaid,
            nCommentID: commentID,
            nCommentorID: userId,
            sComments: comment,
        }
        axios.post(process.env.REACT_APP_SERVER + `user/albumscommentsave`, dataComment, {
            headers: {Authorization: `Bearer ${token}`},
        }).then((res) => {
            handleClose()
        }).catch((err) => {
            console.log(err);
        });
    };

    const defaultFocus = document.querySelector(".testing-area");

    useEffect(() => {
        getcommentID();
        getDefaultData();
        AutoFocusInput();
    }, [showModel, defaultFocus]);

    const AutoFocusInput = () => {
        if (defaultFocus !== null) {
            defaultFocus.focus();   
        }
    };

    const getcommentID = () => {
        axios.get(process.env.REACT_APP_SERVER + `user/albumsgetmediacomment?nUserID=${userId}&nAlbumID=${id}&nMediaID=${mediaid}`, {
            headers: {Authorization: `Bearer ${token}`},
        }).then((res) => {
            setList(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }
    const getFilteredData = (id, mediaid) => {
        axios.get(process.env.REACT_APP_SERVER + `user/albumsgetmediacomment?nUserID=${userId}&nAlbumID=${id}&nMediaID=${mediaid}`, {
            headers: {Authorization: `Bearer ${token}`},
        }).then((res) => {
            setList(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const getDefaultData = () => {
        var token = localStorage.getItem("token");
        var userId = localStorage.getItem("userid");
        axios
            .get(process.env.REACT_APP_SERVER + `user/albumsgetbyid?nUserID=${userId}&nAlbumID=${id}`, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                setaAllAlbums(res.data)
            }).catch((err) => {
            console.log(err);
        });
    }
    const [indexValue, setindexValue] = useState(parseInt(index))
    const handleNextImage = () => {
        if (indexValue !== AlbumData.length - 1) {
            const data = indexValue + 1
            setindexValue(data)
            const item = AlbumData[data]
            history.push(`/image-download/${item?.albumid}/${item?.mediaid}/${item?.s3filename}/${data}`);
            getFilteredData(item?.albumid, item?.mediaid);
        }
    }

    const handlePreviousImage = () => {
        if (indexValue !== 0) {
            const data = indexValue - 1
            setindexValue(data)
            const item = AlbumData[data]
            history.push(`/image-download/${item?.albumid}/${item?.mediaid}/${item?.s3filename}/${data}`);
            getFilteredData(item?.albumid, item?.mediaid);
        }
    }

    const [shows, setShows] = useState(false);
    const handleCloses = () => {
        setShows(false);
    };
    const handleShows = () => {
        setShows(true);
    };

    const handleDeleteAlbumData = () => {
        var userId = localStorage.getItem("userid");
        var token = localStorage.getItem("token");
        const data = {
            nUserID: userId,
            nAlbumID: id,
            nMediaID: mediaid
        }
        axios
            .post(process.env.REACT_APP_SERVER + `user/albumMediaDelete`, data, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                handleCloses()
                history.push(`/albums-images/${id}`)
            })
            .catch((err) => {
                console.log(err);
            });
    }


    const addDefaultSrc = (ev) => {
        ev.target.src = defaultImage
    }
    const handleDownloadImage = () => {
        var token = localStorage.getItem('token');
        if (s3fileName) {
            const userId = localStorage.getItem('userid');
            axios.get(process.env.REACT_APP_SERVER + `gets3file?filename=${s3fileName}&nUserID=${userId}`,
                {headers: {Authorization: `Bearer ${token}`}})
                .then((res) => {
                    const link = document.createElement('a')
                    link.href = res.data.url
                    link.download = 'image file name here'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                })
        }
    }
    return (
        <div>
            <Header title={localStorage.getItem('albumName')}/>
          <div className="main_download_container">
              <Row className="mx-auto text-center mobile-button back-logout-btn">
                  <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      className="p-2 text-start profile-left-btn d-flex btnall">
                      <Link to={`/albums-images/${id}`} className="btn hoy-to-link-btn">
                          <img src={Back} alt="Back" className="w-5"/> Back
                      </Link>
                  </Col>
              </Row>
              <div className="d-flex mt-5 flex-wrap main_container_download">
                  <div className="d-flex  align-items-center flex-column  download_main">
                      <div className="change-image">
                          <div className='btn_header_download'>
                              <div>
                                  <img src={Download_icon} width={35} height={35} className={'download_icon'}
                                       onClick={handleDownloadImage}/>
                              </div>
                              <div className='d-flex align-items-center'>
                                  {
                                      index == 0 ?  <div className="arrow arrow-left">
                                          <img src={disable_arrow} className={'back-arrow'} style={{cursor:'not-allowed'}}/>
                                      </div>: <div className="arrow arrow-left" onClick={handlePreviousImage}>
                                          <img src={back_arrow} className={'back-arrow'}/>
                                      </div>
                                  }
                                  {
                                      index == AlbumData.length - 1 ? <div className="arrow arrow-right">
                                          <img src={disable_arrow} className={'forword-arrow'} style={{cursor:'not-allowed'}} />
                                      </div>:  <div className="arrow arrow-right" onClick={handleNextImage}>
                                          <img src={back_arrow} className={'forword-arrow'}/>
                                      </div>
                                  }
                              </div>
                              <div className='d-flex'>
                                  <img src={DeleteImage} height={35} width={35} style={{cursor:'pointer'}} onClick={handleShows}  />
                              </div>
                          </div>
                          <div className='image-main'>
                              {
                                  AlbumData.slice(-3) === 'mp4' ?
                                      <video  height="100%" width="100%" controls>
                                             <source src={AlbumData?.s3PresignedUrl} type="video/mp4"/>
                                      </video>
                                      :
                                      <img src={AlbumData[indexValue]?.s3PresignedUrl}  className="image_download pt-2 w-100"/>
                              }
                          </div>
                      </div>
                  </div>
                  <div className="comment_box">
                      <div className='d-flex comment_header pb-2'>
                          <Button onClick={()=> {setshowModel(true)}} className='add_message'>Add Message</Button>
                      </div>
                      {
                          List.map((e) => {
                              const image = e.s3PresignedUrl ? e.s3PresignedUrl : defaultProfile
                              return (
                                  <div className="comment_contain mb-2" key={e.id}>
                                      <div className="p-2">
                                          <img src={image} width={'auto'} height={30} className='mr-3' style={{borderRadius:"50%"}}/>
                                          <span style={{fontSize:'21px'}}>{e.fname}{' '}{e.lname}</span>
                                      </div>
                                      <div className="p-2 pb-0">
                                          <div>
                                              <span>{e.comments}</span>
                                          </div>
                                          <div className='d-flex justify-content-between align-items-center'>
                                              <span style={{fontSize:'15px'}}>{moment(e.created).format('MMM D ,  YYYY hh:mm a')}</span>
                                              <Button style={{background:'red',border:'0',marginRight:'5px',padding:'0 12px'}} onClick={()=> {handleOpen(e)}}>
                                                  <b>View</b>
                                              </Button>
                                          </div>
                                      </div>
                                  </div>
                              )
                          })
                      }
                  </div>
              </div>
          </div>

            <Modal show={showModel} onHide={handleClose} size={'lg'} centered>
                <Modal.Header
                    closeButton
                    className={"modelHeader"}
                    onClick={handleClose}
                >
                    {
                        commentID ?  <Modal.Title>Edit Memory</Modal.Title>
                            :<Modal.Title>Memory</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="exampleForm.ControlInput2">
                            <div>
                                <div className={"ml-3 dataMain"}>
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        className="textArea testing-area"
                                        rows={5}
                                        required
                                        onChange={(e) => setComment(e.target.value)}
                                        value={comment}
                                    />
                                </div>
                                <div className='float-right mt-2'>
                                    {
                                        commentID ? <Button className="btngenie"  onClick={handlesubmitCommentEdit}>
                                            Submit
                                        </Button> : <Button className="btngenie"  onClick={handlesubmitComment}>
                                            Submit
                                        </Button>
                                    }
                                </div>
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={shows} onHide={handleCloses}>
                <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}}>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the selected record?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloses}>
                        No
                    </Button>
                    <Button
                        style={{backgroundColor: "#7030a0"}}
                        onClick={handleDeleteAlbumData}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default ImageDownload;