import React from 'react';
import './introduction.css';
import { Col, Container, Row } from 'react-bootstrap';
import contactProfile from '../../../assets/images/contact-profile.svg'

const Introduction = () => {
    return (
        <>
              <section className='contact_introduction_section'>
                <Container>
                    <Row>
                        <Col lg={7} md={7} sm={12} className="introduction_left">
                            <h3>
                            Founded by <span>Randall Arnwine</span>
                            </h3>
                            <p>
                            A software developer, who faced difficulties finding vital documents after the devastating loss of his brother. As a result, he created Keys to My Wishes to help families locate crucial records and introduced the Keep in Touch service. Today, Keys to My Wishes is committed to offering comfort and security as supportive well-wishers.
                            </p>
                        </Col>
                        <Col lg={5} md={5} sm={12} className="">
                            <img
                                src={contactProfile}
                                className="img-fluid"
                                alt="contactProfile"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Introduction;