import React from 'react';
import frontPageCover from "./frontPageCover.png"

const FrontCoverPage = () => {
    return (
        <div className="personal_affairs_container_front_cover">
            <div className="mt-3 mb-5">
                <img src={frontPageCover} alt="Front Page Cover"/>
            </div>
            <div className="personal_affairs_body_front_cover mt-3 mb-5">
                MY PERSONAL <br/>
                AFFAIRS <br/>
                ORGANIZER
            </div>
            <div className="text-center">
                <a
                    href="https://keystomywishes.com" target="_blank"
                    className="personal_affairs_link_front_cover personal_affairs_body_front_cover mt-3 mb-5"
                >
                    WWW.KEYSTOMYWISHES.COM
                </a>
            </div>
        </div>
    );
};

export default FrontCoverPage;