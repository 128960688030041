import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Form, Button, Alert, Modal } from "react-bootstrap";
import "./maindashboard.css";
import Header from "../../components/NewHeader/Header";
import Logout from "../../components/logout/Logout";
import HomeIcon from "@material-ui/icons/Home";
import ReportIcon from "@material-ui/icons/Report";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LockIcon from "@material-ui/icons/Lock";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import defaultProfile from "../../assets/images/default-profile-icon.jpg";
import axios from "axios";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import genie_img from "../../assets/images/Genie_Sitting.svg";
import Wishes_icon from "../../assets/images/Wishes_icon.jpg";
import key_gold from "../../assets/images/key gold.png";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Close from "../../assets/images/close.png";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import {
  FormControlLabel,
  styled,
  Switch,
  TextField,
} from "@material-ui/core";
import { cleanup } from "@testing-library/react";
import Checkinbutton from "../../assets/images/checkinbuttonMain.png";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import OtpInput from "react-otp-input";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Draggable from "react-draggable";
import ic_users from "../../assets/images/ic_users.png";
import Password_icon from "../../assets/images/password_incon.png";
import profile_icon from "../../assets/images/profile_icon.png";
import Serive_icon from "../../assets/images/service_planning.png";
import memory_icon from "../../assets/images/memory_icon.jpg";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SentInvitaionModel from "../sentInvitaionModel";
import SharedAccessApprovalModal from "../../components/SharedAccessApprovalModal";
import {wish} from "../../utils/wishes.utils";
import Cookie from "../../utils/cookie";
import {priceplans} from "../MyServicePlan/MyServicePlanSelection";
import {pdfLists} from "../../utils/pdfLists";
import HandleConfirmation from "../handleConfirmation";
import {pdfLists2} from "../../utils/pdfLists2";
import CloseIcon from "@mui/icons-material/Close";
import why_this_organize from "../../PdfPages2/why_this_organize.pdf";
import my_story from "../../PdfPages2/my_story.pdf";
import AddAlertIcon from '@mui/icons-material/AddAlert';
import {reset} from "react-tabs/lib/helpers/uuid";


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0069d9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const   Maindashboard = () => {
  const classes = useStyles();
  let history = useHistory();
  const [servicePlanRequired, setServicePlanRequired] = useState(null);
  const nUserID = localStorage.getItem("userid");
  const token = localStorage.getItem("token");
  const [BannderAd, setBannerAd] = useState("");
  const [modalOpen, setOpen] = React.useState(false);
  const [s3filename, sets3filename] = useState(null);
  const [email1, setEmail1] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [ReportMessage, setReportMessage] = React.useState();
  const [number, setnumber] = useState("");
  const [quoteoftheday, setquoteoftheday] = useState("");
  const [ReportIssues, setReportIssues] = useState(false);
  const [ accessRequest, setAccessRequest] = useState(false);

  const [LegendData, setLegendData] = React.useState("");
  const [LegendDataHome, setLegendDataHome] = React.useState("");
  const [otp, setOTP] = React.useState("");
  const [showLegend, setShowLegend] = useState(false);
  const [otpisValid, setotpisValid] = React.useState(false);
  const [otpModalOpen, setotpModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // profile data
  const [ShowAccordian, setShowAccordian] = React.useState(false);
  const [nShowads, setnShowads] = React.useState();
  const [currentUser, setCurrentUser] = React.useState();
  const [letsgetstarted, setletsgetstarted] = useState("");
  const [checkInTime, setCheckInTime] = useState("");
  const [AccessCode, setAccessCode] = useState("");
  const [confirmAccessCode, setConfirmAccess] = useState("");
  const [isNewUser, setIsNewUser] = useState("");
  const [openNewUserModel, setOpenNewUserModel] = useState(false);
  const [isServicePlanSelected, setisServicePlanSelected] = useState(false);
  const [openRequiredModel, setopenRequiredModel] = useState(false);
  const [openActiveModel, setopenActiveModel] = useState(false);
  const [showInvalidCheckModal, setShowInvalidCheckModal] = useState(false);
  const [showAlreadyCheckedInModal, setShowAlreadyCheckedInModal] = useState(false);
  const [showCheckInDatesModal, setShowCheckInDatesModal] = useState(false);
  const [checkInDates, setCheckInDates] = useState([]);

  const [sharedReleaseCount, setSharedReleaseCount] = useState(null);
  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] = useState(false);
  const [shareWithGuest, setShareWithGuest] = useState(false);
  const [profileImage, setProfileImage] = useState('');

  const [isOpenSharedModal, setOpenSharedModal] = useState(false);
  const [confirmAccessData, setConfirmAccessData] = useState('');
  const [isTwoFactorChecked, setIsTwoFactorChecked] = useState("");
  const [serviceplandata, setserviceplandata] = useState(false);
  const [servicePlan, setServicePlan] = useState(null);
  const [isLoadingPlan, setIsLoadingPlan] = useState(false);
  const [accessType, setAccessType] = useState(null);
  const [openPrintPagesModel, setOpenPrintPagesModel] = useState(false);
  const [selectedValues, setSelectedValues] = useState("")
  const [getDefaultAlign, setgetDefaultAlign] = useState(parseInt(localStorage.getItem("dashboardLayout")));
  const [showViewMemoryBtn, setShowViewMemoryBtn] = useState(true);
  const [bookVersion, setBookVersion] = useState("version_2");

  const halfLength = Math.ceil(pdfLists.length / 2);
  const halfLength2 = Math.ceil(pdfLists2.length / 2);
  const firstHalf = bookVersion === "version_2" ? pdfLists2.slice(0, halfLength2) : pdfLists.slice(0, halfLength);
  const secondHalf = bookVersion === "version_2" ? pdfLists2.slice(halfLength2) : pdfLists.slice(halfLength);
  const [isOpenPersonalAffairsModal,setPersonalAffairsModal] = useState(false);
  const [isOpenModal,setIsOpenModal] = useState(false);
  const [openAsinNumberModal, setOpenAsinNumberModal] = useState(false)
  const [wrongAsinNumber, setWrongAsinNumber] = useState(false)
  const [asinValue, setAsinValue] = useState("");
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [errors, setErrors] = useState({email: '', code: ''});
  const storageKey = 'servicePlanAttempts';
  const [attempts, setAttempts] = useState( parseInt(localStorage.getItem(storageKey)) || 0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(attempts >= 3);
  const [modalMessage, setModalMessage] = useState('');
  const [openConformModel, setOpenConformModel] = useState(false);


  useEffect(() => {
    localStorage.setItem(storageKey, attempts);
  }, [attempts]);

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    if (savedEmail) {
      setEmail(savedEmail);
    } else {
      setEmail('')
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!email) newErrors.email = 'Email is required';
    if (!code) newErrors.code = 'Code is required';
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;

    try {
      const res = await axios.post(process.env.REACT_APP_SERVER + 'activateServicePlan', {
        sEmail: email,
        sCode: code
      });
      console.log(res)
      if (res.status === 200) {
        const responseData = res.data[0];
        if (responseData.retValue === 1) {
          setModalMessage('Service Plan as be Activated!');
          setAttempts(0);
          setIsButtonDisabled(false);
        } else if (responseData.retValue === 0) {
          setModalMessage('Active Plan Code be Invalid!');
          setAttempts(attempts + 1);
          if (attempts >= 2) {
            setIsButtonDisabled(true);
          }
        } else {
          console.error('Unexpected response:', responseData);
        }
      } else {
        console.error('Error activating service plan:', res.status);
        setModalMessage('Failed to activate service plan. Please try again.');
      }
    } catch (error) {
      console.error('Error activating service plan:', error);
      alert('Failed to activate service plan. Please try again.');
    }
    setOpenConformModel(true);
    handleActivePlan();
  };

    const toggleApprovalModal = () => {
    setOpenSharedModal(!isOpenSharedModal);
  }
  const handleDownload =  () => {
    if (bookVersion === "version_2") {
      wish.downloadPdfByType2(selectedValues)
    } else {
      wish.downloadPdfByType(selectedValues)
    }
  };

  const checkAsinNumber = (event  ) => {
    event.preventDefault();
    const correctASIN = "B0D7V33HFM"
    if (correctASIN === asinValue ){
      bookVersion === "version_2"? wish.downloadPdfByType2(selectedValues) : wish.downloadPdfByType(selectedValues)
      setOpenAsinNumberModal(false);
      handlePrintPages();
    } else {
      setOpenAsinNumberModal(false);
      setWrongAsinNumber(true);
    }
    setAsinValue('')
    setSelectedValues("");
  }

  const handlecloseASINModal = () => {
    setOpenAsinNumberModal(false);
  }

  const handleCloseWrongAsinNummberModal = () => {
    setWrongAsinNumber(false);
    handlePrintPages();
  }

  const handleShareWithGuestModel = () =>{
    setShareWithGuest(!shareWithGuest)
  }
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked){
      setSelectedValues(value);
    }else {
      setSelectedValues("");
    }
  }
  const handlePrintPages = () => {
    setOpenPrintPagesModel(!openPrintPagesModel);
  }

  const handleServicePlan = () => {
    setopenRequiredModel(!openRequiredModel);
  };

  const handleActivePlan = () => {
    setErrors({email: '', code: ''});
    setCode('');
    setopenActiveModel(!openActiveModel);
  };

  const handleShowActiveModel = () => {
    if (!isButtonDisabled) {
      setopenActiveModel(true);
    }
  }
  const handleShowRequiredModel = () => setopenRequiredModel(false);

  const redirectServicePlan = () => {
    handleShowRequiredModel()
    handleShowActiveModel()
    // history.push("/chooseServicePlan");
  };

  const handleRoutes = (link) => {
    if (isServicePlanSelected) {
      handleServicePlan(true);
    } else {
      history.push(link);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token") == null) {
      history.push("/");
    }
    LastcheckInTime();
    getCurrentIp();
    getServicePlan()
  }, []);

  const LastcheckInTime = () => {
    const data = { nUserID };
    axios
      .post(process.env.REACT_APP_SERVER + `user/getusercheckins`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setCheckInTime(res.data[0].checkintime);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const defaultFocus = document.querySelector(".otpInputBox input");

  useEffect(() => {
    AutoFocusInput();
    setTimeout(() => {
      AutoFocusInput();
    }, 500);
  }, [otpModalOpen, defaultFocus]);

  const AutoFocusInput = () => {
    if (defaultFocus !== null) {
      defaultFocus.focus();
    }
  };

  const handleReportIssuse = () => {
    setReportIssues(!ReportIssues);
  };
  const handlesubmitReport = (e) => {
    e.preventDefault();
    const data = {
      sfullname: fname + " " + lname,
      semailaddress: email1,
      smessage: ReportMessage,
    };
    axios
      .post(process.env.REACT_APP_SERVER + "usergetandsendemail", data)
      .then((res) => {
        handleReportIssuse();
        handlecloseMassageModel();
        setFname("");
        setLname("");
        setReportMessage("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAlignment = (newAlignment) => {
    setgetDefaultAlign(parseInt(newAlignment));
    localStorage.setItem("dashboardLayout", newAlignment);
    if (newAlignment === 0 || newAlignment === 1 || newAlignment === 2) {
      saveAlignment();
    } else {
      if (parseInt(localStorage.getItem("isAuthenticatedOTP")) === 0) {
        setSendOTP(true);
      }
    }
  };

  const saveAlignment = () => {
    const data = {
      nUserID: localStorage.getItem("userid"),
      nFlag: localStorage.getItem("dashboardLayout"),
    };
    var token = localStorage.getItem("token");

    axios.post(
      process.env.REACT_APP_SERVER + "user/usercheckinbuttonflagsave",
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const [nextcheckindate, setNextcheckindate] = useState("");
  const setLocalStorage = (key, val) => {
    localStorage.setItem(key, val);
  };

  function profileDetailGet() {
    setgetDefaultAlign("");
    var token = localStorage.getItem("token");
    return axios
      .get(process.env.REACT_APP_SERVER + "profile/detail", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setCurrentUser(res.data[0]);
        const element = res.data[0];
            const userName = element.fname + ' ' + element.lname;
            localStorage.setItem("name",userName);
            setFname(element.fname);
            setLname(element.lname);
            setEmail1(element.email1);
            sets3filename(element.s3filename);
            setnShowads(element.showads);
            setnumber(element.phone1);
            setquoteoftheday(element.quoteoftheday.replaceAll("\n", ""));
            setletsgetstarted(element.letsgetstarted);
            setstart(element.letsgetstarted);
            setIsTwoFactorChecked(element.twofactorauth);
            setNextcheckindate(element.nextcheckindate);
            setIsNewUser(element.newuser);
            setisServicePlanSelected(element.serviceplanrequired === 1);
            setShowViewMemoryBtn(element.viewMemoriesButton === 1)
            setServicePlanRequired(element.serviceplanrequired);

            if (Boolean(element.twofactorauth) && Cookie.get("isFirstTime") === "1"){
              setSendOTP(true);
            }
            getSharedReleaseSummary(element.twofactorauth);

            const userId = localStorage.getItem('userid');
            if(element.s3filename) {
              axios.get(process.env.REACT_APP_SERVER + `gets3file?filename=${element.s3filename}&nUserID=${userId}`,
                  {headers: {Authorization: `Bearer ${token}`}})
                  .then((res) => {
                      setProfileImage(res.data.url);
                  }).catch((err)=> {
                      return 'error';
                  })
            }

            const newUser = element.newuser;
            if (newUser === 1) {
              setLocalStorage("isAuthenticatedOTP", "1");
              if (localStorage.getItem("dashboardLayout") === "0") {
                setgetDefaultAlign(0);
              } else if (localStorage.getItem("dashboardLayout") === "1") {
                setgetDefaultAlign(1);
              } else {
                setgetDefaultAlign(2);
                setLocalStorage("dashboardLayout", 0);
              }
            } else if (newUser === 0 && element.twofactorauth === 1) {
              // setLocalStorage("isAuthenticatedOTP", "0");
              if (localStorage.getItem("dashboardLayout") === "0") {
                setgetDefaultAlign(0);
              } else if (localStorage.getItem("dashboardLayout") === "1") {
                setgetDefaultAlign(1);
              } else {
                setgetDefaultAlign(0);
                setLocalStorage("dashboardLayout", 0);
              }
            } else if (newUser === 0 && element.twofactorauth === 0) {
              setLocalStorage("isAuthenticatedOTP", "1");
              if (localStorage.getItem("dashboardLayout") === "0") {
                setgetDefaultAlign(0);
              } else if (localStorage.getItem("dashboardLayout") === "1") {
                setgetDefaultAlign(1);
              } else {
                setgetDefaultAlign(0);
                setLocalStorage("dashboardLayout", 0);
              }
            } else if (newUser === 0) {
              if (localStorage.getItem("isBtnClicked") === "false") {
                setLocalStorage("isAuthenticatedOTP", "1");
                setLocalStorage("dashboardLayout", 0);
                setgetDefaultAlign(0);
              }
            } else if (newUser === 1 && element.twofactorauth === 1) {
              setLocalStorage("isAuthenticatedOTP", "0");
              setLocalStorage("dashboardLayout", 0);
              setgetDefaultAlign(0);
            }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    profileDetailGet();
    handleletstart();
  }, []);

  function closeModal() {
    setOpen(false);
  }

  function OpenSidebar() {
    setOpen(true);
  }

  const handleCloseLegend = () => {
    setShowLegend(false);
  };

  const getLegendDataHome = (whichScreen = "") => {
    setHomeModel(true);
    axios
      .get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=${whichScreen}`)
      .then((res) => {
        setLegendDataHome(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [planNotSelected, setSelectPlan] = useState(false);

  const handleClosePlan = () => {
    setSelectPlan(false);
  };

  const [sIPAddress, setCurrentIp] = useState("");
  const [shows, setshows] = useState(false);


  const handleServicePlanOpen = () => {
    setopenRequiredModel(true);
  };

  const handleOpenModel = () => {
    setshows(true);
  };
  const handleCloseModel = () => {
    setshows(false);
  };

  const getCurrentIp = () => {
    axios
      .get("https://geolocation-db.com/json/")
      .then((res) => {
        setCurrentIp(res.data.IPv4);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HandleCheckIn = () => {
    if (isNewUser || isServicePlanSelected) {
      setopenRequiredModel(true);
    } else if (
      moment(checkInTime).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
    ) {
      setShowAlreadyCheckedInModal(true);
    } else if (
      moment(nextcheckindate).isSameOrBefore(moment().format("YYYY-MM-DD"))
    ) {
      const nSystemUserID = 0;
      const sMethod = "Android";
      const sNotes = "Notes";
      const data = { nUserID, nSystemUserID, sMethod, sNotes, sIPAddress };
      axios.get(process.env.REACT_APP_SERVER + "profile/detail", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((resp) => {

          if (resp.data[0].newuser === 1) {
            handleOpenModel();
          } else if (resp.data[0].serviceplanrequired === 1) {
            handleServicePlanOpen();
          } else {
            axios.post(process.env.REACT_APP_SERVER + `user/setusercheckins`,data,{
                  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
              })
              .then((res) => {})
              .catch((err) => {
                console.log(err);
              });
            history.push("/CheckInResponse");
          }
        });
    } else {
      setShowInvalidCheckModal(true);
    }
  };

  const expanded = "panel1";

  const [HomeGenieModel, setHomeModel] = useState(false);

  const handleClose = () => {
    setHomeModel(false);
    setLegendDataHome({ LegendDataHome: cleanup });
  };

  const styles = {
    bgColor: {
      backgroundColor: "#0069d9",
      color: "white",
      padding: "7px 10px",
    },
    textDecoration: {
      textDecoration: "underline",
    },
  };

  const getSharedReleaseSummary = (twoFactorEnabled = false) => {
    axios.get(process.env.REACT_APP_SERVER + `inventory/getSharedReleaseSummary?nUserID=${nUserID}&nContactID=${0}`,{
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          setSharedReleaseCount(res.data[0])
          if(res.data[0]["hasAccessRequestFlag"] === 1){
            if (isNewUser == 0){
              Cookie.save("isPendingApproval","1");
            }
            if (!localStorage.getItem("isApprovedModal") && !Boolean(twoFactorEnabled)) {
              localStorage.setItem("isApprovedModal","1");
              toggleApprovalModal();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const handleOtpModelclose = () => {
    setotpModalOpen(false);
    setSendTwoFactorOTP(false);
  };

  const handleChangeotp = (otp) => {
    setOTP(otp);
  };

  const [sendOTP, setSendOTP] = useState(false);
  const [sendTwoFactorOTP, setSendTwoFactorOTP] = useState(false);
  const [selectedType, setSelectedType] = useState("email");

  const handleCloseOTPVerificationModal = () => {
    setotpisValid(false);
    setSendOTP(false);
    setOTP("");
    localStorage.setItem("dashboardLayout", 0);
  };

  const [invitaionModel, setInvitaionModel] = useState(false);

  const handleInvitaionModel = () => {
    setInvitaionModel(!invitaionModel);
  };

  const handleSubmitOTP = () => {
    setIsSending(true);
    axios
      .post(process.env.REACT_APP_SERVER + "usersendsms", {
        sphonenumber: selectedType === "phone" ? number : "",
        smessage: "Your KeysToMyWishes verification code is",
        smessageSMS: "Your KeysToMyWishes verification code is",
        ssubject: "keys to my wishes Verification Code",
        swhoto: null,
        semailaddress: selectedType === "email" ? email1 : "",
        nUserID: localStorage.getItem("userid"),
        nContactID: localStorage.getItem("userid"),
        bEmailSent: selectedType === "email",
        bTextSent: selectedType === "phone",
        otpParam:1
      })
      .then(() => {
        setSelectedType("email");
        setIsSending(false);
        setotpModalOpen(true);
        if (sendTwoFactorOTP) {
          handleAuthApi(0);
        }
        handleCloseOTPVerificationModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [validOTP, setValid] = useState();


  const handleValidatetwofactor = () => {
    if (otp.length < 6) {
      setotpisValid(true);
      setValid("Please enter a OTP.");
    } else {
      axios
        .post(process.env.REACT_APP_SERVER + "usercontactsharedwithaccessverify",{
            nUserID: localStorage.getItem("userid"),
            nContactID: localStorage.getItem("userid"),
            otpcode: otp,
        }).then((res) => {
          profileDetailGet();
          if (res.data[0]["retValue"] === 0) {
            setValid(res.data[0]["retMsg"]);
            setotpisValid(true);
          }
          if (res.data[0]["retValue"] === 1) {
            setSendOTP(false);
            setotpModalOpen(false);
            Cookie.save("isFirstTime", "0");
            localStorage.setItem("isAuthenticatedOTP", "1");
            setgetDefaultAlign(0);
          } else {
            setotpModalOpen(true);
            setotpisValid(true);
          }
        })
        .catch((err) => {
          setotpisValid(true);
        });
    }
  };

  const handleCheckAccount = () => {
    localStorage.setItem("serviceplanrequired", 0);
  };
  const [massagemodel, setMessageModel] = useState(false);

  const handlecloseMassageModel = () => {
    setMessageModel(!massagemodel);
  };

  const seriveModelclose = () => {
    setserviceplandata(false);
  };
  const [getstartData, setgetstartData] = React.useState("");

  const [start, setstart] = useState(true);

  const closeStart = () => {
    setstart(false);
    handleApilets(0);
    setletsgetstarted(0);
    setstart(0);
  };

  const handleletstart = () => {
    axios
      .get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=30`)
      .then((res) => {
        setgetstartData(res.data[0]);
        setstart(!start);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSettingFor = (e) => {
    Cookie.save("isFirstTime", "0");
    if (isNewUser === 1) {
      setOpenNewUserModel(true);
    } else {
      if (e.target.checked) {
        setIsTwoFactorChecked(1);
        handleAuthApi(1);
      } else {
        setSendOTP(true);
        setSendTwoFactorOTP(true);
      }
    }
  };

  const handleNewUser = () => {
    setOpenNewUserModel(!openNewUserModel);
  };

  const handleletgetstart = (e) => {
    if (e.target.checked) {
      handleApilets(1);
      setletsgetstarted(1);
      setstart(1);
    } else {
      handleApilets(0);
      setletsgetstarted(0);
      setstart(0);
    }
  };

  const handleApilets = (nFlag) => {
    axios
      .post(
        process.env.REACT_APP_SERVER +
          `user/userletsgetstartedsave?nUserID=${nUserID}&nFlag=${nFlag}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAuthApi = (nFlag) => {
    axios
      .post(
         process.env.REACT_APP_SERVER +
          `user/usertwofactorauthsave?nUserID=${nUserID}&nFlag=${nFlag}`,{},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBtnClicked = (val) => {
    localStorage.setItem("isBtnClicked", val);
  };

  const profileLink = () => {
    history.push("/my-profile");
  };

  const ServiceLink = () => {
    return (!isLoadingPlan && !servicePlan) ? (window.open('/products', '_blank')) : (window.open('/products', '_blank'));
  };

  const ChangePasswordLink = () => {
    history.push("/change-password");
  };

  const handleChangeTab = (e, newValue) => {
    handleAlignment(newValue);
    localStorage.setItem("isBtnClicked", Boolean(newValue));
  };

  const handleShowCheckInDatesModal = () => {
    if (isNewUser || isServicePlanSelected) {
      setopenRequiredModel(true);
    } else {
      setShowCheckInDatesModal(true);
      axios
        .get(
          process.env.REACT_APP_SERVER +
            `user/futurecheckindates?nUserID=${nUserID}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          setCheckInDates(
            res.data.map((d) => d['DATE_FORMAT(checkindate, "%m-%d-%Y")'])
          );
        });
    }
  };

  const getReadMe = (request) => {
    return axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=61`)
        .then((res) => {
          const userName = `${fname} ${lname}`
          return res.data[0]["thetext"]
              .replace("&lt;username&gt;",request["swhoto"])
              .replace("&lt;contactname&gt;",request["scontactname"])
        }).catch((err) => {
          console.log(err)
        })
  }

  const submitAccessCode = () =>{
    const url = accessType === 'Share' ? `inventory/requestaccess` : `inventory/requestReleaseAccess`
    const params = {
      params :{
        nUserID:nUserID,
        sRequestCode:AccessCode
      },
      headers: {Authorization: `Bearer ${token}`}
    }
      axios.post(process.env.REACT_APP_SERVER + url, {},params)
          .then((res) => {
            if(res.status === 200) {
              setConfirmAccessData(res.data[0].ReturnValue);
              if(res.data[0].sendSMS === 1) {
                const request = res.data[0];
                getReadMe(request).then((response)=>{
                  axios.post(process.env.REACT_APP_SERVER + 'usersendsms', {
                    ssubject: request.ssubject,
                    nUserID: nUserID,
                    smessage: response,
                    smessageSMS: wish.removeHtml(response),
                    nContactID:0,
                    sphonenumber:request["sphonenumber"],
                    semailaddress:request["semail"],
                    swhoto:request.swhoto,
                    bEmailSent:!!request["semail"],
                    bTextSent:!!request["sphonenumber"],
                    otpParam:0
                  }).then(()=>{
                    setAccessRequest(false);
                    setConfirmAccess(true);
                  })
                })
              } else {
                setAccessRequest(false);
                setConfirmAccess(true);
              }
            }
          })
          .catch((err)=> {
            console.log(err);
          })
  }

  const logoutHandler = () => {
    let userId = localStorage.getItem('userid')
    const data = { nUserID: userId };
    var token = localStorage.getItem('token');
    axios.post(`${process.env.REACT_APP_SERVICE_PLAN_API}auth/logout`, data,
        {headers: {Authorization: `Bearer ${token}`}}
    ).then(res => {
      localStorage.clear();
      Cookie.delete("isFirstTime");
      Cookie.delete("isPendingApproval");
      history.push("/new-login")
    }).catch(e => {
        localStorage.clear();
        history.push("/new-login")
    })
  }

  const getServicePlan = () => {
    var token = localStorage.getItem('token');
    const nUserID = localStorage.getItem('userid');
    const data = {nUserID}
    setIsLoadingPlan(true);
    axios.post(process.env.REACT_APP_SERVER + `user/getuserserviceplan`, data,
        {headers: {Authorization: `Bearer ${token}`}}
    ).then((res) => {
      setIsLoadingPlan(false);
      if (res.data.status) {
      } else {
        if (res.data[0] == undefined || null) {
        } else {
          setServicePlan(res.data[0])
        }
      }
    }).catch((err) => {
      setIsLoadingPlan(false);
      console.log(err)
    })
  }

  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleCloses = () => {
    setShowConfirmation(false);
  };
  const handleopen = () => {
    setShowConfirmation(true);
  };

  return (
    <div className={"mainDashboard-Container"}>

      <Modal show={shows} onHide={handleCloseModel}>
        <Modal.Header style={{ backgroundColor: "#0069d9", color: "white" }}>
          <Modal.Title>New User Checking In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will not be allowed to use the Check In(Keep In Touch) feature
          until you have selected a Service Plan
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#0069d9" }}
            onClick={handleCloseModel}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={openConformModel} onHide={() => setOpenConformModel(false)}>
        <Modal.Header
            closeButton
            style={{ backgroundColor: "#0069d9", color: "white" }}
        >
          <Modal.Title> {modalMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngenie" onClick={() => setOpenConformModel(false)}>
              Ok
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={openRequiredModel} onHide={handleServicePlan}>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#0069d9", color: "white" }}
        >
          <Modal.Title>Activate Service Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The Free Trial Period is Over. <br/>
          You must subscribe to a Service Plan. Click the Activate Service Plan button to purchase our Break The Glass Service Plan.
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngenie" onClick={redirectServicePlan} disabled={isButtonDisabled}>
            Activate Service Plan
          </Button>
          <Button className="btngenie" onClick={handleServicePlan}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={openActiveModel} onHide={handleActivePlan}>
        <Modal.Header
            closeButton
            style={{ backgroundColor: "#0069d9", color: "white" }}
        >
          <Modal.Title>Activate Service Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='text-dark'>The following Email Address will be activated</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className='font-weight-bold'>Email:</label>
              <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  readOnly
              />
              {errors.email && <div className="text-danger">{errors.email}</div>}
            </div>
            <div className="mb-3">
              <label className='font-weight-bold'>Enter Code:</label>
              <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
              />
              {errors.code && <div className="text-danger">{errors.code}</div>}
            </div>
            <Modal.Footer>
              <Button type="submit" className="btngenie">
                Submit
              </Button>
              <Button className="btngenie" onClick={handleActivePlan}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>

      </Modal>

      <Modal show={openNewUserModel} onHide={handleNewUser}>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#0069d9", color: "white" }}
        >
          <Modal.Title>Two Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sorry, as a new user you will not be allowed to turn on Two Factor
          Authentication. Once you have selected a Service Plan you will be
          able to turn on Two Factor Authentication.
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngenie" onClick={handleNewUser}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCheckInDatesModal}
        className={"checkin-dates-modal"}
        onHide={() => setShowCheckInDatesModal(false)}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#0069d9", color: "white" }}
        >
          <Modal.Title>Checkin Dates for Year</Modal.Title>
        </Modal.Header>
        <Modal.Body>

           {priceplans.filter(e => e.price == (servicePlan?.serviceplanfreq == "M" ? servicePlan?.monthlyrate : servicePlan?.yearlyrate)).map((e,idx)=>{
             return(
                 <div className={"text-center mb-4"}>
                   <h5 className="mb-2"><b>Your current subscription is:</b></h5>
                   <h5 className="mb-2"><b>{e.CheckIn} - Service Plan</b></h5>
                   <h5 className="mb-2"><b>{e.billed}</b></h5>
                 </div>
             )
             })}

          <div className="checkin-dates-data-container">
            <h6 className="mb-2">Checkin Dates for Year :</h6>
            {checkInDates.map((date) => {
              return (
                <>
                  <div className="date-label">{date}</div>
                  <hr className={"m-0"} />
                </>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btngenie"
            onClick={() => setShowCheckInDatesModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={serviceplandata} onHide={seriveModelclose}>
        <Modal.Header
          closeButton
          className={"modelHeader"}
          onClick={seriveModelclose}
        >
          <Modal.Title>Daily Service Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"ml-3 dataMain"}>
            Time Slots indicates what time you commit to checking in each day of
            the week.
            <br />
            <br />
            Time Slots applies only to people who have chosen the Daily or 3
            Days per week Service Plan.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngenie" onClick={seriveModelclose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={massagemodel} onHide={handlecloseMassageModel}>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#0069d9", color: "white" }}
        >
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            Thank you. Your email was sent to Keys To My Wishes Support Staff
            and we will get back to you soon!
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngenie" onClick={handlecloseMassageModel}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={ReportIssues} onHide={handleReportIssuse} size={"lg"}>
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
          className={"modelcontact"}
        ></Modal.Header>
        <Modal.Body>
          <div className="contact-form-section1 contact-form-section-1">
            <div className="contact-form-section-wrapper1">
              <div className="container">
                <div className="row gx-5 contact-form-section-row justify-content-center">
                  <div className="report_model">
                    <div className="contact-form drop-shadow-2">
                      <div className="contact-form-wrapper">
                        <div className="section-heading section-heading-2 center">
                          <div className="sub-heading c-blue upper ls-1">
                            <i className="las la-envelope" />
                            <h5>Report Issues</h5>
                          </div>
                          <div className="main-heading c-dark"></div>
                        </div>

                        <form
                          autoComplete={"off"}
                          onSubmit={handlesubmitReport}
                        >
                          <div className="form-floating">
                            <TextField
                              id="form-floating-text"
                              label="Full name"
                              fullWidth
                              disabled={true}
                              value={fname + " " + lname}
                            />
                          </div>
                          <div className="form-floating">
                            <TextField
                              id="form-floating-text"
                              type={"email"}
                              label="Email address"
                              fullWidth
                              required
                              value={email1}
                              disabled={true}
                            />
                          </div>
                          <div className={"pt-2"}>
                            <Form.Label style={{ color: "black" }}>
                              Message *
                            </Form.Label>
                            <Form.Control
                              style={{ color: "black" }}
                              as="textarea"
                              className="textArea"
                              rows={4}
                              required
                              value={ReportMessage}
                              onChange={(e) => setReportMessage(e.target.value)}
                            />
                          </div>
                          <button type="submit" className="button button-3">
                            <span className="button-inner">
                              <span className="button-content">
                                <span className="text">
                                  <span>Submit</span>
                                </span>
                              </span>
                            </span>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={openPrintPagesModel} onHide={handlePrintPages} size={"md"}>
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
          className={"modelcontact"}
        >
            <Modal.Title>Print Pages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Row className="mx-0">
              <h6 className="d-block w-100">Newly Added page(s)</h6>
              {[{file: "my_story",pdf: my_story}].map((pdf)=> (
                  <label>
                    <input
                        type="checkbox"
                        value={pdf.file}
                        checked={selectedValues === pdf.file}
                        onChange={handleCheckboxChange}
                    /> My Story
                  </label>
              ))}
            </Row>

            <div className="d-flex gap-2 flex-wrap mb-3">
              <Button onClick={handleDownload}> Download Personal Affairs Organizer Pages</Button>
            </div>

            <Row>
              <Col md={6}>
                {firstHalf.slice(0, 16).map((pdf, index) => (
                    !pdf.hidden && (
                        <Col md={12} className="p-0">
                          <label>
                            <input
                                type="checkbox"
                                value={pdf.file}
                                checked={selectedValues === pdf.file}
                                onChange={handleCheckboxChange}
                            /> {pdf.title}
                          </label>
                        </Col>
                    )
                ))}
              </Col>

              <Col md={6}>
                {secondHalf.slice(-16).map((pdf, index) => (
                    !pdf.hidden && (
                        <Col md={12} className="p-0">
                          <label key={index}>
                            <input
                                type="checkbox"
                                value={pdf.file}
                                checked={selectedValues === pdf.file}
                                onChange={handleCheckboxChange}
                            /> {pdf.title}
                          </label>
                        </Col>
                    )
                ))}
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>


      {/*=======otp radio button ======*/}

      <Modal
          show={sendOTP}
          onHide={() => {
            if (Cookie.get("isFirstTime") === "1") {
              logoutHandler()
            } else {
              handleCloseOTPVerificationModal();
            }
          }}
      >
        <Modal.Header
            closeButton
            className={"modelHeader"}
        >
          <Modal.Title>Send OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"ml-3 dataMain"}>
            <RadioGroup
                value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <div className="d-flex align-items-center">
                <FormControlLabel
                  value={"email"}
                  label="Send Email"
                  control={<Radio color="primary" />}
                />
                <span>{email1}</span>
              </div>
              <div className="d-flex align-items-center">
                {number ? (
                  <FormControlLabel
                    value={"phone"}
                    label="Send Message"
                    control={<Radio color="primary" />}
                  />
                ) : (
                  <FormControlLabel
                    disabled
                    label="Send Message"
                    control={<Radio color="primary" />}
                  />
                )}
                <span>{number}</span>
              </div>
            </RadioGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!Boolean(isTwoFactorChecked) && (
              <Button
                  className="btngenie"
                  onClick={handleCloseOTPVerificationModal}
              >
                Close
              </Button>
          )}
          <Button
            className="btngenie"
            style={{ height: "40px", width: "60px" }}
            onClick={handleSubmitOTP}
          >
            {isSending ? <CircularProgress /> : <span>Send</span>}
          </Button>
        </Modal.Footer>
      </Modal>

      {/*two factor */}
      <Modal
        show={otpModalOpen}
        onHide={()=> {
          if (Cookie.get("isFirstTime") === "1") {
            logoutHandler();
          } else {
            handleOtpModelclose();
          }
        }}
        backdrop="static"
        size="lg"
        className="HowToUse-Modal-Container mainmodelheder"
      >
        <Modal.Header closeButton style={{ border: "none" }} />
        <Modal.Body>
          <div>
            <div className="card">
              {otpisValid && <Alert variant={"danger"}>{validOTP}</Alert>}
              <h5 className=" text-center mt-4">OTP Verification</h5>
              <h6 className={"text-center mt-2"}>
                {sendTwoFactorOTP
                  ? "You must enter the code in order to Turn off Two Factor Authentication"
                  : "You must enter the code in order to gain access to your Maintenance module"}
              </h6>
              {otpModalOpen && (
                  <div className="d-flex justify-content-center mt-4 otpInput optbox">
                    <OtpInput
                        shouldAutoFocus={true}
                        value={otp}
                        onChange={handleChangeotp}
                        numInputs={6}
                        isInputNum={true}
                        separator={<span>&nbsp;</span>}
                        className="otpInputBox"
                    />
                  </div>
              )}

              <div className="mt-4 text-center mb-4">
                <button
                  className="btn btn-primary px-4 validate exit_button"
                  onClick={handleValidatetwofactor}
                >
                  {" "}
                  Submit
                </button>
              </div>
              <div className={"text-center mb-2"}>
                <p className={"resentOtpBtn"} onClick={handleSubmitOTP}>
                  Resend OTP
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showInvalidCheckModal}
        onHide={() => setShowInvalidCheckModal(false)}
        className={"invalid-check-modal"}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#0069d9", color: "white" }}
        >
          <Modal.Title>Sorry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            Sorry {localStorage.getItem("name")}, your next scheduled check in
            date is:{" "}
            {nextcheckindate
              ? moment(nextcheckindate).format(" MMMM DD, YYYY")
              : ""}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btngenie"
            onClick={() => setShowInvalidCheckModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAlreadyCheckedInModal}
        onHide={() => setShowAlreadyCheckedInModal(false)}
        className={"invalid-check-modal"}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#0069d9", color: "white" }}
        >
          <Modal.Title>Sorry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {localStorage.getItem("name")}, You have already checked in today.
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btngenie"
            onClick={() => setShowAlreadyCheckedInModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAcceptConfirmationModal} onHide={() => setShowAcceptConfirmationModal(false)}>
        <Modal.Header closeButton className={"modelHeader"}>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to Accept/Decline?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowAcceptConfirmationModal(false)}>
            Decline
          </Button>
          <Button
              style={{ backgroundColor: "#0069d9" }}
              onClick={() => {}}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={shareWithGuest} onHide={handleShareWithGuestModel} className={'guest_list_model'}>
        <Modal.Header closeButton>
          <Modal.Title>Share With Accept/Decline</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'guest-list'}>
            <span className={'guest_name'}>Rand Friarson</span>
            <div>
              <Button variant="danger mr-1">Decline</Button>
              <Button style={{ backgroundColor: "#0069d9" }}>Accept</Button>
            </div>
          </div>
          <hr className={'line'}/>
          <div className={'guest-list'}>
            <span className={'guest_name'}>Rand Friarson</span>
            <div>
              <Button variant="danger mr-1">Decline</Button>
              <Button style={{ backgroundColor: "#0069d9" }}>Accept</Button>
            </div>
          </div>
          <hr className={'line'}/>
          <div className={'guest-list'}>
            <span className={'guest_name'}>Rand Friarson</span>
            <div>
              <Button variant="danger mr-1">Decline</Button>
              <Button style={{ backgroundColor: "#0069d9" }}>Accept</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className={"mainDashboard"}>
        <Modal show={false} onHide={handleCheckAccount}>
          <Modal.Header closeButton className={"modelHeader"}>
            <Modal.Title>Account Activation Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your trial period has ended and in order to continue you are
            required to choose a Subscription Plan.
            <br />
            Go to the Wishes mode and choose Service Plan in order to choose
            your plan.
            <br />
            <br />
            Otherwise you may Remove your account by sending us an email at:
            support@keystomywishes.com requesting that we remove your account
            from our system.
            <br />
            <br />
            Thank You
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btngenie"
              onClick={handleCheckAccount}
              style={{ backgroundColor: "#0069d9", color: "white" }}
            >
              {" "}
              Okay{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={HomeGenieModel}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          className="HowToUse-Modal-Container"
        >
          <Modal.Header closeButton className={"modelHeader"} >
            <div
              className="dashboard-modal-title"
              dangerouslySetInnerHTML={{ __html: LegendDataHome.theheader }}
            />
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: LegendDataHome.thetext }} />
          </Modal.Body>
        </Modal>

        <Modal show={planNotSelected} onHide={handleClosePlan}>
          <Modal.Header closeButton className={"modelHeader"}>
            <Modal.Title>Please Select Your Plan!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please Select Your Plan!</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ backgroundColor: "#0069d9" }}
              onClick={handleClosePlan}
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showLegend} onHide={handleCloseLegend}>
          <Modal.Header
            closeButton
            className={"modelHeader"}
            onClick={handleCloseLegend}
          >
            <Modal.Title>{LegendData.theheader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className={"ml-3 dataMain"}
              dangerouslySetInnerHTML={{ __html: LegendData.thetext }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btngenie" onClick={handleCloseLegend}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
        <section className="dashbord-section">
          <Header title="HOME" />
          <Row className="mx-auto  mobile-button back-logout-btn">
            {localStorage.getItem("isAuthenticatedOTP") === "1" && (
                <Col lg={6} md={6} sm={6} xs={6} className="text-start">
                  <Col lg={1} md={12} className="menu-bars">
                    <div className="mt-2 manubutton" onClick={() => OpenSidebar()}>
                      <div className="bar1" />
                      <div className="bar2" />
                      <div className="bar3" />
                      <div className="bar4" />
                      <div className="bar5" />
                      <div className="Menu-Text">Menu</div>
                    </div>
                  </Col>
                </Col>
            )}
            <Col
              lg={6}
              md={6}
              sm={6}
              xs={6}
              className="text-right main_header_right"
              style={{ paddingTop: "10px" }}
            >
              <Logout />
            </Col>
            <div className="more_options w-100 px-3">
              <Paper className={classes.root}>
                {localStorage.getItem("isAuthenticatedOTP") === "1" && (
                    <Tabs
                        value={getDefaultAlign}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        onChange={handleChangeTab}
                    >
                      {/*<Tab label="Keep In Touch" />*/}
                      <Tab label="Wishes" />
                      <Tab
                          style={{overflow:"visible"}}
                          label={
                            <div>
                              Shared
                              {/*{(*/}
                              {/* (isNewUser == 0) &&*/}
                              {/* (sharedReleaseCount && sharedReleaseCount["hasAccessRequestFlag"] === 1) &&*/}
                              {/* (Cookie.get("isPendingApproval") === "1")*/}
                              {/*) && (*/}
                              {/*    <span className="blink_me">*** Pending Request Approval</span>*/}
                              {/*)}*/}
                            </div>
                          }
                      />
                    </Tabs>
                )}
              </Paper>
            </div>
          </Row>

          {ShowAccordian && (
            <div className={"position-absolute GenieModelMainDashboard"}>
              <Accordion
                className={"mainAccordian-mainDash"}
                expanded={expanded === "panel1"}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon onClick={() => getLegendDataHome("12")} />
                  }
                  aria-controls="panel1a-content"
                  id="mainAccordianSummery-mainDash"
                  style={{ height: "48px" }}
                >
                  <span className={"w-100"}>
                    <Typography className={classes.heading}>
                      How to Use the System
                      <span
                        className={"float-right"}
                        style={styles.textDecoration}
                      >
                        Tips
                      </span>
                    </Typography>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ width: "100%" }}>
                    <div className="How-to-use-Container">
                      <p onClick={() => getLegendDataHome("13")}>
                        <img
                          src={key_gold}
                          height="18px"
                          alt={"Key Gold"}
                          className="key-gold-img"
                        />
                        <span className="How-to-use-title">Check In Only</span>
                      </p>
                      <p onClick={() => getLegendDataHome("14")}>
                        <img
                          src={key_gold}
                          height="18px"
                          alt={"Key Gold"}
                          className="key-gold-img"
                        />
                        <span className="How-to-use-title">
                          Check In & Digital Vault
                        </span>
                      </p>
                      <p onClick={() => getLegendDataHome("15")}>
                        <img
                          src={key_gold}
                          height="18px"
                          alt={"Key Gold"}
                          className="key-gold-img"
                        />
                        <span className="How-to-use-title">
                          Digital Vault Only
                        </span>
                      </p>
                      <p onClick={() => getLegendDataHome("16")}>
                        <img
                          src={key_gold}
                          height="18px"
                          alt={"Key Gold"}
                          className="key-gold-img"
                        />
                        <span className="How-to-use-title">
                          Wishes Shared With Me
                        </span>
                      </p>
                      <p onClick={() => getLegendDataHome("17")}>
                        <img
                          src={key_gold}
                          height="18px"
                          alt={"Key Gold"}
                          className="key-gold-img"
                        />
                        <span className="How-to-use-title">
                          Retrieve Wishes Released To Me
                        </span>
                      </p>
                      <p onClick={() => getLegendDataHome("18")}>
                        <img
                          src={key_gold}
                          height="18px"
                          alt={"Key Gold"}
                          className="key-gold-img"
                        />
                        <span className="How-to-use-title">
                          Break The Glass
                        </span>
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          )}

          {!!nShowads && (
            <div className="imageContainer justify-content-center text-center">
              <img src={BannderAd} alt="advertisment" />
            </div>
          )}
          {localStorage.getItem("isAuthenticatedOTP") === "1" && (
              <Row className={`mx-auto text-center dashboard-card align-items-center justify-content-center`}>
                <Col
                    xl={12}
                    className="d-flex justify-content-center mx-auto flex-wrap"
                >
                  {getDefaultAlign === 3 ? (
                      <Col xl={5} lg={4} sm={7}>
                        <div className="datacenter datacenter-container">
                          <div>
                      <span className="mb-3 d-block maindash-custom-font">
                        {localStorage.getItem("name")}, you last checked in:
                        {checkInTime
                            ? moment(checkInTime).format(" MMMM DD, YYYY  h:mm A")
                            : ""}
                      </span>

                            <span className="maindash-custom-font custom-border">
                        Your next scheduled check in date is:{" "}
                              {nextcheckindate
                                  ? moment(nextcheckindate).format(" MMMM DD, YYYY")
                                  : ""}
                      </span>
                          </div>
                          <div className="checkBotton">
                            <Row className="checkinbtn mb-5">
                              <img
                                  src={Checkinbutton}
                                  onClick={HandleCheckIn}
                                  style={{ cursor: "pointer" }}
                                  height={"190px"}
                              />
                            </Row>
                            <Row className="justify-content-center">
                              <Button
                                  onClick={handleShowCheckInDatesModal}
                                  className="viewCheckInBtn"
                              >
                                View Checkin Dates
                              </Button>
                            </Row>
                            {getDefaultAlign === 0 && (
                                <div className={"pt-5"}>
                                  <h5
                                      className={"font-weight-bold"}
                                      style={{
                                        color: "purple",
                                        fontFamily: "Monotype Corsiva",
                                        fontSize: "22px",
                                      }}
                                  >
                                    Quote of the Day
                                  </h5>
                                  <div className={"quoteContainer"}>
                                    <p
                                        className={"quoteOftheDay"}
                                        dangerouslySetInnerHTML={{
                                          __html: quoteoftheday,
                                        }}
                                    />
                                  </div>
                                </div>
                            )}
                          </div>
                        </div>
                      </Col>
                  ) : (
                      getDefaultAlign === 0 &&
                      localStorage.getItem("isAuthenticatedOTP") === "1" && (
                          <>
                            <div className="dashboard-button">
                              <div className="d-flex datacenter">
                                <Button
                                    className="Handlemycontact"
                                    onClick={() => handleRoutes("/contacts")}
                                >
                          <span className="Image_home">
                            <img src={ic_users} />
                          </span>
                                  <span className="home_btn">My Contacts</span>
                                </Button>
                                <Button
                                    className="Handlemycontact"
                                    onClick={profileLink}
                                >
                          <span className="Image_home">
                            <img src={profile_icon} />
                          </span>
                                  <span className="home_btn">My Profile</span>
                                </Button>
                              </div>
                              <div className="d-flex datacenter">
                                <Button
                                    className="Handlemycontact"
                                    onClick={() => handleRoutes("/newDashboard")}
                                >
                                  <span className="Image_home_wishes">
                                    <img src={Wishes_icon} className={"wishes_icon"}/>
                                  </span>
                                  <span className="home_btn">My Wishes</span>
                                </Button>
                                <Button
                                    className={(!isLoadingPlan && !servicePlan) ? "service_plan" : "Handlemycontact"}
                                    onClick={() => history.push(ServiceLink())}
                                >
                                  <span className="Image_home">
                                    <img src={Serive_icon} />
                                  </span>
                                  <span className="home_btn">{(!isLoadingPlan && !servicePlan) ? "Purchase Book/Service" : "Purchase Book"}</span>
                                </Button>
                              </div>
                                  <div className="d-flex datacenter">
                                    {showViewMemoryBtn && (
                                        <Button
                                            className="Handlemycontact"
                                            onClick={() => handleRoutes("/my-albums")}
                                        >
                                                <span className="Image_home">
                                                  <img src={memory_icon} />
                                                </span>
                                                <span>My Memories</span>
                                        </Button>
                                    )}

                                    <Button
                                        className="Handlemycontact"
                                        onClick={ChangePasswordLink}
                                    >
                          <span className="Image_home">
                            <img src={Password_icon} />
                          </span>
                                      <span>Change Password</span>
                                    </Button>
                                  </div>

                            </div>
                          </>
                      )
                  )}
                </Col>
                {getDefaultAlign === 1 &&
                    localStorage.getItem("isAuthenticatedOTP") === "1" && (
                        <>
                          <Row className={"mutplefamily-container px-3 m-0 mb-2"}>
                            <div
                                className={"d-flex justify-content-center flex-column"}
                            >
                              <div className="multifamily">
                                <span className="text_reb"> As a Guest there is no Cost to use this module to view Wishes from your loved ones!</span>
                                <h5 className="mt-2">WISHES SHARED/RELEASED TO YOU</h5>
                                <span className={"pb-2"}>
                                  The following two buttons relate to Wishes that are
                                  Shared or Released to you.<br/> The information comes from
                                  your loved ones who have chose to Share or Release a
                                  Wish to You.
                                </span>
                              </div>
                              <div className="mt-5 d-flex justify-content-center">

                                {sharedReleaseCount && Boolean(sharedReleaseCount.showAccessButton) && (
                                    <button
                                        className={"btn mr-2 text-nowrap btn request-access-btn btn-success"}
                                        style={{width:"fit-content"}}
                                        onClick={()=>toggleApprovalModal()}
                                    >Manage Share Access
                                    </button>
                                )}
                              </div>
                              <div className="d-flex released_content justify-content-between"
                                   style={{
                                     margin: "20px 0",
                                   }}
                              >
                                <div className="datacenter multifamily">
                                  <div className="mycontactBotton">
                                    <button
                                        className={"btn mr-2 text-nowrap btn request-access-btn btn-success mb-4"}
                                        style={{width:"fit-content"}}
                                        onClick={()=> {
                                          setAccessType("Share")
                                          setAccessRequest(true)
                                        }}
                                    >Request Share Access
                                    </button>
                                    <Row className="mycontactbtn m-0">
                                      <p className="wishes_description">The Wishes Shared With You button will display Wishes from users of the system who have elected to Share their important information with you.</p>
                                      <span>{sharedReleaseCount ? wish.text(sharedReleaseCount["SharedWithMe"]) : ""}</span>
                                      <Link to={"/Wishes-Shared-with-me"}>
                                        <Button className="Handlemycontact">
                                          <img
                                              src={genie_img}
                                              className="Image_home"
                                          />
                                          <span>Wishes <span className={'underline'}>Shared</span> With You</span>
                                        </Button>
                                      </Link>
                                    </Row>
                                  </div>
                                </div>
                                <div className="datacenter multifamily">
                                  <div className="mycontactBotton">
                                    <button
                                        className={"btn mr-2 text-nowrap btn request-access-btn btn-success mb-4"}
                                        style={{width:"fit-content"}}
                                        onClick={()=> {
                                          setAccessType("Release")
                                          setAccessRequest(true)
                                        }}
                                    >Request Release Access
                                    </button>
                                    <Row className="mycontactbtn d-flex align-items-center m-0">
                                      <p className="wishes_description">The Wishes Released to You button will only display Wishes if the user has become Incapacitated or has passed away. These Wishes would only be Released to you specifically by the user.</p>
                                      <span>{sharedReleaseCount ? wish.text(sharedReleaseCount["ReleaseToMe"]) : ""}</span>
                                      <Link to={"/Wishes-Released-to-me"}>
                                        <Button className="Handlemycontact">
                                          <img
                                              src={genie_img}
                                              alt="genie_img"
                                              className="Image_home"
                                          />
                                          <span>Wishes <span className={'underline'}>Released</span> To You</span>
                                        </Button>
                                      </Link>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </>
                    )}

                <div className="start_btn">
                  {letsgetstarted === 1 && (
                      <Draggable>
                        <div className="datacenter w-100">
                          {!!start && letsgetstarted ? (
                              <div className="w-100" style={{ cursor: "pointer" }}>
                                {!!getstartData ? (
                                    <div className={"getstard"}>
                                      <div className="lets_start">
                                        <div className="lest_model_title">
                                          <p
                                              style={{ paddingRight: "160px" }}
                                              dangerouslySetInnerHTML={{
                                                __html: getstartData.theheader,
                                              }}
                                          />
                                        </div>
                                        <div className="pr-2" onClick={closeStart}>
                                          <img src={Close} height={20} width={20} />
                                        </div>
                                      </div>
                                      <p
                                          className="lest_model_body"
                                          dangerouslySetInnerHTML={{
                                            __html: getstartData.thetext,
                                          }}
                                      />
                                    </div>
                                ) : (
                                    <p className={"getstard no_stard"}>
                                      {" "}
                                      Data not found{" "}
                                    </p>
                                )}
                              </div>
                          ) : null}
                        </div>
                      </Draggable>
                  )}
                </div>
              </Row>
          )}
          <div
            className={modalOpen ? "overlay active" : "overlay"}
            onClick={() => closeModal()}
          />
          <div className={modalOpen ? "menudrawer active" : "menudrawer"}>
            <div className="modal-title-content">
              <div className=" myprofile-left-section">
                <div className="imges d-flex align-items-center justify-content-center">
                  <img
                    src={
                      profileImage
                        ? profileImage
                        : s3filename
                        ? `https://reactnotes.s3.us-east-2.amazonaws.com/${s3filename}`
                        : defaultProfile
                    }
                    alt={"image"}
                    style={{ backgroundColor: "#fff" }}
                  />
                  <div className="names">
                    <h3 className={"text-capitalize"}>
                      {fname}&nbsp;{lname}
                    </h3>
                  </div>
                </div>
              </div>
              <h6 className={"ml-5 email_address"}>{email1}</h6>
              <div className="close-icon" onClick={() => closeModal()}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="#0069d9">
                  <g fill="none">
                    <path d="M0 0h24v24H0z" opacity="0.05" />
                    <path
                      fill="#0069d9"
                      d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="mt-2 ml-2 modal-content-list sidebar_item">
              <ul>
                <li className="mt-4">
                  <Link
                    to="/maindashboard"
                    className="d-flex align-items-center"
                  >
                    <HomeIcon className={"mr-3"} />
                    <span onClick={() => closeModal()}>Home</span>
                  </Link>
                </li>
                <li className="mt-4">
                  <Link
                    to="/privacy-policy"
                    className="d-flex align-items-center"
                  >
                    <LockIcon className={"mr-3"} /> Privacy Policy
                  </Link>
                </li>
                <li className="mt-4">
                  <Link
                    to="/terms-of-use"
                    className="d-flex align-items-center"
                  >
                    <AssignmentIcon className={"mr-3"} /> Terms & Conditions
                  </Link>
                </li>
                <li className="mt-4 ">
                  <Link to="/faq" className="d-flex align-items-center">
                    <LiveHelpIcon className={"mr-3"} /> FAQ
                  </Link>
                </li>
                <li className="mt-4">
                  <span
                      className="d-flex reportBtn align-items-center"
                      style={{fontWeight: "500",color:"#ff0f61"}}
                      onClick={handlePrintPages}
                  >
                    <LocalPrintshopIcon className={"mr-3"}/>Download Organizer Pages
                  </span>
                </li>
                <li className="mt-4">
                  <span
                    className="d-flex reportBtn align-items-center"
                    style={{ fontWeight: "500" }}
                    onClick={handleReportIssuse}
                  >
                    <ReportIcon className={"mr-3"} /> Report an Issue
                  </span>
                </li>
                <li className="mt-4 settingFor">
                  <FormControlLabel
                    style={{ fontWeight: "500", marginBottom: "0" }}
                    control={
                      <IOSSwitch sx={{ m: 1 }} onChange={handleSettingFor} />
                    }
                    checked={isTwoFactorChecked === 1}
                  />
                  <span>Two Factor Authentication</span>
                </li>
                <li className="mt-4 settingFor">
                  <FormControlLabel
                    style={{ fontWeight: "500", marginBottom: "0" }}
                    control={
                      <IOSSwitch sx={{ m: 1 }} onChange={handleletgetstart} />
                    }
                    checked={letsgetstarted === 1}
                  />
                  <span>Let's Get Started</span>
                </li>
                <li
                  className="mt-4"
                  style={{ cursor: "pointer" }}
                  onClick={handleInvitaionModel}
                >
                  <span
                    className="d-flex align-items-center"
                    style={{ fontWeight: "500" }}
                  >
                    <ScreenShareIcon className={"mr-3"} />
                    Send Invitation
                  </span>
                </li>

                {servicePlanRequired === 0 && (
                    <li className="mt-4" style={{cursor: "pointer", fontWeight: 500}} onClick={() => history.push('/changePlan')}>
                      <PublishedWithChangesIcon className={"mr-3 mt-1"}/> Change Service Plan
                    </li>
                )}

                {servicePlanRequired === 1 &&  (
                    <li className="mt-4" style={{
                      cursor: 'pointer',
                      fontWeight: 500,
                      opacity: isButtonDisabled ? 0.6 : 1
                    }} onClick={() => !isButtonDisabled && handleShowActiveModel()}>
                      <AddAlertIcon  className={"mr-3 mt-1"}/> Activate Service Plan
                    </li>
                )}

                <li className="mt-4" style={{cursor: "pointer", fontWeight: 500}} onClick={handleopen}>
                  <ExitToAppIcon className={"mr-3 mt-1"}/> Logout
                </li>
                {/*<li className="mt-4 mb-4" onClick={handleLegendDailog}>*/}
                {/*  <img src={genie_img} className="genie ml-5" />*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </section>
      </div>

      <Modal show={showConfirmation} onHide={handleCloses}>
        <Modal.Header style={{backgroundColor: "#0069d9", color: "white"}}>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to Logout?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloses}>
            No
          </Button>
          <Button
              style={{backgroundColor: "#0069d9"}}
              onClick={() => logoutHandler()}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={confirmAccessCode} onHide={() => setConfirmAccess(false)}>
        <Modal.Header style={{backgroundColor: "#0069d9", color: "white"}}>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography gutterBottom>
            {localStorage.getItem("name")}, your Access Request has been sent.
          </Typography>
          <Typography>
            {confirmAccessData}
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button
              style={{backgroundColor: "#0069d9"}}
              onClick={() => setConfirmAccess(false)}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={accessRequest} onHide={()=> {
        setAccessRequest(false)
        setAccessCode('')
      }}>
        <Modal.Header style={{backgroundColor: "#0069d9", color: "white"}}>
          <Modal.Title>Enter Access Code - {accessType} info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
              type="text"
              placeholder="Enter Access Code"
              maxLength="20"
              value={AccessCode}
              onChange={(e) => setAccessCode(e.target.value.replaceAll(" ",""))}
              required
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=> {
            setAccessRequest(false)
            setAccessCode('')
          }}>
            Cancel
          </Button>
          <Button
              style={{backgroundColor: "#0069d9"}}
              onClick={() => submitAccessCode()}
              disabled={!AccessCode}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
          show={openAsinNumberModal}
          onHide={handlecloseASINModal}
          centered
          size={'md'}
      >
            <Modal.Header style={{backgroundColor: "#0069d9", color: "white"}}>
              {/*<Modal.Title>title</Modal.Title>*/}
            </Modal.Header>

        <Modal.Body >
          <div>
             <label>Enter the ASIN : </label>
             <input className={"asin-number-input"} value={asinValue} onChange={(e) => setAsinValue(e.target.value)}/>
          </div>
        </Modal.Body>
        <form>
              <Modal.Footer >
                <Button onClick={checkAsinNumber} type="submit"  className="invite_btn">
                  okay
                </Button>
              </Modal.Footer>
        </form>
      </Modal>
      <HandleConfirmation
      isOpen={wrongAsinNumber}
      size="md"
      centered={true}
      handleClose={handleCloseWrongAsinNummberModal}
      onConfirm={handleCloseWrongAsinNummberModal}
      question={
        <div>
          You have entered the incorrect ASIN.<br/>
          Look on the back of the book for the ASIN
        </div>
      }
      yesText="Ok"
      noText=""
      title={""}
      />

      {invitaionModel && (
          <SentInvitaionModel
              Fname={fname}
              Lname={lname}
              isOpen={invitaionModel}
              handleCloseNewmodel={handleInvitaionModel}
          />
      )}

      {isOpenSharedModal && (
          <SharedAccessApprovalModal
              isOpen={isOpenSharedModal}
              toggleApprovalModal={toggleApprovalModal}
          />
      )}
      {isOpenPersonalAffairsModal && (
          <HandleConfirmation
              isOpen={isOpenPersonalAffairsModal}
              handleClose={() => setPersonalAffairsModal(false)}
              size="lg"
              centered={false}
              onConfirm={() => {
                if (!isLoadingPlan && !servicePlan) {
                  setIsOpenModal(!isOpenModal)
                } else {
                  wish.downloadPdfByType("personal_affairs_organizer")
                }
                setPersonalAffairsModal(false)
              }}
              question="Are you sure you want to download the KeysToMyWishes Personal Affairs Organizer?"
              yesText="Yes"
              noText="No"
          />
      )}
      {isOpenModal && (
          <HandleConfirmation
              isOpen={isOpenModal}
              size="lg"
              centered={false}
              handleClose={() => setIsOpenModal(false)}
              onConfirm={() => setIsOpenModal(false)}
              question={selectedValues === "" ? "Please select which page you want to download." : "You will only be allowed to download the book if you have purchased a Service Plan."}
              yesText="Ok"
              noText=""
              title={selectedValues === "" ? "Alert" : "Service Plan is Required"}
          />
      )}
    </div>
  );
};

export default Maindashboard;
