import React from 'react';
import './featureslist.css';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import featureImage from '../../../assets/images/features.svg';
import feature1Image from '../../../assets/images/feature1.svg';
import feature2Image from '../../../assets/images/feature2.svg';
import feature3Image from '../../../assets/images/feature3.svg';
import feature4Image from '../../../assets/images/feature4.svg';
import {Link} from "react-router-dom";

const FeaturesList = () => {
    return (
        <>
            <section className='features_list'>
                <Container>
                    <Row>
                        <Col lg={7} md={7} sm={12} className="feature_left pl-3">
                            <h3>
                                Get organized in just 3-minutes
                            </h3>
                            <p>
                                Sign up for a free trial, provide your emergency contact details, enter your
                                <br />
                                wishes, or upload/organize your documents, and choose your plan.
                            </p>
                            <Link to="/new-signup" type="button" className="grediant-btn btn mt-4"><span>  Start for free </span></Link>
                        </Col>
                        <Col lg={5} md={5} sm={12}>
                            <img
                                src={featureImage}
                                className="img-fluid"
                                alt="logo"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='features_list'>
                <Container>
                    <Row>
                        <Col lg={5} md={5} sm={12}>
                            <img
                                src={feature1Image}
                                className="img-fluid"
                                alt="logo"
                            />
                        </Col>
                        <Col lg={7} md={7} sm={12} className="feature_right pl-3">
                            <h3>
                                Never lose your <span>records</span>
                            </h3>
                            <p>
                                Lost the keys to your safety deposit box or forgot the cabinet where you <br />
                                placed your document? We organize all your information in one place, making it
                                accessible to you at all times.
                            </p>
                            <div className='fingertips-details-badge'>
                                <Badge pill variant="" className="pils-badge">
                                    Stocks/Bonds
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Passwords
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Medical Info
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Employment Info
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Social Security
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Pension
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Trust/Will
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Life Insurance
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Safe Deposit Box
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Real Estate
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    P.O. Box
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Bank Accounts
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Crypto Currency
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Jewelry
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Home going Services
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Letters to Loved Ones
                                </Badge>
                                <Badge pill variant="" className="pils-badge">
                                    Video to Loved Ones
                                </Badge>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='features_list'>
                <Container>
                    <Row>
                        <Col lg={7} md={7} sm={12} className="feature_left pl-3">
                            <h3>
                                Hacked or Stolen?
                            </h3>
                            <p>
                                With military-grade security features, enjoy the peace of mind of never facing leaked
                                family information or stolen documents.
                            </p>
                            <ul>
                                <li>AES 256-bit encryption</li>
                                <li>2-Factor Authentication</li>
                                <li>Biometric logins</li>
                                {/*<li>Regular third-party security audits</li>*/}
                                <li>Files encrypted at rest</li>
                            </ul>
                        </Col>
                        <Col lg={5} md={5} sm={12}>
                            <img
                                src={feature2Image}
                                className="img-fluid"
                                alt="logo"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='features_list'>
                <Container>
                    <Row>
                        <Col lg={5} md={5} sm={12}>
                            <img
                                src={feature3Image}
                                className="img-fluid"
                                alt="logo"
                            />
                        </Col>
                        <Col lg={7} md={7} sm={12} className="feature_right pl-3">
                            <h3>
                                Sharing made easy
                            </h3>
                            <p>
                                Your information is securely stored. Should you choose to share it with your family, it's just a click away.
                                The system is designed to allow you to keep your information private and confidential giving you peace of mind that should you become incapacitated or the inevitable,
                                Keys To My Wishes will share your important information to whom you have designated to receive it.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='features_list'>
                <Container>
                    <Row>
                        <Col lg={7} md={7} sm={12} className="feature_left">
                            <h3>
                                Do you want to get your life in order?
                            </h3>

                            <ul>
                                <li>Organize your documents.</li>
                                <li>Stay stress-free when data is protected.</li>
                                <li>Spare your family the hassle of finding your information
                                    when the time comes.
                                </li>
                            </ul>
                            <Link to="/new-signup" type="button" className="grediant-btn btn mt-4"><span>  Start for free </span></Link>
                        </Col>
                        <Col lg={5} md={5} sm={12} className="">
                            <img
                                src={feature4Image}
                                className="img-fluid"
                                alt="logo"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default FeaturesList;