import React, { useEffect, useState } from "react";
import "@pathofdev/react-tag-input/build/index.css";
import {Alert, Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner'
import { Link, useHistory } from "react-router-dom";
import AddItemDescriptionWizard from "./AddItemDescriptionWizard";
import AddItemNotesWizard from "./AddItemNotesWizard";
import AddItemIncapacitatedDeceasedWizard from "./AddItemIncapacitatedDeceasedWizard";
import AddItemSharedWithWizard from "./AddItemSharedWithWizard";
import AddItemReleaseToWizard from "./AddItemReleaseToWizard";
import "./AdditemDiscriptionWizard.css";
import axios from "axios";
import FileAttachment from "./FileAttachment";
import Star_icon from "../../../assets/images/start_icon.png";
import Close from "../../../assets/images/close.png";
import genie_img from "../../../assets/images/genie_image.jpg";
import ReactModal from 'react-modal-resizable-draggable';
import {EditorState} from "draft-js";
import OptionalSection from "../OptionalSection";
import PhoneNumberField from "../../PhoneNumber";
import sharedWishesIcon from "../../../assets/images/ic_users.png";
import releaseWishesIcon from "../../../assets/images/Release.png";
import shareLinkIcon from "../../../assets/images/LandingPage/shareIcon.png";
import inviteIcon from "../../../assets/images/LandingPage/invitationIcon.png";
import {phoneNumber} from "../../../utils/phone";

const AddNewItemContainer = ({
  showUseWizard = false,
  getAllItems,
  existnIncapacitated,
  existnDeceased,
  BackToClear,
                               currentUser,
                               notesData
}) => {
  const [stepModalShow, setStepModalShow] = React.useState(false);
  const [suggestionsData, setSuggestionsData] = useState([]);
  const [stepNumber, setStepNumber] = React.useState(0);
  const [itemName, setItemName] = useState("");
  const [itemNotes, setItemNotes] = useState("");
  const [nIncapacitated, setnIncapacitated] = useState(0);
  const [planNotSelected, setSelectPlan] = useState(false);
  const [nDeceased, setnDeceased] = useState(0);
  const [contactsList, setContactsList] = useState([]);
  const [isSharedReleaseGridShow, setIsSharedReleaseGridShow] = useState(1);
  const [itemID, setItemID] = React.useState("");
  const [Time, setTime] = React.useState(0);
  const [contactsData, setContactsData] = React.useState([]);

  const [Immediatly1, setImmediatly1] = useState(0);
  const [Immediatly2, setImmediatly2] = useState(0);
  const [option1, setOption1] = React.useState(Immediatly1);
  const [option2, setOption2] = React.useState(Immediatly2);
  const [nIncapDays, setNIncapDays] = useState(0);
  const [nDeceasedDays, setNDeceasedDays] = useState(0);
  const [loading,setLoading] = useState(false);
  const [OnVacation, setOnVacation] = useState(0);
  const [VacationDate, setVacationDate] = useState('');
  const [isError,setIsError] = React.useState(false);
  const [showInfoModal,setShowInfoModal] = React.useState(false);
  const [optionalData, setOptionalData] = useState({
    Xbusinessname: null,
    Xaccountnumber: null,
    Xaddress: null,
    Xcity: null,
    Xzip: null,
    Xurl: null,
    Xusername: null,
    Xpword: null,
    Xcontactinfo: null,
    Xstateid:null,
  })
  useEffect(() => {
    setOption1(Immediatly1);
  }, [Immediatly1]);

  useEffect(() => {
    setOption2(Immediatly2);
  }, [Immediatly2]);

  const handleChange1 = (event) => {
    setOption1(event.target.value);
    setImmediatly1(event.target.value);
  };

  const handleChange2 = (event) => {
    setOption2(event.target.value);
    setImmediatly2(event.target.value);
  };

  const handleDaysIncapacitateChange = (event) => {
    const { value } = event.target;
    setNIncapDays(value > 1 && value <= 30 ? value : "");
  };

  const handleDaysPassedAwayChange = (event) => {
    const { value } = event.target;
    setNDeceasedDays(value > 1 && value <= 30 ? value : "");
  };

  const handleNIncapacitatedChange = (event) => {
    setnIncapacitated(event.target.checked ? 1 : 0);
  };

  const handleNDeceasedChange = (event) => {
    setnDeceased(event.target.checked ? 1 : 0);
  };

  useEffect(() => {
    setContactsList(
      (contactsData || []).map((r) => {
        return {
          ...r,
          editsharedwith: r.sharedwith,
          editreleaseto: r.releaseto,
        };
      })
    );
  }, [contactsData]);

  useEffect(() => {
    setnDeceased(existnDeceased);
  }, [existnIncapacitated, existnDeceased]);

  function getAllSuggestions() {
    var token = localStorage.getItem("token");
    axios
      .get(process.env.REACT_APP_SERVER + `inventory/getsuggestions`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setSuggestionsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(()=>{
     if(stepNumber === 1 && notesData.length === 0 && stepModalShow){
       handleletstart(37)
       setShowLegend(true);
     }else {
       setShowLegend(false);
     }
  },[stepNumber,notesData,stepModalShow])

  function getAllContacts() {
    const data = {
      nUserID: localStorage.getItem("userid"),
    };
    var token = localStorage.getItem("token");
    axios
      .post(process.env.REACT_APP_SERVER + "contact/listall", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data =
          res && res.data && res.data[0].length
            ? res.data[0].map((r) => {
                return {
                  ...r,
                  sharedwith: 0,
                  releaseto: 0,
                };
              })
            : [];
        setContactsData(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function backStepModal() {
    setStepNumber(stepNumber - 1);
  }

  function closeStepModal() {
    setItemName("");
    setItemNotes("");
    setIsSharedReleaseGridShow(1);
    setContactsList(
      contactsData?.map((r) => {
        return {
          ...r,
          editsharedwith: r?.sharedwith,
          editreleaseto: r?.releaseto,
        };
      })
    );
    setnIncapacitated(existnIncapacitated);
    setnDeceased(existnDeceased);
    setStepNumber(1);
    setItemID("");
    setStepModalShow(false);
      getAllItems();
  }

  const handleIsSharedReleaseGridChange = (event) => {
    setIsSharedReleaseGridShow(event.target.value);
    if (Number(event.target.value) === 0) {
      setContactsList(
        contactsData.map((r) => {
          return {
            ...r,
            editsharedwith: r.sharedwith,
            editreleaseto: r.releaseto,
          };
        })
      );
    }
  };

  const [checkedId,setCheckedId] = useState("")

  const submitNewItem = (e) => {
    e.preventDefault();
    if (stepNumber === 1 || stepNumber === 2) {
      saveNewItemDetails();
      setStepNumber(stepNumber + 1);
    } else if (stepNumber === 3) {
      saveNewItemDetails();
      setStepNumber(stepNumber + 1);
    } else if (stepNumber === 4) {
      if (Number(isSharedReleaseGridShow) === 1) {
        if (
          contactsList.some(
            (sharedRelease) =>
              Number(sharedRelease.editsharedwith) !==
              Number(sharedRelease.sharedwith)
          )
        ) {
          const allContactIds = contactsList
            .filter(
              (fContact) =>
                Number(fContact.editsharedwith) !== Number(fContact.sharedwith)
            )
            .map((c) => c.contactid)
            .join(",")
            .toString();
          assignExistingSharedToReleasedTo(itemID, allContactIds, "SharedWith");
        }
      }
      setStepNumber(stepNumber + 1);
    } else if (stepNumber === 5) {
      if (
        contactsList.some(
          (sharedRelease) =>
            Number(sharedRelease.editreleaseto) !==
            Number(sharedRelease.releaseto)
        )
      ) {
        const allContactIds = contactsList
          .filter(
            (fContact) =>
              Number(fContact.editreleaseto) !== Number(fContact.releaseto)
          )
          .map((c) => c.contactid)
          .join(",")
          .toString();
        assignExistingSharedToReleasedTo(itemID, allContactIds, "ReleaseTo");
      }
      setStepNumber(stepNumber + 1);
    } else if (stepNumber === 6) {
      setLoading(true)
      if (files && files.length) {
        return files.map((f) => {
          var token = localStorage.getItem("token");
          var saveFiledata = new FormData();
          saveFiledata.append("nUserID", localStorage.getItem("userid"));
          saveFiledata.append("nInvItemID", itemID);
          saveFiledata.append("imgfile", f);

          return axios
            .post(process.env.REACT_APP_SERVER + "uploads3file", saveFiledata, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              var data = new FormData();
              data.append("nUserID", localStorage.getItem("userid"));
              data.append("nInvItemID", Number(itemID));
              data.append("sFileName", f.name);
              data.append("s3FileName", res.data.filename);
              data.append("nFileSize", f.size);

              axios.post(process.env.REACT_APP_SERVER + "inventory/addmedia", data,{headers: { Authorization: `Bearer ${token}` }})
                .then((res) => {
                  setStepModalShow(false);
                  setLoading(false)
                  addNewItems();
                }).catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            })
            .catch((err) => {
              console.log(err);
              setLoading(false)
            });
        });
      } else {
        setStepModalShow(false);
        setLoading(false);
        addNewItems();
      }
    }
  };

  function saveNewItemDetails() {
    var token = localStorage.getItem("token");
    const fieldValues = {...optionalData,Xstateid:parseInt(optionalData.Xstateid)}

    const data = {
      nUserID: localStorage.getItem("userid"),
      sItemName: itemName,
      sItemNotes: itemNotes,
      nIsShared: 0,
      nIsTimeRelease: 0,
      nIncapDays: Immediatly1 === "after" ? nIncapDays : Immediatly1,
      nDeceasedDays: Immediatly2 === "after" ? nDeceasedDays : Immediatly2,
      nIsDeceased: nDeceased,
      nIsIncapacitated: nIncapacitated,
      nIsOnVacation:OnVacation,
      dtVacationDate:OnVacation == 1 ? VacationDate : '',
      nTime:OnVacation == 1 ? Number(Time) : 4,
      ...(itemID ? { nID: itemID } : {}),
      ...fieldValues,
    };

    axios
      .post(process.env.REACT_APP_SERVER + "inventory/additem", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setItemID(res.data[0].ID);
        history.push(`/newDashboard/${res.data[0].ID}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function saveReleaseMyInformation() {
    var token = localStorage.getItem("token");

    axios
      .post(
        process.env.REACT_APP_SERVER + "user/incapacitateddeceasedsettings",
        {
          nUserID: localStorage.getItem("userid"),
          nIncapacitated: Immediatly1 === "after" ? nIncapDays : Immediatly1,
          nDeceased: Immediatly2 === "after" ? nDeceasedDays : Immediatly2,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  function assignExistingSharedToReleasedTo(
    nInvItemID,
    sContacts,
    sSharedOrReleaseTo
  ) {
    const data = {
      nUserID: localStorage.getItem("userid"),
      nInvItemID: nInvItemID,
      sContacts: sContacts,
      sSharedOrReleaseTo: sSharedOrReleaseTo,
    };

    var token = localStorage.getItem("token");
    axios
      .post(
        process.env.REACT_APP_SERVER + "inventory/addsharedwithreleaseto",
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        getAllItems();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [files, setFiles] = useState([]);
  const addNewItems = () => {
    setItemName("");
    setItemNotes("");
    setFiles([]);
    setIsSharedReleaseGridShow(1);
    setContactsList(
      contactsData.map((r) => {
        return {
          ...r,
          editsharedwith: r.sharedwith,
          editreleaseto: r.releaseto,
        };
      })
    );
    setnIncapacitated(existnIncapacitated);
    setnDeceased(existnDeceased);
    setStepNumber(1);
    setStepModalShow(false);
    setItemID("");
    getAllItems();
  };


  function isBackButtonDisabled() {
    if (stepNumber === 1) {
      return itemName.length === 0;
    } else if (stepNumber === 2) {
      return false;
    } else if (stepNumber === 3) {
      return false;
    } else if (stepNumber === 4) {
      return false;
    } else if (stepNumber === 5) {
      return false;
    } else if (stepNumber === 6){
      return loading;
    }
  }
  const nisICE =1
  function isSaveButtonDisabled() {
    if (stepNumber === 1) {
      return itemName.length === 0;
    } else if (stepNumber === 2) {
      return false;
    } else if (stepNumber === 3) {
      return  ((OnVacation === 1 && !VacationDate) || (OnVacation === 1 && !Time) || isError);
    } else if (stepNumber === 4) {
      return false;
    } else if (stepNumber === 5) {
      return false;
    } else if (stepNumber === 6){
      return loading;
    }
  }

  const history = useHistory();

  const CreateNewWish = () => {
    history.push("/newAddNewItem");
    BackToClear();
  };

  const [data, setdata] = useState({
    fName: "",
    lName: "",
    nisICE: 0,
    sEmail:"",
    sPhoneNumber: "",
    nOrderOfPriority: "",
    strErrMsg: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [readMe, setReadMe] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen)
    setPhoneNumber("")
  }
  const handleSaveContact = (e) =>{
    if(e.target.name == 'nisICE'){
      if(e.target.checked){
        setdata({...data,[e.target.name]:1})
      }else{
        setdata({...data,[e.target.name]:0})
      }
    }else {
      setdata({...data,[e.target.name]:e.target.value})
    }
  }

  const toggleReadme = () => {
    if(!readMe){
      handleletstart(36)
      setReadMe(!readMe)
    }else {
      setReadMe(!readMe)
    }

  }

  const openInfoModal = (id) =>{
    setShowInfoModal(true)
    handleletstart(id)
  }

  const submitData = (e) => {
    e.preventDefault()
    const token = localStorage.getItem("token");
    const nUserID = localStorage.getItem("userid");
    const list = {...data,sPhoneNumber:phoneNumber.toString(),nUserID:nUserID}
    axios.post(process.env.REACT_APP_SERVER + "contact/create",list , {
          headers: { Authorization: `Bearer ${token}` },
        }).then((res)=>{
           getAllContacts()
           toggle()
       })
  }

  const handleClosePlan = () => {
    setSelectPlan(false);
  };

  const onChangeFiles = (e) => {
    if (e.target.files && e.target.files.length) {
      setFiles((prev)=>([...prev,...e.target.files]));
    }
  };

  const [getstartData, setgetstartData] = React.useState("");
  const [showLegend, setShowLegend] = useState(false);

  const handleLegendDailog = () => {
    handleletstart(37)
    setShowLegend(true);
  };

  const handleCloseLegend = () => {
    setShowLegend(false);
  };
  const handleletstart = (id) => {
    axios
        .get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=${id}`)
        .then((res) => {
          setgetstartData(res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
  };


  const handleChangeField = (e,name) => {
    if (name === 'richText'){
      setOptionalData((oldProps)=>({
        ...oldProps,
        Xcontactinfo:e.target.value
      }))
    } else {
      setOptionalData((oldProps)=>({
        ...oldProps,
        [e.target.name]:e.target.value
      }))
    }
  }

  return (
    <>
      <ReactModal
          initWidth={400}
          initHeight={100}
          top={-143}
          left={0}
          minHeight={700}
          minWidth={400}
          onFocus={() => {}}
          className={"my-modal-custom-class"}
          onRequestClose={handleCloseLegend}
          isOpen={showLegend}>
        <h4 className="header_Contact">
          <div className="pr-2 start_icon">
            <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-top`} style={{zIndex:'999999999999'}}>
                    You can move this screen anywhere by clicking in the title bar
                  </Tooltip>
                }
            >
              <img src={Star_icon} height={25} width={25}/>
            </OverlayTrigger>
          </div>
          <div dangerouslySetInnerHTML={{ __html: getstartData?.theheader }}/>
          <div className="pr-2 close_icon" onClick={handleCloseLegend}>
            <img src={Close} height={20} width={20}/>
          </div>
        </h4>
        <div className="body">
          <div
              className={"ml-3 pt-5 dataMain"}
              dangerouslySetInnerHTML={{ __html: getstartData?.thetext }}
          />
        </div>
      </ReactModal>
      <Modal show={planNotSelected} onHide={handleClosePlan} >
        <Modal.Header closeButton className={"modelHeader"}>
          <Modal.Title>Please Select Your Plan!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please Select Your Plan!</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ backgroundColor: "#7030a0" }}
            onClick={handleClosePlan}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showInfoModal} onHide={()=>setShowInfoModal(false)} centered className={"lookups_modal "}>
        <Modal.Header className={"modelHeader"}>
          <Modal.Title><div dangerouslySetInnerHTML={{ __html: getstartData?.theheader }} className={"text-center"}/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
              className={"ml-2 pt-2 dataMain"}
              dangerouslySetInnerHTML={{ __html: getstartData?.thetext }}
          />
        </Modal.Body>
      </Modal>

      {showUseWizard ? (
        <Button
          variant="success"
          className="btn mb-1 mb-sm-0  mr-2 text-nowrap"
          onClick={() => {
            getAllContacts();
            setStepModalShow(true);
            setStepNumber(1);
            BackToClear();
          }}
          disabled={(!currentUser?.newuser && currentUser?.serviceplanrequired)}
          style={{ width: "fit-content" }}
        >
          + Add Wish
        </Button>
      ) : (
        <Button
          variant="success"
          className="btn  mr-2 mb-1 mb-sm-0 text-nowrap"
          style={{ width: "fit-content" }}
          disabled={(!currentUser?.newuser && currentUser?.serviceplanrequired)}
          onClick={CreateNewWish}>+ Add Wish</Button>
      )}
      {stepModalShow ? (
        <Modal
          className="add_items add_wishes_modal"
          keyboard={false}
          size={stepNumber === 6 ? "xl" : "lg"}
          show={stepModalShow}
          onHide={() => {closeStepModal()}}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="ShareRelease-Modal-Header">
            {stepNumber === 1 && (
              <div className="modal-title h4">  What Is Your Wish Item</div>
            )}
            {stepNumber === 2 && (
              <div className="modal-title h4">Remarks/Instructions for this Wish</div>
            )}
            {stepNumber === 3 && (
              <div className="modal-title h4">Release of Wishes</div>
            )}
            {stepNumber === 4 && (
              <div className="modal-title h4">
                Who do you Want to SHARE this Wish with?
              </div>
            )}
            {stepNumber === 5 && (
              <div className="modal-title h4">Release This Wish To?</div>
            )}
            {stepNumber === 6 && (
              <div className="modal-title h4">Attach Files to this Wish</div>
            )}
          </Modal.Header>
          <Modal.Body>
            {stepNumber === 1 && (
            <>

              <div  style={{
                display: "flex",
              }}>
                <div  style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "5px"
                }}>
                  <img src={genie_img} className="genie" onClick={handleLegendDailog} style={{marginBottom:'1rem'}}/>
                </div>

                <div
                    style={{
                      border: "1px solid #ced4da",
                      padding: "8px",
                      marginBottom: "20px",
                    }}
                >
                  <b>
                    Enter your Wish into the system. You can be as detailed as
                    you'de like or not by informing your loved ones where to find
                    your Wish items.
                    <br />
                  </b>
                  You can either click the Suggestions button to see Wish items you can use, or you can enter your own Wish item.
                </div>
              </div>

            </>
            )}
            {stepNumber === 2 && (
              <>
                <div style={{
                  border: "1px solid #ced4da",
                  padding: "8px",
                  marginBottom: "20px",
                }}>
                  <b>
                    Do you have a Story that goes with this Wish or do you want to leave Instructions for this Wish?
                  </b>
                </div>
                <div style={{
                  border: "1px solid #ced4da",
                  padding: "8px",
                  marginBottom: "20px",
                }}>
                  <b className={'text-danger'}>
                    <span>Here is Information you may want to consider leaving as instructions for this Wish:</span><br/>
                    <span>Location of item, Account #, Serial#, Purchase Date, Purchased from, Special Instructions</span>
                  </b>
                </div>
              </>
            )}
            {stepNumber === 1 ? (
              <AddItemDescriptionWizard
                itemName={itemName}
                setItemName={setItemName}
                setItemNotes={setItemNotes}
                suggestionsData={suggestionsData}
                getAllSuggestions={getAllSuggestions}
                stepNumber={stepNumber}
                optionalData={optionalData}
                setOptionalData={setOptionalData}
                handleChangeField={handleChangeField}
                isItemNameInput={true}
              />
            ) : stepNumber === 2 ? (
              <AddItemNotesWizard
                itemNotes={itemNotes}
                itemName={itemName}
                setItemNotes={setItemNotes}
                stepNumber={stepNumber}
                setItemName={setItemName}
                isItemNotesInput={true}
              />
            ) : stepNumber === 3 ? (
              <AddItemIncapacitatedDeceasedWizard
                nIncapacitated={nIncapacitated}
                setnIncapacitated={setnIncapacitated}
                nDeceased={nDeceased}
                setnDeceased={setnDeceased}
                handleNIncapacitatedChange={handleNIncapacitatedChange}
                handleNDeceasedChange={handleNDeceasedChange}
                handleChange1={handleChange1}
                handleChange2={handleChange2}
                handleDaysIncapacitateChange={handleDaysIncapacitateChange}
                handleDaysPassedAwayChange={handleDaysPassedAwayChange}
                Immediatly1={Immediatly1}
                Immediatly2={Immediatly2}
                option1={option1}
                option2={option2}
                nIncapDays={nIncapDays}
                nDeceasedDays={nDeceasedDays}
                setImmediatly1={setImmediatly1}
                setImmediatly2={setImmediatly2}
                setOption1={setOption1}
                setOption2={setOption2}
                setNIncapDays={setNIncapDays}
                setNDeceasedDays={setNDeceasedDays}
                stepNumber={stepNumber}
                OnVacation={OnVacation}
                setOnVacation={setOnVacation}
                setVacationDate={setVacationDate}
                VacationDate={VacationDate}
                setIsError={setIsError}
                isError={isError}
                handleletstart={openInfoModal}
                setTime={setTime}
                time={Time}

              />
            ) : stepNumber === 4 ? (
              <AddItemSharedWithWizard
                isSharedReleaseGridShow={isSharedReleaseGridShow}
                handleIsSharedReleaseGridChange={handleIsSharedReleaseGridChange}
                contactsList={contactsList}
                setContactsList={setContactsList}
                stepNumber={stepNumber}
                toggle={toggle}
              />
            ) : stepNumber === 5 ? (
              <AddItemReleaseToWizard
                isSharedReleaseGridShow={isSharedReleaseGridShow}
                contactsList={contactsList}
                setContactsList={setContactsList}
                stepNumber={stepNumber}
                toggle={toggle}
              />
            ) : stepNumber === 6 ? (
              <FileAttachment
                files={files}
                onChangeFiles={onChangeFiles}
                setFiles={setFiles}
                stepNumber={stepNumber}
              />
            ) : null}
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            {stepNumber === 1 ? (
              <Button
                color=""
                className="cancel_btn"
                onClick={() => {
                  setStepModalShow(false)
                  setItemNotes('')
                  setItemName("");
                }}
              >
                {"Cancel"}
              </Button>
            ) : (
              <Button
                color=""
                className="cancel_btn"
                onClick={() => backStepModal()}
                disabled={isBackButtonDisabled()}
              >
                {"Back"}
              </Button>
            )}

            <Button
              variant="primary"
              className="custom-btn"
              onClick={(e) => submitNewItem(e)}
              disabled={isSaveButtonDisabled()}
            >
              {
                stepNumber === 1 ? "Save" :
                stepNumber <= 5 ? 'Next' :
                stepNumber === 6 ? "Done" : "Submit"
              }
              {loading && <Spinner animation="border" size="sm" style={{marginLeft:"5px"}}/>}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      <Modal
          show={isOpen}
          onHide={toggle}
          centered
          size="lg"
          className="contact_person_model"
      >
        <Modal.Header
            closeButton
            className="ShareRelease-Modal-Header justify-content-center"
            style={{}}
        >
          <Modal.Title>Contact Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col md={12}>
            <Form
                className="ml-4 New-Contact-form-data"
                onSubmit={submitData}
            >
              <Form.Group>
                <Row>
                  <div className="genieReverse">
                    <img
                        src={genie_img}
                        alt="genie_img"
                        className="genie"
                        onClick={()=>toggleReadme()}
                    />
                  </div>
                </Row>
                {/*{"phoneError" && (*/}
                {/*    <Alert key="wrong-login" variant="danger">*/}
                {/*      The phone number does not appear to be valid!*/}
                {/*    </Alert>*/}
                {/*)}*/}
                <Row>
                  <div
                      className="checkbox-flex justify-content-between d-flex mt-3"
                      style={{ width: "98%", minHeight: "40px" }}
                  >
                    <div className="d-flex align-items-center checkbox-contact">
                      <input
                          type="checkbox"
                          className="Checked-box mr-2 emer_check_box"
                          name={"nisICE"}
                          onChange={handleSaveContact}
                      />
                      <label className="lable-case">Emergency Contact</label>
                    </div>
                    {data.nisICE == 1 && (
                        <div className="d-flex justify-content-end align-items-center contact-order-person w-50">
                          <OverlayTrigger
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  The order in which you want us to contact your
                                  contact person(s).
                                </Tooltip>
                              }
                          >
                            <Button className="btn-contact-lable">
                              <label>Order of Contact:</label>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  The order in which you want us to contact your
                                  contact person(s).
                                </Tooltip>
                              }
                          >
                            <Button className="btn-order-contact">
                              <Form.Control
                                  type="number"
                                  onChange={handleSaveContact}
                                  name={"nOrderOfPriority"}
                              />
                            </Button>
                          </OverlayTrigger>
                        </div>
                    )}
                  </div>
                </Row>
              </Form.Group>
              <div className="clearfix"></div>
              <Form.Group
                  className="float-none clearfix"
              >
                <Row>
                  <Col md={3} className="d-flex  align-items-center">
                    <label className="pt-2">First Name:</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                        type="text"
                        required
                        maxLength="50"
                        onChange={handleSaveContact}
                        name={"fName"}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md={3} className="d-flex  align-items-center">
                    <label className="pt-2">Last Name:</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                        type="text"
                        maxLength="50"
                        required
                        onChange={handleSaveContact}
                        name={"lName"}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md={3} className="d-flex  align-items-center">
                    <label className="pt-2">Phone:</label>
                  </Col>
                  <Col md={9}>
                    <PhoneNumberField
                        setPhoneNumber={(event) => {
                          setPhoneNumber(event)
                        }}
                        phoneNumber={phoneNumber}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md={3} className="d-flex  align-items-center">
                    <label className="pt-2">Email :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                        type="email"
                        maxLength="100"
                        onChange={handleSaveContact}
                        name={"sEmail"}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                  className="mb-0"
              >
                <Button
                    variant="primary"
                    type="submit"
                    style={{ width: "fit-content" }}
                    className="btn-lg btn-block custom-btn btn-hover mx-auto"
                > Save
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Modal.Body>
      </Modal>
      <ReactModal
          initWidth={400}
          initHeight={500}
          top={50}
          left={0}
          onFocus={() => {}}
          className={"my-modal-custom-class"}
          onRequestClose={()=>toggleReadme}
          minHeight={500}
          minWidth={400}
          isOpen={readMe}
      >
        <h4 className="header_Contact">
          <div className="pr-2 start_icon">
            <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-top`} style={{ zIndex: "999999999999" }}>
                    You can move this screen anywhere by clicking in the title bar
                  </Tooltip>
                }
            >
              <img src={Star_icon} alt="Star_icon" height={25} width={25} />
            </OverlayTrigger>
          </div>
          <div dangerouslySetInnerHTML={{ __html: getstartData?.theheader }} />
          <div className="pr-2 close_icon" onClick={()=>toggleReadme()}>
            <img src={Close} alt="Close" height={20} width={20} />
          </div>
        </h4>
        <div className="body">
          <div
              className={"ml-3 pt-5 dataMain"}
              dangerouslySetInnerHTML={{ __html: getstartData?.thetext }}
          />
        </div>
      </ReactModal>
    </>
  );
};

export default AddNewItemContainer;
