import React from 'react';
import './easysteps.css';
import {Button, Col, Container, Row} from 'react-bootstrap';
import stepImage from '../../../assets/images/steps.svg'
import {Link} from "react-router-dom";



const EasySteps = () => {
    return (
        <Container className='easy_steps_section'>
            <div className='easy_steps_details'>
                <Row className='justify-content-center m-0'>
                    <Col md={12} className='details_title'>
                        <h2>4 Easy Steps </h2>
                        <p>
                            Sign up, add emergency contacts, enter your wishes and choose a plan. Rest easy knowing that your family will be notified of your important information at the time of your choosing.
                        </p>
                    </Col>
                </Row>
                <Row className='justify-content-center m-0'>
                <div className='steps_content'>
                <img
                        src={stepImage}
                        className="d-inline-block align-top"
                        alt="step"
                    />
                </div>
                </Row>
            </div>

            <div className='easy_steps'>
                <Row className='justify-content-center m-0'>
                    <Link to="/new-signup" variant="" className='easy_steps_outlinebtn'>Get Started</Link>
                </Row>
            </div>
        </Container>
    );
};

export default EasySteps;