import React, { useState, useEffect, useContext } from 'react';
import './cart.css';
import {BooksContext} from "../NewPricing/PricingList/App";
import PaymentForm from "../NewPricing/PricingList/Payment";
import Header from "../NewPricing/Header";
import DeleteIcon from '@mui/icons-material/Delete';
import {Modal, Table} from "react-bootstrap";
import backIcon from '../../../../assets/images/back-img.png';
import blackImg from '../../../../assets/images/black-book.png';
import pinkImg from '../../../../../src/assets/images/pink-book.png';
import purpleImg from '../../../../../src/assets/images/purple-book.png';
import whiteImg from '../../../../../src/assets/images/white-book.png';
import {loadStripe} from "@stripe/stripe-js/pure";
import {Elements} from "@stripe/react-stripe-js";
import {useHistory} from "react-router-dom";
import bookBinder from "../../../../assets/images/book_binder.jpg";
import bookPdf from "../../../../assets/images/shopWeb/Pdf_Image.png";
import axios from "axios";
import {Input} from "reactstrap";

const bookImg = {
    "black":blackImg,
    "pink":pinkImg,
    "purple":purpleImg,
    "white":whiteImg,
}

const Cart = () => {
    const context = useContext(BooksContext);
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [promoCode, setPromoCode] = useState("");
    const [payloadCode, setPayloadCode] = useState("");
    const [attempts, setAttempts] = useState(0);
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(false);
    const bookShipingCharge = "2.50";
    const BinderShipingCharge = "5.61";
    const totalCartAmount = context.state.cart.reduce((total, book) => {
        let bookCost = Number(book.yearlyrate) * book.count;
        if (book.mediaType === "Book") {
            bookCost += Number(bookShipingCharge);
        } else if (book.mediaType === "Binder") {
            bookCost += Number(BinderShipingCharge);
        }
        return total + bookCost;
    }, 0).toFixed(2);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    const handlePromo = (e) => {
        e.preventDefault();
        if (isApplyButtonDisabled) return;

        axios.get(`${process.env.REACT_APP_SERVER}getPromoConfirmation?strPromo=${promoCode}`)
            .then((res) => {
                if (res.data[0]?.errMsg) {
                    setAttempts(prev => {
                        const newAttempts = prev + 1;
                        if (newAttempts >= 3) {
                            setIsApplyButtonDisabled(true);
                        }
                        return newAttempts;
                    });
                    setPromoCode("");
                } else {
                    setAttempts(0);
                }
                setShowToast(true);
                setApiData(res.data);
                setPayloadCode(promoCode);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        return () => {
            setAttempts(0);
            setIsApplyButtonDisabled(false);
        };
    }, []);

    return (
        <>
            <Header/>
            <div className="cart_Page">
                <div className="Product_Section">
                    <div className="inner-product">
                        <div>
                            <div className="text-center mt-3 custom-back-perent">
                                <h1 className="">Shopping Cart </h1>
                                <span
                                    className="custom-back-child"
                                    onClick={() => history.push("/products")}
                                >
                                    <img src={backIcon} alt="back" height={14} width={14} /> Back
                                </span>
                                {context.state.cart.length !== 0 && (
                                    <div className="custom-promocode">
                                        <div className="d-flex gap-2 w-100">
                                            <Input type="text" value={promoCode}
                                                   onChange={(e) => setPromoCode(e.target.value)}
                                                   maxLength={12}
                                                   placeholder="Enter Promo Code" />
                                            <button
                                                onClick={handlePromo}
                                                type="button"
                                                className="shop_cart button_primary"
                                                disabled={isApplyButtonDisabled}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="cart-Table">
                                <Table className="cart_list_table">
                                    <thead className="border-0">
                                    <tr>
                                        <th className="border-0 cart_prices"><span>Product Test</span></th>
                                        <th className="border-0 cart_prices"><span>Details</span></th>
                                        <th className="border-0 cart_prices"><span>Price</span></th>
                                        <th className="border-0 cart_prices"><span>Shipping cost</span></th>
                                        <th className="border-0 cart_prices"><span>Quantity</span></th>
                                        <th className="border-0">
                                            SubTotal
                                            <p className="custom_shipping">Includes Shipping</p>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {context.state.cart.length === 0 && (
                                        <tr>
                                            <td colSpan={6}>
                                                <span className="no_Data">No Data Found</span>
                                            </td>
                                        </tr>
                                    )}
                                    {context.state.cart.map((book) => {
                                        const bookShippingCharge = 2.50;
                                        const BinderShippingCharge = 5.61;
                                        let totalCartAmount = (Number(book.yearlyrate) * book.count);
                                        if (book.mediaType === "Book") {
                                            totalCartAmount = (Number(book.yearlyrate) * book.count + bookShippingCharge).toFixed(2);
                                        } else if (book.mediaType === "Binder") {
                                            totalCartAmount = (Number(book.yearlyrate) * book.count + BinderShippingCharge).toFixed(2);
                                        } else {
                                            totalCartAmount = totalCartAmount;
                                        }
                                        return (
                                            <tr key={book.id}>
                                                <td className="border-0">
                                                    <img
                                                        src={
                                                            book.mediaType === "Book"
                                                                ? bookImg[book.color]
                                                                : book.mediaType === "Binder" ? bookBinder : book.mediaType === "Interactive PDF" ? bookPdf : "/logo.png"
                                                        }
                                                        alt={book.name}
                                                        width="50%"
                                                        className="my-4"
                                                    />
                                                </td>
                                                <td className="border-0 cart_prices">
                                                    <div className="my-4">
                                                        <h5 className="mb-2">{book.name}</h5>
                                                        <p
                                                            className="d-flex justify-content-center"
                                                            style={{ gap: "10px" }}
                                                        >
                                                            {(book.color && book.mediaType !== "Yearly Service Plan") && (
                                                                <>
                                                                    Color:
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: book.color,
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            display: "block",
                                                                            boxShadow:
                                                                                "rgba(99, 99, 99, 0.5) 0 2px 8px 0",
                                                                            borderRadius: "100%",
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className="border-0 cart_prices">
                                                    <p className="font-weight-bold my-5 py-3">
                                                        ${book.yearlyrate}
                                                    </p>
                                                </td>
                                                <td className="border-0 cart_prices">
                                                    {book.mediaType === "Book" && (
                                                        <span className="font-weight-bold">{`$${bookShipingCharge}`}</span>
                                                    )}
                                                    {book.mediaType === "Binder" && (
                                                        <span className="font-weight-bold">{`$${BinderShipingCharge}`}</span>
                                                    )}
                                                </td>
                                                <td className="border-0">
                                                    <div
                                                        className="d-flex custom-count align-items-center justify-content-center px-2 my-5 py-3">
                                                        <button
                                                            className="btn border"
                                                            onClick={() => context.decrease(book.id)}
                                                        >
                                                            -
                                                        </button>
                                                        <button
                                                            className="btn border mx-2"
                                                        >
                                                            {book.count}
                                                        </button>
                                                        <button
                                                            className="btn border"
                                                            onClick={() => context.increase(book.id)}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="border-0 position-relative cart_prices">
                                                    <div className="card-body">
                                                        <span className="card-title font-weight-bold">${totalCartAmount} </span>
                                                        <button
                                                            onClick={() => context.removeFromCart(book.id)}
                                                            className="btn position-absolute Delete p-2"
                                                        >
                                                            <DeleteIcon color="error" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="mobile_cart">
                                {context.state.cart.map((book) => {
                                    const bookShippingCharge = 2.50;
                                    const BinderShippingCharge = 5.61;
                                    let totalCartAmount = (Number(book.yearlyrate) * book.count);
                                    if (book.mediaType === "Book") {
                                        totalCartAmount = (Number(book.yearlyrate) * book.count + bookShippingCharge).toFixed(2);
                                    } else if (book.mediaType === "Binder") {
                                        totalCartAmount = (Number(book.yearlyrate) * book.count + BinderShippingCharge).toFixed(2);
                                    } else {
                                        totalCartAmount = totalCartAmount;
                                    }
                                    return (
                                        <div key={book.id} className="rounded shadow-sm border p-3 mb-3">
                                            <div className="d-flex align-items-start">
                                                <div className="outer-image pr-3">
                                                    <img
                                                        src={
                                                            book.mediaType === "Book"
                                                                ? bookImg[book.color]
                                                                : book.mediaType === "Binder" ? bookBinder : book.mediaType === "Digital PDF" ? bookPdf : "/logo.png"
                                                        }
                                                        alt={book.name}
                                                        width="180px"
                                                        height="180px"
                                                    />
                                                </div>
                                                <div className="">
                                                    <h5 className="mb-2">{book.name}</h5>
                                                    <div
                                                        className="d-flex justify-content-start align-items-center"
                                                        style={{ gap: "10px" }}
                                                    >
                                                        {(book.color && book.mediaType !== "Yearly Service Plan") && (
                                                            <>
                                                                <span className="font-weight-bold">Color : </span>
                                                                <span
                                                                    style={{
                                                                        backgroundColor: book.color,
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        display: "block",
                                                                        boxShadow:
                                                                            "rgba(99, 99, 99, 0.5) 0 2px 8px 0",
                                                                        borderRadius: "100%",
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <span className="font-weight-bold">Product Price:</span> ${book.yearlyrate}
                                                    </div>
                                                    {book.mediaType === "Book" && (
                                                        <div><span className="font-weight-bold">Shipping Charge:</span> {`$${bookShipingCharge}`}</div>
                                                    )}
                                                    {book.mediaType === "Binder" && (
                                                        <div><span className="font-weight-bold">Shipping Charge:</span>{`$${BinderShippingCharge}`}</div>
                                                    )}
                                                    <div className="position-relative cart_prices">
                                                        <span className="font-weight-bold">Total Price: </span>${totalCartAmount}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex custom-count align-items-center justify-content-between mt-3">
                                                <div>
                                                    <button
                                                        className="btn border"
                                                        onClick={() => context.decrease(book.id)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        className="btn border mx-2"
                                                    >
                                                        {book.count}
                                                    </button>
                                                    <button
                                                        className="btn border"
                                                        onClick={() => context.increase(book.id)}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                                <button
                                                    onClick={() => context.removeFromCart(book.id)}
                                                    className="btn Delete p-2"
                                                >
                                                    <DeleteIcon color="error" />
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="mb-3">
                            <button
                                className="btn btn-danger"
                                onClick={() => context.EmptyCard()}
                            >
                                Clear Cart
                            </button>
                        </div>
                    </div>
                </div>
                <div className="Payment_card">
                    <Elements stripe={stripePromise}>
                        <PaymentForm totalCartAmount={totalCartAmount} promoCode={payloadCode} apiData={apiData} />
                    </Elements>
                </div>
            </div>
            <div>
                <Modal
                    centered={true}
                    show={showToast}
                    className="custom-modal"
                >
                    <Modal.Header className="border-0 p-0 mt-2 mr-3 d-flex align-items-center justify-content-end">
                        <div className="">
                            <i onClick={() => setShowToast(false)}
                               className="fas fa-times cursor-pointer text-secondary fa-x" />
                        </div>
                    </Modal.Header>
                    <Modal.Body className="d-flex px-3 py-4 align-items-center flex-column row-gap-3"
                                style={{ gap: "10px" }}>
                        {apiData[0]?.errMsg ? (
                            <i className="fas fa-times text-danger fa-3x mr-3" />
                        ) : (
                            <i className="fas fa-check-circle fa-3x text-success" />
                        )}
                        {apiData[0]?.errMsg ? (
                            <div className="sucess_text">
                                <div className="px-3">{apiData[0]?.errMsg}</div>
                            </div>
                        ) : (
                            <div className="sucess_text">
                                <div className="px-3">{`${apiData[0]?.strMsg} ${apiData[0]?.DiscountPerc * 100}%`}</div>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default Cart;