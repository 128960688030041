import React from "react";
import './FooterNewLandingPage.css';
import keysNewLogo from "../../PrivatePeople3LandingNew/assest/images/logo.png";
import {Link} from "react-router-dom";
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import {wish} from "../../../utils/wishes.utils";

const socialLinks = [
    { SocialIcon:TwitterIcon, link: "https://twitter.com/KeysToMyWishes" },
    // { SocialIcon:YouTubeIcon, link: "https://twitter.com/KeysToMyWishes" },
    { SocialIcon:InstagramIcon, link: "https://www.instagram.com/keystomywishes/" },
    { SocialIcon:FacebookIcon, link: "https://www.facebook.com/profile.php?id=61558068633226" },
];

const FooterNewLandingPage = () => {
    return (
        <div className='main_footer'>
            <div className="logo_img text-center">
                <img src={keysNewLogo} alt="keysNewLogo" width="266"/>
            </div>
            <div className="social_media_icons">
                <ul className="justify-content-center flex-wrap">
                    {socialLinks.map(({link,SocialIcon})=> (
                        <li className="social_media_items cursor-pointer" onClick={()=>wish.newTab(link)}>
                            <p><SocialIcon/></p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="footer_menu">
                <ul className="justify-content-center ">
                    <li><Link to="/About-Us">About Us</Link></li>
                    <li><Link to="/how-its-work">How it Works</Link></li>
                    <li><Link to="/terms-of-use">Terms of Service</Link></li>
                    <li><Link to="/privacy-policy">Privacy Notice</Link></li>
                    <li><Link to="/faq">FAQs</Link></li>
                </ul>
            </div>
            <div className="copyright">
                <p className="m-0 text-center">© 2023 Keystomywishes All Right Reserved</p>
            </div>
        </div>
    )
}
export default FooterNewLandingPage;