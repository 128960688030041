import React, {Component} from 'react';
import { Modal, Row} from "react-bootstrap";
import axios from 'axios';

import sf1 from "../../assets/images/sf1.svg";
import sf2 from "../../assets/images/sf2.svg";
import sf3 from "../../assets/images/sf3.svg";
import sf4 from "../../assets/images/sf4.svg";
import sf5 from "../../assets/images/sf5.svg";
import sf6 from "../../assets/images/sf6.svg";
import sf7 from "../../assets/images/sf7.svg";
import sf8 from "../../assets/images/sf8.svg";
import sf9 from "../../assets/images/sf9.svg";
import sf10 from "../../assets/images/sf10.svg";



const items = [
    {
        image : sf1,
        description:"Real-Life Representatives",
        readMe: 44
    },
    {
        image : sf2,
        description:"Multi - Factor Authentication",
        readMe: 45
    },
    {
        image : sf3,
        description:"Document Upload",
        readMe: 46
    },
    {
        image : sf4,
        description:"Emergency Cards",
        readMe: 47
    },
    {
        image : sf5,
        description:"2GB Digital Vault Storage",
        readMe: 48
    },
    {
        image : sf6,
        description:"Mobile Apps With Biometrics",
        readMe: 49
    },
    {
        image : sf7,
        description:"Break-The-Glass\n" + "Functionality",
        readMe: 50
    },
    {
        image : sf8,
        description:"Free Sharing For Guests",
        readMe: 51
    },
    {
        image : sf9,
        description:"256-Bit Encryption",
        readMe: 52
    },
    {
        image : sf10,
        description:"Keep In Touch/Check-in",
        readMe: 53
    },


]

class Standardfeatures extends Component {
    constructor(props) {
        super(props);
        this.state ={
            show : false,
            featureData: [],
        }
    }

     toggleFeatureModal = (readMe = '') =>{
         if(!this.state.show) {
             axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=${readMe}`)
                 .then((res) => {
                    this.setState({
                        featureData: res.data[0],
                    })
                 }).catch((err) => {
                 console.log(err)
             })
             this.setState({show:true});
         } else {
             this.setState({show:!this.state.show});
         }
    }

    render() {
        const { show, featureData } = this.state;
        return (
            <>
            <Modal show={show} onHide={this.toggleFeatureModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="text-center"   dangerouslySetInnerHTML={{
                            __html: featureData.theheader
                        }}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center"   dangerouslySetInnerHTML={{
                        __html: featureData.thetext
                    }}/>
                </Modal.Body>
            </Modal>
                {this.props.box ? (
            <div className="Standard-features-main">
                <div className="title text-center">
                    <h2 className="service_plan_title">KeysToMyWishes</h2>
                    <h6 className="service_plan_des">Standard Features</h6>
                </div>
                <div className="Standard-features-container">
                    <Row className="justify-content-center ">
                        {items.map((e,index)=>{
                            return(
                                <div
                                    className="Standard-features-box cursor-pointer"
                                    onClick={() => this.toggleFeatureModal(e.readMe)}
                                    style={{cursor:"pointer"}}
                                    key={index}
                                >
                                    <div className="Standard-features-inner text-center">
                                        <div className="Standard-features-icon m-auto">
                                            <img src={e.image} alt="image" />
                                        </div>
                                        <p className="Standard-features-title mb-0">{e.description}</p>
                                    </div>
                                </div>
                            )
                        })}

                    </Row>
                </div>
            </div>
                ) : (
                    // <div className="plandescription">
                    //     <h5>KTMW Standard Features</h5>
                    //     <div className="payment-Standard-Features">
                    //         {items.map((e ,index)=>{
                    //             return(
                    //                 <div key={index} className="payment-Standard-inner" onClick={() => this.toggleFeatureModal(e.readMe)} style={{cursor:"pointer"}}>
                    //                     <div><img src={e.image} /></div>
                    //                     <div className="payment-Standard-description">{e.description}</div>
                    //                 </div>
                    //             )
                    //         })}
                    //     </div>
                    // </div>
                    <div className={`Standard-features-main-3 ${this.props.className && this.props.className}`}>
                            <h6 className="service_plan_des-3 text-center">Standard Features</h6>
                        <div className="Standard-features-container-3">
                            <Row className="justify-content-center ">
                                {items.map((e)=>{
                                    return(
                                        <div className="Standard-features-box-3 cursor-pointer" onClick={() => this.toggleFeatureModal(e.readMe)} style={{cursor:"pointer"}}>
                                            <div className="Standard-features-inner text-center">
                                                <div className="Standard-features-icon-3 m-auto">
                                                    <img src={e.image} />
                                                </div>
                                                <p className="Standard-features-title-3 mb-0">{e.description}</p>
                                            </div>
                                        </div>
                                    )
                                })}

                            </Row>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default Standardfeatures;