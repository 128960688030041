import React, {useEffect, useState} from 'react';
import Header from "../../components/NewHeader/Header";
import {FormControl, FormControlLabel, InputLabel, MenuItem, RadioGroup, Select, TextField} from "@material-ui/core";
import {Card, Col, Container, Modal, Row, Form} from "react-bootstrap";
import {Radio, Button} from "@material-ui/core";
import {Link, useHistory, useParams} from "react-router-dom";
import Logout from "../../components/logout/Logout";
import './layout/MyServicePlanSelection.css'
import axios from "axios";
import SubscribePayment from "../../admin/screen/SubscribePlan/Payment/SubscribePayment";
import './layout/ChooseServicePlan.css'
import Back from '../../assets/images/Back_white.png';
import SessionExpired from '../../components/SessionExpired/SessionExpired'
import {makeStyles, withStyles} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import StarIcon from '@material-ui/icons/Star';
import ktwsLogo from "../PrivatePeople3LandingNew/assest/images/logo.png";
import Maskgroup1 from "../../assets/images/Maskgroup1.svg";
import Maskgroup2 from "../../assets/images/Maskgroup2.svg";
import Maskgroup3 from "../../assets/images/Maskgroup3.svg";
import Maskgroup4 from "../../assets/images/Maskgroup4.svg";
import Maskgroup5 from "../../assets/images/Maskgroup5.svg";
import Maskgroup6 from "../../assets/images/Maskgroup6.svg";
import breck_glass_img from "../../assets/images/breackGlassImage.png"
import rightIcon from "../../assets/images/LandingPageNew/right_icon.svg";
import Standardfeatures from "../../components/standardfeatures/standardfeatures";
import whiteColorBook from "../../assets/images/white-color-book.png"
import blackColorBook from "../../assets/images/black-color-book.png"
import pinkColorBook from "../../assets/images/pink-color-books.png"


import sf1 from "../../assets/images/sf1.svg";
import sf2 from "../../assets/images/sf2.svg";
import sf3 from "../../assets/images/sf3.svg";
import sf4 from "../../assets/images/sf4.svg";
import sf5 from "../../assets/images/sf5.svg";
import sf6 from "../../assets/images/sf6.svg";
import sf7 from "../../assets/images/sf7.svg";
import sf8 from "../../assets/images/sf8.svg";
import sf9 from "../../assets/images/sf9.svg";
import sf10 from "../../assets/images/sf10.svg";
import {MdClose} from "react-icons/md";

const priceplans = [
    {
        image: Maskgroup1,
        plan: "Starter",
        price: 4.99,
        billed: "Billed Monthly",
        CheckIn: "Yearly",
        type: "monthly",
    },
    {
        image: breck_glass_img,
        plan: "Starter",
        price: 60.00,
        billed: "Billed Annually",
        CheckIn: "Break the Glass Service Plan",
        type: "yearly",
    },
    {
        image: Maskgroup2,
        plan: "Basic",
        price: 6.99,
        billed: "Billed Monthly",
        CheckIn: "Quarterly",
        type: "monthly",
    },
    {
        image: Maskgroup2,
        plan: "Basic",
        price: 84.00,
        billed: "Billed Annually",
        CheckIn: "Quarterly",
        type: "yearly",
    },
    {
        image: Maskgroup3,
        plan: "Silver",
        price: 9.99,
        billed: "Billed Monthly",
        CheckIn: "Monthly",
        type: "monthly",
    },
    {
        image: Maskgroup3,
        plan: "Silver",
        price: 120.00,
        billed: "Billed Annually",
        CheckIn: "Monthly",
        type: "yearly",
    },
    {
        image: Maskgroup4,
        price: 14.99,
        plan: "Gold",
        billed: "Billed Monthly",
        CheckIn: "Weekly",
        type: "monthly",
    },
    {
        image: Maskgroup4,
        plan: "Gold",
        price: 180.00,
        billed: "Billed Annually",
        CheckIn: "Weekly",
        type: "yearly",
    },
    {
        image: Maskgroup5,
        plan: "Platinum",
        price: 19.99,
        billed: "Billed Monthly",
        CheckIn: "3 Days/Week",
        type: "monthly",
    },
    {
        image: Maskgroup5,
        price: 199.90,
        plan: "Platinum",
        billed: "Billed Annually",
        CheckIn: "3 Days/Week",
        type: "yearly",
    },
    {
        image: Maskgroup6,
        plan: "Ultra",
        price: 24.99,
        billed: "Billed Monthly",
        CheckIn: "Daily",
        type: "monthly",
    },
    {
        image: Maskgroup6,
        plan: "Ultra",
        price: 249.90,
        billed: "Billed Annually",
        CheckIn: "Daily",
        type: "yearly",
    },
]


const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const ChooseServicePlan = () => {
    const history = useHistory();
    const {id} = useParams();
    const [pricingType, setpricingType] = useState("yearly");
    const [plan, setPlan] = useState();
    const [PaymentOpen, setPaymentOpen] = useState(false);
    const [completeBookPayment, setCompleteBookPayment] = useState(false);
    const [selectedBook, setSelectedBook] = useState(1)
    const [selectedDays, setCurrentOption] = React.useState('');
    const [currentPlan, setCurrentPlan] = React.useState('');
    const [subscribePlan, setSubscribePlan] = useState('');
    const [PackageList, setPackageList] = useState([]);
    const [servicePlan, setSelectedService] = React.useState('');
    const [nServicePlanID, setSelectedPlanId] = React.useState('');
    const [TokenExpired, setTokenExpired] = useState(false);
    const [timezone, setTimeZone] = React.useState([]);
    const [nTimeZoneID, setNTimeZoneID] = React.useState(1);
    const [stripProdNumber, setStripProdNumber] = useState("");
    const [state, setState] = useState("");
    const [billingFrequency, setBillingFrequency] = useState("Billed Yearly");
    const description = (`${plan} Service Plan - ${billingFrequency}`)

    useEffect(() => {
        getAllPackageList()
        getCurrentUser()
        setTodayDate();
    }, [])

    const setTodayDate = () => {
        const today = new Date();
        const dayOfMonth = today.getDate(); // Get the day of the month (1-31)
        setCurrentOption(dayOfMonth);
    };

    const getTimeZone = () => {
        axios.get(
            process.env.REACT_APP_SERVER + `user/timezonesget`, {
                headers: {Authorization: `Bearer ${token}`},
            }).then((res) => {
            setTimeZone(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const getCurrentUser = async () => {
        let token = localStorage.getItem("token");
        await axios.get(process.env.REACT_APP_SERVER + "profile/detail", {
            headers: {Authorization: `Bearer ${token}`},
        }).then((res) => {
            if (!res.data[0].phone1) {
                setShow(true)
            }
        })
    }
    var token = localStorage.getItem('token');

    const getAllPackageList = (plan = null) => {
        const nUserID = localStorage.getItem('userid');
        const data = {nUserID}
        axios.post(process.env.REACT_APP_SERVER + `service/getall`, data,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then((res) => {
            if (res.data.status) {
                setTokenExpired(true)
            } else {
                setPackageList(res.data)
                getTimeZone()
                setSelectedService(plan ? plan : id);
                const selected = res.data.find(r => r.monthlyrate == plan ? plan : id || r.yearlyrate == plan ? plan : id)
                setCurrentPlan(selected)
                setserviceplandata(selected?.description);
                setSelectedData(selected)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const setSelectedData = (selectedPlan = "") => {
        if (currentPlan) {
            const selected = PackageList.find(r => r.monthlyrate == selectedPlan ? selectedPlan.monthlyrate : currentPlan.monthlyrate || r.yearlyrate == selectedPlan ? selectedPlan.yearlyrate : currentPlan.yearlyrate);
            setSubscribePlan(selected)
            setSelectedPlanId(selected.id)
            getServicePlanHeader(selected.id)
        }
    }

    const [serviceplandata, setserviceplandata] = useState('')

    const handleChangeServicePlan = (id, pkg = null) => {
        setSelectedService(id);
        setserviceplandata(pkg ? pkg.description : null)
    }


    const selectServicePlan = (plan = "") => {
        const selected = PackageList.find(r => r.monthlyrate == plan || r.yearlyrate == plan);
        const priceProdNum = (pricingType === "monthly" || billingFrequency === "Billed Monthly") ? selected.stripe_productnumber_monthly : selected.stripe_productnumber_yearly;
        setSelectedService(plan)
        setSubscribePlan(selected)
        setCurrentPlan(selected)
        setSelectedPlanId(selected.id)
        getServicePlanHeader(selected.id);
        setPaymentOpen(true);
        setStripProdNumber(priceProdNum);
        setBillingFrequency((pricingType === "monthly" || billingFrequency === "Billed Monthly") ? "Billed Monthly" : "Billed Yearly")
    }

    const styles = {
        mainForm: {
            display: 'grid',
            alignItems: 'center',
            maxWidth: "495px",
        },
        backBtn: {
            backgroundColor: '#7030a0',
            color: 'white',
            padding: '6px 12px',
            textTransform: 'capitalize',
            fontWeight: 'normal',
        },
        widthMax1: {
            maxWidth: '800px',
            border: '3px solid grey',
            padding: '12px',
            minHeight: '625px',
            marginTop: '12px'
        }
    }


    const OpenPaymnetBtn = () => {
        setSelectedData()
        setPaymentOpen(true);
    };

    const handleBackBtn = () => {
        setPaymentOpen(false);
        setCompleteBookPayment(false);
        setCurrentOption('')
    };
    const handleChange = (event) => {
        setCurrentOption(event.target.value);
    };

    const getOptionData = (id) => {
        let dayvalue = ["Sunday", "Monday", "Tuesdsay", "Wednesday", "Thursday", "Friday", "Saturday"]
        let days = []
        let twovalue = ["M/W/Fr", "T/Th/Sa"]
        let time = ['04:00PM', '05:00PM', '06:00PM', '07:00PM']
        for (let i = 1; i <= 31; i++) {
            days.push(i)
        }
        switch (id) {
            case 1:
            case 10:
            case 28:
                return (days.map((day, index) => <MenuItem className={'cursor-pointer'} value={day}
                                                           key={index}>{day}</MenuItem>));
            case 25:
                return (dayvalue.map((day, index) => <MenuItem className={'cursor-pointer'}
                                                               value={index + 1} key={index}>{day}</MenuItem>))
            case 8:
                return (time.map((hour, index) => <MenuItem className={'cursor-pointer'}
                                                            value={hour.charAt(1)} key={index}>{hour}</MenuItem>))
            case 9:
                return (twovalue.map((day, index) => <MenuItem className={'cursor-pointer'}
                                                               value={index + 1} key={index}>{day}</MenuItem>))
            default:
                return null
        }
    }


    const [ServiceHeader, setServiceHeader] = useState([]);
    const [show, setShow] = useState(false);
    const [showBookModel, setShowBookModel] = useState(false);
    const handleClose = () => setShow(false)

    const getServicePlanHeader = (Id) => {
        var userId = localStorage.getItem("userid");
        axios.get(process.env.REACT_APP_SERVER + `user/userTZoneAndPlanBkgd?nUserID=${userId}&nPlanID=${Id}`,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then((res) => {
            setServiceHeader(res.data[0]);
            // setNTimeZoneID(res.data[0].TimeZoneID);
        }).catch((err) => {
            console.log(err)
        })
    }
    const setNewPlan = (e) => {

        const findMyPlan = priceplans.find((plan) => plan.price == e);
        if (findMyPlan) {

            setPlan(`${findMyPlan.CheckIn}`)
            const selected = PackageList.find(r => (r.monthlyrate == e || r.yearlyrate == e));
            const priceProdNum = (findMyPlan.type === "monthly") ? selected.stripe_productnumber_monthly : selected.stripe_productnumber_yearly;
            setBillingFrequency(findMyPlan.billed)
            setStripProdNumber(priceProdNum);
        }
    }
    const selectServiceType = (e) => {
        setSelectedService(e.target.value)
        const findMyPlan = priceplans.find((plan) => plan.price == e.target.value);
        if (findMyPlan) {
            const selected = PackageList.find(r => (r.monthlyrate == e.target.value || r.yearlyrate == e.target.value));
            setpricingType(findMyPlan.CheckIn)
            setPlan(`${findMyPlan.CheckIn}`)
            const priceProdNum = (findMyPlan.type === "monthly") ? selected.stripe_productnumber_monthly : selected.stripe_productnumber_yearly;
            setStripProdNumber(priceProdNum);
        }
    }

    useEffect(() => {
        const day = new Date();
        setCurrentOption(day.getDate());
    }, [selectedDays === ''])

    const openBookPayment = () => {
        setCompleteBookPayment(true);
    }

    const closeBookPayment = () => {
        setCompleteBookPayment(false);
    }

    const handleStateChange = (e) => {
        const value = e.target.value;
        const alphabeticValue = value.replace(/[^a-zA-Z]/g, '');
        const trimmedValue = alphabeticValue.slice(0, 2);
        setState(trimmedValue);
    };

    return (
        <>
            {
                TokenExpired
                && <SessionExpired/>
            }
            <section className="service-plan-section ">
                {
                    (PaymentOpen || completeBookPayment)
                        ? <Header title="PAYMENT"/>
                        : <Header title="SERVICE PLAN"/>
                }
                <Row className="mx-auto text-center mobile-button back-logout-btn m-0">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        {(PaymentOpen || completeBookPayment) ? (
                            <Button onClick={handleBackBtn} style={styles.backBtn}
                                    className="btn custom-link btn-primary">
                                <img src={Back} alt="Back" className="w-5 mr-1"/> Back
                            </Button>
                        ) : (
                            <span
                                onClick={() => history.push('/maindashboard')}
                                style={{backgroundColor: "#0069d9"}}
                                className="btn btn-primary"
                            >
                                <img src={Back} alt="Back" className="w-5"/> Back
                            </span>
                        )}
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <Logout/>
                    </Col>
                </Row>

                {/*{*/}
                {/*    PaymentOpen === false &&*/}
                {/*    <div className={'font-weight-bold text-black plan px-3'}>Choose Your Service Plan</div>*/}
                {/*}*/}
                <div className={'mainCardDiv'}>
                    {
                        PaymentOpen === false
                            ?
                            <div className={'d-flex flex-wrap center flex-column service_content p-0'}>
                                {
                                    PackageList.length !== 0
                                        ? (
                                            <div className="d-flex justify-content-center main_div_sarvice">

                                            </div>
                                        ) : null
                                }
                            </div>
                            : <div className="payment-section-data">
                                <div className="payment_deta">
                                    <div className="d-flex paymnet-popup px-3 justify-content-center">
                                        <div className="plan-details-section ">
                                            {/*<div className="payment_details">*/}
                                            {/*    <div className=" timezone mb-2">*/}
                                            {/*       <span style={{width:'98%'}} className="mb-1 d-inline-block">*/}
                                            {/*           <b>*/}
                                            {/*               You have selected Service Plan*/}
                                            {/*           </b>*/}
                                            {/*       </span>*/}
                                            {/*        <Select*/}
                                            {/*            style={{width: '100%',maxHeight:'37px'}}*/}
                                            {/*            className="timezonedata"*/}
                                            {/*            variant="outlined"*/}
                                            {/*            value={currentPlan?.yearlyrate}*/}
                                            {/*            onChange={(e) => {*/}
                                            {/*                // getAllPackageList(e.target.value)*/}
                                            {/*                // setSelectedService(e.target.value)*/}
                                            {/*                // setNewPlan(e.target.value)*/}
                                            {/*            }}*/}
                                            {/*            input={<BootstrapInput/>}*/}
                                            {/*        >*/}
                                            {/*            <MenuItem className={'cursor-pointer'} value={"49.90"} >Yearly Check In</MenuItem>*/}
                                            {/*            <MenuItem className={'cursor-pointer'} value={"69.90"} >Quarterly Check In</MenuItem>*/}
                                            {/*            <MenuItem className={'cursor-pointer'} value={"99.90"} >Monthly Check In</MenuItem>*/}
                                            {/*            <MenuItem className={'cursor-pointer'} value={"149.90"} >Weekly Check In</MenuItem>*/}
                                            {/*            <MenuItem className={'cursor-pointer'} value={"120.00"} >3-days Check In</MenuItem>*/}
                                            {/*            <MenuItem className={'cursor-pointer'} value={"249.90"} >Daily Check In</MenuItem>*/}
                                            {/*        </Select>*/}
                                            {/*    </div>*/}
                                            {/*    <div className=" timezone mb-2">*/}
                                            {/*        <span style={{width:'98%'}} className="mb-1 d-inline-block">*/}
                                            {/*           <b>*/}
                                            {/*               Billing Frequency*/}
                                            {/*           </b>*/}
                                            {/*       </span>*/}
                                            {/*        <Select*/}
                                            {/*            style={{width: '100%',maxHeight:'37px'}}*/}
                                            {/*            className="timezonedata"*/}
                                            {/*            variant="outlined"*/}
                                            {/*            value={servicePlan ? servicePlan : subscribePlan?.yearlyrate}*/}
                                            {/*            onChange={(e) => {*/}
                                            {/*                selectServiceType(e)*/}
                                            {/*                setBillingFrequency(subscribePlan?.yearlyrate===e.target.value ? "Billed Yearly":"Billed Monthly")*/}
                                            {/*            }}*/}
                                            {/*            input={<BootstrapInput/>}*/}
                                            {/*        >*/}
                                            {/*            <MenuItem className={'cursor-pointer'} value={currentPlan?.yearlyrate} > Yearly Billing </MenuItem>*/}
                                            {/*            <MenuItem className={'cursor-pointer'} value={currentPlan?.monthlyrate} > Monthly Billing </MenuItem>*/}
                                            {/*        </Select>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="timezone justify-content-between align-items-center mb-2">*/}
                                            {/*        <span className="d-block mb-1"><b>What timezone are you in?</b></span>*/}
                                            {/*        <Select*/}
                                            {/*            style={{width: '100%',maxHeight:'37px'}}*/}
                                            {/*            name="timeslot"*/}
                                            {/*            className="timezonedata "*/}
                                            {/*            inputProps={{"aria-label": "Without label"}}*/}
                                            {/*            variant="outlined"*/}
                                            {/*            value={nTimeZoneID}*/}
                                            {/*            onChange={(e) => setNTimeZoneID(e.target.value)}*/}
                                            {/*        >*/}
                                            {/*            {timezone.map((res,index) => (*/}
                                            {/*                <MenuItem value={res.id} key={index}>{res.name}</MenuItem>*/}
                                            {/*            ))}*/}
                                            {/*        </Select>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="timezone mb-2">*/}
                                            {/*       <span style={{width:'98%'}} className="mb-1 d-inline-block">*/}
                                            {/*           <b>*/}
                                            {/*               {ServiceHeader.bkgdDescription}*/}
                                            {/*           </b>*/}
                                            {/*       </span>*/}
                                            {/*            <Select*/}
                                            {/*                style={{width: '100%',maxHeight:'37px'}}*/}
                                            {/*                className="timezonedata"*/}
                                            {/*                variant="outlined"*/}
                                            {/*                value={selectedDays}*/}
                                            {/*                onChange={handleChange}*/}
                                            {/*                input={<BootstrapInput/>}*/}
                                            {/*            >{getOptionData(subscribePlan?.id)}*/}
                                            {/*            </Select>*/}
                                            {/*    </div>*/}

                                            {/*</div>*/}
                                            {/*<div className={'d-flex align-items-center justify-content-between border-dotted-bottom '}>*/}
                                            {/*    <div className={'d-flex align-items-center'}>*/}
                                            {/*        <h6 className="font-weight-bold p-0 m-0" style={{fontSize:'18px'}} >Service Plan:</h6>*/}
                                            {/*        <span className="ml-2 font-weight-bold" style={{fontSize:'18px'}}>{subscribePlan?.shortdescription}</span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div>*/}
                                            {/*    <div className="paymnet-inner-content mt-3">*/}
                                            {/*        <div className="d-flex paymnet-plan-data">*/}
                                            {/*            <h6 className={'font-weight-bold'}>Plan Name </h6>*/}
                                            {/*            <p className={'font-weight-bold'}>{subscribePlan?.name}</p>*/}
                                            {/*        </div>*/}
                                            {/*        <div className="d-flex MainRadioBtn">*/}
                                            {/*            <h6 className={'font-weight-bold mt-1'} style={{width: '25%'}}>Plan*/}
                                            {/*                Price</h6>*/}
                                            {/*            <FormControl component="fieldset">*/}
                                            {/*                <RadioGroup*/}
                                            {/*                    aria-label="timeslot"*/}
                                            {/*                    name="timeslot"*/}
                                            {/*                    value={servicePlan}*/}
                                            {/*                    onChange={(e) => setSelectedService(e.target.value)}*/}
                                            {/*                >*/}
                                            {/*                    <div className={''}>*/}
                                            {/*                        <FormControlLabel*/}
                                            {/*                            className={'formControlRadio w-100'}*/}
                                            {/*                            value={subscribePlan?.monthlyrate.toString().trim()}*/}
                                            {/*                            control={<Radio color="primary"/>}*/}
                                            {/*                            label={<span*/}
                                            {/*                                className={'font-weight-bold'}>$ {subscribePlan?.monthlyrate}/monthly</span>}*/}
                                            {/*                        />*/}
                                            {/*                        <FormControlLabel*/}
                                            {/*                            className={'w-100'}*/}
                                            {/*                            value={subscribePlan?.yearlyrate.toString().trim()}*/}
                                            {/*                            control={<Radio color="primary"/>}*/}
                                            {/*                            label={<span*/}
                                            {/*                                className={'font-weight-bold'}>$ {subscribePlan?.yearlyrate}/Yearly</span>}*/}
                                            {/*                        />*/}
                                            {/*                    </div>*/}
                                            {/*                </RadioGroup>*/}
                                            {/*            </FormControl>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {priceplans.filter(e => (e.price == (servicePlan ? servicePlan : subscribePlan?.yearlyrate))).map((e) => {
                                                return (
                                                    <div className={`selected-plan-info ${e.plan}`}>
                                                        <div className="imag-box"><img style={{width: 140}} src={e.image}/>
                                                        </div>
                                                        <div className="checkin-text">{e.CheckIn}
                                                            {/*Check-In Service Plan*/}
                                                        </div>
                                                        <div className="billed-price">{e.billed}</div>
                                                        <div
                                                            className="plan_price">${e.price.toFixed(2)}{e.billed == "Billed Annually" ? "/yr" : "/mo"}</div>
                                                        <div className="text-center"><img src={rightIcon} alt="rightIcon"
                                                                                          width="34px"/></div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                        <SubscribePayment
                                            planPrice={servicePlan}
                                            nServicePlanID={nServicePlanID}
                                            selectedDays={selectedDays}
                                            nTimeZoneID={nTimeZoneID}
                                            stripProdNumber={stripProdNumber}
                                            pricingType={pricingType}
                                            subscribePlan={subscribePlan}
                                            description={description}
                                        />
                                    </div>
                                    <Standardfeatures box={false} className={'servicePlanFeatureBox'}/>
                                </div>
                            </div>
                    }
                </div>
                <div className={'mainCardDiv'}>
                    {
                        completeBookPayment === false
                            ?
                            <div className={'d-flex flex-wrap center flex-column service_content p-0'}>
                                <div className="d-flex justify-content-center main_div_sarvice">

                                </div>
                            </div>
                            : <div className="payment-section-data">
                                <div className="payment_deta">
                                    <div className="d-flex paymnet-popup px-3 justify-content-center">
                                        <div className="plan-details-section d-flex justify-content-center ">
                                            <div className={'book-info selected-plan-info text-center'}>
                                                <h5 className={'pb-4'}>Your Book Color Selection</h5>
                                                <div>
                                                    <div className={'d-flex justify-content-center'}>
                                                        {selectedBook === 1 ?
                                                            <img src={whiteColorBook} alt={'b'}
                                                                 className={'booksImgs'}/>
                                                            : selectedBook === 2 ?
                                                                <img src={blackColorBook} alt={'b'}
                                                                     className={'booksImgs'}/>
                                                                : selectedBook === 3 ?
                                                                    <img src={pinkColorBook} alt={'b'}
                                                                         className={'booksImgs'}/>
                                                                    : <img src={whiteColorBook} alt={'b'}
                                                                           className={'booksImgs'}/>
                                                        }

                                                    </div>
                                                    <div
                                                        className={'d-flex justify-content-center py-2 selection-main-div payment-selection-main-div'}>
                                                        <img src={whiteColorBook} alt={'b'}
                                                             className={`payment-select-book-img ${selectedBook === 1 && 'active'}`}
                                                             onClick={() => setSelectedBook(1)}/>
                                                        <img src={blackColorBook} alt={'b'}
                                                             className={`payment-select-book-img ${selectedBook === 2 && 'active'}`}
                                                             onClick={() => setSelectedBook(2)}/>
                                                        <img src={pinkColorBook} alt={'b'}
                                                             className={`payment-select-book-img ${selectedBook === 3 && 'active'}`}
                                                             onClick={() => setSelectedBook(3)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <SubscribePayment
                                                planPrice={servicePlan}
                                                nServicePlanID={nServicePlanID}
                                                selectedDays={selectedDays}
                                                nTimeZoneID={nTimeZoneID}
                                                stripProdNumber={stripProdNumber}
                                                pricingType={pricingType}
                                                subscribePlan={subscribePlan}
                                                description={description}
                                            />
                                            <div className={'payment-section common-font-section mt-5 p-0'}>

                                                <Form className={'p-0 p-4'}>
                                                    <h3 className={'pb-3'}>
                                                        Shipping Address :
                                                    </h3>
                                                    <Form.Group as={Row} controlId="formPlaintextService"
                                                                className="mx-0">
                                                        <Col md={2}><Form.Label>Full Name:</Form.Label></Col>
                                                        <Col><Form.Control type="email"/></Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextService"
                                                                className="mx-0">
                                                        <Col md={2}><Form.Label>Address:</Form.Label></Col>
                                                        <Col><Form.Control type="email"/></Col>
                                                    </Form.Group>
                                                    <div className={'d-flex justify-content-between'}>
                                                        <div className={'d-flex align-items-center'}>
                                                            <label className={'payment-label'}>City</label>
                                                            <input type={'text'} name={'city'}
                                                                   className={'address-details-input '}/>
                                                        </div>
                                                        <div className={'d-flex align-items-center'}>
                                                            <label className={'payment-state-label'}>State:</label>
                                                            <input type={'text'} name={'city'} value={state}
                                                                   onChange={handleStateChange}
                                                                   className={'address-details-state-input '}/>
                                                        </div>
                                                        <div className={'d-flex align-items-center'}>
                                                            <label className={'payment-zip-label'}>Zip:</label>
                                                            <input type={'text'} name={'city'}
                                                                   className={'address-details-zip-input '}/>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                {(PaymentOpen === false && completeBookPayment === false) && (
                    <>
                        <div className="service_plan_section">
                            <div className="title text-center">
                                <h2 className="service_plan_title">CHOOSE SERVICE PLAN </h2>
                                {/*<h6 className="service_plan_des">Save 2 Months Free with Annual Billing</h6>*/}
                                {/*<div className="plan_btn_group">*/}
                                {/*    <button*/}
                                {/*        className={`${*/}
                                {/*            pricingType === "monthly" ? "btn active" : "btn"*/}
                                {/*        }`}*/}
                                {/*        onClick={() => setpricingType("monthly")}*/}
                                {/*    >*/}
                                {/*        Monthly*/}
                                {/*    </button>*/}
                                {/*    <button*/}
                                {/*        className={`${pricingType === "yearly" ? "btn active" : "btn"}`}*/}
                                {/*        onClick={() => setpricingType("yearly")}*/}
                                {/*    >*/}
                                {/*        Annual*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>
                        </div>


                        <div className="service_plan_section">
                            <div className="service_plan_main">
                                <table className="text-center service_plan_table w-100 table plan_content_table">
                                    <thead>
                                        <tr>
                                            <th>ITEM</th>
                                            <th>PRICE</th>
                                            <th>KTMW Standard Features</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className={"column-line"}>
                                                <div>
                                                    <span className='book-title'>KeysToMyWishes</span><br/>
                                                    <span className={'book-subtitle'}> Personal Affairs Organizer </span>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div>
                                                        <img src={ktwsLogo} style={{width: '80%'}}/>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <h2 className="plan_price pb-0">
                                                    $16.99
                                                </h2>
                                                <p className={'plus-s-and-h'}>plus S&H</p>
                                            </td>
                                            <td></td>
                                            <td>
                                                <p className="plan_start_btn">
                                                    <button
                                                        className="btn"
                                                        style={{
                                                            color: "white",
                                                            border: "none",
                                                            background: " #008EFF"
                                                        }}
                                                        onClick={() => {
                                                            setPlan("Yearly")
                                                            if (pricingType === "monthly") {
                                                                selectServicePlan("4.99");
                                                            } else {
                                                                selectServicePlan("60.00");
                                                            }
                                                        }}
                                                    >
                                                        Select Plan
                                                    </button>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"column-line"}>
                                                <div>
                                                    <span className='book-title'>Break the Glass <br/> Service Plan</span>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="service_plan_box">
                                                        <img width={100} src={breck_glass_img}/>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {pricingType === "monthly" ? (
                                                    <h2 className="plan_price">
                                                        $5.00<span>/mo</span>
                                                    </h2>
                                                ) : (
                                                    <h2 className="plan_price">
                                                        $60.00<span>/yr</span>
                                                    </h2>
                                                )}
                                            </td>
                                            <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
                                            <td>
                                                <p className="plan_start_btn">
                                                    <button
                                                        className="btn"
                                                        style={{
                                                            color: "white",
                                                            border: "none",
                                                            fontSize:"16px",
                                                            background: " #00D823"
                                                        }}
                                                        onClick={() => setShowBookModel(true)}
                                                        // onClick={() => openBookPayment()}
                                                    >Buy&nbsp;Organizer
                                                    </button>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <Standardfeatures box={true}/>
                    </>
                )}
            </section>
            <Modal show={show}>
                <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}}>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-4">
                        <div>Before purchasing a Service Plan please go to the My Profile module and enter your phone
                            number.
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => history.push("/my-profile")}
                        style={{
                            backgroundColor: "#7030a0",
                            color: "white",
                            border: "none",
                        }}
                    >
                        Add Phone Number
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showBookModel}>
                <Modal.Header style={{backgroundColor: "#0069d9", color: "white", padding: "0px"}}>
                    <div className={" w-100 d-flex justify-content-end mb-2"}>
                        <button className='btn' onClick={() => setShowBookModel(false)}><MdClose size={20}
                                                                                                 style={{color: "white"}}/>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-4">
                        <div className={'text-center'}><span className={"font-weight-bold"}> KeysToMyWishes </span>Personal
                            Affairs Organizer will be available on Amazon soon!
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ChooseServicePlan;