import React from 'react';
import {TextField} from "@material-ui/core";

const PowerOfAttorney = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Power of Attorney</h3>
            </div>
            <div className="content mt-3 mb-5">
                <div className="d-flex align-items-center gap-2">
                    <span>I have a Power of Attorney which was executed on</span>
                    <TextField
                        variant="filled"
                        size="small"
                        className="w-25"
                    />
                    (Date)
                </div>
                <div className="d-flex align-items-center mb-2">
                    <span className="w-50">Power of Attorney document location:</span>
                    <TextField
                        variant="filled"
                        size="small"
                        className="w-75"
                    />
                </div>
                <div>
                    Notes about my Power of Attorney:
                    <TextField
                        variant="filled"
                        fullWidth={true}
                        size="small"
                        multiline={true}
                        rows={30}
                    />
                </div>
            </div>
        </div>
    );
};

export default PowerOfAttorney;