import React from 'react';
import "./personal-affairs.css";
import Header from "../Landing/Header";
import WhyOrganizerImportant from "../../components/PersonalAffairsContents/WhyOrganizerImportant";
import RecommendationToProtectLovedOnes
    from "../../components/PersonalAffairsContents/RecommendationToProtectLovedOnes";
import InCaseOfEmergency from "../../components/PersonalAffairsContents/InCaseOfEmergency";
import MyInformationPage from "../../components/PersonalAffairsContents/MyInformationPage";
import ChildrenPage from "../../components/PersonalAffairsContents/ChildrenPage";
import PetsPage from "../../components/PersonalAffairsContents/PetsPage";
import UponMyDeathDoThis from "../../components/PersonalAffairsContents/UponMyDeathDoThis";
import EmploymentInformation from "../../components/PersonalAffairsContents/EmploymentInformation";
import TrustPage from "../../components/PersonalAffairsContents/TrustPage";
import PowerOfAttorney from "../../components/PersonalAffairsContents/PowerOfAttorney";
import IncapacitationPage from "../../components/PersonalAffairsContents/incapacitationPage";
import RealEstatePage from "../../components/PersonalAffairsContents/RealEstatePage";
import AttorneyInformation from "../../components/PersonalAffairsContents/AttorneyInformation";
import Insurance from "../../components/PersonalAffairsContents/Insurance";
import Insurance2 from "../../components/PersonalAffairsContents/Insurance2";
import Insurance3 from "../../components/PersonalAffairsContents/Insurance3";
import Insurance4 from "../../components/PersonalAffairsContents/Insurance4";
import BankAccounts from "../../components/PersonalAffairsContents/BankAccounts";
import DigitalAccounts from "../../components/PersonalAffairsContents/DigitalAccounts";
import CreditCards from "../../components/PersonalAffairsContents/CreditCards";
import Automobiles from "../../components/PersonalAffairsContents/Automobiles";
import BusinessOwned from "../../components/PersonalAffairsContents/BusinessOwned";
import Jewelry from "../../components/PersonalAffairsContents/Jewelry";
import Heirlooms from "../../components/PersonalAffairsContents/Heirlooms";
import Collectibles from "../../components/PersonalAffairsContents/Collectibles";
import FriendsAndFamily from "../../components/PersonalAffairsContents/FriendsAndFamily";
import CherishedMemoriesWith from "../../components/PersonalAffairsContents/CherishedMemoriesWith";
import Obituary from "../../components/PersonalAffairsContents/Obituary";
import MyFinalWishes from "../../components/PersonalAffairsContents/MyFinalWishes";
import TableOfContents from "../../components/PersonalAffairsContents/TableOfContents";
import OrganizingYourPersonal from "../../components/PersonalAffairsContents/OrganizingYourPersonal";
import FrontCoverPage from "../../components/PersonalAffairsContents/FrontCoverPage";
import LastCoverPage from "../../components/PersonalAffairsContents/LastCoverPage";

const PersonalAffairsPage = () => {
    return (
        <div>
            <Header/>
            <FrontCoverPage/>
            <OrganizingYourPersonal/>
            <TableOfContents/>
            <WhyOrganizerImportant/>
            <RecommendationToProtectLovedOnes/>
            <InCaseOfEmergency/>
            <MyInformationPage/>
            <ChildrenPage/>
            <PetsPage/>
            <UponMyDeathDoThis/>
            <EmploymentInformation/>
            <TrustPage/>
            <PowerOfAttorney/>
            <AttorneyInformation/>
            <Insurance/>
            <Insurance2/>
            <Insurance3/>
            <Insurance4/>
            <BankAccounts/>
            <DigitalAccounts/>
            <CreditCards/>
            <Automobiles/>
            <BusinessOwned/>
            <Jewelry/>
            <Heirlooms/>
            <Collectibles/>
            <RealEstatePage/>
            <IncapacitationPage/>
            <FriendsAndFamily/>
            <CherishedMemoriesWith/>
            <Obituary/>
            <MyFinalWishes/>
            <LastCoverPage/>
        </div>
    );
};

export default PersonalAffairsPage;