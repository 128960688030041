import React from 'react';
import BGHeader from "../Common/BGHeader";
import Footer from "../Home/Footer";
import PricingList from "./PricingList";
import FindMyPlan from "./FindMyPlan";
import FAQ from "./FAQ";

const Pricing = () => {
    return (
        <div>
            <BGHeader
                title={'Stay organized with plans that cover all your needs'}
                description={'From saving your cherished memories to securing your vital documents  in one place – there’s a Keys to My Wishes simple program to preserve and protect your legacy. offer terms'}
            />
            <PricingList/>
            <FindMyPlan/>
            <FAQ/>
            <Footer/>
        </div>
    );
};

export default Pricing;