import React from 'react';

const RecommendationToProtectLovedOnes = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Recommendation to Protect Loved Ones</h3>
            </div>
            <div className="content">
                <p> Dear Reader</p>
                <p> As you use this planner to organize your important information and wishes, we
                    highly recommend considering our online service plan to further protect and
                    assist your loved ones during challenging times.
                </p>
                <p>
                    Our online service complements this book seamlessly. You can enter your
                    information directly into our secure app, providing an additional layer of
                    accessibility and convenience. This ensures that your critical details are safely
                    stored and easily accessible when needed.
                </p>
                <p className="bold">
                    We understand that some individuals may have concerns about cloudbased storage. For those with such reservations, simply make an entry
                    in KeysToMyWishes that informs your loved ones of the location of this
                    physical document. This way, even if you prefer not to store information
                    digitally, your loved ones will still have guidance on where to find your
                    important information.
                </p>
                <p>
                    Our mission is to provide peace of mind by ensuring that your loved ones can
                    locate your essential information promptly as they grieve and navigate through
                    the challenges of loss.
                </p>
                <p>
                    By considering our service plan, you can enhance the effectiveness of this
                    planner and ensure that your preparations serve their intended purpose when
                    the time comes.
                </p>
                <p>
                    Thank you for trusting us to assist you in safeguarding your legacy and
                    supporting your loved ones.
                </p>
                <p>
                    Your home address is not required in our system.
                </p>
                <p>Sincerely</p>
                <p>Staff at KeysToMyWishes</p>
            </div>
        </div>
    );
};

export default RecommendationToProtectLovedOnes;