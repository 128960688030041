import React from 'react';
import './whychoose.css';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import helpIcon from '../assets/images/help-icon.svg';
import mailIcon from '../assets/images/mail-icon.svg';
import questionBoxIcon from '../assets/images/questionbox-icon.svg';
import questionMarkIcon from '../assets/images/question-mark-icon.svg';
import privacyIcon from '../assets/images/privacy-icon.svg';
import fourStpeIcon from '../assets/images/four-icon.svg';
import fingerTips from '../assets/images/fingertips-img.svg';
import WishesVideo from '../../../assets/images/KeysToMyWishes.mp4'


const WhyChoose = () => {
    return (
        <>
            <section className='why_choose_section'>
                <Container className='why_choose_details'>
                    <Row className='justify-content-center m-0'>
                        <div className='details_title'>
                            <h2>Why Choose Us ?</h2>
                        </div>
                    </Row>
                    <Row className='justify-content-center features_detailsbox m-0'>
                        <Col lg={4} md={6} sm={12} className='mt-4'>
                            <Card className='features_details_card'>
                                <Card.Body>
                                    <div className='features_details_image'>
                                        <img
                                            src={helpIcon}
                                            className="d-inline-block align-top"
                                            alt="logo"
                                        />
                                    </div>
                                    <div className='features_details'>
                                        <h4>Keep in touch </h4>
                                        <p>Did you ever feel like not being found when incapacitated? You're never alone with us. We offer regular check-ins to ensure your well-being.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12} className='mt-4'>
                            <Card className='features_details_card'>
                                <Card.Body>
                                    <div className='features_details_image'>
                                        <img
                                            src={mailIcon}
                                            className="d-inline-block align-top"
                                            alt="logo"
                                        />
                                    </div>
                                    <div className='features_details'>
                                        <h4>Break the glass  </h4>
                                        <p>
                                            If contacted that you are unresponsive or have passed away, our process is triggered to reach out to you by text, email and phone calls in order to determine if we should release your information to those you have designated to receive the information.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12} className='mt-4'>
                            <Card className='features_details_card'>
                                <Card.Body>
                                    <div className='features_details_image'>
                                        <img
                                            src={questionBoxIcon}
                                            className="d-inline-block align-top"
                                            alt="logo"
                                        />
                                    </div>
                                    <div className='features_details'>
                                        <h4>What's in it for you </h4>
                                        <p>
                                            A simple, affordable, and user-friendly app to organize and secure your documents with top-notch security to stay stress-free so you can focus on what truly matters.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='justify-content-center features_detailsbox m-0'>
                        <Col lg={4} md={6} sm={12} className='mt-4'>
                            <Card className='features_details_card'>
                                <Card.Body>
                                    <div className='features_details_image'>
                                        <img
                                            src={questionMarkIcon}
                                            className="d-inline-block align-top"
                                            alt="logo"
                                        />
                                    </div>
                                    <div className='features_details'>
                                        <h4>Why it matters?  </h4>
                                        <p>
                                            Never fear losing information in accidents, and prevent your family from the hassle of retrieving documents after your passing.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12} className='mt-4'>
                            <Card className='features_details_card'>
                                <Card.Body>
                                    <div className='features_details_image'>
                                        <img
                                            src={privacyIcon}
                                            className="d-inline-block align-top"
                                            alt="logo"
                                        />
                                    </div>
                                    <div className='features_details'>
                                        <h4>Data privacy </h4>
                                        <p>
                                            Enhanced security with two-factor authentication, biometric login, and AES 256-bit encryption ensures complete privacy. Not even we have access to your data.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12} className='mt-4'>
                            <Card className='features_details_card'>
                                <Card.Body>
                                    <div className='features_details_image'>
                                        <img
                                            src={fourStpeIcon}
                                            className="d-inline-block align-top"
                                            alt="logo"
                                        />
                                    </div>
                                    <div className='features_details'>
                                        <h4>4 Easy Steps </h4>
                                        <p>
                                            Sign up, add emergency contacts, enter your wishes and choose a plan. Rest easy knowing that your family will be notified of your important information at the time of your choosing.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container className='why_choose_details'>
                    <div className='fingertips-content'>
                        <Row className='justify-content-center m-0'>
                            <Col lg={5} md={5} sm={12}>
                                <div className='fingertips-image text-center'>
                                    <img
                                        src={fingerTips}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                </div>
                            </Col>
                            <Col lg={7} md={7} sm={12}>
                                <div className='fingertips-details'>
                                    <h2>Your world is at your <span>Fingertips</span>.</h2>
                                    <p>
                                        KeysToMyWishes gives you a one-stop solution to access all your important information anywhere, anytime.
                                    </p>
                                    <div className='fingertips-details-badge'>
                                        <Badge pill variant="" className="pils-badge">
                                            Stocks/Bonds
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Passwords
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Medical Info
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Employment Info
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Social Security
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Pension
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Trust/Will
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Life Insurance
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Safe Deposit Box
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Real Estate
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            P.O. Box
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Bank Accounts
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Crypto Currency
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Jewelry
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Home going Services
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Letters to Loved Ones
                                        </Badge>
                                        <Badge pill variant="" className="pils-badge">
                                            Video to Loved Ones
                                        </Badge>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section>
                <Container>
                    <div className='care-section'>
                        <Row className='justify-content-center m-0'>
                            <Col lg={7} md={7} sm={12} className="d-flex align-items-center">
                                <div className='care-details'>
                                    <h2>We are the only ones who <span>care</span>. </h2>
                                    <p>
                                        This is the only system that provides the facility to get regular check-ins so you never feel alone knowing your data is in safe hands.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={5} md={5} sm={12}>
                                <div className='video_content'>
                                    <video width="593" height="315" controls>
                                        <source src={WishesVideo} type="video/mp4" />
                                    </video>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default WhyChoose;