import React, {useEffect, useState} from 'react'
import Header from "../../components/NewHeader/Header";
import {Col, Modal, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import Logout from "../../components/logout/Logout";
import axios from 'axios';
import './CheckInPage.css'
import {
    Button
} from 'react-bootstrap'
import {useHistory} from "react-router-dom";
import moment from 'moment'
import Back from "../../assets/images/Back_white.png";
import SessionExpired from '../../components/SessionExpired/SessionExpired'
import Checkinbutton from "../../assets/images/checkinbutton.png";

const CheckInPage = () => {
    const history = useHistory()

    const nUserID = localStorage.getItem("userid");
    const token = localStorage.getItem("token");

    const [BannderAd, setBannerAd] = useState('')
    const [checkInTime, setCheckInTime] = useState('')
    const [sIPAddress, setCurrentIp] = useState('')
    const [TokenExpired, setTokenExpired] = useState(false);
    const [shows,setshows] = useState(false);
    const [planNotSelected, setSelectPlan] = useState(false);
    const [openRequiredModel, setopenRequiredModel] = useState(false);

    const handleServicePlanClose = () => {
        setopenRequiredModel(false);
    };
    const handleServicePlanOpen = () => {
        setopenRequiredModel(true);
    };

    useEffect(() => {
        getBannderAds();
        getCurrentIp();
        LastcheckInTime();
    }, [])

    const getBannderAds = () => {
        const nID = localStorage.getItem('nID')
        if (nID === null) {
            localStorage.setItem('nID', 1)
        }
        axios.get(process.env.REACT_APP_SERVER + `user/advertisementget?nUserID=${nUserID}&nID=${nID}`,
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            .then((res) => {
                localStorage.setItem('nID', res.data[0].nNextID)
                setBannerAd(res.data[0].url)
            }).catch((err) => {
            console.log(err)
        })
    }

    const LastcheckInTime = () => {
        const data = {nUserID}
        axios.post(process.env.REACT_APP_SERVER + `user/getusercheckins`, data, {
            headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}
        }).then((res) => {
            if (res.data.status) {
                setTokenExpired(true)
            } else {
                setCheckInTime(moment(res.data[0].checkintime).format(' MMMM DD, YYYY h:mm A'))
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const getCurrentIp = () => {
        axios.get('https://geolocation-db.com/json/')
            .then(res => {
                setCurrentIp((res.data.IPv4));
            }).catch((err) => {
            console.log(err)
        })
    }

    const HandleCheckIn = () => {
        const nSystemUserID = 0
        const sMethod = "Android"
        const sNotes = "Notes"
        const data = {nUserID, nSystemUserID, sMethod, sNotes, sIPAddress}
        axios
            .get(process.env.REACT_APP_SERVER + "profile/detail", {
                headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
            })
            .then((resp) => {
                if (resp.data[0].newuser === 1) {
                    handleOpenModel()
                } else if(resp.data[0].serviceplanrequired === 1){
                    handleServicePlanOpen()
                }else {
                    axios.post(process.env.REACT_APP_SERVER + `user/setusercheckins`, data, {
                        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}
                    }).then((res) => {

                    }).catch((err) => {
                        console.log(err)
                    });
                    history.push('/CheckInResponse')
                }
            });
    }

    const handleClosePlan = () => {
        setSelectPlan(false)
    }

    const handleOpenModel = () => {
        setshows(true);
    }
    const handleCloseModel = () => {
        setshows(false);
    }
    const redirectServicePlan = () => {
        history.push("/chooseServicePlan");
    };

    return (
        <>
            <Modal show={openRequiredModel} onHide={handleServicePlanClose}>
                <Modal.Header
                    closeButton
                    style={{ backgroundColor: "#7030a0", color: "white" }}
                >
                    <Modal.Title>Service Plan Required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        The Free Trial Period is Over.<br/>
                        You must subscribe to a Service Plan. Click the Service Plan button and choose a Service Plan.
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btngenie" onClick={redirectServicePlan}>
                        Go To Service Plan
                    </Button>
                    <Button className="btngenie" onClick={handleServicePlanClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={shows} onHide={handleCloseModel}>
                <Modal.Header style={{ backgroundColor: "#7030a0", color: "white" }}>
                    <Modal.Title>New User Checking In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You will not be allowed to use the Check In(Keep In Touch) feature until you have selected a Service Plan
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{ backgroundColor: "#7030a0" }}
                        onClick={handleCloseModel}
                    >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

            {TokenExpired && <SessionExpired/>}
            <Modal show={planNotSelected} onHide={handleClosePlan}>
                <Modal.Header closeButton className={'modelHeader'}>
                    <Modal.Title>Please Select Your Plan!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please Select Your Plan!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{backgroundColor: '#7030a0'}} onClick={handleClosePlan}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
            <section className="profile-main-section">
                <Header title="CHECK IN"/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maindashboard" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <Logout/>
                    </Col>
                </Row>
                <div className="mainContainer">
                    {
                        localStorage.getItem('showads') === '1'
                        &&<img src={BannderAd} alt="image"/>
                    }
                    <div className="HandleCheckBtn">
                        <h4>Keep In Touch</h4>
                        <p style={{color: '#7030a0'}}>Click this button to Check In</p>
                        <img src={Checkinbutton} onClick={HandleCheckIn} className={'mt-5 checkin-btn'} style={{cursor:'pointer'}} />
                    </div>
                    <p className="mt-5 text-dark">{localStorage.getItem('name')}, You last checked In</p>
                    <p className="mt-4 text-dark" style={{fontSize:'20px'}}>{checkInTime}</p>
                </div>
            </section>
        </>
    )
}

export default CheckInPage