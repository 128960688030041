import React, {Component} from 'react';
import HeaderNewLandingPage from "../HeaderNewLandingPage/HeaderNewLandingPage";
import FooterNewLandingPage from "../FooterNewLandingPage/FooterNewLandingPage";
import {Alert, Button, Col, Form, FormGroup, Modal, Row} from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {GoogleLogin} from "react-google-login";
import FacebookLoginWithButton from "react-facebook-login";
import FacebookIcon from "../../../assets/images/LandingPageNew/Facebook_icon.svg"
import axios from "axios";
import pattern from "../../../assets/images/LandingPage/patterns/pattern.jpg";
import {Link} from "react-router-dom";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./SignupPage.css"
import {getAuth, OAuthProvider, signInWithPopup} from "firebase/auth";
import {app} from "../../../Firebase";
import GoogleIcon from "../../../assets/images/search.png";
import {wish} from "../../../utils/wishes.utils";
import PhoneNumberField from "../../../components/PhoneNumber";
import {LinkedIn} from "react-linkedin-login-oauth2";
import LinkedInIcon from "../../../assets/images/linkedin.png";

class SignupPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            firstname: "",
            lastname: "",
            email: "",
            phonenumber: "",
            password: "",
            cPassword: "",
            signuperrormessage: false,
            signuperrorpassword: false,
            signupsucess: false,
            showWelcomeModel: false,
            hidden: true,
            confirmHidden: true,
            showphone: false,
            MessageSignUp: "",
            sDeviceID: "",
            isSigningUp: false
        };
        this.pageScroll()
    }

    componentDidMount() {
        this.getCurrentIp();
    }

    getCurrentIp = () => {
        axios.get('https://geolocation-db.com/json/')
            .then(res => {
                this.setState({sDeviceID: res.data.IPv4});
            }).catch((err) => {
            console.log(err)
        })
    }

    pageScroll = () => {
        return window.scrollTo(0, 0);
    }

    loginUser = (user = {}) => {
        const data = {
            email: user["email"],
            password: user["password"],
            sDeviceID: this.state["sDeviceID"],
            sDeviceType: 'WEB'
        };
        axios.post(process.env.REACT_APP_SERVER + 'auth/login', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            this.setState({showWelcomeModel: true})
            document.cookie = `serviceplanid=${res.data.users.serviceplanid}; expires=${new Date(
                Date.now() + 10800000
            ).toUTCString()}; path=/`;
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('login', true);
            localStorage.setItem('userid', res.data.users.id);
            localStorage.setItem('email', res.data.users.email);
            localStorage.setItem('name', res.data.users.name);
            localStorage.setItem('accountnumber', res.data.users.accountnumber);
            localStorage.setItem('isLogin', 1)
        }).catch(err => {
            console.log(err);
        });
    }

    submitSignupBtn = (e) => {
        e.preventDefault();
        const {
            firstname,
            lastname,
            email,
            phonenumber,
            password,
            cPassword
        } = this.state;
        const Pass_REGEX =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&^_~+-.])[A-Za-z\d!@#$%&^-_~+-.]{8,}$/;
        const data = {
            password_confirmation: cPassword,
            password: password,
            email: email,
            firstname: firstname,
            lastname: lastname,
            phonenumber: phonenumber,
        };

        if (phonenumber && phonenumber.length > 2) {
            if (phonenumber.length === 12) {
                this.setState({
                    showphone: false,
                })
                if (!Pass_REGEX.test(password)) {
                    this.handleAleart();
                } else if (password !== cPassword) {
                    this.setState({
                        signuperrorpassword: true,
                        signuperrormessage: false,
                        signupsucess: false,
                    });
                } else {
                    this.createUser(data);
                }
            } else {
                this.setState({
                    showphone: true,
                });
            }
        } else if (!Pass_REGEX.test(password)) {
            this.handleAleart();
        } else if (password !== cPassword) {
            this.setState({
                signuperrorpassword: true,
                signuperrormessage: false,
                signupsucess: false,
            });
        } else {
            this.createUser(data);
        }
    };

    createUser = (data) => {
        this.setState({isSigningUp: true})
        axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=32`)
            .then((res) => {
                const allData = {
                    ...data,
                    emailtext: res.data[0].thetext
                }
                axios.post(process.env.REACT_APP_SERVER + "auth/register", allData)
                    .then((response) => {
                        const user = response.data.user;
                        user.password = this.state.password;
                        this.loginUser(user);
                        this.setState({
                            firstname: "",
                            lastname: "",
                            email: "",
                            phonenumber: "1",
                            password: "",
                            cPassword: "",
                            signuperrormessage: false,
                            signuperrorpassword: false,
                            signupsucess: true,
                            showWelcomeModel: false,
                            isSigningUp: false
                        });
                    })
                    .catch((err) => {
                        console.log(err)
                        this.setState({
                            signuperrorpassword: false,
                            signuperrormessage: true,
                            signupsucess: false,
                            isSigningUp: false
                        });
                    });
            }).catch((err) => {
            console.log(err)
        })
    }

    responseGoogle = (response) => {
        const data = {
            email: response.profileObj.email,
            name: response.profileObj.googleId,
            accessToken: response.accessToken,
            type: 'google'
        }
        axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=32`)
            .then((res) => {
                this.loginGoogle({...data, emailtext: res.data[0].thetext})
            })
    }

    loginGoogle = (data) => {
        axios.post(process.env.REACT_APP_SERVER + 'auth/loginviaapp', data).then((res) => {
            localStorage.setItem("login", true);
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('email', res.data.users.email)
            localStorage.setItem('name', data.name)
            localStorage.setItem("isLogin", 1);
            localStorage.setItem("userid", res.data.users.id);
            this.props.history.push("/maindashboard");
        }).catch((err) => {
            console.log(err)
        })
    }

    responseLinkedin = (code) => {
        console.log(window.location.origin)
        const data = {
            grant_type: "authorization_code",
            code: code,
            client_id: "864pwkbncnk6f1",
            client_secret: "A5cbCOga84AuOTAu",
            redirect_uri: `${window.location.origin}/linkedin`
        }
        console.log(data)
        axios.post('https://www.linkedin.com/oauth/v2/accessToken', new URLSearchParams(data), {
            headers: {
                 'Content-Type': 'application/x-www-form-urlencoded',
                 'access-control-allow-origin':`*`,
            }
        })
            .then((response) => {
                const token = response.data.access_token
                axios.get(`https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))&oauth2_access_token=${response.data.access_token}`)
                    .then((res) => {
                        const data = {
                            email: res.data.elements[0]["handle~"].emailAddress,
                            type: 'linkedin',
                            accessToken: token
                        }
                        axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=32`)
                            .then((res) => {
                                axios.post(process.env.REACT_APP_SERVER + 'auth/loginviaapp', {...data, emailtext: res.data[0].thetext}).then((res) => {
                                    localStorage.setItem("login", true);
                                    localStorage.setItem('token', res.data.token)
                                    localStorage.setItem('email', res.data.users.email)
                                    localStorage.setItem('name', res.data.users.name)
                                    localStorage.setItem("isLogin", 1);
                                    localStorage.setItem("userid", res.data.users.id);
                                }).catch((err) => {
                                    console.log(err)
                                })
                            })
                    })
            }).catch((err) => {
            console.log(err)
        })
    }

    responseFacebook = (response) => {
        console.log(response)
        const data = {
            email: response.email,
            name: response.id,
            accessToken: response.accessToken,
            type: 'facebook'
        }
            axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=32`)
                .then((res) => {
                    this.signupFacebook({...data, emailtext: res.data[0].thetext})
                })
    }

    signupFacebook = (data) => {
        axios.post(process.env.REACT_APP_SERVER + 'auth/loginviaapp', data).then((res) => {
            localStorage.setItem("login", true);
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('email', res.data.users.email)
            localStorage.setItem('name', data.name)
            localStorage.setItem("isLogin", 1);
            localStorage.setItem("userid", res.data.users.id);
            this.props.history.push("/maindashboard");
        }).catch((err) => {
            console.log(err)
        })
    }

    handleClose = () => this.setState({show: false});

    handleAleart = () => {
        this.setState({show: true});
    };

    closeWelcomeModel = () => {
        this.setState({showWelcomeModel: false});
    };

    toggleVisibility = () => {
        this.setState({
            hidden: !this.state.hidden,
        });
    };

    toggleConfirmVisibility = () => {
        this.setState({
            confirmHidden: !this.state.confirmHidden,
        });
    };

    render() {
        const {
            show,
            firstname,
            lastname,
            email,
            phonenumber,
            password,
            cPassword,
            signuperrormessage,
            signuperrorpassword,
            signupsucess,
            showphone
        } = this.state;


        return (
            <>
                <HeaderNewLandingPage/>
                <Modal show={show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Password Rule</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className={"font-weight-bold"}>
                            Your password must be at least 8 characters long.
                        </Row>
                        <div className={"ml-3"}>
                            <Row>It cannot begin with a number or special character</Row>
                            <Row>It must contain at least 1 upper case letter</Row>
                            <Row>It must contain at least 1 lower case letter</Row>
                            <Row>It must contain at least 1 number</Row>
                            <Row>It must contain at least 1 special character</Row>
                            <Row>Valid Special Characters are:</Row>
                            <strong className={"ml-4"}>! @ # $ % ^ - _ ~ + .</strong>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleClose}>
                            Okay
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    className="Welcome_modal"
                    show={this.state.showWelcomeModel}
                    onHide={this.closeWelcomeModel}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title className="d-flex justify-content-center position-relative">
                            <div className="icon-box">
                                <i className="material-icons">&#xE876;</i>
                            </div>
                        </Modal.Title>
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <div
                                className="background-pattern-img background-loop"
                                style={{backgroundImage: `url(${pattern})`}}
                            />
                            <div className="background-pattern-gradient"/>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="mb-4">
                            <div className={"modal_title"}>
                                <h1>Welcome</h1>
                            </div>
                            <div>Your account has been created successfully.</div>
                        </div>
                        <Link to={"/maindashboard"} className="button button-standard">
                            <div className="button-inner">
                                <div className="button-content">
                                    <h4>Get Started</h4>
                                </div>
                            </div>
                        </Link>
                    </Modal.Body>
                </Modal>
                <div className="signup_form">
                    <div className="form_content">
                        <h2 className="form_title text-center">Sign Up</h2>
                        <Form action="/" onSubmit={(e) => this.submitSignupBtn(e)}>
                            {signuperrormessage && (
                                <Alert key="wrong-login" variant="danger">
                                    Sorry that Email already exists.
                                </Alert>
                            )}
                            {signuperrorpassword && (
                                <Alert key="wrong-login" variant="danger">
                                    Your passwords do not match!
                                </Alert>
                            )}
                            {showphone && (
                                <Alert key="wrong-login" variant="danger">
                                    The phone number does not appear to be valid!
                                </Alert>
                            )}
                            {signupsucess && (
                                <Alert key="wrong-login" variant="success">
                                    User created successfully
                                </Alert>
                            )}
                            <Form.Row>
                                <Col xl={6} lg={6}>
                                    <FormGroup>
                                        <Form.Control
                                            type="text"
                                            placeholder="First Name"
                                            value={firstname}
                                            onChange={(e) =>
                                                this.setState({firstname: e.target.value})
                                            }
                                            required/>
                                    </FormGroup>
                                </Col>
                                <Col xl={6} lg={6}>
                                    <FormGroup>
                                        <Form.Control
                                            type="text"
                                            placeholder="Last Name"
                                            value={lastname}
                                            onChange={(e) =>
                                                this.setState({lastname: e.target.value})
                                            }
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Form.Row>
                            <FormGroup>
                                <Form.Control
                                    type="email"
                                    placeholder="Email Address"
                                    required
                                    value={email}
                                    onChange={(e) =>
                                        this.setState({email: e.target.value})
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <PhoneNumberField
                                    phoneNumber={phonenumber}
                                    setPhoneNumber={(phonenumber) => {
                                        this.setState({
                                            phonenumber: phonenumber
                                        })
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="position-relative">
                                <Form.Control
                                    type={this.state.hidden ? "password" : "text"}
                                    placeholder="Password"
                                    required
                                    value={password}
                                    onChange={(e) =>
                                        this.setState({password: e.target.value})
                                    }/>
                                {this.state.hidden ? (
                                    <VisibilityOffIcon
                                        className="pass_show pass_show_signup"
                                        onClick={this.toggleVisibility}
                                    />
                                ) : (
                                    <VisibilityIcon
                                        className="pass_show pass_show_signup"
                                        onClick={this.toggleVisibility}
                                    />
                                )}
                                <ErrorOutlineIcon
                                    className="pass_show info-icon"
                                    onClick={this.handleAleart}
                                />
                            </FormGroup>
                            <FormGroup className="position-relative">
                                <Form.Control
                                    type={this.state.confirmHidden ? "password" : "text"}
                                    placeholder="Confirm Password"
                                    required
                                    value={cPassword}
                                    onChange={(e) =>
                                        this.setState({cPassword: e.target.value})
                                    }/>
                                {this.state.confirmHidden ? (
                                    <VisibilityOffIcon
                                        className="pass_show pass_show_signup"
                                        onClick={this.toggleConfirmVisibility}
                                    />
                                ) : (
                                    <VisibilityIcon
                                        className="pass_show pass_show_signup"
                                        onClick={this.toggleConfirmVisibility}
                                    />
                                )}
                            </FormGroup>
                            <Button variant="primary" type="submit" className="btn-lg btn-block custom-btn">
                                <span>Sign Up</span>
                            </Button>
                            <div>
                                <div className="googleLogin">
                                    <GoogleLogin
                                        className={'googleLoginBtn'}
                                        clientId="766078001860-4nnidh2hnfgqu02ekgsa7g9v59bo0k6d.apps.googleusercontent.com"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                    >
                                        <img src={GoogleIcon} alt="FacebookIcon" style={{marginRight: "10px"}}
                                             height="28px"/>
                                        <span>Continue with Google</span></GoogleLogin>
                                </div>
                                <div>
                                    <FacebookLoginWithButton
                                        className={'FbLoginButton'}
                                        appId="949279355668718"
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        callback={this.responseFacebook}
                                        textButton={"Continue with Facebook"}
                                        icon={<img src={FacebookIcon} alt="FacebookIcon"
                                                   style={{marginRight: "10px"}}/>}/>
                                </div>
                                {/*<div>*/}
                                {/*    <LinkedIn*/}
                                {/*        clientId="864pwkbncnk6f1"*/}
                                {/*        redirectUri={`${window.location.origin}/linkedin`}*/}
                                {/*        onSuccess={(code) => {*/}
                                {/*            console.log(code);*/}
                                {/*        }}*/}
                                {/*        scope={"r_emailaddress r_liteprofile"}*/}
                                {/*        onError={(error) => {*/}
                                {/*            console.log(error);*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        {({linkedInLogin}) => (*/}
                                {/*            <button onClick={linkedInLogin} className={"kep-login-facebook gap-2"}>*/}
                                {/*                <img*/}
                                {/*                    src={LinkedInIcon}*/}
                                {/*                    alt="Sign in with Linked In"*/}
                                {/*                    style={{marginRight: "10px"}}*/}
                                {/*                    height={28}*/}
                                {/*                    width={28}*/}
                                {/*                />*/}
                                {/*                <span>Continue with LinkedIn</span>*/}
                                {/*            </button>*/}
                                {/*        )}*/}
                                {/*    </LinkedIn>*/}
                                {/*</div>*/}
                            </div>
                        </Form>
                        <div className="bottom-link">
                            <Link to={"/new-login"}>Already have an account?</Link>
                        </div>
                    </div>
                </div>
                <FooterNewLandingPage/>
            </>
        );
    }
};

export default SignupPage;