import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";

export const isValidPhoneNumber = (phoneNumber = "") => {
    if(phoneNumber && phoneNumber.length > 2) {
        return phoneNumber.length === 12;
    }
    return true;
}

export const phoneNumber = (finalValue = "") => {
    if (finalValue?.length <= 2){
        return ""
    } else {
        return finalValue;
    }
}

export const getDraftContent = (element) => {
    return element
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(element))
        )
        : EditorState.createEmpty()
}

export const getHtmlElement = (element) => {
    return element ? draftToHtml(convertToRaw(element.getCurrentContent())) : "";
}