import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const ChildrenPage = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Children</h3>
            </div>
            <div className="content mt-4 mb-5">
                {Array(12).fill(1).map((ele,index)=>(
                    <Row className="mx-0 mb-2" key={index}>
                        <Col md="1" className="d-flex align-items-center p-0">
                            Name:
                        </Col>
                        <Col md="4">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                        <Col md="1" className="d-flex align-items-center p-0">
                            D.O.B.:
                        </Col>
                        <Col md="3">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                        <Col md="1" className="d-flex align-items-center p-0">
                            Gender:
                        </Col>
                        <Col md="2">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                ))}
                <div className="mt-4">
                    <TextField
                        variant="filled"
                        fullWidth={true}
                        size="small"
                        multiline={true}
                        rows={15}
                    />
                </div>
            </div>
        </div>
    );
};

export default ChildrenPage;