import React from 'react';
import '../Introduction/introduction.css'
import {Container, Row} from 'react-bootstrap';
import {Link} from "react-router-dom";

const Introduction = () => {
    return (
        <Container fluid className='introduction_container p-0'>
            <div className='introduction_content'>
                <Row className='justify-content-center m-0'>
                    <h1 className='heading'>
                        Is Your Family Prepared <br/> If “<span style={{color: "#007BFF"}}>YOU</span>” Have an Emergency?
                    </h1>
                </Row>
                <Row className='justify-content-center m-0'>
                    <p className='represent_heading'>
                        Ensure Peace of Mind with KeysToMyWishes! <br/>
                        Don't Be the Cause for Them to Panic in Crisis. <br/>
                        Keep Your Personal & Private Information Organized & Ready for Urgent Situations. <br/>
                        Take Action Today, Knowing That You Have Prepared Them for the Unexpected. <br/>

                    </p>
                </Row>
                <div className='helper_button'>
                    <Row className='justify-content-center d-flex align-items-start gap-2 m-0'>
                        <div className="d-flex flex-column">
                            <Link to="/new-signup" variant='' className='helper_btn mt-0'>Start For Free</Link>
                            <span className={"text-dark pt-1"}>Credit card not required</span>
                        </div>
                        <Link to="/how-to" variant='' className='helper_btn mt-0'>How it Works</Link>
                    </Row>
                </div>
            </div>
        </Container>
    );
};

export default Introduction;