import React, {useEffect} from 'react';
import './learn-more.css';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import {Link} from "react-router-dom";

const LearnMore = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section>
                <Container className='learn_more_section'>
                    <div className='learn_more_details'>
                        <Row className='justify-content-center m-0'>
                            <div className='details_title'>
                                <h2>The Best Of <span>KeysToMyWishes</span> , For The Best Of You</h2>
                                <p>We're creating a more conscious system by providing military-grade data security, check-ins for peace of mind, and seamless transition of conveying your important information and Wishes to ease the process for your family when you are not here.</p>
                            </div>
                        </Row>
                        <Row className='justify-content-center mt-5'>
                            <div className='learn_more_btn'>
                                <Link to="/about" variant="" className='btn_primarybtn'>Learn More</Link>
                            </div>
                        </Row>
                    </div>
                </Container>
            </section>


            <section className='counter'>
                <Container>
                    <Row className='justify-content-center m-0' >
                        <Col lg={6} md={6} sm={12}>
                            <Card className='grediant-card mt-4'>
                                <Card.Body className='value-card'>
                                    <p>If something were to happen to you TODAY, would your loved ones know where to find your end of life information? </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Card className='grediant-card mt-4'>
                                <Card.Body className='value-card'>
                                    <p>Will they have to sort through safes, cloud storages, hard drives, and mountains of paperwork
                                        throughout your home - adding stress to an already difficult time? </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} className='mt-4'>
                            <Card className='grediant-card'>
                                <Card.Body className='value-card'>
                                    <p>Are you certain that when it's your time, your last wishes will be conveyed to the right people?  </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} className='mt-4'>
                            <Card className='grediant-card pb-0 pt-3'>
                                <Card.Body className='value-card'>
                                    <p>Depending on the plan you choose we frequently check your well-being, and if the time comes; seamlessly share your wishes and legacy to your loved ones you have designated to receive them</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default LearnMore;