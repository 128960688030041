import React from "react";
import './HeaderNewLandingPage.css';
import keysNewLogo from "../../../assets/images/LandingPageNew/keytomywishesnew.png";
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import clsx from 'clsx';
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const NewHeaderPage = () => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="mobile_menu">
                <Link to="/" className="text-center">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/" className="btn btn-primary btn Landing_signup_btn">Security</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/features" className="text-center">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/features" className="btn Landing_signup_btn">Features</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/" className="text-center">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/" className="btn Landing_signup_btn">Digital Vault</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/" className="text-center">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/" className="btn Landing_signup_btn">About Us</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/contact" className="text-center">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/contact" className="btn Landing_signup_btn">Contact Us</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/pricing" className="text-center">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/pricing" className="btn Landing_signup_btn">Pricing</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-signup" className="text-center">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-signup" className="btn Landing_signup_btn">Sign Up</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-login" className="text-center">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-login" className="btn Landing_login_btn">Login</ListItemText>
                    </ListItem>
                </Link>
            </List>
        </div>
    );


    
    const anchor = 'right';
    return (
        <>
        <div className="header_menu">
            <ul className="menu_list">
                <li><Link to="/">Security</Link></li>
                <li><Link to="/features">Features</Link></li>
                <li><Link to="/">Digital Vault</Link></li>
                <li><Link to="/About-Us">About Us</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/pricing">Pricing</Link></li>
            </ul> 
            <ul className="menu_list">  
                <li><Link to="/new-signup">Sign Up</Link></li>
                <li><Link to="/new-login">Log In</Link></li>
            </ul>
            <div className="menu_bar">
                <MenuIcon onClick={toggleDrawer(anchor, true)}/>
            </div>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
            </Drawer>
        </div>
        </>
    )
}
export default NewHeaderPage;