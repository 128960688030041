import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import Notestable from "../../components/newNotestable/notestable";
import "./dashboard.css";
import { Link } from "react-router-dom";
import Back from "../../assets/images/Back_white.png";
import axios from "axios";
import genie_img from "../../assets/images/genie_image.jpg";
import Header from "../../components/NewHeader/Header";
import Logout from "../../components/logout/Logout";
import SessionExpired from "../../components/SessionExpired/SessionExpired";
import DraggebleModal from "../../components/DraggebleModal";

const Dashboard = () => {
  const [notesData, setNotesData] = useState([]);
  const [isLoadedNotesData, setIsLoadedNotesData] = useState(false);
  const [isProcess, setIsprocess] = useState(true);
  const [ExpiredToken, setExpired] = useState(false);
  const [selected, setSelected] = useState(null);
  const [showLegends, setShowLegends] = useState(false);
  const [planNotSelected, setSelectPlan] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);

  useEffect(()=>{
    if(notesData.length === 0 && !isLoadedNotesData){
      setShowLegends(true)
    }else {
      setShowLegends(false)
    }
  },[isLoadedNotesData])

  function getAllItems(contactId = 0) {
    setIsLoadedNotesData(true)
    var token = localStorage.getItem("token");
    const id = localStorage.getItem("userid");
    axios
      .get(
        process.env.REACT_APP_SERVER +
          `inventory/getbyuserid?nUserID=${id}&nContactID=${contactId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        if (res.data.status) {
          setExpired(true);
          setIsLoadedNotesData(false)
        } else {
          setNotesData(res.data);
          setIsLoadedNotesData(true);
          setIsprocess(false);
          setIsLoadedNotesData(false)
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoadedNotesData(false)
      });
  }

  const BackToClear = () => {
    localStorage.removeItem("isLoginFirstTime");
  };


  const handleClosePlan = () => {
    setSelectPlan(false);
  };

  const [deleteData, setDelete] = useState(false);
  const [rowId, setRowId] = useState(false);
  const handleDeleteBtn = (id) => {
    setRowId(id);
    setDelete(!deleteData);
    setDeleteModalShow(true);
  };

  const removeItems = (e) => {
    if (rowId) {
      const data = {
        nUserID: localStorage.getItem("userid"),
        nInvItemID: rowId,
      };

      var token = localStorage.getItem("token");
      axios
        .post(process.env.REACT_APP_SERVER + `inventory/deleteitem`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          getAllItems();
          setDeleteModalShow(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getSuggeson = () => {
    setShowLegends(true);
  };

  return (
    <>
      {ExpiredToken && <SessionExpired />}

      <Modal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ backgroundColor: "#0069d9", color: "white" }}>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            Are you sure you want to Delete this Wish Item?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModalShow(false)}>
            No
          </Button>
          <Button style={{ backgroundColor: "#0069d9" }} onClick={removeItems}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={planNotSelected} onHide={handleClosePlan}>
        <Modal.Header closeButton className={"modelHeader"}>
          <Modal.Title>Please Select Your Plan!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please Select Your Plan!</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ backgroundColor: "#0069d9" }}
            onClick={handleClosePlan}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>

      <Header title="MY WISHES" />
      <Row className="mx-auto text-center mobile-button back-logout-btn">
        <Col
          lg={6}
          md={6}
          sm={6}
          xs={6}
          className="p-2 text-start profile-left-btn d-flex btnall"
        >
          <Link to="/maindashboard" className="btn hoy-to-link-btn">
            <img src={Back} alt="Back" className="w-5" /> Back
          </Link>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6} className="btnall">
          <Logout />
        </Col>
      </Row>
      <img src={genie_img} alt="genie_img" className="genie ml-3" onClick={getSuggeson} />
      <Row className="bg-white p-3 m-0 justify-content-center">
        <Col md={12}>
          <div className="MyWishes-TableContainer">
            <Notestable
              rowId={rowId}
              deleteData={deleteData}
              handleDeleteBtn={handleDeleteBtn}
              selected={selected}
              setSelected={setSelected}
              notesData={notesData}
              getAllItems={getAllItems}
              isProcess={isProcess}
              BackToClear={BackToClear}
              isLoadedNotesData={isLoadedNotesData}
            />
          </div>
        </Col>
      </Row>
      <DraggebleModal
          screen={63}
          isOpen={showLegends}
          setOpenModal={setShowLegends}
      />
    </>
  );
};
export default Dashboard;
