import React, { Component, useState } from "react";
import "./HelpingSection.css";
import {Col, Row, Button} from 'react-bootstrap';
import rightIcon from "../../../assets/images/LandingPageNew/right_icon.svg";
import { Link } from "react-router-dom";
import Maskgroup1 from "../../../assets/images/Maskgroup1.svg";
import  Maskgroup2 from "../../../assets/images/Maskgroup2.svg";
import  Maskgroup3 from "../../../assets/images/Maskgroup3.svg";
import  Maskgroup4 from "../../../assets/images/Maskgroup4.svg";
import  Maskgroup5 from "../../../assets/images/Maskgroup5.svg";
import  Maskgroup6 from "../../../assets/images/Maskgroup6.svg";
import Back from '../../../assets/images/Back_white.png';



class NewPlanSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricingType: "yearly",
    };
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  render() {
    const {
      pricingType,
    } = this.state;
    return (
      <>
      <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Button onClick={this.handleBack} className="btn custom-link-btn btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Button>
                    </Col>
        </Row>
        <div className="service_plan_section">
          <div className="title text-center">
            <h2 className="service_plan_title">CHOOSE A PLAN AND START YOUR<br/>
              14-DAY TRIAL!</h2>
            <h6 className="service_plan_des">Save 2 Months Free with Annual Billing</h6>
            <div className="plan_btn_group">
              <button
                className={`${
                  pricingType === "monthly" ? "btn active" : "btn"
                }`}
                onClick={() => this.setState({ pricingType: "monthly" })}
              >
                Monthly
              </button>
              <button
                className={`${pricingType === "yearly" ? "btn active" : "btn"}`}
                onClick={() => this.setState({ pricingType: "yearly" })}
              >
                Annual
              </button>
            </div>
          </div>
        </div>

        <div className="service_plan_section">
          <div className="service_plan_main">
          <table className="text-center service_plan_table w-100">
            <tbody>
             <tr>
               <td className="sticky-col"></td>
               <td>
                 <div className="d-flex justify-content-center">
                 <div className="service_plan_box">
                   <img src={Maskgroup1}/>
                 </div>
                 </div>
               </td>
               <td>
                 <div className="d-flex justify-content-center">
                   <div className="service_plan_box">
                     <img src={Maskgroup2}/>
                   </div>
                 </div>
               </td>
               <td>
                 <div className="d-flex justify-content-center">
                   <div className="service_plan_box">
                     <img src={Maskgroup3}/>
                   </div>
                 </div>
               </td>
               <td>
                 <div className="d-flex justify-content-center">
                   <div className="service_plan_box">
                     <img src={Maskgroup4}/>
                   </div>
                 </div>
               </td>
               <td>
                 <div className="d-flex justify-content-center">
                   <div className="service_plan_box">
                     <img src={Maskgroup5}/>
                   </div>
                 </div>
               </td>
               <td>
                 <div className="d-flex justify-content-center">
                   <div className="service_plan_box">
                     <img src={Maskgroup6}/>
                   </div>
                 </div>
               </td>


             </tr>
            <tr>
              <td className="sticky-col"></td>
              <td>Yearly</td>
              <td>Quarterly</td>
              <td>Monthly</td>
              <td>Weekly</td>
              <td>3-days</td>
              <td>Daily</td>
            </tr>
            <tr>
              <td className="sticky-col"></td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
            </tr>
            <tr>
              <td className="text-left price-lable sticky-col">Price</td>
              <td>
                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $4.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $49.90<span>/yr</span>
                    </h2>
                )}
              </td>
              <td>

                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $6.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $69.90<span>/yr</span>
                    </h2>
                )}

              </td>
              <td>
                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $9.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $99.90<span>/yr</span>
                    </h2>
                )}
              </td>
              <td>
                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $14.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $149.90<span>/yr</span>
                    </h2>
                )}
              </td>
              <td>
                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $19.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $199.90<span>/yr</span>
                    </h2>
                )}
              </td>
              <td>
                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $24.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $249.90<span>/yr</span>
                    </h2>
                )}
              </td>
            </tr>
            <tr>
              <td className="text-left sticky-col">Check In Frequency</td>
              <td>Yearly</td>
              <td>Quarterly</td>
              <td>Monthly</td>
              <td>Weekly</td>
              <td>3 Days/Week</td>
              <td>Daily</td>
            </tr>
            <tr>
              <td className="text-left sticky-col"> KTMW Standard Features</td>
              <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
              <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
              <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
              <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
              <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
              <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
            </tr>
            <tr>
              <td className="sticky-col"></td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
          </div>
        </div>

      </>
    );
  }
}
export default NewPlanSection;
