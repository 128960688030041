import why_this_organize from "../PdfPages2/why_this_organize.pdf"
import our_mission from "../PdfPages2/our_mission.pdf"
import In_case_of_emergency from "../PdfPages2/In_case_of_emergency.pdf"
import My_information from "../PdfPages2/My_information.pdf"
import children from "../PdfPages2/children.pdf"
import pets from "../PdfPages2/pets.pdf"
import Employment_Information from "../PdfPages2/Employment_Information.pdf"
import Power_of_Attorney from "../PdfPages2/Power_of_Attorney.pdf"
import Attorney_Information from "../PdfPages2/Attorney_Information.pdf"
import Insurance from "../PdfPages2/Insurance.pdf"
import Bank_Accounts from "../PdfPages2/Bank_Accounts.pdf"
import password_digital_accounts from "../PdfPages2/password_digital_accounts.pdf"
import Credit_Cards from "../PdfPages2/Credit_Cards.pdf"
import Automobiles from "../PdfPages2/Automobiles.pdf"
import Business_Owned from "../PdfPages2/Business_Owned.pdf"
import Jewelry from "../PdfPages2/Jewelry.pdf"
import Heirlooms from "../PdfPages2/Heirlooms.pdf"
import Collectibles from "../PdfPages2/Collectibles.pdf"
import Real_Estate_Information from "../PdfPages2/Real_Estate_Information.pdf"
import upon_my_death_do_this from "../PdfPages2/upon_my_death_do_this.pdf"
import Trust from "../PdfPages2/Trust.pdf"
import Will from "../PdfPages2/Will.pdf"
import INCAPACITATION from "../PdfPages2/INCAPACITATION.pdf"
import Friends_and_Family from "../PdfPages2/Friends_and_Family.pdf"
import cherished_memories_with from "../PdfPages2/cherished_memories_with.pdf"
import Obituary from "../PdfPages2/Obituary.pdf"
import my_final_wishes from "../PdfPages2/my_final_wishes.pdf"
import my_story from "../PdfPages2/my_story.pdf"


export const pdfLists2 = [
    {file: "my_story",pdf: my_story, title: "My Story",hidden:true},
    {file: "why_this_organize",pdf: why_this_organize, title: "Why This Organizer"},
    {file: "our_mission",pdf: our_mission, title: "Our Mission"},
    {file: "In_case_of_emergency",pdf: In_case_of_emergency, title: "In Case of Emergency"},
    {file: "My_information",pdf: My_information, title: "My Information "},
    {file: "children",pdf: children, title: "Children"},
    {file: "pets",pdf: pets, title: "Pets"},
    {file: "Employment_Information",pdf: Employment_Information, title: "Employment Information"},
    {file: "Power_of_Attorney",pdf: Power_of_Attorney, title: "Power of Attorney"},
    {file: "Attorney_Information",pdf: Attorney_Information, title: "Attorney Information"},
    {file: "Insurance",pdf: Insurance, title: "Insurance"},
    {file: "Bank_Accounts",pdf: Bank_Accounts, title: "Bank Accounts"},
    {file: "password_digital_accounts",pdf: password_digital_accounts, title: "Password/Digital Accounts"},
    {file: "Credit_Cards",pdf: Credit_Cards, title: "Credit Cards"},
    {file: "Automobiles",pdf: Automobiles, title: "Automobiles "},
    {file: "Business_Owned",pdf: Business_Owned, title: "Business Owned"},
    {file: "Jewelry",pdf: Jewelry, title: "Jewelry"},
    {file: "Heirlooms",pdf: Heirlooms, title: "Heirlooms "},
    {file: "Collectibles",pdf: Collectibles, title: "Collectibles"},
    {file: "Real_Estate_Information",pdf: Real_Estate_Information, title: "Real Estate Information "},
    {file: "upon_my_death_do_this",pdf: upon_my_death_do_this, title: "Upon My Death Do This"},
    {file: "Trust",pdf: Trust, title: "Trust"},
    {file: "Will",pdf: Will, title: "Will"},
    {file: "INCAPACITATION",pdf: INCAPACITATION, title: "Incapacitation"},
    {file: "Friends_and_Family",pdf: Friends_and_Family, title: "Friends and Family "},
    {file: "cherished_memories_with",pdf: cherished_memories_with, title: "Cherished Memories With"},
    {file: "Obituary",pdf: Obituary, title: "Obituary"},
    {file: "my_final_wishes",pdf: my_final_wishes, title: "My Final Wishes"},
]