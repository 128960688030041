import React from 'react';
import {indexLists} from "./indexLists";

const TableOfContents = () => {
    const scrollIntoContent = (title) => {
        const htmlTextElements = document.querySelectorAll('h3');
        let targetElement;
        htmlTextElements.forEach((element) => {
            if (element.textContent.toLowerCase() === title.toLowerCase()) {
                targetElement = element.closest(".personal_affairs_container");
                return;
            }
        });
        targetElement.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }

    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Table of Contents</h3>
            </div>
            <div className="content mt-3 mb-5">
                {indexLists.map(({title, number}, index) => (
                    <div
                        key={index}
                        className="d-flex justify-content-between cursor-pointer mb-3"
                        onClick={() => scrollIntoContent(title)}
                    >
                        <h5>{title}</h5>
                        <h5>{number}</h5>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TableOfContents;