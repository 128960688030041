import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import '@pathofdev/react-tag-input/build/index.css';

const DeleteModal = ({ deleteModalShow, setDeleteModalShow, removeItems }) => {


	return (
		<>
			<Modal
				show={deleteModalShow}
				onHide={() => setDeleteModalShow(false)}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header style={{backgroundColor: "#0069d9", color: "white"}}>
					<Modal.Title>Confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text-center">
						Are you sure you want to Delete this Wish Item?
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setDeleteModalShow(false)}>
						No
					</Button>
					<Button
						style={{backgroundColor: "#0069d9"}}
						onClick={removeItems}
					>
						Yes
					</Button>
				</Modal.Footer>

			</Modal>
		</>
	);
};

export default DeleteModal;
