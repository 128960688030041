import React, {useEffect, useState} from 'react';
import './planlist.css';
import {Button, Card, Col, Container, Row, Table} from 'react-bootstrap';
import {IoCheckmarkSharp} from "react-icons/io5";
import Switch from 'react-switch'
import WishesVideo from '../../../../../assets/images/KeysToMyWishes.mp4'
import {ModalBody, Modal, ModalHeader, ModalFooter} from "reactstrap";
import {MdClose} from "react-icons/md";
import pricingModal from "../../../assest/images/pricingmodal.png"
import axios from "axios";
import {useHistory} from "react-router-dom";
import {wish} from "../../../../../utils/wishes.utils";

const PricingList = () => {
    const [isYearly, setIsYearly] = useState(true);
    const [showVideo, setShowVideo] = useState(false);
    const [showOffer, setShowOffer] = useState(false);
    const [showBookmodel, setShowBookModel] = useState(false);
    const [featuresData, setFeatureData] = useState([]);
    const [openFeaturesModal, setOpenFeaturesModal] = useState(false)
    const history = useHistory();
    const handleFeatureModal = (feature) => {
        if (feature) {
            axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=${feature}`)
                .then((res) => {
                    setFeatureData(res.data[0])
                    setOpenFeaturesModal(true)
                })
        }
    }
    useEffect(() => {
        const visitedBefore = localStorage.getItem('visitedBefore');
        if (!visitedBefore) {
            localStorage.setItem("visitedBefore", "false")
            setShowOffer(true);
        }

        if (visitedBefore === "false") {
            setShowOffer(true);
            localStorage.setItem('visitedBefore', "true");
        }
        window.addEventListener('beforeunload', () => {
            localStorage.setItem('visitedBefore', 'false');
        });
    }, []);

    const closeModal = () => {
        setShowOffer(false);
        localStorage.setItem("visitedBefore", "true");
    };
    const closeBookModal = () => {
        setShowBookModel(false);
    };
    const closeFeatureModal = () => {
        setOpenFeaturesModal(false);
        setFeatureData([])
    }
    const toggleVideo = () => {
        setShowVideo(!showVideo);
    };
    const handleSwitchChange = (checked) => {
        setIsYearly(checked);
    };
    const priceFontSize = {
        fontSize: '23px'
    }
    return (
        <section className='plan_list_table'>
            <Container fluid>
                <Row className='justify-content-center m-0'>
                    {/* <Col sm={12}>
                        <button className={"special_button_offer mx-auto"} onClick={() => setShowOffer(true)}> Special
                            Offer!
                        </button>
                    </Col> */}
                    {/*<Col sm={12} className='mb-4'>*/}
                    {/*    <div className='pricing_switch'>*/}
                    {/*        <p>Monthly</p>*/}
                    {/*        <Switch*/}
                    {/*            name={'pricing'}*/}
                    {/*            height={32}*/}
                    {/*            width={59}*/}
                    {/*            onChange={handleSwitchChange}*/}
                    {/*            checkedIcon={false}*/}
                    {/*            uncheckedIcon={false}*/}
                    {/*            checked={isYearly}*/}
                    {/*        />*/}
                    {/*        <p>Yearly</p>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col sm={12}>
                        <Table responsive bordered className='plans-table'>
                            <thead>
                            <tr className='bg-header'>
                                <th className={"pricing_header_cards"}>
                                    <h4 className='pricing-title'>Choose Your Plan</h4>
                                </th>
                                <th className={"pricing_header_cards"}>
                                    <Card className='plan_bg_card_pricing'>
                                        <Card.Body>
                                            <div className='plan_list_menu'>
                                                <span style={priceFontSize} className='book-title'>KeysToMyWishes</span>
                                                <h3 className='price m-0'>$16.99</h3>
                                                <span className={'book-subtitle'}> Personal Affairs Organizer </span>
                                            </div>
                                            {/*<p>$49.99/year<span>*</span>Save $9.98<span>*</span></p>*/}
                                        </Card.Body>
                                    </Card>
                                </th>
                                <th className={"pricing_header_cards"}>
                                    <Card className='plan_bg_card_pricing'>
                                        <Card.Body>
                                            <div className='plan_list_menu mt-1'>
                                                <span style={priceFontSize} className='book-title'> Break the Glass Service Plan</span>
                                                {!isYearly
                                                    ? <h3 className='price m-0'>$5.00</h3>
                                                    : <h3 className='price m-0'>$60.00</h3>
                                                }
                                               <span className='book-subtitle'>Billed Annually</span>

                                            </div>
                                            {/*<p>$49.99/year<span>*</span>Save $9.98<span>*</span></p>*/}
                                        </Card.Body>
                                    </Card>
                                </th>
                                {/*<th className={"pricing_header_cards"}>*/}
                                {/*    <Card className='plan_card_pricing'>*/}
                                {/*        <Card.Body>*/}
                                {/*            <div className='plan_list_menu mt-1'>*/}
                                {/*                <span style={priceFontSize} className='plan_bg_card_pricing_text'>Basic Plan</span>*/}
                                {/*                {!isYearly*/}
                                {/*                    ? <h3 className='plan_bg_card_pricing_text'>$7.00</h3>*/}
                                {/*                    : <h3 className='plan_bg_card_pricing_text'>$84.00</h3>*/}
                                {/*                }*/}
                                {/*                {!isYearly*/}
                                {/*                    ? <span style={priceFontSize}*/}
                                {/*                            className='plan_bg_card_pricing_text'>Per Month</span>*/}
                                {/*                    : <span style={priceFontSize}*/}
                                {/*                            className='plan_bg_card_pricing_text'>Per Year</span>*/}
                                {/*                }*/}
                                {/*            </div>*/}
                                {/*            /!*<p className="text-light">$69.90/year<span>*</span>Save $13.98<span>*</span></p>*!/*/}
                                {/*        </Card.Body>*/}
                                {/*    </Card>*/}
                                {/*</th>*/}
                                {/*<th className={"pricing_header_cards"}>*/}
                                {/*    <Card className='plan_bg_card_pricing'>*/}
                                {/*        <Card.Body>*/}
                                {/*            <div className='plan_list_menu mt-1'>*/}
                                {/*                <span style={priceFontSize} className='price'>Silver Plan</span>*/}
                                {/*                {!isYearly*/}
                                {/*                    ? <h3 className='price m-0'>$10.00</h3>*/}
                                {/*                    : <h3 className='price m-0'>$120</h3>*/}
                                {/*                }*/}
                                {/*                {!isYearly*/}
                                {/*                    ? <span style={priceFontSize} className='price'>Per Month</span>*/}
                                {/*                    : <span style={priceFontSize} className='price'>Per Year</span>*/}
                                {/*                }*/}
                                {/*            </div>*/}
                                {/*            /!*<p>$99.90/year<span>*</span>Save $19.98<span>*</span></p>*!/*/}
                                {/*        </Card.Body>*/}
                                {/*    </Card>*/}
                                {/*</th>*/}
                                {/*<th className={"pricing_header_cards"}>*/}
                                {/*    <Card className='plan_bg_card_pricing'>*/}
                                {/*        <Card.Body>*/}
                                {/*            <div className='plan_list_menu mt-1'>*/}
                                {/*                <span style={priceFontSize} className='price'>Gold Plan</span>*/}
                                {/*                {!isYearly*/}
                                {/*                    ? <h3 className='price m-0'>$15.00</h3>*/}
                                {/*                    : <h3 className='price m-0'>$180</h3>*/}
                                {/*                }*/}
                                {/*                {!isYearly*/}
                                {/*                    ? <span style={priceFontSize} className='price'>Per Month</span>*/}
                                {/*                    : <span style={priceFontSize} className='price'>Per Year</span>*/}
                                {/*                }*/}
                                {/*            </div>*/}
                                {/*            /!*<p>$99.90/year<span>*</span>Save $19.98<span>*</span></p>*!/*/}
                                {/*        </Card.Body>*/}
                                {/*    </Card>*/}
                                {/*</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            <tr className='bg-header'>
                                <th></th>
                                <th>
                                    <Button
                                        className={"book_buy_button"}
                                        onClick={() => wish.newTab("https://www.amazon.com/dp/B0D7V33HFM?ref_=pe_93986420_774957520")}
                                    >
                                        Buy organizer
                                    </Button>
                                    <p className={'payment-instraction'}>plus S&H</p>
                                </th>
                                <th><Button className={"yearly_button"} onClick={() => history.push('/new-signup')}>Start
                                    For Free</Button>
                                    <p className={"payment-instraction"}>No credit card required</p>
                                </th>
                                {/*<th><Button className={"quartely_button"} onClick={() => history.push('/new-signup')}>Start*/}
                                {/*    For Free</Button>*/}
                                {/*    <p className={"payment-instraction"}>No credit card required</p>*/}
                                {/*</th>*/}
                                {/*<th><Button className={"monthly_button"} onClick={() => history.push('/new-signup')}>Start*/}
                                {/*    For Free</Button>*/}
                                {/*    <p className={"payment-instraction"}>No credit card required</p>*/}
                                {/*</th>*/}
                                {/*<th><Button className={"weekly_button"} onClick={() => history.push('/new-signup')}>Start*/}
                                {/*    For Free</Button>*/}
                                {/*    <p className={"payment-instraction"}>No credit card required</p>*/}
                                {/*</th>*/}
                            </tr>
                            <tr className='bg-header'>
                                <th onClick={toggleVideo} className="cursor-pointer">
                                    <h4>Features</h4>
                                </th>
                                <th></th>
                                <th></th>
                                {/*<th></th>*/}
                                {/*<th></th>*/}
                                {/*<th></th>*/}
                            </tr>
                            <tr>
                                <td onClick={() => handleFeatureModal(76)} className="cursor-pointer"> Personal Affairs Organizer
                                </td>
                                <td><IoCheckmarkSharp/></td>
                                <td>{isYearly && <IoCheckmarkSharp/>}</td>
                                {/*<td>{isYearly && <IoCheckmarkSharp/>}</td>*/}
                                {/*<td>{isYearly && <IoCheckmarkSharp/>}</td>*/}
                                {/*<td>{isYearly && <IoCheckmarkSharp/>}</td>*/}
                            </tr>
                                {/*<tr>*/}
                                {/*    <td onClick={() => handleFeatureModal(77)} className="cursor-pointer">Check-in*/}
                                {/*        frequency*/}
                                {/*    </td>*/}
                                {/*    <td></td>*/}
                                {/*    <td className="pricing_table">Yearly</td>*/}
                                {/*    /!*<td className="pricing_table">Quarterly</td>*!/*/}
                                {/*    /!*<td className="pricing_table">Monthly</td>*!/*/}
                                {/*    /!*<td className="pricing_table">Weekly</td>*!/*/}
                                {/*</tr>*/}
                            <tr>
                                <td onClick={() => handleFeatureModal(44)} className="cursor-pointer">Real Life
                                    representatives
                                </td>
                                <td></td>
                                <td><IoCheckmarkSharp/></td>
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                            </tr>
                            <tr>
                            <td onClick={() => handleFeatureModal(45)} className="cursor-pointer">Two-way
                                    authentication
                                </td>
                                <td></td>
                                <td><IoCheckmarkSharp/></td>
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                            </tr>
                            <tr>
                                <td onClick={() => handleFeatureModal(52)} className="cursor-pointer">256 Bit
                                    encryption
                                </td>
                                <td></td>
                                <td><IoCheckmarkSharp/></td>
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                            </tr>
                            <tr>
                                <td onClick={() => handleFeatureModal(78)} className="cursor-pointer">Emergency cards
                                </td>
                                <td></td>
                                <td><IoCheckmarkSharp/></td>
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                            </tr>
                            <tr>
                                <td onClick={() => handleFeatureModal(79)} className="cursor-pointer">Storage space</td>
                                <td></td>
                                <td>2GB</td>
                                {/*<td>2GB</td>*/}
                                {/*<td>5GB</td>*/}
                                {/*<td>10GB</td>*/}
                            </tr>
                            <tr>
                                <td onClick={() => handleFeatureModal(80)} className="cursor-pointer">Biometric scan
                                </td>
                                <td></td>
                                <td><IoCheckmarkSharp/></td>
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                            </tr>
                            <tr>
                                <td onClick={() => handleFeatureModal(18)} className="cursor-pointer">Break-the-Glass
                                </td>
                                <td></td>
                                <td><IoCheckmarkSharp/></td>
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                            </tr>
                            <tr>
                                <td onClick={() => handleFeatureModal(51)} className="cursor-pointer">Free sharing</td>
                                <td></td>
                                <td><IoCheckmarkSharp/></td>
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                                {/*<td><IoCheckmarkSharp/></td>*/}
                            </tr>
                            </tbody>
                        </Table>
                        <div className={'mobile_plans_card'}>
                            <Card className='plan_card card mb-2'>
                                <Card.Body className="flex">
                                    <Card className='plan_bg_card_pricing mb-2'>
                                        <Card.Body className='m-auto'>
                                            <div className='plan_list_menu'>
                                                <span style={priceFontSize} className='book-title'>KeysToMyWishes</span>
                                                <h3 className='price m-0'>$16.99</h3>
                                                <span className={'book-subtitle'}> Personal Affairs Organizer </span>
                                            </div>
                                            {/*<p>$49.99/year<span>*</span>Save $9.98<span>*</span></p>*/}
                                        </Card.Body>
                                    </Card>
                                    <Table className='mobile_plans_table'>
                                        <tr className={""}>
                                            <td colSpan={"2"}>
                                                <Button className={"yearly_button"}
                                                        onClick={() => history.push('/new-signup')}>Start For
                                                    Free</Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td onClick={() => handleFeatureModal(76)} className="feature_title">Personal Estate
                                                Organizer
                                            </td>
                                            <td className={"check_mark_column"}>{isYearly && <IoCheckmarkSharp/>}</td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td className="feature_title"*/}
                                        {/*        onClick={() => handleFeatureModal(77)}>Check-in frequency*/}
                                        {/*    </td>*/}
                                        {/*    <td className={"check_mark_column"}>*/}
                                        {/*        <p className="pricing_table"></p>*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td className="feature_title" onClick={() => handleFeatureModal(44)}>Real
                                                Life representatives
                                            </td>
                                            <td className={"check_mark_column"}></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title" onClick={() => handleFeatureModal(45)}>Two-way
                                                authentication
                                            </td>
                                            <td className={"check_mark_column"}></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title" onClick={() => handleFeatureModal(52)}>256 Bit
                                                encryption
                                            </td>
                                            <td className={"check_mark_column"}></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title"
                                                onClick={() => handleFeatureModal(78)}>Emergency cards
                                            </td>
                                            <td className={"check_mark_column"}></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title" onClick={() => handleFeatureModal(79)}>Storage
                                                space
                                            </td>
                                            <td className={"check_mark_column"}></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title"
                                                onClick={() => handleFeatureModal(80)}>Biometric scan
                                            </td>
                                            <td className={"check_mark_column"}></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title"
                                                onClick={() => handleFeatureModal(18)}>Break-the-Glass
                                            </td>
                                            <td className={"check_mark_column"}></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title" onClick={() => handleFeatureModal(51)}>Free
                                                sharing
                                            </td>
                                            <td className={"check_mark_column"}></td>
                                        </tr>
                                    </Table>
                                    {/*<p>$49.99/year<span>*</span>Save $9.98<span>*</span></p>*/}
                                </Card.Body>
                            </Card>
                            <Card className='plan_card card mb-2'>
                                <Card.Body className={"flex justify-content-end"}>
                                    <Card className='plan_bg_card_pricing mb-2'>
                                        <Card.Body className='m-auto'>
                                            <div className='plan_list_menu mt-1'>
                                                <span style={priceFontSize} className='book-title text-center'> Break the Glass Service Plan</span>
                                                {!isYearly
                                                    ? <h3 className='price m-0'>$5.00</h3>
                                                    : <h3 className='price m-0'>$60.00</h3>
                                                }
                                                <span className='book-subtitle'>Billed Annually</span>

                                            </div>
                                            {/*<p>$49.99/year<span>*</span>Save $9.98<span>*</span></p>*/}
                                        </Card.Body>
                                    </Card>
                                    <Table className='mobile_plans_table'>
                                        <tr className={""}>
                                            <td colSpan={"2"}>
                                                <Button className={"quartely_button"}
                                                        onClick={() => history.push('/new-signup')}>Start For
                                                    Free</Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td onClick={() => handleFeatureModal(76)} className="feature_title">Estate
                                                Organizer
                                            </td>
                                            <td className={"check_mark_column"}>{isYearly && <IoCheckmarkSharp/>}</td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td className="feature_title"*/}
                                        {/*        onClick={() => handleFeatureModal(77)}>Check-in frequency*/}
                                        {/*    </td>*/}
                                        {/*    <td className={"check_mark_column"}><p*/}
                                        {/*        className="pricing_table">Quarterly</p>*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td className="feature_title" onClick={() => handleFeatureModal(44)}>Real
                                                Life representatives
                                            </td>
                                            <td className={"check_mark_column"}><IoCheckmarkSharp/></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title" onClick={() => handleFeatureModal(45)}>Two-way
                                                authentication
                                            </td>
                                            <td className={"check_mark_column"}><IoCheckmarkSharp/></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title" onClick={() => handleFeatureModal(52)}>256 Bit
                                                encryption
                                            </td>
                                            <td className={"check_mark_column"}><IoCheckmarkSharp/></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title"
                                                onClick={() => handleFeatureModal(78)}>Emergency cards
                                            </td>
                                            <td className={"check_mark_column"}><IoCheckmarkSharp/></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title" onClick={() => handleFeatureModal(79)}>Storage
                                                space
                                            </td>
                                            <td className={"check_mark_column"}>2GB</td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title"
                                                onClick={() => handleFeatureModal(80)}>Biometric scan
                                            </td>
                                            <td className={"check_mark_column"}><IoCheckmarkSharp/></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title"
                                                onClick={() => handleFeatureModal(18)}>Break-the-Glass
                                            </td>
                                            <td className={"check_mark_column"}><IoCheckmarkSharp/></td>
                                        </tr>
                                        <tr>
                                            <td className="feature_title" onClick={() => handleFeatureModal(51)}>Free
                                                sharing
                                            </td>
                                            <td className={"check_mark_column"}><IoCheckmarkSharp/></td>
                                        </tr>
                                    </Table>
                                    {/*<p>$49.99/year<span>*</span>Save $9.98<span>*</span></p>*/}
                                </Card.Body>
                            </Card>
                            {/*<Card className='plan_card card mb-2'>*/}
                            {/*    <Card.Body className={"flex justify-content-end"}>*/}
                            {/*        <Card className='plan_bg_card_pricing mb-2'>*/}
                            {/*            <Card.Body className='m-auto'>*/}
                            {/*                <div className='plan_list_menu '>*/}
                            {/*                    <span style={priceFontSize} className='price'>Silver Plan</span>*/}
                            {/*                    {!isYearly*/}
                            {/*                        ? <h3 className='price m-0 text-center'>$10.00</h3>*/}
                            {/*                        : <h3 className='price m-0'>$120</h3>*/}
                            {/*                    }*/}
                            {/*                    {!isYearly*/}
                            {/*                        ? <span style={priceFontSize} className='price'>Per Month</span>*/}
                            {/*                        : <span style={priceFontSize} className='price'>Per Year</span>*/}
                            {/*                    }*/}
                            {/*                </div>*/}
                            {/*                /!*<p>$49.99/year<span>*</span>Save $9.98<span>*</span></p>*!/*/}
                            {/*            </Card.Body>*/}
                            {/*        </Card>*/}
                            {/*        <Table className='mobile_plans_table'>*/}
                            {/*            <tr className={""}>*/}
                            {/*                <td colSpan={"2"}>*/}
                            {/*                    <Button className={"monthly_button"}*/}
                            {/*                            onClick={() => history.push('/new-signup')}>Start For*/}
                            {/*                        Free</Button>*/}
                            {/*                </td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td onClick={() => handleFeatureModal(76)} className="feature_title">Estate*/}
                            {/*                    Organizer*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}>{isYearly && <IoCheckmarkSharp/>}</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title"*/}
                            {/*                    onClick={() => handleFeatureModal(77)}>Check-in frequency*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><p*/}
                            {/*                    className="pricing_table">Monthly</p></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title" onClick={() => handleFeatureModal(44)}>Real*/}
                            {/*                    Life representatives*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title" onClick={() => handleFeatureModal(45)}>Two-way*/}
                            {/*                    authentication*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title" onClick={() => handleFeatureModal(52)}>256 Bit*/}
                            {/*                    encryption*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title"*/}
                            {/*                    onClick={() => handleFeatureModal(78)}>Emergency cards*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title" onClick={() => handleFeatureModal(79)}>Storage*/}
                            {/*                    space*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}>5GB</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title"*/}
                            {/*                    onClick={() => handleFeatureModal(80)}>Biometric scan*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title"*/}
                            {/*                    onClick={() => handleFeatureModal(18)}>Break-the-Glass*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title" onClick={() => handleFeatureModal(51)}>Free*/}
                            {/*                    sharing*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*        </Table>*/}
                            {/*        /!*<p>$49.99/year<span>*</span>Save $9.98<span>*</span></p>*!/*/}
                            {/*    </Card.Body>*/}
                            {/*</Card>*/}
                            {/*<Card className='plan_card card mb-2'>*/}
                            {/*    <Card.Body className={"flex justify-content-end"}>*/}
                            {/*        <Card className='plan_bg_card_pricing mb-2'>*/}
                            {/*            <Card.Body className='m-auto'>*/}
                            {/*                <div className='plan_list_menu '>*/}
                            {/*                    <span style={priceFontSize} className='price'>Gold Plan</span>*/}
                            {/*                    {!isYearly*/}
                            {/*                        ? <h3 className='price m-0 text-center'>$15.00</h3>*/}
                            {/*                        : <h3 className='price m-0'>$180</h3>*/}
                            {/*                    }*/}
                            {/*                    {!isYearly*/}
                            {/*                        ? <span style={priceFontSize} className='price'>Per Month</span>*/}
                            {/*                        : <span style={priceFontSize} className='price'>Per Year</span>*/}
                            {/*                    }*/}
                            {/*                </div>*/}
                            {/*                /!*<p>$49.99/year<span>*</span>Save $9.98<span>*</span></p>*!/*/}
                            {/*            </Card.Body>*/}
                            {/*        </Card>*/}
                            {/*        <Table className='mobile_plans_table'>*/}
                            {/*            <tr className={""}>*/}
                            {/*                <td colSpan={"2"}>*/}
                            {/*                    <Button className={"weekly_button"}*/}
                            {/*                            onClick={() => history.push('/new-signup')}>Start For*/}
                            {/*                        Free</Button>*/}
                            {/*                </td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td onClick={() => handleFeatureModal(76)} className="feature_title">Estate*/}
                            {/*                    Organizer*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}>{isYearly && <IoCheckmarkSharp/>}</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title"*/}
                            {/*                    onClick={() => handleFeatureModal(77)}>Check-in frequency*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><p className="pricing_table">Weekly</p>*/}
                            {/*                </td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title" onClick={() => handleFeatureModal(44)}>Real*/}
                            {/*                    Life representatives*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title" onClick={() => handleFeatureModal(45)}>Two-way*/}
                            {/*                    authentication*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title" onClick={() => handleFeatureModal(52)}>256 Bit*/}
                            {/*                    encryption*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title"*/}
                            {/*                    onClick={() => handleFeatureModal(78)}>Emergency cards*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title" onClick={() => handleFeatureModal(79)}>Storage*/}
                            {/*                    space*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}>10GB</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title"*/}
                            {/*                    onClick={() => handleFeatureModal(80)}>Biometric scan*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title"*/}
                            {/*                    onClick={() => handleFeatureModal(18)}>Break-the-Glass*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td className="feature_title" onClick={() => handleFeatureModal(51)}>Free*/}
                            {/*                    sharing*/}
                            {/*                </td>*/}
                            {/*                <td className={"check_mark_column"}><IoCheckmarkSharp/></td>*/}
                            {/*            </tr>*/}
                            {/*        </Table>*/}
                            {/*        /!*<p>$49.99/year<span>*</span>Save $9.98<span>*</span></p>*!/*/}
                            {/*    </Card.Body>*/}
                            {/*</Card>*/}
                        </div>
                    </Col>
                </Row>
            </Container>
            {showVideo && (
                <Modal
                    isOpen={showVideo}
                    className='helper_video_frame d-flex justify-content-center'
                    fullscreen={true}
                    size='xl'
                >
                    <ModalHeader className="d-flex justify-content-end">
                        <button className='btn' onClick={() => setShowVideo(false)}><MdClose size={26}/></button>
                    </ModalHeader>
                    <ModalBody className='helper_video p-4'>
                        <video width="500" height="503" controls>
                            <source src={WishesVideo} type="video/mp4"/>
                        </video>
                    </ModalBody>
                </Modal>
            )}
            <Modal
                // isOpen={showOffer}
                isOpen={false}
                className='offer-frame d-flex justify-content-center'
                size={'lg'}
                toggle={closeModal}
            >
                <ModalHeader className="p-0 m-0 pricing_modal ">
                    <div className={"pricing_header mb-2"}>
                        <div className={"w-100 ml-5"}>
                            <h3 className={"text-center"}>Limited Time Offer</h3>
                            <p className={"text-center"}>
                                Offer expires <span className={""}>April 30th, 2024</span>
                            </p>
                        </div>
                        <button className='btn' onClick={closeModal}><MdClose size={26}/></button>
                    </div>
                </ModalHeader>
                <ModalBody className='helper_video p-2'>
                    <img src={pricingModal} className={"offer-img"}/>
                </ModalBody>
            </Modal>
            <Modal
                // isOpen={showOffer}
                isOpen={showBookmodel}
                className='offer-frame d-flex justify-content-center'
                toggle={closeBookModal}
            >
                <ModalHeader className="p-0 m-0 pricing_modal " style={{backgroundColor:"#54a0f3"}}>
                    <div className={"d-flex justify-content-end mb-2"} >
                        <button className='btn' onClick={closeBookModal}><MdClose size={26}/></button>
                    </div>
                </ModalHeader>
                <ModalBody className='helper_video p-2'>
                    <div className={'px-2 py-3 text-center'}>
                        <span className={"font-weight-bold"}> KeysToMyWishes </span>Personal Affairs Organizer will be available on Amazon soon!
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={openFeaturesModal}>
                <ModalHeader closeButton className={'modelHeader'}>
                    {/*<h5>{featuresData.theheader}</h5>*/}
                    <div className="model-genie"
                         dangerouslySetInnerHTML={{__html: featuresData.theheader}}
                    >
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="model-genie"
                         dangerouslySetInnerHTML={{__html: featuresData.thetext}}
                    >
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btngenie" onClick={() => closeFeatureModal()}>
                        Okay
                    </Button>
                </ModalFooter>
            </Modal>
        </section>
    );
};

export default PricingList;