import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const CreditCards = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Credit Cards</h3>
            </div>
            {Array(4).fill(1).map((ele,index)=> (
                <div className="content mt-3 mb-5" key={index}>
                    {["Institution:", "Address:", "City:"].map((title) => (
                        <Row className="mx-0" key={title}>
                            <Col md="2 pl-0">
                                {title}
                            </Col>
                            <Col md="5 pr-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                        </Row>
                    ))}
                    <Row className="mx-0">
                        <Col md="2 pl-0" className="d-flex align-items-center">
                            State:
                        </Col>
                        <Col md="2 pr-0">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                        <Col md="1" className="d-flex align-items-center justify-content-center bg-white">
                            Zip:
                        </Col>
                        <Col md="2 pr-0">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col md="2 pl-0" className="d-flex align-items-center">
                            Account #:
                        </Col>
                        <Col md="5 pr-0">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                        <Col md="2" className="d-flex align-items-center justify-content-center bg-white">
                            Type:
                        </Col>
                        <Col md="3 pl-0">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col md="2 pl-0" className="d-flex align-items-center">
                            Acct. Rep:
                        </Col>
                        <Col md="5 pr-0">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                        <Col md="2" className="d-flex align-items-center justify-content-center bg-white">
                            Phone #:
                        </Col>
                        <Col md="3 pl-0">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    );
};

export default CreditCards;