import React, {useEffect, useState} from 'react';
import Header from "../../components/NewHeader/Header";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import Back from "../../assets/images/Back_white.png";
import {Link, useHistory} from "react-router-dom";
import "./how-to-screen.css"
import axios from "axios";
import keysNewLogo from "../../screens/PrivatePeopleLandingNew/assets/images/logo.png"

const HowToScreen = () => {
    let history = useHistory()
    const [readmeContent, setReadmeContent] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [howToVideo, setHowToVideo] = useState([]);
    const [currentVideo, setCurrentVideo] = useState('');

    useEffect(()=>{
        getReadMe();
        getHowToData();
    },[])
    const getHowToData = () =>{
        let token = localStorage.getItem("token");
        axios.get(process.env.REACT_APP_SERVER + `gethowtomedia`,)
            .then((res)=>{
                setHowToVideo(res.data)
            })
    }
    // const getReadmeData = (id) =>{
    //     console.log(id)
    //       const data = readmeContent.filter((e) =>  e.whichscreen === id)
    //     console.log(data)
    //     return data[0]?.thetext
    // }
    const getReadMe = () => {
        return axios.get(process.env.REACT_APP_SERVER + `readme`)
            .then((res) => {
                setReadmeContent(res.data);
            }).catch((err) => {
                console.log(err)
            })
    }
    const handleBack = () => {
        history.goBack()
    }
    const imgUrl = `/howtomedia/${currentVideo}`
    return (
        <>
            <Header title="HOW TO" />
            <Row className="mx-auto text-center mobile-button back-logout-btn">
                <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="p-2 text-start profile-left-btn d-flex btnall"
                >
                    <Button onClick={handleBack} className="btn hoy-to-link-btn btn-primary">
                        <img src={Back} alt="Back" className="w-5"/> Back
                    </Button>
                </Col>
            </Row>
            <Row className="mx-auto text-center mobile-button back-logout-btn">
                <Col
                    className="p-2 text-start profile-left-btn d-flex btnall border-0 pl-5"
                >
                    <Link to='/' className={"border-0"}>
                        <img src={keysNewLogo} className="logo_img" alt={"logo"} width={200}/>
                    </Link>
                </Col>
            </Row>
            {(howToVideo || []).map((row)=>{
                const image = row.thumbnail.replace('jpg','png')
                return(
                    <div className="how_to_video_container mt-5">
                        <div className={"video-container"} onClick={() => {
                            setIsOpen(true)
                            setCurrentVideo(row.mediaName)
                        }}>
                            <h3 dangerouslySetInnerHTML={{__html: row.theHeader}} className={"how_to_header mb-4"}/>
                            <img
                                src={`/howtomedia/${image}`}
                                className="how_to_video cursor-pointer"
                                width={300}

                            />
                        </div>
                        <div
                            onClick={() => {
                                setIsOpen(true)
                                setCurrentVideo(row.mediaName)
                            }}
                            className={"cursor-pointer"}
                        >
                            <h2 dangerouslySetInnerHTML={{__html:row.theHeader}} className={"how_body_to_header mb-4"}/>
                            <p  dangerouslySetInnerHTML={{__html:row.theText}} />
                        </div>
                    </div>
                )
            })}

            <Modal
                show={isOpen}
                onHide={()=>setIsOpen(false)}
                size="xl"
                centered
            >
                <Modal.Header closeButton className={'modelHeader'} style={{fontFamily: 'Century'}}>
                </Modal.Header>
                <Modal.Body style={{fontFamily: 'Century'}}>
                 <div className={"d-flex justify-content-center"}>
                         <video
                             controls
                             autoPlay
                             height="600"
                             src={imgUrl}
                             className="how_to_video"
                             width={"100%"}
                         />
                 </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default HowToScreen;