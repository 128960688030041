import React, {useState} from 'react';
import {Box, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import StandardTableHead from "../../StandardTableHead";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import {Button} from "react-bootstrap";
import axios from "axios";
import {useLocation, useParams} from "react-router-dom";
import HandleConfirmation from "../../../screens/handleConfirmation";
import {validateDate} from "@mui/x-date-pickers/internals";

const headCells = [
    {
        disablePadding: true,
        label: 'File Name',
        align: 'left',
    },
    {
        numeric: true,
        disablePadding: false,
        label: 'File Size',
        align: 'left',
    },
    {
        numeric: true,
        disablePadding: false,
        label: 'Upload Date',
        align: 'center',
    },
    {
        numeric: true,
        disablePadding: false,
        label: 'Action',
        align: 'center',
    }
];

const ShowFilesList = (props) => {
    let id = useLocation().search;
    const rowid = id.replace('?', '');
    const {mediaDetails} = props;
    const [isLoading,setIsLoading] = useState(null);
    const [nMediaId, setMediaId] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = React.useState(false);

    const handleDeleteBtn = (id = null) => {
        setMediaId(id);
        setDeleteModalShow(true);
    };

    const downloadImage = (row) => {
        var token = localStorage.getItem('token');
        const userId = localStorage.getItem('userid');
        setIsLoading(row.id);
        axios.get(process.env.REACT_APP_SERVER + `gets3file?filename=${row.s3filename}&nUserID=${userId}`,
            {headers: {Authorization: `Bearer ${token}`}})
            .then((res) => {
                let link = document.createElement('a');
                link.setAttribute('href', res.data.url);
                link.setAttribute('download', row.filename);
                link.click();
                setIsLoading(null);
            }).catch((err) => {
            setIsLoading(null);
            console.log(err)
        })
    }

    function saveMedia(file = {}) {
        const token = localStorage.getItem("token");

        var saveFiledata = new FormData();
        saveFiledata.append('nUserID', localStorage.getItem('userid'));
        saveFiledata.append('nInvItemID', rowid);
        saveFiledata.append('imgfile', file);

        return axios.post(process.env.REACT_APP_SERVER + 'uploads3file',saveFiledata,
                {headers: {Authorization: `Bearer ${token}`}}
            )
            .then((res) => {
                var data = new FormData();
                data.append('nUserID', localStorage.getItem('userid'));
                data.append('nInvItemID', rowid);
                data.append('s3FileName', res.data.filename);
                data.append('nFileSize', file.size);
                data.append('sFileName', file.name);
                axios.post(process.env.REACT_APP_SERVER + 'inventory/addmedia', data,
                    {headers: {Authorization: `Bearer ${token}`}}
                ).then(() => {
                    props.getMedia();
                    setDeleteModalShow(false);
                }).catch((err) => {
                    console.log(err);
                });
            })
            .catch((err) => {
                console.log(err);
            })
    }


    const removeItems = () => {
        var token = localStorage.getItem("token");
        const data = {
            nUserID: localStorage.getItem("userid"),
            nInvItemID: rowid,
            nID:nMediaId
        };

        axios
            .post(process.env.REACT_APP_SERVER + `inventory/deletemedia`, data, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                props.getMedia();
                setDeleteModalShow(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Box sx={{ border:"1px solid #ced4da", p:2 }}>
            <Box
                sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    alignItems:"center",
                    mb:2
                }}
            >
                <Typography gutterBottom variant="body1" style={{fontWeight:"600"}}>Item Attachments</Typography>
                <input
                    type="file"
                    id="file_attchment"
                    className="d-none"
                    accept="image/*"
                    onChange={(e)=>saveMedia(e.target.files[0])}
                />
                <Button onClick={()=>document.querySelector("#file_attchment").click()}>Upload</Button>
            </Box>
            <TableContainer>
                <Table stickyHeader>
                    <StandardTableHead headCells={headCells}/>
                    <TableBody>
                        {(mediaDetails || []).map((media,index)=>(
                            <TableRow hover key={index}>
                                <TableCell
                                    component="th"
                                    align="left"
                                    style={{wordBreak: 'break-all'}}
                                >
                                    {media["filename"]}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    align="left"
                                    style={{wordBreak: 'break-all'}}
                                >
                                    {media["filesize"]}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    align="center"
                                    style={{wordBreak: 'break-all'}}
                                >
                                    {media["created"] ? moment(media["created"]).format("ll") : "-"}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    align="center"
                                    style={{wordBreak: 'break-all'}}
                                >
                                    <div>
                                        <Button
                                            variant="info"
                                            className="mx-2 btn-sm-height"
                                            onClick={() => downloadImage(media)}
                                            disabled={isLoading === media.id}
                                        >
                                            Download
                                            {isLoading === media.id && <Spinner animation="border" className="ml-2" size="sm"/>}
                                        </Button>
                                        <Button
                                            variant="danger"
                                            className="mx-2 btn-sm-height"
                                            onClick={()=>handleDeleteBtn(media.id)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <HandleConfirmation
                isOpen={deleteModalShow}
                handleClose={() => setDeleteModalShow(false)}
                onConfirm={removeItems}
                question="Are you sure you want to Delete this Wish Item?"
                yesText="Yes"
                noText="No"
            />
        </Box>
    );
};

export default ShowFilesList;