import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const FriendsAndFamily = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Friends and Family</h3>
            </div>
            <Row className="mt-4">
                <Col md={4}>
                    Name:
                </Col>
                <Col md={4}>
                    Phone:
                </Col>
                <Col md={4}>
                    Email:
                </Col>
            </Row>
            {Array(25).fill(1).map((value, index)=>(
                <Row key={index}>
                    <Col md={4}>
                        <TextField fullWidth={true}/>
                    </Col>
                    <Col md={4}>
                        <TextField fullWidth={true}/>
                    </Col>
                    <Col md={4}>
                        <TextField fullWidth={true}/>
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default FriendsAndFamily;