import React from 'react';
import {Button, Modal} from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import CloseIcon from '@mui/icons-material/Close';


const HandleConfirmation = (props) => {
    const {
        isOpen,
        isLoading,
        handleClose,
        onConfirm,
        title,
        question,
        yesText,
        noText,
        color,
        size,
        centered,
        image,
        bodyClass,
        imageSize,
        modalFooter,
        showHeader,
        showCloseIcon
    } = props;

    const data = {
        "primary":"#0069d9",
        "secondary":""
    }
    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            style={{zIndex: 1051}}
            centered={centered}
            size={size}
        >
            {showHeader && (
                <Modal.Header style={{backgroundColor: "#0069d9", color: "white"}}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body className={bodyClass}>
                {showCloseIcon && (
                  <div className="d-flex justify-content-end">
                      <CloseIcon className="cursor-pointer" onClick={handleClose}/>
                  </div>
                )}
                {image && (
                    <img src={image} width={imageSize} alt={image}/>
                )}
                {question}
            </Modal.Body>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onConfirm();
                }}
            >
                {modalFooter && (
                    <Modal.Footer className={bodyClass}>
                        {noText && (
                            <Button type="button" variant="secondary" onClick={handleClose}>
                                No
                            </Button>
                        )}
                        <Button type="submit" style={{backgroundColor: data[color]}} className="invite_btn">
                            {isLoading ? <CircularProgress/> : <span>{yesText ? yesText : "Yes"}</span>}
                        </Button>
                    </Modal.Footer>
                )}
            </form>
        </Modal>
    );
};

HandleConfirmation.defaultProps = {
    noText:"No",
    title:"Confirmation",
    color:"primary",
    centered:true,
    modalFooter:true,
    showHeader:true,
    showCloseIcon:false,
}

HandleConfirmation.propTypes ={
    title:PropTypes.string,
    color:PropTypes.oneOf(["primary","secondary"]),
    isOpen:PropTypes.bool
}

export default HandleConfirmation;