import React from 'react';
import Header from './Header';
import Introduction from './Introduction';
import LearnMore from './LearnMore';
import WhyChoose from '../../WhyChoose';
import EasySteps from './EasySteps';
import Reviews from '../../Reviews';
import Footer from './Footer';
import MultipalReviews from "../../../Landing/MultipalReviews";


const OrganizerLandingPage = () => {
    return (
        <div>
            <Header/>
            {/*<Introduction/>*/}
            <LearnMore/>
            <MultipalReviews/>
            {/*<WhyChoose/>*/}
            {/*<EasySteps/>*/}
            <Reviews/>
            <Footer/>
        </div>
    );
};

export default OrganizerLandingPage;