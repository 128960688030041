import React, { useState, useEffect } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";
import { Row, Form, Button, Toast, Modal } from "react-bootstrap";
import axios from "axios";
import "@pathofdev/react-tag-input/build/index.css";
import "./Layout/notesform.css";
import AddNewItemContainer from "./AddNewItemModals/AddNewItemContainer";
import Section1 from "./Section1";
import Section2 from "./Section2";
import DeleteModal from "./DeleteModal";
import SessionExpired from "../SessionExpired/SessionExpired";
import Checkbox from "@material-ui/core/Checkbox";
import Notestable from "../newNotestable/notestable";
import Menutiles from "../../assets/images/menutile.png";
import ViewListIcon from "@material-ui/icons/ViewList";
import $ from "jquery";
import { MenuItem, Select } from "@material-ui/core";

const Notesform = ({
  BackToClear,
  getAllItems,
  selected,
  setSelected,
  tile,
  isLoadedNotesData,
  notesData,
                     currentUser
}) => {
  const [itemName, setItemName] = useState("");
  const [itemNotes, setItemNotes] = useState("");
  const [existingSharedReleaseWithTags, setExistingSharedReleaseWithTags] =
    useState([]);
  const [existingSharedContacts, setExistingSharedContacts] = useState([]);

  const [existingFiles, setExistingFiles] = useState([]);
  const [files, setFiles] = useState([]);

  const [deleteModalShow, setDeleteModalShow] = React.useState(false);

  const [searchData, setSearchData] = useState({});
  const [showTost, setShowTost] = useState(false);
  const [showUseWizard, setShowUseWizard] = React.useState(true);

  const history = useHistory();
  const rowid = selected;
  const [TokenExpired, setTokenExpired] = useState(false);
  const [contactdata, setContactdata] = useState([]);
  const [selectedContactID, setSelectedContactID] = useState("");

  useEffect(() => {
    const data = { nUserID: localStorage.getItem("userid") };
    axios
      .post(process.env.REACT_APP_SERVER + "contact/listall", data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.data.status) {
          setTokenExpired(true);
        } else {
          setContactdata(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (rowid) {
      getDetails();
    }
  }, [rowid]);

  const getDetails = () => {
    setFiles([]);
    const data = {
      nUserID: localStorage.getItem("userid"),
      nID: rowid,
    };
    var token = localStorage.getItem("token");
    axios
      .post(process.env.REACT_APP_SERVER + "inventory/getdetails", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.status) {
          setTokenExpired(true);
        } else {
          setSearchData((res.data || []).length ? res.data[0] : {});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setItemName(searchData !== undefined ? searchData.itemname : "");
    setItemNotes(searchData !== undefined ? searchData.itemnotes : "");
  }, [searchData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTost(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showTost]);

  const toggleTost = () => setShowTost(!showTost);

  function getmedia() {
    var token = localStorage.getItem("token");
    axios
      .post(
        process.env.REACT_APP_SERVER + "inventory/getmedia",
        { nInvItemID: rowid },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setExistingFiles((res.data || []).length ? res.data : []);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getIsShareIsReleaseTo() {
    var token = localStorage.getItem("token");

    axios
      .get(
        process.env.REACT_APP_SERVER +
          `contact/getisshareisreleaseto?nUserID=${localStorage.getItem(
            "userid"
          )}&nInvItemID=${rowid}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const data = (res.data || []).length ? res.data : [];
        setExistingSharedReleaseWithTags(
          data.map((r) => {
            return {
              ...r,
              editsharedwith: r.sharedwith,
              editreleaseto: r.releaseto,
            };
          })
        );
        let SharedContacts = [];
        if (data && data.length) {
          data.map((contact) => {
            return contact.sharedwith === 1
              ? SharedContacts.push({
                  ...contact,
                  isSendText: 0,
                  isSendEmail: 0,
                })
              : null;
          });
        }
        setExistingSharedContacts(SharedContacts);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function addNewItem() {
    setItemName("");
    setItemNotes("");
    setFiles([]);
    setExistingFiles([]);
    setExistingSharedReleaseWithTags([]);
    setSearchData({});
    history.action.link("/newAddNewItem");
  }

  function resetData() {
    setItemName("");
    setItemNotes("");
    setFiles([]);
    setExistingFiles([]);
    setExistingSharedReleaseWithTags([]);
    setSearchData({});
  }

  function saveMedia(nInvItemID) {
    if (files && files.length) {
      return files.map((f) => {
        var token = localStorage.getItem("token");

        var saveFiledata = new FormData();
        saveFiledata.append("nUserID", localStorage.getItem("userid"));
        saveFiledata.append("nInvItemID", nInvItemID);
        saveFiledata.append("imgfile", f);

        return axios
          .post(process.env.REACT_APP_SERVER + "uploads3file", saveFiledata, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            var data = new FormData();
            data.append("nUserID", localStorage.getItem("userid"));
            data.append("nInvItemID", nInvItemID);
            data.append("sFileName", res.data.filename);
            data.append("nFileSize", f.size);

            axios
              .post(process.env.REACT_APP_SERVER + "inventory/addmedia", data, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {
                getAllItems();
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
    setFiles([]);
  }

  const onChangeFiles = (e) => {
    if (e.target.files && e.target.files.length) {
      setFiles([...files, e.target.files[0]]);
    }
  };

  function removedFiles(index) {
    let newFiles = [];
    files.map((file, fIndex) =>
      index !== fIndex ? newFiles.push(file) : null
    );
    setFiles(newFiles);
  }

  function removedExistingFiles(mediaID) {
    if (mediaID) {
      const data = {
        nUserID: localStorage.getItem("userid"),
        nID: mediaID,
        nInvItemID: rowid,
      };
      var token = localStorage.getItem("token");
      axios
        .post(process.env.REACT_APP_SERVER + "inventory/deletemedia", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setSelected(null);
          let newFiles = [];
          existingFiles.map((file, fIndex) =>
            file.id !== mediaID ? newFiles.push(file) : null
          );
          setExistingFiles(newFiles);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function saveIsShareIsReleaseto(nInvItemID) {
    if (existingSharedReleaseWithTags && existingSharedReleaseWithTags.length) {
      existingSharedReleaseWithTags
        .filter(
          (existing) =>
            existing.editsharedwith !== existing.sharedwith ||
            existing.editreleaseto !== existing.releaseto
        )
        .map((contact) => {
          if (
            contact.id !== null &&
            ((contact.editsharedwith === 0 && contact.sharedwith === 1) ||
              (contact.editreleaseto === 0 && contact.releaseto === 1))
          ) {
            removedExistingSharedToReleasedTo(
              contact.id,
              contact.contactid,
              !contact.editsharedwith === 0 && contact.sharedwith === 1
                ? "share"
                : "release"
            );
          }
          if (
            (contact.editsharedwith === 1 && contact.sharedwith === 0) ||
            (contact.editreleaseto === 1 && contact.releaseto === 0)
          ) {
            assignExistingSharedToReleasedTo(
              contact.contactid,
              nInvItemID,
              contact.editsharedwith,
              contact.editreleaseto
            );
          }
        });
      getIsShareIsReleaseTo();
    }
  }

  function removedExistingSharedToReleasedTo(ID, nContactID, isShareIsRelease) {
    if (ID) {
      const data = {
        nUserID: localStorage.getItem("userid"),
        nID: ID,
        nInvItemID: rowid,
        nContactID: nContactID,
      };

      var token = localStorage.getItem("token");
      axios
        .post(
          process.env.REACT_APP_SERVER + `inventory/delete${isShareIsRelease}`,
          data,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function assignExistingSharedToReleasedTo(
    nContactID,
    nInvItemID,
    sharedWith,
    releaseTo
  ) {
    const data = {
      nUserID: localStorage.getItem("userid"),
      nContactID: nContactID,
      nInvItemID: nInvItemID,
      nIsSharedWith: sharedWith,
      nIsReleaseTo: releaseTo,
    };

    var token = localStorage.getItem("token");
    return axios
      .post(
        process.env.REACT_APP_SERVER + "contact/saveisshareisreleaseto",
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        history.push(
          `/newDashboard/?${rowid ? Number(rowid) : res.data[0].ID}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const removeItems = (e) => {
    if (rowid) {
      const data = {
        nUserID: localStorage.getItem("userid"),
        nInvItemID: rowid,
      };

      var token = localStorage.getItem("token");
      axios
        .post(process.env.REACT_APP_SERVER + `inventory/deleteitem`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          getAllItems();
          history.push("/newDashboard");
          setDeleteModalShow(false);
          resetData();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleShowUseWizardChange = (event) => {
    setShowUseWizard(event.target.checked);
  };

  const [planNotSelected, setSelectPlan] = useState(false);

  const handleClosePlan = () => {
    setSelectPlan(false);
  };

  const handleEditBtn = () => {
    if (rowid) {
      history.push(`/newAddNewItem/?${rowid}`);
    } else {
      history.push(`'/newAddNewItem'`);
    }
  };

  const handleDeleteBtn = () => {
    setDeleteModalShow(true);
  };

  const handleRefresh = () => {
    getAllItems();
  };
  const [fdata, setfdata] = useState("");

  useEffect(() => {
    var token = localStorage.getItem("token");
    var userId = localStorage.getItem("userid");
    axios
      .get(
        process.env.REACT_APP_SERVER +
          `user/userfilelistingget?nUserID=${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setfdata(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChangeOption = (contactId) => {
    setSelectedContactID(contactId);
    getAllItems(contactId);
  };

  return (
    <>
      {TokenExpired && <SessionExpired />}
      <Modal show={planNotSelected} onHide={handleClosePlan}>
        <Modal.Header closeButton className={"modelHeader"}>
          <Modal.Title>Please Select Your Plan!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please Select Your Plan!</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ backgroundColor: "#7030a0" }}
            onClick={handleClosePlan}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          backgroundColor: "red",
        }}
      >
        <Toast
          style={{
            position: "absolute",
            top: -85,
            right: -20,
            backgroundColor: "red",
          }}
          show={showTost}
          onClose={toggleTost}
        >
          <Toast.Header>
            <strong className="mr-auto">
              Sorry Wish Description Item cannot exceed 500 characters.
            </strong>
          </Toast.Header>
        </Toast>
      </div>
      <Row className="d-flex align-items-center mb-3">
        <div>
        <div style={{ height: "37px" }} />
        <div
          style={{
            marginBottom: "5px",
            paddingLeft: "15px",
            marginTop: "8px",
          }}
          className="wish_card_page d-block d-md-flex"
        >
          <div className="add_contact_content">
            <AddNewItemContainer
              getDetails={getDetails}
              showUseWizard={showUseWizard}
              getAllItems={getAllItems}
              BackToClear={BackToClear}
              currentUser={currentUser}
              notesData={notesData}
            />
            {tile === 1 ? (
              <Button
                variant="danger"
                className="btn mr-2 "
                disabled={!selected}
                onClick={handleDeleteBtn}
                style={{ width: "140px" }}
              >
                Delete
              </Button>
            ) : null}
            {isLoadedNotesData && fdata.length !== 0 && (
              <Link to="/fileListing">
                <Button
                  variant="success"
                  className="btn  mr-2"
                  // disabled={!selected}
                  // onClick={handleDeleteBtn}
                  style={{ width: "140px", border: "none" }}
                >
                  Show Files
                </Button>
              </Link>
            )}
          </div>
          {notesData.length != 0 &&
            <div className="shared_content d-flex align-items-center mt-2 mt-md-0 ml-3">
              <label className="text-nowrap mb-0 mr-1">Shared With : </label>
              <Select
                style={{ width: "230px", maxHeight: "37px" }}
                className="selectOption "
                inputProps={{ "aria-label": "Without label" }}
                variant="outlined"
                value={selectedContactID}
                onChange={(e) => handleChangeOption(e.target.value)}
              >
                <MenuItem value={0} >Display All</MenuItem>
                {contactdata.map((res, index) => (
                  <MenuItem value={res.contactid} key={index}>
                    {res.fname + " " + res.lname}
                  </MenuItem>
                ))}
              </Select>
            </div>}
          </div>

          {/*{tile === 0 ? (
                        <Button
                            variant="primary"
                            className="btn mr-2 mb-1 mb-sm-0"
                            style={{width: '140px'}}
                            onClick={handleRefresh}
                        >
                            Refresh
                        </Button>
                    ) : null}*/}
          {/* <Checkbox
                      checked={showUseWizard}
                      onChange={handleShowUseWizardChange}
                      color="default"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    Use Wizard*/}
        </div>
      </Row>
      <div className="notes-form">
        {/*<Section2*/}
        {/*    isInputDisabled={true}*/}
        {/*    itemName={itemName}*/}
        {/*    setItemName={setItemName}*/}
        {/*    itemNotes={itemNotes}*/}
        {/*    setItemNotes={setItemNotes}*/}
        {/*    rowid={rowid}*/}
        {/*    existingSharedContacts={existingSharedContacts}*/}
        {/*    isItemNameInput={true}*/}
        {/*    isItemNoteInput={true}*/}
        {/*    isViewSendTextEmail={true}*/}
        {/*    isViewSuggetion={true}*/}
        {/*    itemNameLabel="Wish Description Item:"*/}
        {/*    itemNoteLabel="Notes:"*/}
        {/*/>*/}
      </div>

      {deleteModalShow ? (
        <DeleteModal
          getAllItems={getAllItems}
          deleteModalShow={deleteModalShow}
          setDeleteModalShow={setDeleteModalShow}
          removeItems={removeItems}
        />
      ) : null}
    </>
  );
};

export default Notesform;
