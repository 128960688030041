import React from 'react';
import {Form} from "react-bootstrap";
import {Editor} from '@tinymce/tinymce-react';

const NoteSection = (props) => {
    const {
        isItemNoteInput,
        isInputDisabled,
        itemNotes,
        setItemNotes,
        itemNameLabel
    } = props;

    const onEditorStateChange = (editorState) => {
        setItemNotes(editorState);
    };

    return (
        <div className="box d-flex  ">
            <div className="pr-4 align-items-center justify-content-between pb-2 flex-wrap">
                <label>
                    <b>{itemNameLabel}</b>
                </label>
            </div>
            {isItemNoteInput && (
                <Form.Group
                    controlId="exampleForm.ControlInput2-Notes"
                    className="d-flex flex-column align-items-start w-100 notes-editor-container"
                    id="demoEditor"
                >
                    {isInputDisabled ? (
                        <div
                            className="notes-content"
                            dangerouslySetInnerHTML={{__html: itemNotes}}
                        />
                    ) : (
                        <Editor
                            value={itemNotes}
                            onEditorChange={onEditorStateChange}
                            wrapperClassName="notes-wrapper"
                            editorClassName="demo-editor"
                            apiKey='0uwj75xw3ds611tc44hl9n9o0sc6yemx1h8rgnxbyd6bzbmu'
                            id="demo"
                            init={{
                                plugins: "  fullpage  fullscreen  link advlist lists  ",
                                toolbar: " bold italic underline strikethrough | fontselect | fontsizeselect | formatselect | lineheight|alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor | casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                                content_style: "body p { margin: 5px; } .mce-content-body {margin:5px}"
                            }}
                        />
                    )}
                </Form.Group>
            )}
        </div>
    );
};

export default NoteSection;