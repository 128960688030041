import React from 'react';
import './reviews.css';
import { Col, Container, Row } from 'react-bootstrap';
import {Link} from "react-router-dom";

const Reviews = () => {
    return (
        <>
            <section className='reviews_section_content mt-5'>
                <Container>
                    <Row className='justify-content-center m-0'>
                        <Col md={12} className='reviews_title mb-4'>
                            <h1>
                                Let us paint a picture for you.
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='reviews_section pt-0 mb-4'>
                <Container>
                    <Row className='justify-content-center m-0'>
                        <Col md={12}>
                            <div className="scenarios_content">
                                <div className="row m-0 ">
                                    <h4>Your loved ones receive word that you’ve passed. They’re now tasked with
                                        settling your affairs. They spend countless hours:</h4>
                                    <ul className="ml-md-5 ml-3 mt-2 discription-font ">
                                        <li>Looking for the key and password to your safe</li>
                                        <li>Digging through mountains of paperwork</li>
                                        <li>Flipping through notebooks in search of passwords for your accounts</li>
                                        <li>Sifting through files on hard drives</li>
                                        <li>…and more.</li>
                                    </ul>
                                </div>
                                <div className="mb-3 discription-font">
                                    Their grief is now compounded by the stress of locating your important information.
                                    You can’t control what happens after you pass, but you don’t want to add undue frustration to the process.
                                    You want your loved ones to easily manage your affairs.
                                </div>
                                <div className="mb-3 discription-font">
                                    What if you had <b> one safe place </b>to securely store your private end-of-life information?
                                </div>
                                <div className="mb-3 discription-font">
                                    A place that could release your information to your pre-designated loved ones upon your death.
                                </div>
                                <div className="mb-3 discription-font">
                                    A place that offers you the ability to check-in at your desired frequency, so you can feel at ease in your later years.
                                </div>
                                <div className="mb-3 discription-font">
                                    KeysToMyWishes is ready to help you eliminate stress for your loved ones. So you can enjoy your retirement with peace of mind.
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Link to="/new-signup" type="button" className="Secure-inforamtion-btn btn"><span>Secure Your Information Now</span></Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Reviews;