import React from 'react';
import './header.css';
import keysLogoImage from '../../../assets/images/logo.png';
import {Button, Card, Container, Navbar, Row} from 'react-bootstrap';
import { Link ,useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import WishesVideo from '../../../../../assets/images/KeysToMyWishes.mp4'
import playBtnIcon from '../../../assets/images/Play-icon.svg';
import linesImage from '../../../../Landing/assets/images/lines.svg';
import dotsImage from '../../../../Landing/assets/images/dot.svg';



const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});



const Header = () => {
    const history = useHistory();
    const classes = useStyles();
    const anchor = 'right';
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="mobile_menu">
                <div className='header_logo d-flex gap-2'>
                    <img
                        src={keysLogoImage}
                        className="d-inline-block align-top"
                        alt="logo"
                    />
                    <CloseIcon />
                </div>
                <Link to="/privatepeople2">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2" className="btn Landing_signup_btn">Home</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/privatepeople2/about">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2/about" className="btn Landing_signup_btn">About</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/privatepeople2/features">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2/features" className="btn Landing_signup_btn">Features</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/privatepeople2/pricing">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2/pricing" className="btn Landing_signup_btn">Pricing</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/privatepeople2/contact">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2/contact" className="btn Landing_signup_btn">Contact</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-signup">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-signup" className="btn Landing_signup_btn">Sign Up</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-login">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-login" className="btn Landing_login_btn">Login</ListItemText>
                    </ListItem>
                </Link>
            </List>
        </div>
    );


    return (
        <>
            <div className='introduction_section'>
                <Navbar className='main-header'>
                    <Navbar.Brand>
                        <img
                            src={keysLogoImage}
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse expand="sm" className="justify-content-end">
                        <ul className='main_menu_list'>
                            <li className='menu_item active'>
                                <Link to="/privatepeople2">Home</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/privatepeople2/about">About</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/privatepeople2/features">Features</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/privatepeople2/pricing">Pricing</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/privatepeople2/contact">Contact</Link>
                            </li>
                            <li className='menu_item'>
                                <Button variant="" className='header_menu_outlinebtn' onClick={() => history.push('/new-login')}>Login</Button>
                                <Button variant="" className='header_menu_btn' onClick={() => history.push('/new-signup')}>Start Free Trial </Button>
                            </li>
                        </ul>
                    </Navbar.Collapse>
                    <div className="menu_bar">
                        <MenuIcon onClick={toggleDrawer(anchor, true)} />
                    </div>
                </Navbar>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                </Drawer>

                <Container fluid>
                    <div className='inroduction_details'>
                        <Row className='justify-content-center m-0'>
                            <div className='inroduction_heading'>
                                <h1>The <span>UItimate</span> System To Organize And <span>Protect</span> Your information</h1>
                                <p>Safe Online Storage for your private information that will get into your loved ones' hands at just the right time, so you can live with peace of mind that your loved ones are covered should something happen to you.</p>
                            </div>
                        </Row>
                        <Row className='justify-content-center m-0'>
                            <div className='inroduction_details_btn'>
                                <Button variant="" className='btn_outlinebtn' onClick={()=>history.push("/how-to")}>
                                    <img
                                        src={playBtnIcon}
                                        className="d-inline-block align-items-center mb-1"
                                        alt="logo"
                                    />
                                    See How it works
                                </Button>
                                <Button variant="" className='btn_primarybtn' onClick={()=>history.push("/new-signup")}>
                                    Start Free Trial
                                </Button>
                            </div>
                        </Row>
                    </div>
                    <div className='helper__video_content pb-4'>
                        <Row className='justify-content-center m-0'>
                            <Card className='helper_video_container'>
                                <Card.Body className='p-0'>
                                    <div className='helper_video_header'>
                                        <div className='d-flex justify-content-between'>
                                            <img
                                                src={dotsImage}
                                                className="d-inline-block align-top"
                                                alt="images"
                                            />
                                            <img
                                                src={linesImage}
                                                className="d-inline-block align-top"
                                                alt="images"
                                            />
                                        </div>
                                    </div>
                                    <div className='helper_video_frame'>
                                        <div className='helper_video'>
                                            <video width="914" height="503" controls>
                                                <source src={WishesVideo} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
};
export default Header;