import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const EmploymentInformation = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Employment Information</h3>
            </div>
            <div className="content mt-3 mb-5">
                {["CURRENT EMPLOYER:","PRIOR EMPLOYER:"].map((title,index)=> (
                    <div className="mb-3" key={index}>
                        <h5 className="mb-3">{title}</h5>
                        {["Company:", "Address:", "Contact:", "Phone #:"].map((title) => (
                            <Row className="mb-2 mx-0" key={title}>
                                <Col md="2">
                                    {title}
                                </Col>
                                <Col md="10">
                                    <TextField
                                        variant="filled"
                                        fullWidth={true}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Row className="mx-0">
                            <Col md="2" className="d-flex align-items-center">
                                From:
                            </Col>
                            <Col md="4 pr-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                            <Col md="1" className="d-flex align-items-center bg-white">
                                to
                            </Col>
                            <Col md="5 pl-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                        </Row>
                        <Row className="my-2 mx-0">
                            <Col md="12">
                                Notes:
                            </Col>
                            <Col md="12">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                    multiline={true}
                                    rows={5}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmploymentInformation;