import React, {useState} from 'react';
import './getintouch.css';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import sendIcon from '../../../assets/images/send-icon.svg'
import axios from "axios";

const GetInTouch = () => {
    return (
        <>
            <section className='contact_form_section'>
                <Container>
                    <Form className='contact_form'>
                        <Row>
                            <Col sm={12} className='mb-3'>
                                <h3>Get In Touch</h3>
                            </Col>
                            <Col md={6} className='mb-3'>
                                <Form.Control placeholder="Name" />
                            </Col>
                            <Col md={6} className='mb-3'>
                                <Form.Control placeholder="Phone" />
                            </Col>
                            <Col sm={12} className='mb-3'>
                                <Form.Control type="email" placeholder="Email" />
                            </Col>
                            <Col sm={12} className='mb-3'>
                                <Form.Control as="textarea" rows={3} placeholder="Enter Message" />
                            </Col>
                            <Col sm={12} className='mb-3'>
                                <div className='contact_form_btn'>
                                    <button type="button" className="grediant-btn btn mt-4">
                                        <img
                                            src={sendIcon}
                                            className="img-fluid"
                                            alt="logo"
                                        />
                                        Send Message
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </section>
        </>
    );
};

export default GetInTouch;