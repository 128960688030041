import React, {useEffect, useState} from 'react';
import SharedWithReleasedToGridContainer from './SharedWithReleasedToGridContainer';
import {Checkbox, InputBase, NativeSelect, TextField} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {FormControl} from '@material-ui/core';
import {Button} from "react-bootstrap";

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        maxHeight: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            width: "50px",
            minWidth: "50px",
            textAlign: "center",
            border: "solid 2px",
            height: "22px",
        },
    },
}));

const AddItemReleaseToWizard = ({
                                    contactsList,
                                    setContactsList,toggle
                                }) => {
    const classes = useStyles();
    const handleIsReleaseToChange = (id, value) => {
        const newContactsData = contactsList.map((contact) => {
            return {
                ...contact,
                ...(contact.contactid === Number(id)
                    ? {editreleaseto: Number(value)}
                    : {}),
            };
        });
        setContactsList(newContactsData);
    };


    return (
        <div>
            <div  style={{
                border: "1px solid #ced4da",
                padding: "8px",
                marginBottom: "20px",
            }}>
                <b>Who do You Want to Release This information to should you become Incapacitated or Deceased?</b>
            </div>
            <Button
                variant="success"
                className="btn mr-2 text-nowrap"
                onClick={toggle}
            >
                + Add Contact
            </Button>
            <SharedWithReleasedToGridContainer
                contactsList={contactsList}
                handleIsReleaseToChange={handleIsReleaseToChange}
                isShowShareColumn={false}
                isShowReleaseColumn={true}
            />
        </div>
    );
};

export default AddItemReleaseToWizard;
