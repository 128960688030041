import React, {useEffect, useRef, useState} from "react";
import { Button, Form } from "react-bootstrap";
import LookupsModal from "./LookupsModal";
import SendTextEmailModal from "./SendTextEmailModal";
import "./Layout/Section2.css";
import axios from "axios";
import "./Layout/Section1.css";
import SessionExpired from "../SessionExpired/SessionExpired";

let options = {
  inlineStyleFn: (styles) => {
    let key = "color-";
    let color = styles.filter((value) => value.startsWith(key)).first();

    if (color) {
      return {
        element: "span",
        style: {
          color: color.replace(key, ""),
        },
      };
    }
  },
};

const Section2 = ({
  isInputDisabled,
  itemName,
  itemNotes,
  setItemName,
  setItemNotes,
  existingSharedContacts,
  isItemNameInput = false,
  isItemNotesInput = false,
  isViewSuggetion = false,
  itemNameLabel = "Wish Description Item:",
  stepNumber,
   }) => {
  const [Lookups, setLookups] = React.useState(false);
  const [selectedSuggetion, setSelectedSuggetion] = useState("");
  // const [notes, setNotes] = React.useState("");
  const [sendTextEmailModalShow, setSendTextEmailModalShow] =
    React.useState(false);
  const [sharedContacts, setSharedContacts] = React.useState([]);
  const [sendMessage, setSendMessage] = useState("");
  const [suggestionsData, setSuggestionsData] = useState([]);
  const textInput = useRef(null);

  const [TokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    if(stepNumber === 1 || stepNumber === 2) {
      textInput.current.focus();
    }
  },[stepNumber]);

  useEffect(() => {
    setSharedContacts(existingSharedContacts);
  }, [existingSharedContacts]);

  const handleSelectSuggetions = (id) => {
    if(selectedSuggetion == id) {
      setSelectedSuggetion('');
    } else {
      setSelectedSuggetion(Number(id));
    }
  };

  const handleSaveSuggetions = () => {
    const data = suggestionsData.find((e) => e.id === selectedSuggetion);
    if (stepNumber === 2){
      setItemNotes(itemNotes + " " + data.sSubject);
    } else {
      setItemName(itemName + " " + data.sSubject);
    }
    setLookups(false);
  };

  // const onEditorStateChange = (editorState) => {
  //   setNotes(editorState);
  //   //setItemNotes(editorState.getCurrentContent().getPlainText());
  // };

  const handleSendTextChange = (event) => {
    const newContactsData = sharedContacts.map((contact) => {
      return {
        ...contact,
        ...(contact.contactid === Number(event.target.id)
          ? {
              isSendText: event.target.checked ? 1 : 0,
            }
          : {}),
      };
    });
    setSharedContacts(newContactsData);
  };

  const handleSendEmailChange = (event) => {
    const newContactsData = sharedContacts.map((contact) => {
      return {
        ...contact,
        ...(contact.contactid === Number(event.target.id)
          ? {
              isSendEmail: event.target.checked ? 1 : 0,
            }
          : {}),
      };
    });
    setSharedContacts(newContactsData);
  };

  const sendTextEmail = () => {
    setSendTextEmailModalShow(false);
    setSendMessage("");
    setSharedContacts(existingSharedContacts);
  };
  const closeSendTextEmailModal = () => {
    setSendTextEmailModalShow(false);
    setSendMessage("");
    setSharedContacts(existingSharedContacts);
  };


  function getAllSuggestions() {
    var token = localStorage.getItem("token");
    axios
      .get(process.env.REACT_APP_SERVER + `inventory/getsuggestions`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.status) {
          setTokenExpired(true);
          setLookups(false);
        } else {
          setSuggestionsData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="box mb-0">
      {TokenExpired && <SessionExpired />}
      {isInputDisabled ? <div>*Section 2</div> : null}
      {isItemNameInput ? (
        <Form.Group
          className="d-flex flex-column align-items-start mb-0"
        >
          <div className="w-100 d-flex align-items-center justify-content-between pb-2 flex-wrap">
            <label>
              <b>{itemNameLabel}<span className="required-text">(required)</span></b>
            </label>
            <div className="with_Sugg">
              {isViewSuggetion ? (
                <Button
                  variant="secondary"
                  className="btn-sm-height import-suggestion-Btn"
                  style={{height:"30px"}}
                  onClick={() => {
                    getAllSuggestions();
                    setSelectedSuggetion("");
                    setLookups(true);
                  }}
                >
                  {isInputDisabled ? "View Suggestion" : "Suggestions"}
                </Button>
              ) : null}
            </div>
          </div>
          <Form.Control
            as="textarea"
            placeholder=""
            rows={3}
            required
            value={itemName}
            disabled={isInputDisabled}
            onChange={(e) => setItemName(e.target.value)}
            style={{ overflowY: "hidden" }}
            ref={textInput}
            maxLength="4000"
          />
        </Form.Group>
      ) : null}

      {isItemNotesInput ? (
          <Form.Group className="d-flex flex-column align-items-start">
            <div className="w-100 d-flex align-items-center justify-content-between pb-2 flex-wrap">
              <label className={"mb-0"}>
                <b>{itemNameLabel}</b>
              </label>
            </div>
            <Form.Control
                as="textarea"
                placeholder=""
                rows={4}
                required
                value={itemNotes}
                disabled={isInputDisabled}
                onChange={(e) => setItemNotes(e.target.value)}
                style={{ overflowY: "hidden"}}
                ref={textInput}
            />
          </Form.Group>
      ) : null}
      {Lookups ? (
        <LookupsModal
          Lookups={Lookups}
          setSelectedSuggetion={setSelectedSuggetion}
          setLookups={setLookups}
          suggestionsData={suggestionsData}
          selectedSuggetion={selectedSuggetion}
          handleSelectSuggetions={handleSelectSuggetions}
          handleSaveSuggetions={handleSaveSuggetions}
          isInputDisabled={isInputDisabled}
        />
      ) : null}
      {sendTextEmailModalShow ? (
        <SendTextEmailModal
          sendTextEmailModalShow={sendTextEmailModalShow}
          closeSendTextEmailModal={closeSendTextEmailModal}
          itemName={itemName}
          sharedContacts={sharedContacts}
          handleSendTextChange={handleSendTextChange}
          handleSendEmailChange={handleSendEmailChange}
          sendTextEmail={sendTextEmail}
          sendMessage={sendMessage}
          setSendMessage={setSendMessage}
        />
      ) : null}
    </div>
  );
};

export default Section2;
