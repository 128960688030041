import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const Insurance2 = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Insurance</h3>
            </div>
            <div className="content mt-3 mb-5">
                {["INSURANCE TYPE: DENTAL","INSURANCE TYPE: VISION"].map((title,index)=> (
                    <div className="mb-4" key={index}>
                        <h5 className="mb-3">{title}</h5>
                        {[
                            "Company:", "Address:", "Policy #:",
                            "Amount:","Date of Policy:",
                            "Beneficiary:","Location: "
                        ].map((title) => (
                            <Row className="mx-0" key={title}>
                                <Col md="3 pl-0">
                                    {title}
                                </Col>
                                <Col md="9">
                                    <TextField
                                        variant="filled"
                                        fullWidth={true}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Row className="mx-0">
                            <Col md="3 pl-0" className="d-flex align-items-center">
                                Agent:
                            </Col>
                            <Col md="4 pr-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                            <Col md="2" className="d-flex align-items-center justify-content-center bg-white">
                                Phone #
                            </Col>
                            <Col md="3 pl-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                        </Row>
                        <Row className="my-2 mx-0">
                            <Col md="12 pl-0">
                                Notes:
                            </Col>
                            <Col md="12 pl-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                    multiline={true}
                                    rows={2}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Insurance2;