import React, {useEffect} from 'react';
import './learn-more.css';
import '../Introduction/introduction.css'
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import {Link} from "react-router-dom";

const LearnMore = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section>
                <Container className='learn_more_section'>
                    <div className='learn_more_details'>
                        {/*<Row className='justify-content-center m-0 introduction_content'>*/}
                        {/*    <div className='heading text-center'>*/}
                        {/*        The New Standard In Organizing, Protecting, And Optimizing Your Most Important Information.*/}
                        {/*    </div>*/}
                        {/*</Row>*/}
                        <Row className='justify-content-center mt-5'>
                            <div className='learn_more_btn'>
                                <Link to="/about" variant="" className='btn_primarybtn'>Learn More</Link>
                            </div>
                        </Row>
                    </div>
                </Container>
            </section>


            <section className='counter'>
                <Container>
                    <Row className='justify-content-center m-0' >
                        <Col lg={6} md={6} sm={12}>
                            <Card className='grediant-card mt-4'>
                                <Card.Body className='value-card'>
                                    <p>If you were in an accident or had a medical emergency and couldn’t communicate, would loved ones be able to find your most important information needed to managing your affairs?</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Card className='grediant-card mt-4'>
                                <Card.Body className='value-card'>
                                    <p>Would loved ones have to search through numerous file folders or piles of paper in order to find insurance policies, medical directive, passwords to access computer and bank account?</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} className='mt-4'>
                            <Card className='grediant-card'>
                                <Card.Body className='value-card'>
                                    <p>Upon your death, would loved ones know what you want said in your obituary? Are your final wishes clear and updated? Where is your Will located?</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/*<Col lg={6} md={6} sm={12} className='mt-4'>*/}
                        {/*    <Card className='grediant-card pb-0 pt-3'>*/}
                        {/*        <Card.Body className='value-card'>*/}
                        {/*            <p>Depending on the plan you choose we frequently check your well-being, and if the time comes; seamlessly share your wishes and legacy to your loved ones you have designated to receive them</p>*/}
                        {/*        </Card.Body>*/}
                        {/*    </Card>*/}
                        {/*</Col>*/}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default LearnMore;