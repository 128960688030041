import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const InCaseOfEmergency = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>In case of Emergncy</h3>
            </div>
            <div className="content mt-3 mb-5">
                {["Name:", "Relationship:", "Phone #:", "Other:"].map((title,index) => (
                    <Row className="mb-2 mx-0" key={index}>
                        <Col md="2">
                            {title}
                        </Col>
                        <Col md="10">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                ))}
                <Row className="mb-2 mx-0">
                    <Col md="12">
                        Notes:
                    </Col>
                    <Col md="12">
                        <TextField
                            variant="filled"
                            fullWidth={true}
                            size="small"
                            multiline={true}
                            rows={4}
                        />
                    </Col>
                </Row>
            </div>
            <div className="content mb-5">
                {["Name:", "Relationship:", "Phone #:", "Other:"].map((title) => (
                    <Row className="mb-2 mx-0" key={title}>
                        <Col md="2">
                            {title}
                        </Col>
                        <Col md="10">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                ))}
                <Row className="mb-2 mx-0">
                    <Col md="12">
                        Notes:
                    </Col>
                    <Col md="12">
                        <TextField
                            variant="filled"
                            fullWidth={true}
                            size="small"
                            multiline={true}
                            rows={4}
                        />
                    </Col>
                </Row>
            </div>
            <div className="content">
                {["Name:", "Relationship:", "Phone #:", "Other:"].map((title) => (
                    <Row className="mb-2 mx-0" key={title}>
                        <Col md="2">
                            {title}
                        </Col>
                        <Col md="10">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                ))}
                <Row className="mb-2 mx-0">
                    <Col md="12">
                        Notes:
                    </Col>
                    <Col md="12">
                        <TextField
                            variant="filled"
                            fullWidth={true}
                            size="small"
                            multiline={true}
                            rows={4}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default InCaseOfEmergency;