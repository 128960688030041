import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const Automobiles = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Automobiles</h3>
            </div>
            {Array(3).fill(1).map((ele,index)=> (
                <div className="content mt-3 mb-5" key={index}>
                    {["Make:", "Model:"].map((title) => (
                        <Row className="mx-0" key={title}>
                            <Col md="2 pl-0">
                                {title}
                            </Col>
                            <Col md="10 pr-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                        </Row>
                    ))}
                    <Row className="mx-0">
                        <Col md="2 pl-0">
                            Year:
                        </Col>
                        <Col md="4 pr-0">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                    <Row className="my-2 mx-0">
                        <Col md="12 pl-0">
                            Notes:
                        </Col>
                        <Col md="12 px-0">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                                multiline={true}
                                rows={5}
                            />
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    );
};

export default Automobiles;