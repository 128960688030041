import React from 'react';
import './bgheader.css';
import keysLogoImage from '../../../assets/images/logo_white.svg';
import LogoImage from '../../../assets/images/logo.png';
import { Button, Navbar, Container } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const BGHeader = (props) => {
    const history = useHistory();
    const isActive = (path) => {
        return window.location.pathname.includes(path);
    };

    const classes = useStyles();
    const anchor = 'right';
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="mobile_menu">
                <div className='header_logo d-flex gap-2'>
                    <img
                        src={LogoImage}
                        className="d-inline-block align-top"
                        alt="logo"
                    />
                    <CloseIcon />
                </div>
                <Link to="/privatepeople2">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2" className="btn Landing_signup_btn">Home</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/privatepeople2/about">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2/about" className="btn Landing_signup_btn">About</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/privatepeople2/features">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2/features" className="btn Landing_signup_btn">Features</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/privatepeople2/pricing">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2/pricing" className="btn Landing_signup_btn">Pricing</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/privatepeople2/contact">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2/contact" className="btn Landing_signup_btn">Contact</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-signup">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-signup" className="btn Landing_signup_btn">Sign Up</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-login">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-login" className="btn Landing_login_btn">Login</ListItemText>
                    </ListItem>
                </Link>
            </List>
        </div>
    );

    return (
        <>
            <div className="bgheader-main">
                <Navbar className='landing_header'>
                    <Navbar.Brand>
                        <img
                            src={keysLogoImage}
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <ul className='header_menu_list'>
                            <li className='menu_list_item'>
                                <Link to="/privatepeople2">Home</Link>
                            </li>
                            <li className={`menu_list_item ${isActive('/about') ? 'active' : ''}`}>
                                <Link to="/privatepeople2/about">About</Link>
                            </li>
                            <li className={`menu_list_item ${isActive('/features') ? 'active' : ''}`}>
                                <Link to="/privatepeople2/features">Features</Link>
                            </li>
                            <li className={`menu_list_item ${isActive('/pricing') ? 'active' : ''}`}>
                                <Link to="/privatepeople2/pricing">Pricing</Link>
                            </li>
                            <li className={`menu_list_item ${isActive('/contact') ? 'active' : ''}`}>
                                <Link to="/privatepeople2/contact">Contact</Link>
                            </li>
                            <li className={`menu_list_item ${isActive('/login') ? 'active' : ''}`}>
                                <Button variant="" className='header_menu_outlinebtn' onClick={() => history.push('/new-login')}>Login</Button>
                                <Button variant="" className='header_menu_btn' onClick={() => history.push('/new-signup')}>
                                    <span>   Start free trail </span>
                                </Button>
                            </li>
                        </ul>
                    </Navbar.Collapse>
                    <div className="menu_bar">
                        <MenuIcon onClick={toggleDrawer(anchor, true)} />
                    </div>
                </Navbar>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                </Drawer>
                {window.location.pathname == '/privatepeople2/about' || window.location.pathname == '/privatepeople2/pricing' ?
                    <Container fluid>
                        <div className='text-center text-white menu-title'>
                            <h2 className='mb-4'>{props.title}</h2>
                            <div className="d-flex justify-content-center">
                                <p className="w-75">{props.description}</p>
                            </div>

                        </div>
                    </Container>
                    :
                    <Container>
                        <div className='text-center text-white menu-title'>
                            <h2 className='mb-4'>{props.title}</h2>
                            <p>{props.description}</p>

                        </div>
                    </Container>
                }
            </div>
        </>
    )
};
export default BGHeader;