import React from 'react';
import { Button, Card, Col, Container, Navbar, Row } from "react-bootstrap";
import StarIcon from "@mui/icons-material/Star";
import { Link } from "react-router-dom";
import Img5 from '../../../assets/images/shopWeb/img_5.jpg';
import CheckIcon from '@mui/icons-material/Check';
import './about-book.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import keysLogoImage from '../../PrivatePeople3LandingNew/assest/images/logo.png';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
    list: {
        width: 280,
    },
    fullList: {
        width: 'auto',
    },
});

const AboutPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const anchor = 'right';
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="mobile_menu">
                <div className='header_logo d-flex gap-2'>
                    <Link to='/book'>
                        <img
                            src={keysLogoImage}
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Link>
                    <CloseIcon />
                </div>
                <Link to="/book">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/home_book" className="btn">Home</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/shop_book">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/shop_book" className="btn">Shop</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/about_book">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/about_book" className="btn">About</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/contact_book">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/contact_book" className="btn">Contact</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-signup">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-signup" className="btn Landing_signup_btn">Sign Up</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-login">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-login" className="btn Landing_login_btn">Login</ListItemText>
                    </ListItem>
                </Link>
            </List>
        </div>
    );
    return (
        <div>
            <Navbar className='main-header webcopy_main_header'>
                <Navbar.Brand>
                    <Link to='/book'>
                        <img
                            src={keysLogoImage}
                            className="d-inline-block align-top"
                            alt="logo"
                            style={{width: "221px"}}
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle/>
                <Navbar.Collapse expand="lg" className="justify-content-end">
                    <ul className='main_menu_list'>
                        <li className='menu_item'>
                            <Link to="/book">Home</Link>
                        </li>
                        <li className='menu_item'>
                            <Link to="/shop_book">Shop</Link>
                        </li>
                        <li className='menu_item active'>
                            <Link to="/about_book">About</Link>
                        </li>
                        <li className='menu_item'>
                            <Link to="/contact_book">Contact</Link>
                        </li>
                    </ul>
                    <div className='header_menu_button'>
                        <Button variant="" className='header_menu_outlinebtn'
                                onClick={() => history.push('/new-login')}>Login</Button>
                        <Button variant="" className='header_menu_btn ml-2' onClick={() => history.push('/new-signup')}>
                            Start Your Free 14-Day Trial
                        </Button>
                    </div>
                </Navbar.Collapse>
                <div className="menu_bar">
                    <MenuIcon onClick={toggleDrawer(anchor, true)}/>
                </div>
            </Navbar>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
            </Drawer>
            <section className='section_container about_page_section '>
                <Container fluid>
                    <Row className='align-items-center'>
                        <Col xl={6}>
                            <Row>
                                <Col>
                                    <h2>Our Story</h2>
                                    <p className='sub_title'>
                                        In 2011, <span className='strong_title'>KeysToMyWishes</span> founder and
                                        software developer Randall Arnwine was faced
                                        with his brother’s death. Locating the information his needed to settle his
                                        brother’s
                                        affairs was overwhelming and stressful. He didn’t want anyone else to go through
                                        such
                                        a tedious process in the middle of their grief. <span
                                        className='strong_title'>KeysToMyWishes</span> is Randall’s simple and
                                        safe solution to your storage needs. We provide you with print and digital
                                        storage solutions for your private information, or the location of your private
                                        information.
                                    </p>
                                    <p className='sub_title'>
                                        What makes us different? We release this information to your designated loved
                                        ones
                                        upon confirmation of your incapacitation or death. So you can discover peace of
                                        mind
                                        knowing your last wishes will be fulfilled.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5 className='mt-4'>
                                        KeysToMyWishes is the only online platform that offers a Personal Affairs
                                        Organizer
                                        and safe online storage of its location. By choosing KeysToMyWishes, you can
                                        feel
                                        confident knowing that:
                                    </h5>
                                    <ul className='our_story_list'>
                                        <li>
                                            <span>
                                                <CheckIcon/>
                                            </span>
                                            Your last wishes will be kept private until the time comes
                                        </li>
                                        <li>
                                            <span>
                                                <CheckIcon/>
                                            </span>
                                            You’ll eliminate the stress of your loved ones spending countless hours
                                            trying to
                                            find what they need to settle your affairs
                                        </li>
                                        <li>
                                            <span>
                                                <CheckIcon/>
                                            </span>
                                            You won’t have to worry about remembering what information you’ve already
                                            shared
                                            with your loved ones and what still needs to be shared
                                        </li>
                                        <li>
                                            <span>
                                                <CheckIcon/>
                                            </span>
                                            Your important information will get into the right hands, and stay away from
                                            the
                                            wrong ones
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={6}>
                            <div className='side_image_section'>
                                <img src={Img5} alt='image' className='img-fluid'/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section_container private_storage_section'>
                <Container fluid>
                    <Row className='mx-5'>
                        <Col>
                            <h2>
                                KeysToMyWishes operates on three central values, to provide you with the best solution
                                for your <span>private storage</span> needs.
                            </h2>
                        </Col>
                    </Row>
                    <Row className='mx-5 '>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Comfort + Security</h4>
                                    <div className='mt-4'>
                                        <p>
                                            Your privacy and comfort matter to us. That’s why we give you multiple
                                            options for organizing and storing your personal information.
                                        </p>
                                        <p>
                                            With 256-bit encryption and multi-factor authentication, <span
                                            className='strong_title'>KeysToMyWishes</span> won’t be able to see what you
                                            store. By using biometric scans to access your files, you won’t have to
                                            worry about remembering yet another password.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card className='support_card danger_card'>
                                <Card.Body>
                                    <h4>Legacy Preservation</h4>
                                    <div className='mt-4'>
                                        <p>
                                            Your last wishes are important, and they should be treated as such. You can
                                            upload any information you’d like us to release on the KeyToMyWishes
                                            platform. Bank account numbers, important passwords, pre-recorded videos you
                                            want your loved ones to see after you pass–feel at ease knowing they’ll all
                                            be preserved and released at the right time
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Peace Of Mind</h4>
                                    <div className='mt-4'>
                                        <p>
                                            You can’t control what will happen after you pass. You can control what
                                            information will get into whose hands with <span
                                            className='strong_title'>KeysToMyWishes</span>. Your loved ones will get
                                            what they need easily, without wasting time digging through your things. By
                                            storing your private information in one secure location, you can have peace
                                            of mind.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section_container trial_section mb-3'>
                <Container>
                    <Row className='justify-content-center align-items-center'>
                        <Col lg={8}>
                            <p>
                                <span className='strong_title'>KeysToMyWishes </span> leverages over 40 years of
                                software development experience to
                                provide you with trusted digital storage solutions. Are you ready to secure your private
                                information?
                            </p>
                        </Col>
                        <Col lg={4}>
                            <div>
                                <Link to="/new-signup" variant="" className='button_primary'>Discover Peace Of Mind Now</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='quote_section about_quote_section'>
                <Container fluid className='about_quote_box_container'>
                    <div className='quote_box_content text-center'>
                        <div className='avtar-img'>
                            <span>
                                GA
                            </span>
                        </div>
                        <h6>Greg A.</h6>
                        <div className='mt-3'>
                            <StarIcon color="warning"/>
                            <StarIcon color="warning"/>
                            <StarIcon color="warning"/>
                            <StarIcon color="warning"/>
                            <StarIcon color="warning"/>
                        </div>
                        <div className='mt-4'>
                            <h4>
                                "I think this is one of the most useful apps a person can have. I am single
                                and somewhat of a private person. Especially as it pertains to my financial
                                affairs.
                            </h4>
                            <h4>
                                I love the fact that I can simply make an entry into Keys with the location
                                of my important documents without having to give this information to
                                someone.
                            </h4>
                            <h4>
                                I do have information I want passed along to my family should something
                                happen to me. I just don't want to share that information while I'm alive."
                            </h4>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    );
};

export default AboutPage;