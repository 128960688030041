import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const BankAccounts = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Bank Accounts</h3>
            </div>
            <div className="content mt-3 mb-5">
                {["TYPE OF ACCOUNT:","TYPE OF ACCOUNT:","TYPE OF ACCOUNT:","TYPE OF ACCOUNT:"].map((title,index)=> (
                    <div className="mb-4" key={index}>
                        <Row className="mx-0">
                            <Col md="4 pl-0">
                                <h5>{title}</h5>
                            </Col>
                            <Col md="8">
                            <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                        </Row>
                        {["Bank Name:", "Address:"].map((title) => (
                            <Row className="mx-0" key={title}>
                                <Col md="3 pl-0">
                                    {title}
                                </Col>
                                <Col md="9">
                                    <TextField
                                        variant="filled"
                                        fullWidth={true}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Row className="mx-0">
                            <Col md="3 pl-0" className="d-flex align-items-center">
                                City:
                            </Col>
                            <Col md="3 pr-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                            <Col md="1" className="d-flex align-items-center justify-content-center bg-white">
                                State:
                            </Col>
                            <Col md="2 pl-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                            <Col md="1" className="d-flex align-items-center justify-content-center bg-white">
                                Zip:
                            </Col>
                            <Col md="2 pl-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                        </Row>
                        {["Account #:","Amount:"].map((title) => (
                            <Row className="mx-0" key={title}>
                                <Col md="3 pl-0">
                                    {title}
                                </Col>
                                <Col md="9">
                                    <TextField
                                        variant="filled"
                                        fullWidth={true}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Row className="mx-0">
                            <Col md="3 pl-0" className="d-flex align-items-center">
                                Representative:
                            </Col>
                            <Col md="4 pr-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                            <Col md="2" className="d-flex align-items-center justify-content-center bg-white">
                                Phone #
                            </Col>
                            <Col md="3 pl-0">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BankAccounts;