import React from 'react';
import './whychoose.css';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import supportIcon from '../assets/images/support.svg';
import securityIcon from '../assets/images/cyber-security.svg';
import processIcon from '../assets/images/process.svg';
import verifiedIcon from '../assets/images/verified.svg';
import userIcon from '../assets/images/add-user.svg';
import familyIcon from '../assets/images/family.svg';
import { Link } from "react-router-dom";

const WhyChoose = () => {
    return (
        <Container fluid className='advance_features_container'>
            <Row className='justify-content-center m-0'>
                <h1 className='advance_features_heading'>Why Choose Us?</h1>
            </Row>
            <div className='advance_features_content'>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <Card className='advance_features_card'>
                            <Card.Body>
                                <div className='advance_features_img'>
                                    <div className='features_icon bg-blue'>
                                        <img src={supportIcon} alt='organizedIcon' />
                                    </div>
                                </div>
                                <Card.Title>
                                    <h4 className='advance_features_title'>We stay in touch</h4>
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className='advance_features_card'>
                            <Card.Body>
                                <div className='advance_features_img'>
                                    <div className='features_icon bg-pink'>
                                        <img src={securityIcon} alt='organizedIcon' />
                                    </div>
                                </div>
                                <Card.Title>
                                    <h4 className='advance_features_title'>Break the glass</h4>
                                    <p className='advance_features_description'>Securing your legacy</p>
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className='advance_features_card'>
                            <Card.Body>
                                <div className='advance_features_img'>
                                    <div className='features_icon bg-green'>
                                        <img src={processIcon} alt='organizedIcon' />
                                    </div>
                                </div>
                                <Card.Title>
                                    <h4 className='advance_features_title'>4 steps to stay organized</h4>
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className='advance_features_card'>
                            <Card.Body>
                                <div className='advance_features_img'>
                                    <div className='features_icon bg-yellow'>
                                        <img src={verifiedIcon} alt='organizedIcon' />
                                    </div>
                                </div>
                                <Card.Title>
                                    <h4 className='advance_features_title'>Data privacy</h4>
                                    <p className='advance_features_description'>AES 256-bit encryption</p>
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className='advance_features_card'>
                            <Card.Body>
                                <div className='advance_features_img'>
                                    <div className='features_icon bg-valerian'>
                                        <img src={userIcon} alt='organizedIcon' />
                                    </div>
                                </div>
                                <Card.Title>
                                    <h4 className='advance_features_title'>Affordable and User-friendly</h4>
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className='advance_features_card'>
                            <Card.Body>
                                <div className='advance_features_img'>
                                    <div className='features_icon bg-light-green'>
                                        <img src={familyIcon} alt='organizedIcon' />
                                    </div>
                                </div>
                                <Card.Title>
                                    <h4 className='advance_features_title'>Family OS</h4>
                                    <p className='advance_features_description'>No hassle retrieving records</p>
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default WhyChoose;