import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './MyCarousel.css'; // Custom CSS file for styling
import one from "../../../assest/images/carousel-imgs/carousel-img-1.png"
import two from "../../../assest/images/carousel-imgs/carousel-img-2.png"
import three from "../../../assest/images/carousel-imgs/carousel-img-3.png"
import four from "../../../assest/images/carousel-imgs/carousel-img-4.png"
import five from "../../../assest/images/carousel-imgs/carousel-img-5.png"
import six from "../../../assest/images/carousel-imgs/carousel-img-6.png"
import seven from "../../../assest/images/carousel-imgs/carousel-img-7.png"
import eight from "../../../assest/images/carousel-imgs/carousel-img-8.png"
import rightarrow from "../../../assest/images/carousel-imgs/right-arrow-next-com.svg";
import leftarrow from "../../../assest/images/carousel-imgs/left-arrow-back-com.svg";

const MyCarousel = () => {
    const customPrevArrow = (onClickHandler, hasPrev) =>(
            <button type="button" onClick={onClickHandler} className="custom-arrow custom-arrow-prev">
                <img src={leftarrow} alt={'l'} style={{width:35}}/>
            </button>
        )

    const customNextArrow = (onClickHandler, hasNext) =>(
        <button type="button" onClick={onClickHandler} className="custom-arrow custom-arrow-next">
            <img src={rightarrow} alt={'r'} style={{width:35}}/>
        </button>
    )

    return (
        <Carousel
            renderArrowPrev={customPrevArrow}
            renderArrowNext={customNextArrow}
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            useKeyboardArrows
        >
            <div className={"carousel-imges"}>
                <img src={one} alt="Slide 1"/>
            </div>
            <div className={"carousel-imges"}>
                <img src={two} alt="Slide 2"/>
            </div>
            <div className={"carousel-imges"}>
                <img src={three} alt="Slide 2"/>
            </div>
            <div className={"carousel-imges"}>
                <img src={four} alt="Slide 2"/>
            </div>
            <div className={"carousel-imges"}>
                <img src={five} alt="Slide 2"/>
            </div>
            <div className={"carousel-imges"}>
                <img src={six} alt="Slide 2"/>
            </div>
            <div className={"carousel-imges"}>
                <img src={seven} alt="Slide 2"/>
            </div>
            <div className={"carousel-imges"}>
                <img src={eight} alt="Slide 2"/>
            </div>
        </Carousel>
    );
};

export default MyCarousel;
