import React, {useEffect, useContext, useState} from 'react';
import './planlist.css';
import {Button, Container, Modal, Row} from 'react-bootstrap';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {Link} from 'react-router-dom';
import {BooksContext} from "./App";
import {Badge} from "@material-ui/core";
import blackImg from '../../../../../assets/images/black-book.png';
import pinkImg from '../../../../../assets/images/pink-book.png';
import purpleImg from '../../../../../assets/images/purple-book.png';
import whiteImg from '../../../../../assets/images/white-book.png';
// import backIcon from "../../../../../assets/images/back-img.png";
import  ktmwPdf from '../../../../../../src/assets/images/KTMW-PURPLE.pdf'
import bookBinder from '../../../../../assets/images/book_binder.jpg';
import bookPdf from '../../../../../assets/images/shopWeb/Pdf_Image.png';
import axios from "axios";

const PricingList = () => {
    const context = useContext(BooksContext);
    const totalCartCount = context.state.cart.reduce(
        (total, book) => (total = total + book.count),
        0
    );
    const [showToast, setShowToast] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({
        color: "black",
        id: 31,
        imgUrl: blackImg
    });
    const [openPdfModel, setOpenPdfModel] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({email: ''});

    const bookImg = [
        {
            bookColor: "black",
            imgUrl: blackImg
        },
        {
            bookColor: "pink",
            imgUrl: pinkImg
        },
        {
            bookColor: "purple",
            imgUrl: purpleImg
        },
        {
            bookColor: "white",
            imgUrl: whiteImg
        }
    ];
    useEffect(() => {
        const visitedBefore = localStorage.getItem('visitedBefore');
        if (!visitedBefore) {
            localStorage.setItem("visitedBefore", "false");
        }
        if (visitedBefore === "false") {
            localStorage.setItem('visitedBefore', "true");
        }
        window.addEventListener('beforeunload', () => {
            localStorage.setItem('visitedBefore', 'false');
        });
    }, []);

    const handelBookImg = (bookColor) => {
        const filterImg = bookImg.find((book, _) => book.bookColor === bookColor);
        setSelectedProduct((prevElem) => ({
            ...prevElem,
            imgUrl: filterImg.imgUrl,
        }));
    };
    const toggleToast = () => setShowToast(!showToast);

    const validateForm = () => {
        if (!email.trim()) {
            setErrors({ email: 'Email address is required' });
            return false;
        }
        return true;
    };

    const handlePdfPlan = () => {
        setErrors({ email: '' });
        setEmail('');
        setOpenPdfModel(!openPdfModel);
    };

    const handlePdf = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {

                const link = document.createElement('a');
                link.href = ktmwPdf;
                link.download = `printable_personal_affairs_organizer.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);


                const res = await axios.post(`${process.env.REACT_APP_SERVER}getDownLoadEmail`, {
                    sEmail: email,
                });
                if (res.data && res.data.downloadLink) {
                    window.location.href = res.data.downloadLink;
                }
            } catch (error) {
                console.error('Error fetching the PDF:', error);
                setErrors({ email: 'Failed to fetch PDF' });
            } finally {
                setOpenPdfModel(false);
            }
        }
    };

    return (
        <>
            <section className="plan_list_header">
                <div>
                    <Modal
                        centered={true}
                        show={showToast}
                        onHide={toggleToast}
                        className="custom-modal"
                    >
                        <Modal.Header closeButton className="modal-header-success">
                            <strong className="mr-5">Item added to cart</strong>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                            <i className="fas fa-check-circle fa-3x text-success mb-3" />
                            <p className="mb-0">Your item has been successfully added to cart.</p>
                        </Modal.Body>
                    </Modal>
                </div>

                <Container>
                    <div className="plan_header_section">
                        <h3>
                            Safe Storage and Delivery of your final wishes. Let go of worry
                            and embrace the <span>peace of mind</span> that comes with
                            securing your private information.
                        </h3>
                    </div>
                </Container>
            </section>
            <section className="plan_table_section">
                <Container fluid>
                    <Row className="justify-content-between mb-3">
                        <button className="btn btn-primary" onClick={handlePdfPlan}>
                            <span className="mr-2">Download Free Printable PDF Version</span>
                        </button>
                        <Link to="/cart">
                            <button className="btn btn-primary">
                                <span className="mr-2">View Cart</span>
                                <Badge
                                    badgeContent={
                                        !Number.isNaN(totalCartCount) ? totalCartCount : 0
                                    }
                                    color="error"
                                >
                                    <ShoppingCartOutlinedIcon/>
                                </Badge>
                            </button>
                        </Link>
                    </Row>
                    <Row className="justify-content-center">
                        <div className="custom_table">
                            <table className="table plan_content_table">
                                <thead>
                                <tr>
                                    <th>
                                        <h5>ITEM</h5>
                                    </th>
                                    <th>
                                        <h5>PRICE</h5>
                                    </th>
                                    <th>
                                        <h5>DESCRIPTION OF ITEM</h5>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {context.state.bookList.map((book, index) => {
                                    const colorOption = book?.colorOptions?.split(",").map(color => {
                                        return color.split(" ").map(part => part.trim()).join("").toLowerCase();
                                    });
                                    const isDisabledBtn = !(book.mediaType === "Yearly Service Plan") ? !((book.mediaType !== "Interactive PDF" ? colorOption : ['black']).some((e) => e === selectedProduct.color)) : false;

                                    return (
                                        <tr key={index}>
                                            <td>
                                                <h6>{book.name}</h6>
                                                <div className="outer-image">
                                                    <img
                                                        src={
                                                            book.mediaType === "Book"
                                                                ? selectedProduct.imgUrl
                                                                : book.mediaType === "Binder" ? bookBinder : book.mediaType === "Interactive PDF" ?  bookPdf : "/logo.png"
                                                        }
                                                        alt={selectedProduct.color}
                                                        className="img-fluid inner-image"
                                                        width={180}
                                                        height={180}
                                                    />
                                                </div>
                                            </td>
                                            <td className="p-0 table_row ">
                                                <div className="custom_price_box">
                                                    <div className="book_price_box">
                                                        <h6>{book.mediaType}</h6>
                                                        <p>${book.yearlyrate}</p>
                                                        {(book.mediaType === "Book" || book.mediaType === "Binder" || book.mediaType === "Interactive PDF") && (
                                                            <div
                                                                className="d-flex justify-content-center align-items-center w-100"
                                                                style={{gap: "5px"}}
                                                            >
                                                                {book.mediaType === "Interactive PDF" ? (
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: "black",
                                                                            width: selectedProduct.color === "black" ? "25px" : "20px",
                                                                            height: selectedProduct.color === "black" ? "25px" : "20px",
                                                                            display: "block",
                                                                            boxShadow: "rgba(99, 99, 99, 0.5) 0 2px 8px 0",
                                                                            borderRadius: "100%",
                                                                            cursor: "pointer",
                                                                            outline: selectedProduct.color === "black" ? "2px solid #3395FF" : "2px solid transparent",
                                                                        }}
                                                                        onClick={() => {
                                                                            book.selectedColor = "black";
                                                                            setSelectedProduct((prevElem) => ({
                                                                                ...prevElem,
                                                                                color: "black",
                                                                                id: book.id,
                                                                            }));
                                                                            context.setColor("black");
                                                                            if (book.mediaType === "Book") {
                                                                                handelBookImg("black");
                                                                            }
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    colorOption.map((color, index) => (
                                                                        <span
                                                                            key={index}
                                                                            style={{
                                                                                backgroundColor: color,
                                                                                width: selectedProduct.color === color ? "25px" : "20px",
                                                                                height: selectedProduct.color === color ? "25px" : "20px",
                                                                                display: "block",
                                                                                boxShadow: "rgba(99, 99, 99, 0.5) 0 2px 8px 0",
                                                                                borderRadius: "100%",
                                                                                cursor: "pointer",
                                                                                outline: selectedProduct.color === color ? "2px solid #3395FF" : "2px solid transparent",
                                                                            }}
                                                                            onClick={() => {
                                                                                book.selectedColor = color;
                                                                                setSelectedProduct((prevElem) => ({
                                                                                    ...prevElem,
                                                                                    color,
                                                                                    id: book.id,
                                                                                }));
                                                                                context.setColor(color);
                                                                                if (book.mediaType === "Book") {
                                                                                    handelBookImg(color);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ))
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="cart_btn">
                                                        <button
                                                            type="button"
                                                            className={`${isDisabledBtn ? "disabled" : ""} Cart_button button_primary`}
                                                            onClick={() => {
                                                                toggleToast();
                                                                context.addToCart(book);
                                                                setTimeout(() => {
                                                                    setShowToast(false);
                                                                }, 2000)
                                                            }}
                                                            disabled={isDisabledBtn}
                                                        >
                                                            Add to Cart
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-left">
                                                <span dangerouslySetInnerHTML={{__html: book.description}}/>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </Row>
                    <Row className="justify-content-center">
                        <div className="mobile_product">
                            {context.state.bookList.map((book, index) => {
                                const colorOption = book?.colorOptions?.split(",").map(color => {
                                    return color.split(" ").map(part => part.trim()).join("").toLowerCase();
                                });
                                const isDisabledBtn = !(book.mediaType === "Yearly Service Plan") ? !((book.mediaType !== "Interactive PDF" ? colorOption : ['black']).some((e) => e === selectedProduct.color)) : false;
                                return (
                                    <div key={index}
                                         className="d-flex align-items-start shadow-sm border rounded mb-4 p-3">
                                        <div className="outer-image pr-3">
                                            <img
                                                src={
                                                    book.mediaType === "Book"
                                                            ? selectedProduct.imgUrl
                                                            : book.mediaType === "Binder" ? bookBinder : book.mediaType === "Interactive PDF" ?  bookPdf : "/logo.png"
                                                    }
                                                    alt={selectedProduct.color}
                                                    className="img-fluid inner-image"
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>
                                        <div className="">
                                            <div className="p-0 table_row">
                                                <div className="custom_price_box">
                                                    <div className="book_price_box">
                                                        {(book.mediaType === "Book" || book.mediaType === "Binder" || book.mediaType === "Interactive PDF") && (
                                                            <>
                                                                <div className="mb-2 font-weight-bold">colors:</div>
                                                                <div
                                                                    className="d-flex justify-content-start align-items-center w-100"
                                                                    style={{gap: "5px"}}
                                                                >
                                                                    {book.mediaType === "Interactive PDF" ? (
                                                                        <span
                                                                            style={{
                                                                                backgroundColor: "black",
                                                                                width: selectedProduct.color === "black" ? "25px" : "20px",
                                                                                height: selectedProduct.color === "black" ? "25px" : "20px",
                                                                                display: "block",
                                                                                boxShadow: "rgba(99, 99, 99, 0.5) 0 2px 8px 0",
                                                                                borderRadius: "50%",
                                                                                cursor: "pointer",
                                                                                outline: selectedProduct.color === "black" ? "2px solid #3395FF" : "2px solid transparent",
                                                                            }}
                                                                            onClick={() => {
                                                                                book.selectedColor = "black";
                                                                                setSelectedProduct({ ...selectedProduct, color: "black", id: book.id });
                                                                                context.setColor("black");
                                                                                if (book.mediaType === "Book") {
                                                                                    handelBookImg("black");
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        colorOption.map((color, index) => (
                                                                            <span
                                                                                key={index}
                                                                                style={{
                                                                                    backgroundColor: color,
                                                                                    width: selectedProduct.color === color ? "25px" : "20px",
                                                                                    height: selectedProduct.color === color ? "25px" : "20px",
                                                                                    display: "block",
                                                                                    boxShadow: "rgba(99, 99, 99, 0.5) 0 2px 8px 0",
                                                                                    borderRadius: "50%",
                                                                                    cursor: "pointer",
                                                                                    outline: selectedProduct.color === color ? "2px solid #3395FF" : "2px solid transparent",
                                                                                }}
                                                                                onClick={() => {
                                                                                    book.selectedColor = color;
                                                                                    setSelectedProduct({ ...selectedProduct, color, id: book.id });
                                                                                    context.setColor(color);
                                                                                    if (book.mediaType === "Book") {
                                                                                        handelBookImg(color);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        ))
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                        <h6 className="mt-2">{book.name}</h6>
                                                        <h6>{book.mediaType}</h6>
                                                        <p className="m-0">${book.yearlyrate}</p>
                                                    </div>
                                                    <span className="my-2"
                                                          dangerouslySetInnerHTML={{__html: book.description}}/>
                                                </div>
                                            </div>
                                            <div className="text-left">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className={`${isDisabledBtn ? "disabled" : ""} Cart_button button_primary`}
                                                        onClick={() => {
                                                            toggleToast();
                                                            context.addToCart(book);
                                                            setTimeout(() => {
                                                                setShowToast(false);
                                                            }, 2000)
                                                        }}
                                                        disabled={isDisabledBtn}
                                                    >
                                                        Add to Cart
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Row>
                </Container>
            </section>

            <Modal show={openPdfModel} onHide={handlePdfPlan} className='activeModel'>
                <Modal.Header
                    closeButton
                    style={{backgroundColor: "#0069d9", color: "white"}}
                >
                    <Modal.Title>Free Printable PDF Download</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This is our Printable PDF version of our Personal Affairs Organizer.
                        You will be able to print this and keep for your personal use.</p>
                    <p> With your Email address we will inform you of any new pages and any page updates.</p>
                    <p>Please check out our Interactive version which does cost. The Digital Interactive PDF version allows
                        you to enter your important information allowing you to digitally store your important information on your computer or your smartphone.</p>
                    <form onSubmit={handlePdf}>
                        <div className="mb-3">
                            <label className='font-weight-bold'>Email:</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </div>
                        <Modal.Footer>
                            <Button type="submit" className="btngenie">
                                Submit
                            </Button>
                            <Button className="btngenie" onClick={handlePdfPlan}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default PricingList;