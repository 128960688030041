import React, {useState} from 'react';
import './planlist.css';
import {Card, Col, Container, Row, Table} from 'react-bootstrap';
import { IoCheckmarkSharp } from "react-icons/io5";
import Switch from 'react-switch'
import WishesVideo from '../../../../../assets/images/KeysToMyWishes.mp4'
import {ModalBody, Modal, ModalHeader} from "reactstrap";
import { MdClose } from "react-icons/md";
const PricingList = () => {
    const [isYearly, setIsYearly] = useState(true);
    const [showVideo, setShowVideo] = useState(false);

    const toggleVideo = () => {
        setShowVideo(!showVideo);
    };
    const handleSwitchChange = (checked) => {
        setIsYearly(checked);
    };
    return (
        <section className='plan_list_table'>
            <Container fluid>
                <Row className='justify-content-center m-0' >
                    <Col sm={12} className='mb-4'>
                        <div className='pricing_switch'>
                            <p>Monthly</p>
                            <Switch
                                name={'pricing'}
                                height={32}
                                width={59}
                                onChange={handleSwitchChange}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                checked={isYearly}
                            />
                            <p>Yearly</p>
                        </div>
                    </Col>
                    <Col sm={11}>
                        <Table  responsive bordered className='plans-table'>
                            <thead>
                                <tr className='bg-header'>
                                    <th>
                                        <h4 className='pricing-title'>Choose Your Plan</h4>
                                    </th>
                                    <th>
                                        <Card className='plan_bg_card_pricing'>
                                            <Card.Body>
                                                <Card.Title>
                                                    Yearly Check-In
                                                </Card.Title>
                                                <div className='plan_list_menu mt-1'>
                                                    {!isYearly
                                                        ? <h3 className='price'>$4.99<span>/Month</span></h3>
                                                        : <h3 className='price'>$49.90<span>/Yr</span></h3>
                                                    }
                                                </div>
                                                {/*<p>$49.99/year<span>*</span>Save $9.98<span>*</span></p>*/}
                                            </Card.Body>
                                        </Card>
                                    </th>
                                    <th>
                                        <Card className='plan_card_pricing'>
                                            <Card.Body>
                                                <Card.Title className="text-light">
                                                    Quarterly Check-In
                                                </Card.Title>
                                                <div className='plan_list_menu mt-3'>
                                                    {!isYearly
                                                        ? <h3 className='plan_bg_card_pricing_text'>$6.99<span>/Month</span></h3>
                                                        : <h3 className='plan_bg_card_pricing_text'>$69.90<span>/Yr</span></h3>
                                                    }
                                                </div>
                                                {/*<p className="text-light">$69.90/year<span>*</span>Save $13.98<span>*</span></p>*/}
                                            </Card.Body>
                                        </Card>
                                    </th>
                                    <th>
                                        <Card className='plan_bg_card_pricing'>
                                            <Card.Body>
                                                <Card.Title>
                                                    Monthly Check-In
                                                </Card.Title>
                                                <div className='plan_list_menu mt-1'>
                                                    {!isYearly
                                                        ? <h3 className='price'>$9.99<span>/Month</span></h3>
                                                        : <h3 className='price'>$99.90<span>/Yr</span></h3>
                                                    }
                                                </div>
                                                {/*<p>$99.90/year<span>*</span>Save $19.98<span>*</span></p>*/}
                                            </Card.Body>
                                        </Card>
                                    </th>
                                    <th>
                                        <Card className='plan_bg_card_pricing'>
                                            <Card.Body>
                                                <Card.Title>
                                                    Weekly Check-In
                                                </Card.Title>
                                                <div className='plan_list_menu mt-1'>
                                                    {!isYearly
                                                        ? <h3 className='price'>$14.99<span>/Month</span></h3>
                                                        : <h3 className='price'>$149.90<span>/Yr</span></h3>
                                                    }
                                                </div>
                                                {/*<p>$99.90/year<span>*</span>Save $19.98<span>*</span></p>*/}
                                            </Card.Body>
                                        </Card>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='bg-header'>
                                    <th>
                                        <h4>Features</h4>
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td onClick={toggleVideo} className="cursor-pointer"> Estate Organizer</td>
                                    <th></th>
                                    <th><IoCheckmarkSharp/></th>
                                    <th><IoCheckmarkSharp/></th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>Check-in frequency</td>
                                    <td className="pricing_table">Yearly</td>
                                    <td className="pricing_table">Quarterly</td>
                                    <td className="pricing_table">Monthly</td>
                                    <td className="pricing_table">Weekly</td>
                                </tr>
                                <tr>
                                    <td>Real Life representatives</td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                </tr>
                                <tr>
                                    <td>Two-way authentication</td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                </tr>
                                <tr>
                                    <td>256 Bit encryption</td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                </tr>
                                <tr>
                                    <td>Emergency cards</td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                </tr>
                                <tr>
                                    <td>Storage space</td>
                                    <td>2GB</td>
                                    <td>2GB</td>
                                    <td>2GB</td>
                                    <td>2GB</td>
                                </tr>
                                <tr>
                                    <td>Biometric scan</td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                </tr>
                                <tr>
                                    <td>Break-the-Glass</td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                </tr>
                                <tr>
                                    <td>Free sharing</td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                    <td><IoCheckmarkSharp /></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            {showVideo && (
                <Modal
                    isOpen={showVideo}
                    className='helper_video_frame d-flex justify-content-center'
                    fullscreen={true}
                    size='xl'
                >
                    <ModalHeader className="d-flex justify-content-end">
                        <button className='btn' onClick={() => setShowVideo(false)}><MdClose size={26} /></button>
                    </ModalHeader>
                    <ModalBody className='helper_video p-4'>
                    <video width="500" height="503" controls>
                            <source src={WishesVideo} type="video/mp4" />
                        </video>
                    </ModalBody>
                </Modal>
            )}
        </section>
    );
};

export default PricingList;