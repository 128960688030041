import React from 'react';
import Header from "./Header";
import EstatePlanning from "./EstatePlanning";

const HomeWebCopy = () => {
    return (
        <div>
            <Header/>
            <EstatePlanning/>
        </div>
    );
};

export default HomeWebCopy;