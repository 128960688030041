import business_page from "../PdfPages/business_page.pdf";
import employment_page from "../PdfPages/employment_page.pdf";
import front_cover from "../PdfPages/front_cover.pdf";
import back_cover from "../PdfPages/back_cover.pdf";
import jewelry_inventory from "../PdfPages/jewelry-inventory-collectibles_page.pdf";
import letter_to_loved_ones from "../PdfPages/letter_to_loved_ones.pdf";
import life_Insurance from "../PdfPages/life_Insurance-bank_account_page.pdf";
import bank_accounts from "../PdfPages/bank_accounts_page.pdf" ;
import household_inventory_items from "../PdfPages/Household_Inventory_Items_page.pdf" ;
import Collectibles_page from "../PdfPages/Collectibles_page.pdf" ;
import my_obituary_page from "../PdfPages/my_obituary_page.pdf"
import My_Final_Wishes_page from "../PdfPages/My_Final_Wishes_page.pdf";
import powerAttorney from "../PdfPages/power_of_attorney_page.pdf";
import real_estate from "../PdfPages/real_estate_page.pdf";
import safe_deposit_page from "../PdfPages/safe_deposit_page.pdf";
import trust_and_will_page from "../PdfPages/trust_and_will_page.pdf";
import attorney_info from "../PdfPages/attorney_info.pdf";
import automobiles from "../PdfPages/automobiles.pdf";
import business_owned from "../PdfPages/business_owned.pdf";
import case_emergency from "../PdfPages/case_emergency.pdf";
import cherishmemories from "../PdfPages/cherishmemories.pdf";
import children from "../PdfPages/children.pdf";
import coolectibles from "../PdfPages/coolectibles.pdf";
import credit_cards from "../PdfPages/credit_cards.pdf";
import digital_accounts from "../PdfPages/digital_accounts.pdf";
import friendsfamily from "../PdfPages/friendsfamily.pdf";
import heirlooms from "../PdfPages/heirlooms.pdf";
import incapacitation from "../PdfPages/incapacitation.pdf";
import insurance from "../PdfPages/insurance.pdf";
import my_Information from "../PdfPages/my_Information.pdf";
import pets from "../PdfPages/pets.pdf";
import upon_my_death_do_this from "../PdfPages/upon_my_death_do_this.pdf";
import personal_affairs_organizer from "../PdfPages/personal_affairs_organizer.pdf";
import inCaseOfEmergency from "../PdfPages/in_case_of_EMERGENCY.pdf";
import passwordDigitalAccounts from "../PdfPages/PASSWORD-DIGITAL- ACCOUNIS.pdf"

export const pdfLists = [
    // {file: "front_cover",pdf: front_cover, title: "Front Cover"},
    {file: "safe_deposit_page",pdf: safe_deposit_page, title: "Safe Deposit page"},
    {file: "case_emergency",pdf: case_emergency, title: "In Case Of Emergency page"},
    {file: "my_Information",pdf: my_Information, title: "My Information"},
    {file: "children",pdf: children, title: "Children"},
    {file: "pets",pdf: pets, title: "Pets"},
    {file: "upon_my_death_do_this",pdf: upon_my_death_do_this, title: "Upon My Death Do this"},
    {file: "employment_page",pdf: employment_page, title: "Employment Information"},
    {file: "trust_page",pdf: trust_and_will_page, title: "Trust"},
    {file: "will_page",pdf: trust_and_will_page, title: "Will"},
    {file: "power_of_attorney_page",pdf: powerAttorney, title: "Power of Attorney"},
    {file: "attorney_info",pdf: attorney_info, title: "Attorney Information"},
    {file: "insurance",pdf: insurance, title: "Insurance"},
    {file: "bank_accounts",pdf: bank_accounts, title: "Bank Account page"},
    {file: "digital_accounts",pdf: digital_accounts, title: "Digital Accounts"},
    {file: "credit_cards",pdf: credit_cards, title: "Credit Cards"},
    {file: "automobiles",pdf: automobiles, title: "Automobiles"},
    {file: "business_owned",pdf: business_owned, title: "Business Owned"},
    {file: "jewelry-inventory",pdf: jewelry_inventory, title: "Jewelry page"},
    {file: "coolectibles",pdf: coolectibles, title: "Collectibles"},
    {file: "real_estate_page",pdf: real_estate, title: "Real Estate page"},
    {file: "incapacitation",pdf: incapacitation, title: "Incapacitation"},
    {file: "friendsfamily",pdf: friendsfamily, title: "Friends and Family"},
    {file: "cherishmemories",pdf: cherishmemories, title: "Cherished Memories With"},
    {file: "my_obituary_page",pdf: my_obituary_page, title: "Obituary"},
    {file: "My_Final_Wishes_page",pdf: My_Final_Wishes_page, title: "Final Wishes page"},
    {file: "heirlooms",pdf: heirlooms, title: "Heirlooms"},
    {file: "personal_affairs_organizer",pdf: personal_affairs_organizer, title: "Personal Affairs Organizer",hidden:true},
    {file: "business_page",pdf: business_page, title: "Business page"},
    {file: "household_inventory_items",pdf: household_inventory_items, title: "Inventory page"},
    {file: "Collectibles_page",pdf: Collectibles_page, title: "Collectibles page"},
    {file: "letter_to_loved_ones",pdf: letter_to_loved_ones, title: "Letter to Loved Ones"},
    {file: "password_digital_accounts",pdf: passwordDigitalAccounts, title: "Password Digital Accounts"},
    // {file: "back_cover",pdf: back_cover, title: "Back Cover"},
]