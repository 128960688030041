import React from 'react';
import './faq.css';
import {Col, Container, Row} from 'react-bootstrap';
import {AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion} from 'reactstrap';

const FAQ = () => {
    return (
        <section className='faq_section'>
            <Container>
                <Row className='justify-content-center m-0'>
                    <Col sm={12}>
                        <div className='section-title mb-4'>
                            <h2>Frequently Asked Questions</h2>
                        </div>
                    </Col>

                    <Col sm={12}>
                        <div className='faq-accordion'>
                            <UncontrolledAccordion defaultOpen="1">
                                <AccordionItem>
                                    <AccordionHeader targetId="1">
                                        If I leave my info on your system how do I know my information will not be
                                        released to my loved ones prior to me wanting it released?
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1">
                                        KeysToMyWishes process is that only if we are contacted by someone with your
                                        account number is our process triggered. <br/>
                                        Once triggered we send you email and text messages. If you do not respond we(a
                                        live representative) will then attempt to call you. If we are still unable to
                                        contact you we will then reach out to your emergency contact. If your emergency
                                        contact informs us that you are incapacitated or have passed away we will then
                                        Release your information to those you have designated to receive your
                                        information.
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="2">
                                        What if I need extra space?
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2">
                                        You can easily get 5 additional GB for an extra $5 per month
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="3">
                                        How well is my data protected?
                                    </AccordionHeader>
                                    <AccordionBody accordionId="3">
                                        We use 256 bit encryption and encryption on all files as the are in the file
                                        system. <br/>
                                        We also have added security using 2 factor authentication.
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="4">
                                        What is Break-the-Glass?
                                    </AccordionHeader>
                                    <AccordionBody accordionId="4">
                                        Break-the-Glass is our term for the process that is triggered if we receive a
                                        call informing us that our customer is either incapacitated or has passed away.
                                        Upon receiving our customer's account # our representatives will attempt to
                                        contact our customer directly via text and phone calls.
                                        If we cannot reach the customer our representatives will reach out to the
                                        Emergency Contact(s).<br/>
                                        If we are informed that something has happened to our customer we will then
                                        follow your instructions as to when to Release/Share your information and to
                                        whom we are to share it with.
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="5">
                                        Who has access to my information?
                                    </AccordionHeader>
                                    <AccordionBody accordionId="5">
                                        No one but you. The only way someone can view your information is if you have
                                        Shared it with them. This is totally done by you.
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="6">
                                        Why is it important to insert emergency contacts?
                                    </AccordionHeader>
                                    <AccordionBody accordionId="6">
                                        We use your emergency contacts to inform us of your well being if we can't
                                        contact you.
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="7">
                                        How will you know if I am incapacitated or dead?
                                    </AccordionHeader>
                                    <AccordionBody accordionId="7">
                                        When someone has contacted us.
                                        This is why you have the Emergency cards.
                                        Your loved ones will reach out to us providing your account number which is on
                                        the Emergency cards.
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="8">
                                        How do you ensure that my emergency contacts are not contacted prematurely?
                                    </AccordionHeader>
                                    <AccordionBody accordionId="8">
                                        We attempt to reach out to you with 3 emails, 3 text messages and then we
                                        attempt to call you. <br/>
                                        If we are not able to make contact with you from these, we then will reach out
                                        to your emergency contact(s).
                                    </AccordionBody>
                                </AccordionItem>
                            </UncontrolledAccordion>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default FAQ;