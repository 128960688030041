import React from 'react';

const OrganizingYourPersonal = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Keys to Organizing Your Personal Affairs</h3>
            </div>
            <div className="content mt-3 mb-5">
                <div className="mt-4">
                    <h4>CLAIMS</h4>
                    <p>
                        We claim that if you use this journal, it will assist you in organizing your personal documents. By doing so you will know what documents you currently
                        have and perhaps what documents you need to acquire. It will also help you
                        know where these documents are located. Additionally, it will also assist
                        your family in locating these documents in case of an emergency.
                    </p>
                </div>
                <div className="mt-4">
                    <h4>DISCLAIMS</h4>
                    <p>
                        This journal is not meant to be a legal document or provide legal guidance.
                        Be sure to consult an attorney on any legal matters related to any and all
                        your personal documents.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OrganizingYourPersonal;