import React, {useEffect, useState} from 'react';
import keyIcon from "../../assets/images/LandingPageNew/keyIcon.png"
import "./asset-sheet-page.css";
import {Col, Form, FormControl, Row} from "react-bootstrap";
import {Grid, Typography} from "@material-ui/core";
import axios from "axios";

const AssetSheetPdfPage = ({
       itemName,
       optionalData,
       itemNotes,
       mediaDetails
}) => {
    const [states,setStates] = useState();

    useEffect(()=>{
        axios.get(process.env.REACT_APP_SERVER + 'getstates').
        then(res => {
            setStates(res.data);
        });
    },[]);

    return (
        <div className="show-print asset-sheet-page">
            <div className="header">
                <div>
                    <img src={keyIcon} alt="keyIcon"/>
                </div>
                <div>
                    <h1 className="title">www.KeysToMyWishes.com</h1>
                </div>
                <div>
                    <h4>Phone: 747-774-0966</h4>
                </div>
            </div>
            <div className="asset-sheet-title">
                <h1>Asset Sheet</h1>
            </div>

            <div>
                <label>
                    Item:
                </label>
                <div
                    className="rich_text_html form-control"
                    dangerouslySetInnerHTML={{__html:itemName.replaceAll("\n","<br/>")}}
                />
            </div>

            <div className="asset_info_card">
                <h4 className="title_info">Optional Info:</h4>
                <Grid container>
                    <Grid item sm={12}>
                        <Typography style={{marginRight: 15}}>
                            <Typography>
                                <Form.Group
                                    as={Row}
                                    controlId="exampleForm.ControlInput2"
                                    className={"businessFormGroup"}
                                >
                                    <Col md="3">
                                        <Form.Label>Business Name:</Form.Label>
                                    </Col>
                                    <Col md="9">
                                        <Form.Control
                                            type="text"
                                            className="postalCode sort-input-field"
                                            name={"Xbusinessname"}
                                            placeholder=""
                                            value={optionalData.Xbusinessname}
                                            maxLength="170"
                                        />
                                    </Col>
                                </Form.Group>
                            </Typography>
                            <Typography>
                                <Form.Group
                                    as={Row}
                                    controlId="exampleForm.ControlInput2"
                                    className={"formGroup"}
                                >
                                    <Col md="3">
                                        <Form.Label>Account Number : </Form.Label>
                                    </Col>
                                    <Col md="9">
                                        <Form.Control
                                            name={"Xaccountnumber"}
                                            value={optionalData.Xaccountnumber}
                                            placeholder=""
                                            maxLength="170"
                                        />
                                    </Col>
                                </Form.Group>
                            </Typography>
                            <Typography>
                                <Form.Group
                                    as={Row}
                                    controlId="exampleForm.ControlInput2"
                                    className={"formGroup"}
                                >
                                    <Col md="3">
                                        <Form.Label>Address:</Form.Label>
                                    </Col>
                                    <Col md="9">
                                        <Form.Control
                                            type="text"
                                            name={"Xaddress"}
                                            value={optionalData.Xaddress}
                                            placeholder=""
                                            maxLength="170"
                                        />
                                    </Col>
                                </Form.Group>
                            </Typography>
                            <Typography>
                                <Form.Group
                                    as={Row}
                                    controlId="exampleForm.ControlInput2"
                                    className={"formGroup"}
                                >
                                    <Col md="3">
                                        <Form.Label>City:</Form.Label>
                                    </Col>
                                    <Col md="9">
                                        <Form.Control
                                            type="text"
                                            value={optionalData.Xcity}
                                            placeholder=""
                                            maxLength="170"
                                        />
                                    </Col>
                                </Form.Group>
                            </Typography>
                            <Typography>
                                <Form.Group
                                    as={Row}
                                    controlId="exampleForm.ControlInput2"
                                    className={"formGroup"}
                                >
                                    <Col md="3">
                                        <Form.Label>State:</Form.Label>
                                    </Col>
                                    <Col md="9">
                                        <FormControl
                                            as="select"
                                            value={optionalData.Xstateid}
                                            name={"Xstateid"}
                                            className="sort-input-field"
                                        >
                                            {states &&
                                                states.map((e) => {
                                                    return (
                                                        <option key={e.id} value={e.id}>
                                                            {e.name}
                                                        </option>
                                                    );
                                                })}
                                        </FormControl>
                                    </Col>
                                </Form.Group>
                            </Typography>
                            <Typography>
                                <Form.Group
                                    as={Row}
                                    controlId="exampleForm.ControlInput2"
                                    className={"formGroup"}
                                >
                                    <Col md="3">
                                        <Form.Label>Zip Code:</Form.Label>
                                    </Col>
                                    <Col md="9">
                                        <Form.Control
                                            type="text"
                                            name={"Xaddress"}
                                            placeholder=""
                                            maxLength="170"
                                            value={optionalData.Xzip}
                                        />
                                    </Col>
                                </Form.Group>
                            </Typography>
                            <Typography>
                                <Form.Group
                                    as={Row}
                                    controlId="exampleForm.ControlInput2"
                                    className={"formGroup"}
                                >
                                    <Col md="3">
                                        <Form.Label>URL :</Form.Label>
                                    </Col>
                                    <Col md="9">
                                        <Form.Control
                                            type="text"
                                            name={"Xurl"}
                                            placeholder=""
                                            value={optionalData.Xurl}
                                            maxLength='170'
                                        />
                                    </Col>
                                </Form.Group>
                            </Typography>
                            <Typography>
                                <Form.Group
                                    as={Row}
                                    controlId="exampleForm.ControlInput2"
                                    className={"formGroup"}
                                >
                                    <Col md="3">
                                        <Form.Label>User Name :</Form.Label>
                                    </Col>
                                    <Col md="9">
                                        <Form.Control
                                            type="text"
                                            className="postalCode sort-input-field"
                                            name={"Xusername"}
                                            placeholder=""
                                            value={optionalData.Xusername}
                                            maxLength='170'
                                        />
                                    </Col>
                                </Form.Group>
                            </Typography>
                            <Typography>
                                <Form.Group
                                    as={Row}
                                    controlId="exampleForm.ControlInput2"
                                    className={"formGroup"}
                                >
                                    <Col md="3">
                                        <Form.Label>Password :</Form.Label>
                                    </Col>
                                    <Col md="9">
                                        <Form.Control
                                            type="text"
                                            className="postalCode sort-input-field"
                                            name={"Xpword"}
                                            value={optionalData.Xpword}
                                            placeholder=""
                                            maxLength='170'
                                        />
                                    </Col>
                                </Form.Group>
                            </Typography>
                            <Typography>
                                <Form.Group
                                    as={Row}
                                    controlId="exampleForm.ControlInput2"
                                    className={"formGroup"}
                                >
                                    <Col md="3">
                                        <Form.Label>Contact Info :</Form.Label>
                                    </Col>
                                    <Col md="9">
                                        <textarea
                                            value={optionalData.Xcontactinfo}
                                            className="postalCode sort-input-field form-control"
                                            name={"Xusername"}
                                            placeholder=""
                                            maxLength='170'
                                        />
                                    </Col>
                                </Form.Group>
                            </Typography>
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            <div className="mb-3">
                <label>
                    Notes:
                </label>
                <div
                    className="rich_text_html_lg form-control"
                    dangerouslySetInnerHTML={{__html:itemNotes}}
                />
            </div>
            <div className="avoid_line_break">
                <label>
                    Documents Associated with this Item:
                </label>
                {mediaDetails.map((file,index)=>(
                    <div className="line_text" key={index}>
                        {file.filename}
                    </div>
                ))}
            </div>

            <h4 className="footer">www.KeysToMyWishes.com</h4>
        </div>
    );
};

export default AssetSheetPdfPage;