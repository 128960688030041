import React from 'react';
import BGHeader from '../Common/BGHeader';
import LastWish from './LastWish';
import OurValues from './OurValues';
import OurStory from './OurStory';
import FreeTrial from './FreeTrial';
import Footer from '../Home/Footer';

const About = () => {
    return (
        <div>
            <BGHeader
             title={'Save FRANTIC hours searching your vital documents'}
             description={'Our mission is to safeguard and organize your crucial information for easy access and seamless transfer to your loved ones after your passing. We provide an operating system to avoid the hassle of remembering and locating information stored in different places.'}
            />
            <LastWish/>
            <OurValues/>
            <OurStory/>
            <Footer/>
        </div>
    );
};

export default About;