import React from 'react';
import './planlist.css';
import { Card, Col, Container, Row } from 'react-bootstrap';
import {Link} from "react-router-dom";
import dotsImage from "../../../../Landing/assets/images/dot.svg";
import linesImage from "../../../../Landing/assets/images/lines.svg";
import WishesVideo from "../../../../../assets/images/KeysToMyWishes.mp4";

const PlanList = () => {
    return (
        <>
            <section className='plan_list'>
                <Container>
                    <Row className='justify-content-center m-0'>
                        <Col lg={12} className='mb-3'>
                            <Card className='helper_video_container'>
                                <Card.Body className='p-0'>
                                    <div className='helper_video_header'>
                                        <div className='d-flex justify-content-between'>
                                            <img
                                                src={dotsImage}
                                                className="d-inline-block align-top"
                                                alt="images"
                                            />
                                            <img
                                                src={linesImage}
                                                className="d-inline-block align-top"
                                                alt="images"
                                            />
                                        </div>
                                    </div>
                                    <div className='helper_video_frame'>
                                        <div className='helper_video'>
                                            <video width="914" height="503" controls>
                                                <source src={WishesVideo} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        {/*    <Card className='plan_list_card'>*/}
                        {/*        <Card.Body>*/}
                        {/*            <Card.Title>*/}
                        {/*                Standard*/}
                        {/*            </Card.Title>*/}
                        {/*            <div className='plan_list_menu'>*/}
                        {/*                <ul>*/}
                        {/*                    <li>*/}
                        {/*                    Your information should not get lost in a fire or an accident.*/}
                        {/*                    </li>*/}
                        {/*                    <li>*/}
                        {/*                    A weekly check-in will ensure relaxed and restful sleep.*/}
                        {/*                    </li>*/}
                        {/*                    <li>*/}
                        {/*                    You want to communicate your wishes to your family after your passing.*/}
                        {/*                    </li>*/}
                        {/*                </ul>*/}
                        {/*            </div>*/}
                        {/*            */}
                        {/*        </Card.Body>*/}
                        {/*    </Card>*/}
                        {/*</Col>*/}
                        {/*<Col lg={4} md={6} sm={12} className='mb-3'>*/}
                        {/*<Card className='plan_bg_card'>*/}
                        {/*        <Card.Body>*/}
                        {/*            <Card.Title>*/}
                        {/*            Executive*/}
                        {/*            </Card.Title>*/}
                        {/*            <div className='plan_list_menu'>*/}
                        {/*                <ul>*/}
                        {/*                    <li>*/}
                        {/*                    Your documents require effective organization and protection.*/}
                        {/*                    </li>*/}
                        {/*                    <li>*/}
                        {/*                    You seek consistent wellness check-ins every 3-days.*/}
                        {/*                    </li>*/}
                        {/*                    <li>*/}
                        {/*                    You intend to simplify passing down cherished memories and your legacy.*/}
                        {/*                    </li>*/}
                        {/*                </ul>*/}
                        {/*            </div>*/}
                        {/*            */}
                        {/*        </Card.Body>*/}
                        {/*    </Card>*/}
                        {/*</Col>*/}
                        {/*<Col lg={4} md={6} sm={12} className='mb-3'>*/}
                        {/*    <Card className='plan_list_card'>*/}
                        {/*        <Card.Body>*/}
                        {/*            <Card.Title>*/}
                        {/*            Premium*/}
                        {/*            </Card.Title>*/}
                        {/*            <div className='plan_list_menu'>*/}
                        {/*                <ul>*/}
                        {/*                    <li>*/}
                        {/*                    You have intensive amounts of data that require protection.*/}
                        {/*                    </li>*/}
                        {/*                    <li>*/}
                        {/*                    You are concerned about being located if incapacitated and desire daily check-ins.*/}
                        {/*                    </li>*/}
                        {/*                    <li>*/}
                        {/*                    Your assets and wishes need to pass on to your loved ones.*/}
                        {/*                    </li>*/}
                        {/*                </ul>*/}
                        {/*            </div>*/}
                        {/*            */}
                        {/*        </Card.Body>*/}
                        {/*    </Card>*/}
                        </Col>
                    </Row>
                    <Row className='justify-content-center mt-5'>
                    <Link to="/how-to" type="button" className="grediant-btn btn"><span>   Learn More </span></Link>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default PlanList;