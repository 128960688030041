import React, {useEffect, useState} from "react";
import {Form, FormControl, Button, Row, Col, Modal} from "react-bootstrap";
import "./myprofile.css";
import "react-tabs/style/react-tabs.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./myprofile.css";
import {Link} from "react-router-dom";
import axios from "axios";
import Header from "../../components/NewHeader/Header";
import Logout from "../../components/logout/Logout";
import "./myprofile.css";
import EditIcon from "@material-ui/icons/Edit";
import defaultProfile from "../../assets/images/default-profile-icon.jpg";
import Back from "../../assets/images/Back_white.png";
import SessionExpired from "../../components/SessionExpired/SessionExpired";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import HandleConfirmation from "../handleConfirmation";
import PhoneInput from "react-phone-input-2";
import PhoneNumberField from "../../components/PhoneNumber";
import {isValidPhoneNumber, phoneNumber} from "../../utils/phone";

const Myprofile = () => {
    const [modalOpen, setOpenModel] = React.useState(false);
    const [states, setStates] = useState();
    const [nStateID, setnStateID] = useState();
    const [fname, setFname] = useState("");
    const [account, setaccount] = useState("");
    const [lname, setLname] = useState("");
    const [email1, setEmail1] = useState("");
    const [Phonenumber, setphoneNumber] = useState("");
    const [phone1, setPhone1] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [phone2, setPhone2] = useState("");
    const [email2, setEmail2] = useState("");
    const [s3filename, sets3filename] = useState(null);
    const [zipcode, setZipcode] = useState("");
    const [nNode, setnNode] = useState("");
    const [profileImage, setProfileImage] = useState(null);
    const [isSaved, setIsSaved] = useState(false);
    const [TokenExpired, setTokenExpired] = useState(false);
    const [timezone, setTimeZone] = React.useState([]);
    const [nTimeZoneID, setNTimeZoneID] = React.useState("");
    const [save , setSave] = useState('');
    const [phoneNumberError , setPhoneNumberError] = useState(false);

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER + "getstates")
            .then((res) => {
                setStates(res.data);
            });
    }, []);

    function profileDetailGet() {
        var token = localStorage.getItem("token");
        axios.get(process.env.REACT_APP_SERVER + "profile/detail", {
                headers: {Authorization: `Bearer ${token}`},
            }).then((res) => {
                if (res.data.status) {
                    setTokenExpired(true);
                } else {
                    res &&
                    res.data &&
                    res.data.forEach((element) => {
                        setnStateID(element.stateid);
                        setSave(element.fname + ' ' + element.lname)
                        setFname(element.fname);
                        setaccount(element.accountnumber);
                        setLname(element.lname);
                        setEmail1(element.email1);
                        setPhone1(element.phone1 ? element.phone1 : '' );
                        setCity(element.city);
                        setAddress(element.address);
                        setAddress2(element.address2);
                        setPhone2(element.phone2);
                        setEmail2(element.email2);
                        setZipcode(element.zipcode);
                        setnNode(element.notes);
                        sets3filename(element.s3filename);
                        setNTimeZoneID(element.timezoneid);

                        const userId = localStorage.getItem('userid');
                        if(element.s3filename) {
                            axios.get(process.env.REACT_APP_SERVER + `gets3file?filename=${element.s3filename}&nUserID=${userId}`,
                                {headers: {Authorization: `Bearer ${token}`}})
                                .then((res) => {
                                    setProfileImage(res.data.url);
                                }).catch((err)=> {
                                return 'error';
                            });
                        }

                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        profileDetailGet();
        getTimeZone();
    }, []);

    function myProfileDataSubmitHandler(e) {
        e.preventDefault();
        if (isValidPhoneNumber(phone1)) {
            const data = {
                nUserID: localStorage.getItem("userid"),
                fName: fname,
                lName: lname,
                sAddress: address,
                sAddress2: address2,
                sCity: city,
                nStateID: nStateID,
                sZipCode: zipcode,
                sEmail1: email1,
                sEmail2: email2,
                sPhone1: phoneNumber(phone1),
                sPhone2: phone2,
                sNotes: nNode,
                nTimeZoneID :nTimeZoneID
            };

            setPhoneNumberError(false);
            setIsSaved(true);
            var token = localStorage.getItem("token");
            axios.post(process.env.REACT_APP_SERVICE_PLAN_API + "profile/create", data, {
                headers: {Authorization: `Bearer ${token}`},
            })
                .then((res) => {
                    const fullname = data.fName + ' ' + data.lName;
                    localStorage.setItem('name',fullname);
                    if (res.data.status) {
                        setTokenExpired(true);
                    } else {
                        setOpenModel(true);
                        setIsSaved(false);

                        if (profileImage !== null) {
                            handleUploadImage();
                        } else {
                            profileDetailGet();
                        }
                    }
                })
                .catch((err) => {
                    setIsSaved(false);
                    console.log(err);
                });
        } else {
            setPhoneNumberError(true);
        }
    }
    const [imgFile, setProfile] = useState(null);

    const nUserID = localStorage.getItem("userid");
    const token = localStorage.getItem("token");

    const handleImageChange = (event) => {
        setProfile(event.target.files[0]);
        setProfileImage(URL.createObjectURL(event.target.files[0]));
    };


    const getTimeZone = () => {
        axios
            .get(
                process.env.REACT_APP_SERVER +
                `user/timezonesget`,
                {
                    headers: {Authorization: `Bearer ${token}`},
                }
            )
            .then((res) => {
                if (res.data.status) {
                    setTokenExpired(true)
                } else {
                    setTimeZone(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUploadImage = () => {
        if (imgFile){
            const data = new FormData();
            data.append("imgfile", imgFile);
            data.append("nUserID", nUserID);
            axios
                .post(process.env.REACT_APP_SERVER + `uploads3file`, data, {
                    headers: {Authorization: `Bearer ${token}`},
                })
                .then((res) => {
                    saveImageFile(res.data?.filename);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const saveImageFile = (filename) => {
        const sProfileImage = imgFile.name;
        const size = imgFile?.size;
        const s3FileName = filename;
        const data = {nUserID, sProfileImage, s3FileName, size};
        axios
            .post(process.env.REACT_APP_SERVER + `profile/userprofileimagesave`, data, {
                    headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                if (res.data.status) {
                    setTokenExpired(true);
                } else {
                    setProfileImage(null);
                    setProfile(null);
                    profileDetailGet();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const styles = {
        handleSize: {
            padding: "4px",
            height: "35px",
            width: "36px",
        },
    };

    const handleClosePlan = () => {
        setOpenModel(false);
    };
    return (
        <>
            {TokenExpired && <SessionExpired/>}
            <section className="profile-main-section">
                <Header title="MY PROFILE" save={save}/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maindashboard" className="btn hoy-to-link-btn">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className="btnall">
                        <Logout/>
                    </Col>
                </Row>
                <div className="divider-mobile"/>
                <Row className="p-2" style={{justifyContent: "center",marginLeft:"0px",marginRight:"0px"}}>
                    <Col lg={7} md={12}>
                        <div className="myprofile-left-section">
                            <div className="img">
                                <img
                                    src={
                                        profileImage
                                            ? profileImage
                                            : s3filename
                                            ? `https://reactnotes.s3.us-east-2.amazonaws.com/${s3filename}`
                                            : defaultProfile
                                    }
                                    alt={"image"}
                                    style={{backgroundColor: "#fff"}}
                                />
                                <div
                                    className="btn btn-lg btn-primary main-div mt-3 "
                                    style={styles.handleSize}
                                >
                                    <p>
                                        <EditIcon/>
                                        <input
                                            className="imgicon"
                                            type="file"
                                            name="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className="w-100 my-profile-fields" style={{paddingLeft:"100px"}}>
                                <Form onSubmit={myProfileDataSubmitHandler}>
                                    <Form.Group
                                        as={Row}
                                        className="float-none clearfix align-items-center "
                                    >
                                        <Col sm="2">
                                            <Form.Label>Account:</Form.Label>
                                        </Col>
                                        <Col sm="5">
                                            <Form.Control
                                                type="text"
                                                value={account}
                                                placeholder=""
                                                readOnly
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="float-none clearfix align-items-center"
                                    >
                                        <Col sm="2">
                                            <Form.Label>First Name:</Form.Label>
                                        </Col>
                                        <Col sm="5">
                                            <Form.Control
                                                type="text"
                                                value={fname}
                                                placeholder=""
                                                maxLength="50"
                                                onChange={(e) => setFname(e.target.value)}
                                                required
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className={"align-items-center"}
                                    >
                                        <Col sm="2">
                                            <Form.Label>Last Name:</Form.Label>
                                        </Col>
                                        <Col sm="5">
                                            <Form.Control
                                                type="text"
                                                value={lname}
                                                placeholder=""
                                                maxLength="50"
                                                onChange={(e) => setLname(e.target.value)}
                                                required
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className={"align-items-center"}
                                    >
                                        <Col sm="2">
                                            <Form.Label>Phone:</Form.Label>
                                        </Col>
                                        <Col sm="5">
                                            <PhoneNumberField
                                                setPhoneNumber={(event)=> {
                                                    setPhone1(event)
                                                }}
                                                phoneNumber={phone1}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className={"align-items-center"}
                                    >
                                        <Col sm="2">
                                            <Form.Label>Email:</Form.Label>
                                        </Col>
                                        <Col sm="5">
                                            <Form.Control
                                                type="email"
                                                placeholder=""
                                                value={email1}
                                                maxLength="100"
                                                readOnly={true}
                                                onChange={(e) => setEmail1(e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className={"align-items-center"}
                                    >
                                        <Col sm="2">
                                            <Form.Label>Address:</Form.Label>
                                        </Col>
                                        <Col sm="5">
                                            <Form.Control
                                                type="text"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                                placeholder="Home Address"
                                                maxLength="70"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className={"align-items-center"}
                                    >
                                        <Col sm="2">
                                            <Form.Label>Address 2:</Form.Label>
                                        </Col>
                                        <Col sm="5">
                                            <Form.Control
                                                type="text"
                                                value={address2}
                                                onChange={(e) => setAddress2(e.target.value)}
                                                placeholder="Home Address2"
                                                maxLength="70"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className={"align-items-center"}
                                    >
                                        <Col sm="2">
                                            <Form.Label>City:</Form.Label>
                                        </Col>
                                        <Col sm="3">
                                            <Form.Control
                                                type="text"
                                                className="sort-input-field"
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                                placeholder=""
                                                maxLength="50"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className={"align-items-center"}
                                    >
                                        <Col sm="2">
                                            <Form.Label>State:</Form.Label>
                                        </Col>
                                        <Col sm="3">
                                            <FormControl
                                                as="select"
                                                value={nStateID}
                                                className="sort-input-field"
                                                onChange={(e) => setnStateID(e.target.value)}
                                            >
                                                {states &&
                                                states.map((e) => {
                                                    return (
                                                        <option key={e.id} value={e.id}>
                                                            {e.name}
                                                        </option>
                                                    );
                                                })}
                                            </FormControl>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className={"align-items-center"}
                                    >
                                        <Col sm="2">
                                            <Form.Label>Zip Code:</Form.Label>
                                        </Col>
                                        <Col sm="3">
                                            <Form.Control
                                                type="text"
                                                className="postalCode sort-input-field"
                                                value={zipcode}
                                                placeholder=""
                                                maxLength="10"
                                                onChange={(e) => setZipcode(e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className={"align-items-center"}
                                    >
                                        <Col sm="2">
                                            <Form.Label>Timezone:</Form.Label>
                                        </Col>
                                        <Col sm={"3"}>
                                            <div className="timezone">
                                                <Select
                                                    className="timezonedata"
                                                    inputProps={{"aria-label": "Without label"}}
                                                    variant="outlined"
                                                    value={nTimeZoneID}
                                                    onChange={(e) => setNTimeZoneID(e.target.value)}
                                                >
                                                    {timezone.map((res) => (
                                                        <MenuItem value={res.id} key={res.id}>{res.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        controlId="exampleForm.ControlTextarea1 "
                                        className={"align-items-center"}
                                    >
                                        <Col sm={"2"}>
                                            <Form.Label>Notes:</Form.Label>
                                        </Col>
                                        <Col sm="9">
                                            <Form.Control
                                                as="textarea"
                                                className="textArea"
                                                maxLength="500"
                                                rows={3}
                                                value={nNode}
                                                onChange={(e) => setnNode(e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="btn-lg btn-block mt-4 custom-btn"
                                        disabled={isSaved}
                                    >
                                        Submit
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
            <HandleConfirmation
                isOpen={modalOpen}
                handleClose={handleClosePlan}
                onConfirm={handleClosePlan}
                title="Profile Information"
                color="secondary"
                question="Your profile information has been successfully saved!"
                yesText="Okay"
                noText=""
            />
            <HandleConfirmation
                isOpen={phoneNumberError}
                handleClose={() => setPhoneNumberError(false)}
                onConfirm={() => setPhoneNumberError(false)}
                color="secondary"
                question="The phone number does not appear to be valid!"
                yesText="Okay"
                noText=""
            />
        </>
    );
};

export default Myprofile;
