import React from 'react';
import './freetrial.css';
import { Button, Container, Row } from 'react-bootstrap';
import {Link} from "react-router-dom";

const FreeTrial = () => {
    return (
        <>
            <section className='free_trial bg_grediant'>
                <Container>
                    <Row className='justify-content-center'>
                        <div className='section-title text-center'>
                            <h2 className='text-white'>Discover how we can help you <br/>
                                (organize and protect your data online)</h2>
                            <Link to="/new-signup" type="button" className="outline-btn btn mt-4"><span>Start 14 day Free Trial</span></Link>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default FreeTrial;