import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const UponMyDeathDoThis = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>UPON MY DEATH DO THIS</h3>
            </div>
            <div className="content mt-3 mb-5">
                <div>
                    1. Call:
                    <Row className="my-2 mx-0">
                        <Col md="4">
                            Name
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                                multiline={true}
                                rows={3}
                            />
                        </Col>
                        <Col md="4">
                            Relationship
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                                multiline={true}
                                rows={3}
                            />
                        </Col>
                        <Col md="4">
                            Phone #
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                                multiline={true}
                                rows={3}
                            />
                        </Col>
                    </Row>
                </div>
                <div>
                    2. Make arrangements with funeral home and burial:
                    <Row className="my-2 mx-0">
                        <Col md="6">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                        <Col md="6" className="d-flex align-items-end gap-1">
                            <div className="w-25">Phone #:</div>
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
                <div>
                    3. Notify my Employer:
                    <Row className="my-2 mx-0">
                        <Col md="6">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                        <Col md="6" className="d-flex align-items-end gap-1">
                            <div className="w-25">Phone #:</div>
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
                <div>
                    4. Contact my Attorney Regarding Will/Trust
                    <Row className="my-2 mx-0">
                        <Col md="6">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                        <Col md="6" className="d-flex align-items-end gap-1">
                            <div className="w-25">Phone #:</div>
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
                <div>
                    5. Contact Social Security to file a claim:
                    <Row className="my-2 mx-0">
                        <Col md="6" className="d-flex align-items-end gap-1">
                            <div className="w-25">SSN#</div>
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                        <Col md="6" className="d-flex align-items-end gap-1">
                            <div className="w-25">Phone #:</div>
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
                {[
                    "6. Contact Insurance Company(s):",
                    "7. Contact my lenders: Real Estate Page:",
                    "8. Contact my creditors: Credit Card Page",
                    "9. Contact my digital accounts: Digital Accounts Page"
                ].map((title)=> (
                    <div key={title}>
                        {title}
                        <Row className="my-2 mx-0">
                            <Col md="6" className="d-flex align-items-end gap-1">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                    multiline={true}
                                    rows={2}
                                />
                            </Col>
                            <Col md="6" className="d-flex align-items-end gap-1">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                    multiline={true}
                                    rows={2}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
                {[
                    "10. My Post Office Box Information:",
                    "11. My Safe Deposit Box Information"
                ].map((title,index)=> (
                    <div key={index}>
                        {title}
                        <Row className="my-2 mx-0">
                            <Col md="6" className="d-flex align-items-end gap-1">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                            <Col md="6" className="d-flex align-items-end gap-1">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UponMyDeathDoThis;