import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/images/LandingPage/logo.png";
import pattern from "../../assets/images/LandingPage/patterns/pattern.jpg";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { TextField } from "@material-ui/core";
import "./NewPassword.css";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Alert, Modal } from "react-bootstrap";
import axios from "axios";

const HandleChangePassword = () => {
  const history = useHistory()
  const [hidden, setHidden] = useState(true);
  const [password, setPassword] = useState();
  const [confirmPass, setConfirmPass] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [loginError, setloginError] = useState(false)
  const LocalStorageEmail = localStorage.getItem("ForgetEmail");

  const handleSent = (e) => {
    e.preventDefault();
    if (password !== confirmPass) {
      setloginError(true);
    } else {
      axios
        .post(`${process.env.REACT_APP_SERVER}otp/resetpassword`, {
          email: LocalStorageEmail,
          password: password,
        })
        .then(function (response) {
          if (response.data[0] === "password changed") {
            setConfirmModal(true)
             setTimeout(() => {
              history.push('/new-login');
              localStorage.removeItem("ForgetEmail");
              setConfirmModal(false)
             }, 2000);
              
          }
        })
        .catch(function (error) {
          console.log("Error while resetting password", error);
        });
    }
  };

  const closeAlert = () => {
    setloginError(false);
  };

  return (
    <>
      <div className={"newPassword"}>
        <div className={`navigation`}>
          <div className="navigation-wrapper">
            <div className="container">
              <div className="navigation-inner login-header">
                <div className="navigation-logo">
                  <Link to="/">
                    <img src={logo} alt="orions-logo" height="60px" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-form-section login_form">
          <div className="contact-form-section-wrapper">
            <div className="container">
              <div className="row gx-5 contact-form-section-row justify-content-center">
                <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-sm-8 col-12 offset-1">
                  <div className="contact-form drop-shadow-2">
                    <div className="contact-form-wrapper">
                      <div className="section-heading section-heading-2 center">
                        <div className="main-heading c-blue">
                          <h1 style={{ fontSize: "36px" }}>New Password</h1>
                          <Link to="/login" className="back_btn">
                            <KeyboardBackspaceIcon className="back_icon" />
                          </Link>
                        </div>
                      </div>
                      <div className={"mb-3"}>
                        {loginError === true && (
                          <Alert
                            key="wrong-login"
                            variant="danger"
                            onClose={closeAlert}
                            dismissible
                          >
                            Please Confirm your password
                          </Alert>
                        )}
                      </div>
                      <form onSubmit={handleSent} autoComplete={"off"}>
                        <div className="form-floating form-floating-email">
                          <TextField
                            id="resetPasswordEmail"
                            label="Email"
                            value={LocalStorageEmail !== null ? LocalStorageEmail : ''}
                            fullWidth
                            disabled
                            inputProps={{ readOnly: true }}
                          />
                        </div>
                        <div className="form-floating form-floating-email">
                          <TextField
                            id="password"
                            label="New Password"
                            autoComplete={"off"}
                            type={hidden ? "password" : "text"}
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          {hidden ? (
                            <VisibilityOffIcon
                              className="pass_show"
                              onClick={() => setHidden(!hidden)}
                            />
                          ) : (
                            <VisibilityIcon
                              className="pass_show"
                              onClick={() => setHidden(!hidden)}
                            />
                          )}
                        </div>
                        <div
                          className="form-floating form-floating-email"
                          style={{ marginTop: "18px" }}
                        >
                          <TextField
                            id="password"
                            label="Confirm Password"
                            autoComplete={"off"}
                            type={hidden ? "password" : "text"}
                            fullWidth
                            value={confirmPass}
                            onChange={(e) => setConfirmPass(e.target.value)}
                            required
                          />
                          {hidden ? (
                            <VisibilityOffIcon
                              className="pass_show"
                              onClick={() => setHidden(!hidden)}
                            />
                          ) : (
                            <VisibilityIcon
                              className="pass_show"
                              onClick={() => setHidden(!hidden)}
                            />
                          )}
                        </div>
                        <button type="submit" className="button button-3">
                          <span className="button-inner">
                            <span className="button-content">
                              <span className="text">SUBMIT</span>
                            </span>
                          </span>
                        </button>
                      </form>
                    </div>
                    <div className="background-pattern background-pattern-radius">
                      <div
                        className="background-pattern-img background-loop"
                        style={{ backgroundImage: `url(${pattern})` }}
                        data-width="1850"
                        data-height="1960"
                      />
                      <div className="background-pattern-gradient" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={confirmModal} onHide={()=>{}}>
                <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}}>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Your password has been reset!
                </Modal.Body>
            </Modal>
    </>
  );
};

export default HandleChangePassword;
