import React from 'react';
import './ourvalues.css';
import {Card, Col, Container, Row} from "react-bootstrap";
import iconOne from "../../../assets/images/icon/1.svg";
import iconTwo from "../../../assets/images/icon/2.svg";
import iconThree from "../../../assets/images/icon/3.svg";
import iconFour from "../../../assets/images/icon/4.svg";


const OurValues = () => {
    return (
        <>
            <section className='our_value'>
                <Container>
                    <Row className='justify-content-center m-0' >
                        <Col sm={12}>
                            <div className='section-title'>
                                <h2>Our Values</h2>
                                <p>Our values guide us to assist you and your family at all times</p>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Card className='grediant-card'>
                                <Card.Body className='value-card'>
                                    <img
                                        src={iconOne}
                                        className="icon"
                                        alt="icon"
                                    />
                                    <div className='content-right'>
                                        <h4>Data Security</h4>
                                        <p>We guarantee the privacy and confidentiality of your information through 256-bit encryption, two-factor authentication and biometric scans.</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Card className='grediant-card'>
                                <Card.Body className='value-card'>
                                    <img
                                        src={iconTwo}
                                        className="icon"
                                        alt="icon"
                                    />
                                    <div className='content-right'>
                                        <h4>Focus on Loved Ones</h4>
                                        <p>Our commitment involves check-ins to monitor your well-being and ongoing communication to undertand your needs and desires.</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} className='mt-4'>
                            <Card className='grediant-card'>
                                <Card.Body className='value-card'>
                                    <img
                                        src={iconThree}
                                        className="icon"
                                        alt="icon"
                                    />
                                    <div className='content-right'>
                                        <h4>Securing Legacy</h4>
                                        <p>All documents and information are released to the user's chosen members, along with their final wishes.</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} className='mt-4'>
                            <Card className='grediant-card'>
                                <Card.Body className='value-card'>
                                    <img
                                        src={iconFour}
                                        className="icon"
                                        alt="icon"
                                    />
                                    <div className='content-right'>
                                        <h4>Peaceful Life</h4>
                                        <p>Enjoy the flavors of life with peace of mind, knowing your data is in safe hands, and your legacy will be transferred to those you name.</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default OurValues;