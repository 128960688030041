import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const CherishedMemoriesWith = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Cherished Memories With</h3>
            </div>
            <div className="content mt-3 mb-5">
                <Row className="mb-2 mx-0">
                    <Col md="12 p-0">
                        Name:
                    </Col>
                    <Col md="12 p-0">
                        <TextField
                            variant="filled"
                            fullWidth={true}
                            size="small"
                            multiline={true}
                            rows={35}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default CherishedMemoriesWith;