import React, {useEffect, useRef, useState} from "react";
import Header from "../../components/NewHeader/Header";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import Back from "../../assets/images/Back_white.png";
import Logout from "../../components/logout/Logout";
import {
    Card,
    CardContent,
    Grid,
    CardActions,
    Checkbox,
    TextField,
} from "@material-ui/core";
import axios from "axios";
import gallary_upload from "../../assets/images/gallary_upload.png";
import genie_img from "../../assets/images/genie_image.jpg";
import "./MyAlbums.css";
import {Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ShareAlbumContainer from "./ShareReleaseAlbumData/ShareAlbumContainer";
import ReleaseAlbumContainer from "./ShareReleaseAlbumData/ReleaseAlbumContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import coverimage from "../../assets/images/coverimage.jpg"
import DraggebleModal from "../../components/DraggebleModal";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

const MyAlbums = () => {
    const defaultImage = coverimage;
    const [List, setList] = React.useState([]);
    const [ablbumUploadModel, setablbumUploadModel] = React.useState(false);
    const [showLegend, setShowLegend] = useState(false);
    const [shows, setShows] = useState(false);
    const [idAlbum, setidAlbum] = useState("");
    const [imgFile, setFile] = useState(null);
    const [profileImage, setProfileImage] = useState("");
    const [albumName, setalbumName] = useState("");
    const [albumNotes, setalbumNotes] = useState("");
    const [editAlbumid, setAlbumid] = useState(false);
    const [s3fileName, setS3fileName] = useState("");
    const [albumData, setAlbumData] = useState("");
    const [thumLine, setThumLine] = useState("");
    const [nIncapacitated, setnIncapacitated] = useState(0);
    const [nDeceased, setnDeceased] = useState(0);
    const [Immediatly1, setImmediatly1] = useState(0);
    const [Immediatly2, setImmediatly2] = useState(0);
    const [option1, setOption1] = React.useState();
    const [option2, setOption2] = React.useState();
    const [nIncapDays, setNIncapDays] = useState(0);
    const [nDeceasedDays, setNDeceasedDays] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [noDataContent, setNoDataContent] = useState([]);

    const [accountNumber, setAccountNumber] = useState('');

    const textInput = useRef(null);
    useEffect(() => {
        if(ablbumUploadModel) {
            textInput.current.focus();
        }
    },[ablbumUploadModel]);

    const classes = useStylesBootstrap();

    const styles = {
        containerFirst: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
        },
        RadioBtn: {
            display: "flex",
            flexDirection: "row",
        },
    };

    const handleCloses = () => {
        setShows(false);
    };
    const handleShows = (Albumid) => {
        setidAlbum(Albumid);
        setShows(true);
    };
    const handleLegendDailog = () => {
        setShowLegend(true);
    };

    const handleUploadModelOpen = () => {
        setAlbumData("");
        setalbumName("");
        setalbumNotes("");
        setFile("");
        setProfileImage("");
        setNDeceasedDays("");
        setNIncapDays("");
        setImmediatly2("");
        setImmediatly1("");
        setnDeceased("");
        setnIncapacitated("");
        setablbumUploadModel(true);
    };
    const handleUploadModelClose = () => {
        setAlbumData("");
        setablbumUploadModel(false);
        setalbumName("");
        setalbumNotes("");
        setFile("");
        setProfileImage("");
        setNDeceasedDays("");
        setNIncapDays("");
        setImmediatly2("");
        setImmediatly1("");
        setnDeceased("");
        setnIncapacitated("");
    };
    const [currentUser,setCurrentUser] = useState()
    useEffect(() => {
        handleAlbumDetails();
        axios
            .get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=56`).then((res) => {
            setNoDataContent(res.data[0])
        }).catch((err) => { console.log(err)});

        var token = localStorage.getItem("token");
        axios
            .get(process.env.REACT_APP_SERVER + "profile/detail", {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                setCurrentUser(res.data[0])
                res &&
                res.data &&
                res.data.forEach((element) => {
                    setAccountNumber(element.accountnumber);
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const submitContactClickBtn = (e) => {
        console.log(e)
    }
    const handleAlbumDetails = () => {
        var token = localStorage.getItem("token");
        var userId = localStorage.getItem("userid");
        setIsLoading(true);
        axios
            .get(
                process.env.REACT_APP_SERVER + `user/albumsgetall?nUserID=${userId}`,
                {
                    headers: {Authorization: `Bearer ${token}`},
                }
            )
            .then((res) => {
                setIsLoading(false);
                setList(res.data);

                if (
                    res.data.incapacitatedreleasedays != 0 &&
                    res.data.incapacitatedreleasedays != 100
                ) {
                    setImmediatly1("after");
                } else {
                    setImmediatly1(res.data.incapacitatedreleasedays);
                }

                if (
                    res.data.deceasedreleasedays != 0 &&
                    res.data.deceasedreleasedays != 100
                ) {
                    setImmediatly2("after");
                } else {
                    setImmediatly2(res.data.deceasedreleasedays);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    const handleUploadImage = () => {
        setIsLoading(true);
        var token = localStorage.getItem("token");
        var userId = localStorage.getItem("userid");
        const data = new FormData();
        data.append("imgfile", imgFile);
        data.append("nUserID", userId);
        axios
            .post(process.env.REACT_APP_SERVER + `uploads3file`, data, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                handleUploadAlbum(res.data?.filename);
            })
            .catch((err) => {
                handleUploadAlbum(null);
            });
    };

    const handleUploadAlbum = (image) => {
        const userId = localStorage.getItem("userid");
        const token = localStorage.getItem("token");
        const imgName = imgFile ? imgFile.name : null;
        const data = {
            nUserID: userId,
            nAlbumID: 0,
            sAlbumName: albumName,
            sNotes: albumNotes,
            sFileName: imgName,
            s3FileName: image,
            nIsDeceased: nDeceased,
            nIsIncapacitated: nIncapacitated,
            nIncapDays: Immediatly1 === "after" ? nIncapDays : Immediatly1,
            nDeceasedDays: Immediatly2 === "after" ? nDeceasedDays : Immediatly2,
        };
        axios
            .post(process.env.REACT_APP_SERVER + `user/albumssave`, data, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                setalbumName("");
                setalbumNotes("");
                setFile("");
                setProfileImage("");
                setIsLoading(false);
                handleAlbumDetails();
                handleUploadModelClose();
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const handleImageChange = (event) => {
        setFile(event.target.files[0]);
        setProfileImage(URL.createObjectURL(event.target.files[0]));
    };

    const AlbumEdit = (AlbumData) => {
        handleAlbumDetails();
        setThumLine(albumData.thumbnail);
        setAlbumData(AlbumData.albumname);
        setAlbumid(AlbumData.id);
        setablbumUploadModel(true);
        setalbumName(AlbumData.albumname);
        setalbumNotes(AlbumData.notes);
        setProfileImage(AlbumData.s3PresignedUrl)
        setS3fileName(AlbumData?.thumbnails3);
        setnIncapacitated(AlbumData.isincapacitated);
        setnDeceased(AlbumData.isdeceased);
        setNIncapDays(AlbumData?.incapacitatedreleasedays);
        setNDeceasedDays(AlbumData?.deceasedreleasedays);
        const incapiciated = AlbumData?.incapacitatedreleasedays;
        const deceased = AlbumData?.deceasedreleasedays;

        if (incapiciated == null || incapiciated === 0) {
            setOption1(0);
        } else if (incapiciated === 100) {
            setOption1(100);
        } else {
            setOption1("after");
        }

        if (deceased == null || deceased === 0) {
            setOption2(0);
        } else if (deceased === 100) {
            setOption2(100);
        } else {
            setOption2("after");
        }
    };
    const handleEditImage = () => {
        var token = localStorage.getItem("token");
        var userId = localStorage.getItem("userid");
        setIsLoading(true);
        if (imgFile == '' || imgFile == null) {
            const datas = {
                nUserID: userId,
                nAlbumID: editAlbumid,
                sAlbumName: albumName,
                sNotes: albumNotes,
                sFileName: thumLine,
                s3FileName: s3fileName,
                nIsDeceased: nDeceased,
                nIsIncapacitated: nIncapacitated,
                nIncapDays: Immediatly1 === "after" ? nIncapDays : Immediatly1,
                nDeceasedDays: Immediatly2 === "after" ? nDeceasedDays : Immediatly2,
            };
            axios
                .post(process.env.REACT_APP_SERVER + `user/albumssave`, datas, {
                    headers: {Authorization: `Bearer ${token}`},
                })
                .then((res) => {
                    setalbumName("");
                    setalbumNotes("");
                    setFile("");
                    setProfileImage("");
                    setIsLoading(false);
                    handleAlbumDetails();
                    handleUploadModelClose();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setIsLoading(true);
            const data = new FormData();
            data.append("imgfile", imgFile);
            data.append("nUserID", userId);
            axios
                .post(process.env.REACT_APP_SERVER + `uploads3file`, data, {
                    headers: {Authorization: `Bearer ${token}`},
                })
                .then((res) => {
                    var imgName = imgFile ? imgFile.name : null;
                    const dataEdit = {
                        nUserID: userId,
                        nAlbumID: editAlbumid,
                        sAlbumName: albumName,
                        sNotes: albumNotes,
                        sFileName: imgName,
                        s3FileName: res.data?.filename,
                        nIsDeceased: nDeceased,
                        nIsIncapacitated: nIncapacitated,
                        nIncapDays: Immediatly1 === "after" ? nIncapDays : Immediatly1,
                        nDeceasedDays:
                            Immediatly2 === "after" ? nDeceasedDays : Immediatly2,
                    };
                    axios
                        .post(process.env.REACT_APP_SERVER + `user/albumssave`, dataEdit, {
                            headers: {Authorization: `Bearer ${token}`},
                        })
                        .then((res) => {
                            setalbumName("");
                            setalbumNotes("");
                            setFile("");
                            setProfileImage("");
                            setIsLoading(false);
                            handleAlbumDetails();
                            handleUploadModelClose();
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleDeleteAlbum = () => {
        var userId = localStorage.getItem("userid");
        var token = localStorage.getItem("token");
        const data = {
            nUserID: userId,
            nAlbumID: idAlbum,
        };
        axios
            .post(process.env.REACT_APP_SERVER + `user/albumDelete`, data, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                const s3file = {};
                const userId = localStorage.getItem('userid');
                axios
                    .post(
                        process.env.REACT_APP_SERVER +
                        `deletes3file?filename=${s3fileName}&nUserID=${userId}`,
                        s3file,
                        {
                            headers: {Authorization: `Bearer ${token}`},
                        }
                    )
                    .then((res) => {
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                handleAlbumDetails();
                handleCloses();
                handleUploadModelClose();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCloseLegend = () => {
        setShowLegend(false);
    };
    const HandleviewMore = (id, albumName) => {
        localStorage.setItem("albumName", albumName);
    };
    const handleError = (e) => {
        e.target.src = defaultImage;
    };
    const handleNIncapacitatedChange = (event) => {
        setnIncapacitated(event.target.checked ? 1 : 0);
    };
    const handleNDeceasedChange = (event) => {
        setnDeceased(event.target.checked ? 1 : 0);
    };
    const handleChange1 = (event) => {
        setOption1(event.target.value);
        setImmediatly1(event.target.value);
    };
    const handleChange2 = (event) => {
        setOption2(event.target.value);
        setImmediatly2(event.target.value);
    };

    const handleDaysIncapacitateChange = (event) => {
        const {value} = event.target;
        setNIncapDays(value > 0 && value <= 30 ? value : "");
    };
    const handleDaysPassedAwayChange = (event) => {
        const {value} = event.target;
        setNDeceasedDays(value > 0 && value <= 30 ? value : "");
    };

    return (
        <div className={"my-albums-page"}>
            <Header title="MY MEMORIES"/>
            <Row className="mx-auto text-center mobile-button back-logout-btn">
                <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="p-2 text-start d-flex btnall flex-column align-items-start"
                >
                    <Link to="/maindashboard">
                        <Button className="btn hoy-to-link-btn">
                            <span className={'d-flex align-items-center justify-content-center'}>
                            <img src={Back} alt="Back" className="w-5"/> Back</span>
                        </Button>
                    </Link>
                    <div
                        className={`d-none d-md-flex justify-content-between`}
                        style={{alignItems: "flex-end", marginTop:"8px"}}
                    >
                        <img src={genie_img} className="genie" onClick={handleLegendDailog}/>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={6} xs={6} className="btnall">
                    <Logout/>
                </Col>
            </Row>
            {!isLoading && List.length === 0 && (
                <Col lg={12} className="text-center">
                    <h4 className="description_text"
                        dangerouslySetInnerHTML={{ __html: noDataContent.thetext }}
                    />
                </Col>
            )}
            <Button
                className=" mt-5  d-flex ml-4 btn-success"
                onClick={handleUploadModelOpen}
                style={{width:'fit-content'}}
                disabled={(!currentUser?.newuser && currentUser?.serviceplanrequired)}
            >
                + Add Album
            </Button>
            <div className={"d-flex justify-content-center album-contain"}>
                <Grid
                    container
                    spacing={2}
                    className={
                        "cardContainer card-contain-album d-flex align-items-center pt-2"
                    }
                >
                    {List &&
                    (List || []).map((e) => {
                        return (
                            <Grid item className="card_grid card_albums" key={e.id}>
                                <Card
                                    className="contact_card card_albums_body"
                                    onClick={() => HandleviewMore(e.id, e.albumname)}
                                >
                                    {/*<Link to={`/albums-images/${e.id}`}>*/}
                                        <CardContent className="card_header_danger card_header card_albums_header">
                                            <div>{e.albumname}</div>
                                            <Link  to={`/albums-images/${e.id}`} className={'my-album-upload-button'}>Upload..</Link>
                                        </CardContent>
                                        <CardContent className="card_body">
                                            <Link to={`/albums-images/${e.id}`}>
                                            <div className={"thumbnail-image"}>
                                                {e.storeagelocation == null ||
                                                e.thumbnails3 === null ? (
                                                    <img src={defaultImage} className="albums_image"/>
                                                ) : (
                                                    <img
                                                        src={e.s3PresignedUrl}
                                                        // src={`https://reactnotes.s3.us-east-2.amazonaws.com/${accountNumber}/${e.thumbnails3}`}
                                                        className="albums_image"
                                                    />
                                                )}
                                            </div>
                                            </Link>
                                        </CardContent>
                                    {/*</Link>*/}
                                    <CardActions className="justify-content-between">
                                        <div className="album_btn">
                                            <div className="album-name">
                                                <strong>
                                                    Shared
                                                    <br/>
                                                    With{" "}
                                                </strong>
                                            </div>
                                            <ShareAlbumContainer
                                                itemDetails={e}
                                                getAllItems={handleAlbumDetails}
                                            />
                                        </div>
                                        <div className="album_btn">
                                            <div className="album-name">
                                                <strong>
                                                    Release
                                                    <br/>
                                                    To
                                                </strong>
                                            </div>
                                            <ReleaseAlbumContainer
                                                itemDetails={e}
                                                getAllItems={handleAlbumDetails}
                                            />
                                        </div>
                                        <div className="album_btn">
                                            <div className="album-name">
                                                <strong>
                                                    Incap-
                                                    <br/>
                                                    acitated
                                                </strong>
                                            </div>
                                            <Tooltip
                                                arrow
                                                classes={classes}
                                                placement="top"
                                                title={"Indicates Incapacitated is selected or not"}
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    inputProps={{"aria-label": "secondary checkbox"}}
                                                    checked={e.isincapacitated === 1}
                                                />
                                            </Tooltip>
                                        </div>
                                        <div className="album_btn">
                                            <div className="album-name">
                                                <strong>
                                                    Death
                                                    <br/>
                                                    &nbsp;
                                                </strong>
                                            </div>
                                            <Tooltip
                                                arrow
                                                classes={classes}
                                                placement="top"
                                                title={"Indicates Deceased is selected or not"}
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    inputProps={{"aria-label": "secondary checkbox"}}
                                                    checked={e.isdeceased === 1}
                                                />
                                            </Tooltip>
                                        </div>
                                        <div>
                        <span
                            className="edit_btn_Album"
                            onClick={() => AlbumEdit(e)}
                        >
                          <b>Edit</b>
                        </span>
                                        </div>
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
            <Modal
                size="lg"
                show={ablbumUploadModel}
                onHide={handleUploadModelClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="ShareRelease-Modal-Container"
            >
                <Modal.Header closeButton className="ShareRelease-Modal-Header">
                    {albumData ? (
                        <Modal.Title>Edit Memory</Modal.Title>
                    ) : (
                        <Modal.Title>New Memory</Modal.Title>
                    )}
                </Modal.Header>

                <Modal.Body>
                        <div className="d-md-flex">
                        <Col lg={8}>
                            <Form.Label style={{fontSize: "18px"}}>
                                <strong>Album Name</strong>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                className="textArea"
                                type="text"
                                rows={1}
                                required
                                onChange={(e) => setalbumName(e.target.value)}
                                value={albumName}
                                ref={textInput}
                            />
                            <Form.Label style={{fontSize: "18px"}}>
                                <strong>Album Notes</strong>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                className="textArea"
                                rows={10}
                                required
                                onChange={(e) => setalbumNotes(e.target.value)}
                                value={albumNotes}
                            />
                        </Col>
                        <Col lg={4}>
                            <div className={"d-flex justify-content-center mb-1"}>
                                <Form.Label style={{fontSize: "18px"}}>
                                    <strong>Cover Photo</strong>
                                </Form.Label>
                            </div>
                            <div className="image_preview mb-2">
                                <img
                                    width={"100%"}
                                    height={"100%"}
                                    src={profileImage ? profileImage : defaultImage}
                                    defaultValue={defaultImage}
                                    alt={"image"}
                                    onError={handleError}
                                    style={{backgroundColor: "#fff"}}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <Button
                                    style={{
                                        backgroundColor: "#0069d9",
                                        borderRadius: "0",
                                        border: "none",
                                    }}
                                >
                                    <input
                                        className="image_input"
                                        style={{position: "absolute"}}
                                        type="file"
                                        name="file"
                                        accept="image/png, image/jpeg,image/jpg"
                                        onChange={handleImageChange}
                                    />
                                    Browse
                                </Button>
                            </div>
                        </Col>
                    </div>

                    <div>
                        <div className="mr-2 font-weight-bold mt-3">
                            This Album is used: {" "}
                        </div>
                        <div className="d-flex align-items-center justify-content-between section1-custom-checkbox">
              <span style={styles.containerFirst}>
                <div className="d-flex">
                  <p style={{width: "100px"}}>Incapacitated</p>
                  <Checkbox
                      checked={nIncapacitated === 1}
                      onChange={handleNIncapacitatedChange}
                      color="primary"
                      inputProps={{
                          "aria-label": "secondary checkbox",
                      }}
                  />
                </div>

                <div className="d-flex align-items-center flex-wrap mb-3 mb-sm-0">
                  <span className={"marginLeft"}>Release this Album:</span>

                  <div className="align-items-center ml-2 mr-2 newReleaseMyInfo-container">
                    <form className={"mt-1"}>
                      <select
                          style={{width: "220px"}}
                          className={"selectOption"}
                          value={option1}
                          onChange={handleChange1}
                          defaultValue={option1}
                      >
                        <option value={0}>Immediately</option>
                        <option value={"after"}>After # of Days</option>
                      </select>

                        {option1 === "after" && (
                            <span className={classes.root}>
                          <TextField
                              style={{
                                  border: "none",
                                  marginLeft: "10px",
                                  width: "40px",
                              }}
                              id="outlined-basic"
                              variant="outlined"
                              value={
                                  Immediatly1 === "Immediately" || nIncapDays === 0
                                      ? ""
                                      : nIncapDays
                              }
                              type="number"
                              onChange={handleDaysIncapacitateChange}
                              disabled={Immediatly1 === "Immediately"}
                          />
                          <span className={"ml-1"}> days</span>
                        </span>
                        )}
                    </form>
                  </div>
                </div>
              </span>
                        </div>
                        <div
                            className="d-flex w-100 align-items-center justify-content-between section1-custom-checkbox">
              <span style={styles.containerFirst}>
                <div className="d-flex">
                  <span style={{width: "100px"}}>Deceased</span>
                  <Checkbox
                      checked={nDeceased === 1}
                      onChange={handleNDeceasedChange}
                      color="primary"
                      inputProps={{
                          "aria-label": "secondary checkbox",
                      }}
                  />
                </div>
                <div className="d-flex align-items-center flex-wrap ">
                  <span className={"marginLeft"}>Release this Album:</span>
                  <div className="align-items-center ml-2 mr-2 newReleaseMyInfo-container">
                    <form className={"mt-2 d-flex"}>
                      <select
                          style={{width: "220px"}}
                          className={"selectOption"}
                          value={option2}
                          onChange={handleChange2}
                          defaultValue={option2}
                      >
                        <option value={0}>Immediately</option>
                        <option value={"after"}>After # of Days</option>
                        <option value={100}>
                          Receipt of Death Certificate
                        </option>
                      </select>
                        {option2 === "after" && (
                            <span className={classes.root}>
                          <TextField
                              style={{
                                  border: "none",
                                  marginLeft: "10px",
                                  width: "40px",
                              }}
                              id="outlined-basic"
                              variant="outlined"
                              value={
                                  Immediatly2 === "Immediately" ||
                                  nDeceasedDays === 0
                                      ? ""
                                      : nDeceasedDays
                              }
                              type="number"
                              onChange={handleDaysPassedAwayChange}
                              disabled={Immediatly2 === "Immediately"}
                          />
                          <span className={"ml-1"}> days</span>
                        </span>
                        )}
                    </form>
                  </div>
                </div>
                </span>
                </div>
                    </div>
               </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    {albumData ? (
                        <Button variant="danger" onClick={() => handleShows(editAlbumid)}>
                            Delete
                        </Button>
                    ) : (
                        <Button variant="danger" disabled>
                            Delete
                        </Button>
                    )}
                    {albumData ? (
                        <Button
                            variant="primary"
                            className="custom-btn"
                            style={{backgroundColor: "#0069d9", border: "none"}}
                            onClick={handleEditImage}
                            disabled={(!currentUser?.newuser && currentUser?.serviceplanrequired)}
                        >
                            {isLoading ? <CircularProgress/> : <span>Save</span>}
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            className="custom-btn "
                            disabled={isLoading || albumName.length===0 || (!currentUser?.newuser && currentUser?.serviceplanrequired)}
                            style={{backgroundColor: "#0069d9", border: "none"}}
                            onClick={handleUploadImage}
                        >
                            {isLoading ? <CircularProgress/> : <span>Save</span>}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <Modal show={shows} onHide={handleCloses}>
                <Modal.Header style={{backgroundColor: "#0069d9", color: "white"}}>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the selected Memory Album?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        style={{border: "none"}}
                        onClick={handleCloses}
                    >
                        No
                    </Button>
                    <Button
                        style={{backgroundColor: "#0069d9", border: "none"}}
                        onClick={handleDeleteAlbum}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <DraggebleModal
                screen={35}
                isOpen={showLegend}
                setOpenModal={setShowLegend}
            />
        </div>
    );
};
export default MyAlbums;
