import React, {useState} from 'react';
import './header.css';
import keysLogoImage from '../../../assest/images/logo.png';
import {Button, Card, Container, Navbar, Row} from 'react-bootstrap';
import { Link ,useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import WishesVideo from '../../../../../assets/images/KeysToMyWishes.mp4'
import playBtnIcon from '../../../assest/images/Play-icon.svg';
import linesImage from '../../../../Landing/assets/images/lines.svg';
import dotsImage from '../../../../Landing/assets/images/dot.svg';
import allcolorbooks from '../../../../../assets/images/all-color-books.png'
import whiteBookImage from '../../../../../assets/images/white-color-book.png'
import blackBookImage from '../../../../../assets/images/black-color-book.png'
import pinkBookImage from '../../../../../assets/images/pink-color-books.png'

import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {MdClose} from "react-icons/md";
import pricingModal from "../../../assest/images/pricingmodal.png";
import MyCarousel from "../CarouselBookImg";
import {wish} from "../../../../../utils/wishes.utils";



const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});



const Header = () => {
    const [openBookModel, setOpenBookModel] = useState(false)
    const [selectedBook, setSelectedBook] = useState(1)
    const [showBookmodel, setShowBookModel] = useState(false);
    const [bookInstruction , setBookInstruction] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const anchor = 'right';
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const closeModel = () => {
        setOpenBookModel(false)
    }

    const closeBookModal = () => {
        setShowBookModel(false);
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="mobile_menu">
                <div className='header_logo d-flex gap-2'>
                    <Link to='/'>
                        <img
                            src={keysLogoImage}
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Link>
                    <CloseIcon/>
                </div>
                <Link to="/">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/" className="btn Landing_signup_btn">Home</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/organizer">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/" className="btn Landing_signup_btn">Personal Affairs Organizer</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/about">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2/about" className="btn Landing_signup_btn">About</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/pricing">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2/pricing" className="btn Landing_signup_btn">Pricing</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/contact">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/privatepeople2/contact" className="btn Landing_signup_btn">Contact</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-signup">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-signup" className="btn Landing_signup_btn">Sign Up</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-login">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-login" className="btn Landing_login_btn">Login</ListItemText>
                    </ListItem>
                </Link>
            </List>
        </div>
    );


    return (
        <>
            <div className='introduction_section'>
                <Navbar className='main-header'>
                    <Navbar.Brand>
                        <Link to='/'>
                            <img
                                src={keysLogoImage}
                                className="d-inline-block align-top"
                                alt="logo"
                                style={{width: "221px"}}
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse expand="md" className="justify-content-end">
                        <ul className='main_menu_list'>
                            <li className='menu_item'>
                                <Link to="/">Home</Link>
                            </li>
                            <li className='menu_item menu_item_book' onClick={() => setBookInstruction(true)}>
                                <Link to="/organizer">*Download Organizer Pages*</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/about">About</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/pricing">Pricing</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/contact">Contact</Link>
                            </li>
                            <li className='menu_item'>
                                <Button variant="" className='header_menu_outlinebtn'
                                        onClick={() => history.push('/new-login')}>Login</Button>
                                <Button variant="" className='header_menu_btn'
                                        onClick={() => history.push('/new-signup')}>Start Your Free 14-Day
                                    Trial</Button>
                            </li>
                        </ul>
                    </Navbar.Collapse>
                    <div className="menu_bar">
                        <MenuIcon onClick={toggleDrawer(anchor, true)} />
                    </div>
                </Navbar>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                </Drawer>

                <Container fluid>
                    <div className='inroduction_details'>
                        <Row className='justify-content-center m-0'>
                            <div className='inroduction_heading organizer_inroduction_heading'>
                                <h1>The <span>Ultimate</span>  System to Help You <span>Organize</span> your <span>Personal Affairs</span>  if the Expected or Unexpected Happens</h1>

                                <div className={'text-center'}>
                                    <h4>
                                        “My Personal Affairs Organizer” <br/>
                                        is the Perfect Organizational Tool for You
                                    </h4>
                                </div>
                                <div className={'text-center d-flex justify-content-center w-100 mb-5'} >
                                    <div style={{width:325}} >
                                        <MyCarousel/>
                                    </div>
                                    {/*<img src={allcolorbooks} alt={'all books'} className={'all-books-img'}/>*/}
                                </div>
                                <div className={'d-sm-flex justify-content-center mt-4'}>
                                    {/*<div className='inroduction_details_btn'>*/}
                                    {/*    <Button variant="" className='btn_primarybtn'*/}
                                    {/*            onClick={() => history.push("/new-signup")}>*/}
                                    {/*        Start Your Free 14-Day Trial*/}
                                    {/*    </Button>*/}
                                    {/*</div>*/}
                                    {/*<div className='inroduction_details_btn'>*/}
                                    {/*    <Button variant="" className='btn_primarybtn'*/}
                                    {/*            onClick={() => history.push("/how-to")}>*/}
                                    {/*        How it Works*/}
                                    {/*    </Button>*/}
                                    {/*</div>*/}
                                    <div className='inroduction_details_btn'>
                                        <Button
                                            variant="" className='btn_primarybtn ml-0'
                                            onClick={() => wish.newTab("https://www.amazon.com/dp/B0D7V33HFM?ref_=pe_93986420_774957520")}
                                            style={{minWidth:300}}
                                        >
                                            Buy the Book! $16.99
                                        </Button>
                                    </div>
                                    <div className='inroduction_details_btn'>
                                        <Button variant="" className='btn_primarybtn'
                                                onClick={() => setOpenBookModel(true)}>
                                            Personal Affairs Organizer
                                        </Button>
                                    </div>
                                </div>
                                <div className={"text-center m-auto p-3"} style={{border:"3px solid #007bff" ,maxWidth:628, borderRadius: 7}}>
                                    <h4 className={'p-0'} style={{fontSize:18}}>Sign Up at no cost and</h4>
                                    <h4 className={'p-0'} style={{fontSize:18}}>Get Free Organizer page Downloads and Page Updates!</h4>
                                </div>
                                <p className={'text-left'} style={{maxWidth: 880}}>
                                    Benefits of KeysToMyWishes My Personal Affairs Organizer:<br/>
                                    <ul className={'ml-5'}>
                                        <li>
                                            Gives you comfort and a sense of accomplishment knowing that you have taken
                                            the time to get this important information organized and in order.
                                        </li>
                                        <li>
                                            Gives you a better picture of what documents you do have and what you need
                                            to acquire with the advice of a legal professional.
                                        </li>
                                        <li>
                                            Relieves some of the stress, frustration and guessing that a loved one may
                                            experience when wondering “does a specific document exist”
                                        </li>
                                        <li>
                                            Works in conjunction with the www.KeysToMyWishes.com website which gives you
                                            additional capabilities such as downloading additional pages for your
                                            organizer, accessing other pages not included in organizer, storing photos,
                                            videos, writing your story, and so much more.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </Row>
                        <Row className='justify-content-center m-0'>

                        </Row>
                    </div>
                    <div className='helper__video_content pb-4'>
                        <Row className='justify-content-center m-0'>
                            <Card className='helper_video_container'>
                                <Card.Body className='p-0'>
                                    <div className='helper_video_header'>
                                        <div className='d-flex justify-content-between'>
                                            <img
                                                src={dotsImage}
                                                className="d-inline-block align-top"
                                                alt="images"
                                            />
                                            <img
                                                src={linesImage}
                                                className="d-inline-block align-top"
                                                alt="images"
                                            />
                                        </div>
                                    </div>
                                    <div className='helper_video_frame'>
                                        <div className='helper_video'>
                                            <video width="914" height="503" controls>
                                                <source src={WishesVideo} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Row>
                    </div>
                </Container>
            </div>
            <Modal
                // isOpen={showOffer}
                isOpen={openBookModel}
                className='offer-frame d-flex justify-content-center'
                size={'lg'}
                toggle={closeModel}
            >
                <ModalHeader className="p-0 m-0 pricing_modal ">
                    <div className={"pricing_header justify-content-end mb-2"}>
                        {/*<div className={"w-100 ml-5"}>*/}
                        {/*    <h3 className={"text-center"}>Limited Time Offer</h3>*/}
                        {/*    <p className={"text-center"}>*/}
                        {/*        Offer expires <span className={""}>April 30th, 2024</span>*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                        <button className='btn' onClick={closeModel} ><MdClose size={26}/></button>
                    </div>
                </ModalHeader>
                <ModalBody className='helper_video p-2'>
                    <div className={'d-flex justify-content-center'}>
                        {selectedBook === 1 ?
                            <img src={whiteBookImage} alt={'b'} className={'booksImgs'}/>
                            : selectedBook === 2 ?
                                <img src={blackBookImage} alt={'b'} className={'booksImgs'}/>
                                : selectedBook === 3 ?
                                    <img src={pinkBookImage} alt={'b'} className={'booksImgs'}/>
                                    : <img src={pinkBookImage} alt={'b'} className={'booksImgs'}/>
                        }

                    </div>
                    <div className={'d-flex justify-content-center my-2 selection-main-div'}>
                        <img src={whiteBookImage} alt={'b'} className={`model-select-book-img ${selectedBook === 1 && 'active'}`} onClick={() => setSelectedBook(1)}/>
                        <img src={blackBookImage} alt={'b'} className={`model-select-book-img ${selectedBook === 2 && 'active'}`} onClick={() => setSelectedBook(2)}/>
                        <img src={pinkBookImage}  alt={'b'} className={`model-select-book-img ${selectedBook === 3 && 'active'}`} onClick={() => setSelectedBook(3)}/>
                    </div>
                </ModalBody>
            </Modal>
            <Modal
                isOpen={showBookmodel}
                className='offer-frame d-flex justify-content-center'
                toggle={closeBookModal}
            >
                <ModalHeader className="p-0 m-0 pricing_modal " style={{backgroundColor:"#54a0f3"}}>
                    <div className={"d-flex justify-content-end mb-2"} >
                        <button className='btn' onClick={closeBookModal}><MdClose size={26}/></button>
                    </div>
                </ModalHeader>
                <ModalBody className='helper_video p-2'>
                    <div className={'px-2 py-3 text-center'}>
                        <span className={"font-weight-bold"}> KeysToMyWishes </span>Personal Affairs Organizer should be available on this site by June 15th.
                    </div>
                </ModalBody>
            </Modal>
            <Modal
                isOpen={bookInstruction}
                className='offer-frame d-flex justify-content-center'
                toggle={() => setBookInstruction(false)}
            >
                <ModalHeader className="p-0 m-0 d-flex book-details-Model" style={{backgroundColor:"#54a0f3"}}>
                        <div className={"book-details-Model-title"}>
                            Free Downloads
                        </div>
                        <div className={"d-flex align-items-center"}>
                            <button className='btn' onClick={() => setBookInstruction(false)}><MdClose size={26}/>
                            </button>
                        </div>
                </ModalHeader>
                <ModalBody className='helper_video p-2'>
                    <div className={'px-2 '}>
                        <h5 className={"book-details-Model-content-title"}>It is Free to download My Personal Affairs
                            Organizer pages!!!</h5>
                        <h5 className={"book-details-Model-content-discription"}><span>*</span> You must Login in order to download Personal Affairs Organizer pages.</h5>
                        <h5 className={"book-details-Model-content-discription"}><span>*</span> If you have not already Signed Up please Sign Up and Login.</h5>
                        <h5 className={"book-details-Model-content-discription"}><span>*</span> Once you have logged in click on the Menu option bars in the top left
                            corner of the page and look for the Option: <span>Download Organizer Pages</span></h5>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
};
export default Header;