import React from 'react';
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";

const MyInformationPage = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>My Information</h3>
            </div>
            {["ME","SPOUSE"].map((title,index)=> (
                <div className="content mt-3 mb-5" key={index}>
                    <Row className="mx-0">
                        <Col md="3">
                            <h4>{title}</h4>
                        </Col>
                    </Row>
                    {["Name:", "Date of Birth:", "Place of Birth:", "Address:", "City:"].map((title) => (
                        <Row className="mx-0" key={title}>
                            <Col md="3" className="d-flex align-items-center">
                                {title}
                            </Col>
                            <Col md="9">
                                <TextField
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Col>
                        </Row>
                    ))}
                    <Row className="mx-0">
                        <Col md="3" className="d-flex align-items-center">
                            State:
                        </Col>
                        <Col md="5 pr-0">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                        <Col md="1" className="d-flex align-items-center bg-white">
                            Zip:
                        </Col>
                        <Col md="3 pl-0">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
            ))}
            <div className="content mt-3 mb-5">
                <Row className="mx-0">
                    <Col md="3">
                        <h4>PARENTS</h4>
                    </Col>
                </Row>
                {["Mother:", "Father:"].map((title) => (
                    <Row className="mx-0" key={title}>
                        <Col md="3" className="d-flex align-items-center">
                            {title}
                        </Col>
                        <Col md="9">
                            <TextField
                                variant="filled"
                                fullWidth={true}
                                size="small"
                            />
                        </Col>
                    </Row>
                ))}
            </div>
        </div>
    );
};

export default MyInformationPage;