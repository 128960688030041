import React from 'react';
import './reviews.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Carousel } from "react-responsive-carousel";
import profileImage from '../assets/images/profile.svg';
import profile1Image from '../assets/images/profile2.svg';
import profile2Image from '../assets/images/profile3.svg';
import starImage from '../assets/images/star.svg';
import ScenariosCardImg1 from "../../../assets/images/LandingPageNew/scenarios_card_img_1.svg";

const Reviews = () => {
    return (
        <>
            <section className='reviews_section_content'>
                <Container>
                    <Row className='justify-content-center m-0'>
                        <Col md={12} className='reviews_title mb-4'>
                            <h1>
                                Don't just take our word for it
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='reviews_section pt-0 mb-4'>
                <Container>
                    <Row className='justify-content-center m-0'>
                        <div className="scenarios_content">
                            <div className="row m-0 scenarios-slider">
                                <Carousel
                                    className="d-none d-sm-block"
                                    showThumbs={false}
                                    autoPlay={false}
                                    swipeable={false}
                                    emulateTouch={false}
                                    showStatus={true}
                                    dynamicHeight={false}
                                    showArrows={true}
                                    showIndicators={false}
                                    interval={8000}
                                    infiniteLoop={true}
                                >
                                    <div className="d-flex gap-2">
                                        <div className='slider-contentbox'>
                                            <div className='slider-box'>
                                                <img
                                                    src={profileImage}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                                <div className="scenarios_card w-100 scenarios_card_1">
                                                    <div>
                                                        <p className="scenarios_card_description">
                                                            “When my Mother passed away suddenly, I had no idea where
                                                            to start looking for her insurance policy or Will. I was
                                                            pretty sure she had those things in place but where she
                                                            kept them was a mystery to me and my siblings. We were all
                                                            devastated and stressed as we mourned her loss and had the
                                                            arduous task of trying to locate her papers. It took 10
                                                            hard and stress-filled months before we found everything.”
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className='mt-4'>Mr. Ben </p>
                                                <img
                                                    src={starImage}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                            </div>
                                        </div>
                                        <div className='slider-contentbox'>
                                            <div className='slider-box'>
                                                <img
                                                    src={profile1Image}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                                <div className="scenarios_card w-100 scenarios_card_1">
                                                    <div>
                                                        <p className="scenarios_card_description">
                                                            I am 77 years old and a widow now living alone. Unfortunately my son lives in another State
                                                            and even though he wants me to move closer, I can’t bring myself to leave the home and memories
                                                            me and George built for over 50 years. Also, living in a colder climate would never work for me.
                                                            Even though I talk to my son and grandchildren weekly, my biggest fear is falling, not being able
                                                            to get up and not being discovered for another week. By then it may be too late
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className='mt-4'>Mr. Mike </p>
                                                <img
                                                    src={starImage}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex gap-2">
                                        <div className='slider-contentbox'>
                                            <div className='slider-box'>
                                                <img
                                                    src={profile2Image}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                                <div className="scenarios_card w-100 scenarios_card_1">
                                                    <div>
                                                        <p className="scenarios_card_description">
                                                            “After a brief illness my husband passed away. He took care of all the household finances,
                                                            paid all the bills, made all the investments, hired all the handymen to do the renovations
                                                            and repairs in our home. He did everything and I was perfectly happy with that. When he became ill,
                                                            I was in denial that it was not that serious and knew he would get well. He didn’t. After he passed away
                                                            it was up to me to keep things going. Where to begin and what to do was a mystery. Where was all the paperwork
                                                            to everything? Who did I need to contact to get things done? I became depressed and found myself wanting
                                                            to give up because it seemed overwhelming.”
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className='mt-4'>Mr. Jake </p>
                                                <img
                                                    src={starImage}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                            </div>
                                        </div>
                                        <div className='slider-contentbox'>
                                            <div className='slider-box'>
                                                <img
                                                    src={profileImage}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                                <div className="scenarios_card w-100 scenarios_card_1">
                                                    <div>
                                                        <p className="scenarios_card_description">
                                                            “Both my God-parents were elderly and ailing. My Godfather, however, was determined he should be
                                                            the main caregiver for the love of his life, who was bed ridden as a result of a stroke.
                                                            Fortunately once a week a RN would come to the house to provide additional care for my Godmother.
                                                            When my Godfather failed to answer the door, the RN contacted the police department to enter the home and
                                                            perform a welfare check. My Godfather was found on the kitchen floor and it was obvious
                                                            he had been there for a few days since my Godmother was soiled, dehydrated and had not been fed.”
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className='mt-4'>Mr. Ben </p>
                                                <img
                                                    src={starImage}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex gap-2">
                                        <div className='slider-contentbox'>
                                            <div className='slider-box'>
                                                <img
                                                    src={profile1Image}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                                <div className="scenarios_card w-100 scenarios_card_1">
                                                    <div>
                                                        <p className="scenarios_card_description">
                                                            “How often do we hear in the news of natural disasters such as hurricanes, tornados, wild fires, floods, destroying people’s homes?
                                                            We see that way too often little if anything is saved including important documents and irreplaceable possessions.
                                                            How are those things replaced?”
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className='mt-4'>Mr. Mike </p>
                                                <img
                                                    src={starImage}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                            </div>
                                        </div>
                                        <div className='slider-contentbox'>
                                            <div className='slider-box'>
                                                <img
                                                    src={profile2Image}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                                <div className="scenarios_card w-100 scenarios_card_1">
                                                    <div>
                                                        <p className="scenarios_card_description">
                                                            “Mom passed away over 30 years ago. In 2021 someone told me that my name was seen on a “claim property” website.
                                                            I discovered that mom had stock in one of the airplane producing companies years ago and neither
                                                            me nor my siblings knew anything about it. Fortunately we were able to recover the proceeds many years later.”
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className='mt-4'>Mr. Jake </p>
                                                <img
                                                    src={starImage}
                                                    className="d-inline-block align-top"
                                                    alt="logo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                        <div className="scenarios_mobile_content">
                            <div className='slider-contentbox mb-3'>
                                <div className='slider-box'>
                                    <img
                                        src={profileImage}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                    <div className="scenarios_card scenarios_card_1">
                                        <div>
                                            <p className="scenarios_card_description">
                                                “I am 77 years old and a widow now living alone.
                                                Unfortunately my son lives in another State and even
                                                though he wants me to move closer, I can’t bring myself to
                                                leave the home and memories me and George built for over
                                                50 years. Also, living in a colder climate would never
                                                work for me. Even though I talk to my son and
                                                grandchildren weekly, my biggest fear is falling, not
                                                being able to get up and not being discovered for another
                                                week. By then it may be too late”
                                            </p>
                                        </div>
                                    </div>
                                    <p className='mt-4'>Mr. Ben </p>
                                    <img
                                        src={starImage}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                </div>
                            </div>
                            <div className='slider-contentbox mb-3'>
                                <div className='slider-box'>
                                    <img
                                        src={profile1Image}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                    <div className="scenarios_card scenarios_card_1">
                                        <div>
                                            <p className="scenarios_card_description">
                                                “I am 77 years old and a widow now living alone.
                                                Unfortunately my son lives in another State and even
                                                though he wants me to move closer, I can’t bring myself to
                                                leave the home and memories me and George built for over
                                                50 years. Also, living in a colder climate would never
                                                work for me. Even though I talk to my son and
                                                grandchildren weekly, my biggest fear is falling, not
                                                being able to get up and not being discovered for another
                                                week. By then it may be too late”
                                            </p>
                                        </div>
                                    </div>
                                    <p className='mt-4'>Mr. Mike </p>
                                    <img
                                        src={starImage}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                </div>
                            </div>
                            <div className='slider-contentbox mb-3'>
                                <div className='slider-box'>
                                <img
                                        src={profile2Image}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                    <div className="scenarios_card scenarios_card_1">
                                        <div>
                                            <p className="scenarios_card_description">
                                                “I am 77 years old and a widow now living alone.
                                                Unfortunately my son lives in another State and even
                                                though he wants me to move closer, I can’t bring myself to
                                                leave the home and memories me and George built for over
                                                50 years. Also, living in a colder climate would never
                                                work for me. Even though I talk to my son and
                                                grandchildren weekly, my biggest fear is falling, not
                                                being able to get up and not being discovered for another
                                                week. By then it may be too late”
                                            </p>
                                        </div>
                                    </div>
                                    <p className='mt-4'>Mr. Jake </p>
                                    <img
                                        src={starImage}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                </div>
                            </div>
                            <div className='slider-contentbox mb-3'>
                                <div className='slider-box'>
                                    <img
                                        src={profileImage}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                    <div className="scenarios_card scenarios_card_1">
                                        <div>
                                            <p className="scenarios_card_description">
                                                “I am 77 years old and a widow now living alone.
                                                Unfortunately my son lives in another State and even
                                                though he wants me to move closer, I can’t bring myself to
                                                leave the home and memories me and George built for over
                                                50 years. Also, living in a colder climate would never
                                                work for me. Even though I talk to my son and
                                                grandchildren weekly, my biggest fear is falling, not
                                                being able to get up and not being discovered for another
                                                week. By then it may be too late”
                                            </p>
                                        </div>
                                    </div>
                                    <p className='mt-4'>Mr. Ben </p>
                                    <img
                                        src={starImage}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                </div>
                            </div>
                            <div className='slider-contentbox mb-3'>
                                <div className='slider-box'>
                                    <img
                                        src={profile1Image}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                    <div className="scenarios_card scenarios_card_1">
                                        <div>
                                            <p className="scenarios_card_description">
                                                “I am 77 years old and a widow now living alone.
                                                Unfortunately my son lives in another State and even
                                                though he wants me to move closer, I can’t bring myself to
                                                leave the home and memories me and George built for over
                                                50 years. Also, living in a colder climate would never
                                                work for me. Even though I talk to my son and
                                                grandchildren weekly, my biggest fear is falling, not
                                                being able to get up and not being discovered for another
                                                week. By then it may be too late”
                                            </p>
                                        </div>
                                    </div>
                                    <p className='mt-4'>Mr. Mike </p>
                                    <img
                                        src={starImage}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                </div>
                            </div>
                            <div className='slider-contentbox mb-3'>
                                <div className='slider-box'>
                                    <img
                                        src={profile2Image}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                    <div className="scenarios_card scenarios_card_1">
                                        <div>
                                            <p className="scenarios_card_description">
                                                “I am 77 years old and a widow now living alone.
                                                Unfortunately my son lives in another State and even
                                                though he wants me to move closer, I can’t bring myself to
                                                leave the home and memories me and George built for over
                                                50 years. Also, living in a colder climate would never
                                                work for me. Even though I talk to my son and
                                                grandchildren weekly, my biggest fear is falling, not
                                                being able to get up and not being discovered for another
                                                week. By then it may be too late”
                                            </p>
                                        </div>
                                    </div>
                                    <p className='mt-4'>Mr. Jake </p>
                                    <img
                                        src={starImage}
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Reviews;