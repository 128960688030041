import React, { useEffect } from 'react';
import {Col, Container, Row} from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import '../../NewLandingPage/NewLandingPage.css'
import Typography from "@material-ui/core/Typography";
import storageIcon from '../../../../src/assets/images/shopWeb/storage.svg';
import secureIcon from '../../../../src/assets/images/shopWeb/secure.svg';
import infoIcon from '../../../../src/assets/images/shopWeb/info.svg';
import uploadIcon from '../../../../src/assets/images/shopWeb/upload.svg';
import bookImg from '../../../../src/assets/images/shopWeb/book.png';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import './shop-webcopy.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import blackImg from "../../../assets/images/black-book.png";
import pinkImg from "../../../assets/images/pink-book.png";
import purpleImg from "../../../assets/images/purple-book.png";
import whiteImg from "../../../assets/images/white-book.png";


const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const faqData1 = [
    {
        id: 1,
        question: "Will KeysToMyWishes be able to see my private information?",
        answer: "No. With multi-factor authentication and 256-bit encryption, you're getting a secure log-in and the most up-to-date level of protection."
    },
    {
        id: 2,
        question: "Can I leave something to a friend without a family member knowing?",
        answer: "Yes, you can leave whatever information you’d like to whoever you choose. The only people who will know what information you store are the predesignated loved ones you choose. They’ll get the information you stored upon your incapacitation or death."
    },
    {
        id: 3,
        question: "How will you know when to release my information?",
        answer: "We'll release your information when we receive word that you've become incapacitated or have passed away. This is why it's important to place the emergency cards you receive from us in places that are easy to find in your home."
    },
    {
        id: 5,
        question: "If someone finds me incapacitated or deceased, how will they know to contact KeysToMyWishes?",
        answer: "When you sign up with us, we'll provide you with 3 emergency cards. These cards have our contact information, so the person who finds you can contact us. You should place these cards in obvious locations in your home."
    }
];

const SafeStorageAndDelivery = () => {
    const [expandedId1, setExpandedId1] = React.useState('panel1');
    const history = useHistory();

    useEffect(() => {
        setExpandedId1(faqData1.length ? faqData1[0]['id'] : '')
    }, [faqData1]);

    const handleChange = (id) => (event, newExpanded) => {
        setExpandedId1(newExpanded ? id : '');
    };

    return (
        <>
            <section className='section_container shop_web_introduction_section introduction_section'>
                <Container fluid>
                    <Row className='align-items-center'>
                        <Col lg={8}>
                            <div className='header_section'>
                                <h3>
                                    KeysToMyWishes offers safe Storage and Delivery of your final wishes. Let go of
                                    worry and embrace the
                                    <span> peace of mind</span> that comes
                                    with securing your private information.
                                </h3>
                            </div>
                            <div className='process_steps_section p-0'>
                                <div className="process_steps_box p-0">
                                    <p>
                                        Your golden years are passing you by. You want to make the most of them by
                                        spending <br/>
                                        time with your kids and grand kids. You want to do what you love. But you’ve
                                        also been <br/>
                                        thinking about how to prepare your loved ones for once you pass.
                                    </p>
                                </div>
                            </div>
                            <div className='process_steps_section p-0'>
                                <div className="process_steps_box p-0">
                                    <p>
                                        When you go, you want your family to have everything they need to settle your
                                        affairs. <br/>
                                        But you don’t know where to start. There’s an overwhelming amount of information
                                        your <br/>
                                        loved ones will need. You want to keep everything safe in hard copy, so you can
                                        update <br/>
                                        it accordingly.
                                    </p>
                                </div>
                            </div>
                            <div>
                                <h5>You can’t control what happens after you pass, but you don’t want your family to
                                    have <br/> to:</h5>
                            </div>
                            <ul className='header_content_section'>
                                <li>
                                    <span>
                                        <CheckIcon/>
                                    </span>
                                    <h5 className="font-weight-normal mb-3">
                                        Dig through mountains of paperwork for important documents
                                    </h5>
                                </li>
                                <li>
                                    <span>
                                        <CheckIcon/>
                                    </span>
                                    <h5 className="font-weight-normal mb-3">
                                        Flip through notebooks in search of passwords
                                    </h5>
                                </li>
                                <li>
                                    <span>
                                        <CheckIcon/>
                                    </span>
                                    <h5 className="font-weight-normal mb-3">
                                        Sift through files on hard drives, not sure what to make of any of it
                                    </h5>
                                </li>
                                <li>
                                    <span>
                                        <CheckIcon/>
                                    </span>
                                    <h5 className="font-weight-normal mb-3">
                                        Spend hours looking for the key and code to your safe
                                    </h5>
                                </li>
                            </ul>
                            <h5 className="font-weight-normal mb-3" style={{color: "#74788d"}}>
                                ...and more. You know it’ll be a challenging time for your loved ones. You want
                                them to <br/> easily manage your affairs.
                            </h5>
                        </Col>
                        <Col lg={4}>
                            <div className="col-md-12 text-center">
                                <img src={bookImg} alt="bookImg" className="img-fluid"/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section_container shop_web_introduction_section header_section text-center'>
                <h4>
                    <span className="dark_para">Take the challenge out of your estate planning</span> with the KeysToMyWishes Personal <br/>
                    Affairs Organizer. Complete with 25 fillable templates that address what your loved <br/>
                    ones will need after you pass – like what to do with your collectibles, pets, real estate, <br/>
                    and more. Let your estate planning journey be one of comfort, knowing that your loved <br/>
                    ones won’t deal with unnecessary stress during the grief process.
                </h4>
                <h4 className="mb-3">
                    And most importantly, keep your private information private.
                </h4>
                <h4 className="mb-5">
                    If you’re worried about your loved ones finding your Personal Affairs Organizer, add a <br/>
                    KeysToMyWishes service plan. Store only the location of your Organizer on our highly <br/>
                    encrypted platform. When the time comes, we’ll release this information to your <br/>
                    predesignated loved ones.
                </h4>
                <h4>
                    KeysToMyWishes is ready to help you eliminate stress for your loved ones. You <br/>
                    deserve to enjoy your retirement with peace of mind.
                </h4>
            </section>
            <section className='section_container storage_options_section'>
            <Container fluid>
                <Row>
                    <Col className="text-center">
                        <h2 className='main_title'>Storage + Organization Options</h2>
                    </Col>
                </Row>
                <Row className='justify-content-center custom_div'>
                    <div className="text-center custom_para_main">
                        Though our platform is highly secure, we understand you’re protective of your private
                        information. We’re proud to offer options that are tailored  to your level of comfort –
                        <b> so you’re in control of how your  information is organized and stored.</b>
                    </div>
                    <div className="custom_card">
                        <div className="single_card">
                            <div className="main_card_content">
                                <h4 className="card_h4">I need help organizing
                                    my estate planning
                                    information.</h4>
                                <div className="card_para">
                                    <p>Not sure what documents your loved ones will need to settle your affairs? We’ve
                                        got you covered.</p>
                                    <p>The KeysToMyWishes Personal Affairs Organizer is a comprehensive 36- page book
                                        with 25 estate templates that are easy to fill in. So you can feel at ease with
                                        a hard copy of your private information– organized and managed all together.</p>
                                    <p>
                                        Fill in information regarding your bank accounts, insurance, credit cards,
                                        passwords, last wishes, and more. Available in 3 formats as listed below.
                                    </p>
                                    <div className="card_para_in d-block">
                                        <p>Personal Affairs Organizer Print Book</p>
                                        <p>$16.99</p>
                                        <ul className="ml-4">
                                            <li>8.5 x 0.09 x 11 inches</li>
                                            <li>36 pages in paperback</li>
                                            <li>Available in black, pink, purple and white</li>
                                        </ul>
                                        <button
                                            className="button_primary ml-2"
                                            onClick={() => history.push('/products')}
                                        > Buy Now
                                        </button>
                                    </div>
                                    <div className="card_para_in d-block">
                                        <p>Personal Affairs Organizer Binder</p>
                                        <p>$26.99</p>
                                        <ul className="ml-4">
                                            <li>11.75 x 10.5 inches</li>
                                            <li>Holds up to 150 sheets</li>
                                            <li>Made from vegan PU leather</li>
                                            <li>Available in black, dark blue, light, blue, yellow, green and red</li>
                                            <li>Add and remove sheets to update your Organizer as needed</li>
                                        </ul>
                                        <button
                                            className="button_primary ml-2"
                                            onClick={() => history.push('/products')}
                                        > Buy Now
                                        </button>
                                    </div>
                                    <div className="card_para_in d-block">
                                        <p>Personal Affairs Organizer PDF</p>
                                        <p>$12.99</p>
                                        <ul className="ml-4">
                                            <li>Downloadable PDF that can be stored on your local devices and accessed
                                                at any time
                                            </li>
                                        </ul>
                                        <button
                                            className="button_primary ml-2"
                                            onClick={() => history.push('/products')}
                                        > Buy Now
                                        </button>
                                    </div>
                                    <div className="card_para_in d-block">
                                        <p>Personal Affairs Organizer</p>
                                        <p>Binder - $26.99</p>
                                        <button
                                            className="button_primary ml-2"
                                            onClick={() => history.push('/products')}
                                        > Buy Now
                                        </button>
                                    </div>
                                    <div className="card_para_in d-block">
                                        <p>Personal Affairs Organizer</p>
                                        <p>PDF - $12.99</p>
                                        <button
                                            className="button_primary ml-2"
                                            onClick={() => history.push('/products')}
                                        > Buy Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single_card">
                            <div className="main_card_content">
                                <h4 className="card_h4">
                                    I need help organizing and storing my estate planning information.
                                </h4>
                                <div className="card_para">
                                    <p>
                                        Have you stored your Personal Affairs
                                        Organizer in a secure place like your safe
                                        or a safe deposit box? Make sure your
                                        loved ones can easily access it when it’s
                                        time by storing the location of your
                                        Personal Affairs Organizer on our highly
                                        encrypted platform.
                                    </p>
                                    <p>
                                        Maybe you’d like to store the password to
                                        your device, so your loved ones can
                                        access your Personal Affairs Organizer
                                        PDF. Or you want to store some
                                        cherished photos and pre-recorded
                                        videos you don’t want your loved ones to
                                        see until you’re gone.
                                    </p>
                                    <p>
                                        No matter what information you choose to
                                        store, we’ll tell your loved ones the
                                        information they need when it’s time.
                                    </p>
                                    <div className="card_para_in d-block">
                                        <h5 className="mb-2">Personal Affairs Organizer Print Book + Yearly KTMW Service Plan</h5>
                                        <p>$76.99 + $60 yearly after the first year.</p>
                                        <ul className="ml-4">
                                            <li>8.5 x 0.09 x 11 inches</li>
                                            <li>36 pages in paperback</li>
                                            <li>Available in black, pink, purple and white</li>
                                        </ul>
                                        <button
                                            className="button_primary ml-2"
                                            onClick={() => history.push('/new-signup')}
                                        > Start Your Free 14-Day Trial
                                        </button>
                                    </div>
                                    <div className="card_para_in d-block">
                                        <h5 className="mb-2">Personal Affairs Organizer Binder + Yearly KTMW Service Plan</h5>
                                        <p>$86.99 + $60 yearly after the first year.</p>
                                        <ul className="ml-4">
                                            <li>11.75 x 10.5 inches</li>
                                            <li>Holds up to 150 sheets</li>
                                            <li>Made from vegan PU leather</li>
                                            <li>Available in black, dark blue, light, blue, yellow, green, and red</li>
                                            <li>Add and remove sheets to update your Organizer as needed</li>
                                        </ul>
                                        <button
                                            className="button_primary ml-2"
                                            onClick={() => history.push('/new-signup')}
                                        > Start Your Free 14-Day Trial
                                        </button>
                                    </div>
                                    <div className="card_para_in d-block">
                                        <h5 className="mb-2">Personal Affairs Organizer PDF + Yearly KTMW Service Plan</h5>
                                        <p>$72.99 + $60 yearly after the first year.</p>
                                        <ul className="ml-4">
                                            <li>Downloadable PDF that can be stored on your local devices and accessed at any time.</li>
                                        </ul>
                                        <button
                                            className="button_primary ml-2"
                                            onClick={() => history.push('/new-signup')}
                                        > Start Your Free 14-Day Trial
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
                <div className="d-flex justify-content-center mt-4">
                    <Col md={2} className="d-flex justify-content-center">
                        <div className="text-center">
                            <img src={blackImg} alt={"Organizer"} height={200} width={200}/>
                            <b>Black</b>
                        </div>
                    </Col>
                    <Col md={2} className="d-flex justify-content-center">
                        <div className="text-center">
                            <img src={pinkImg} alt={"Organizer"} height={200} width={200}/>
                            <b>Pink</b>
                        </div>
                    </Col>
                    <Col md={2} className="d-flex justify-content-center">
                        <div className="text-center">
                            <img src={purpleImg} alt={"Organizer"} height={200} width={200}/>
                            <b>Purple</b>
                        </div>
                    </Col>
                    <Col md={2} className="d-flex justify-content-center">
                        <div className="text-center">
                            <img src={whiteImg} alt={"Organizer"} height={200} width={200}/>
                            <b>White</b>
                        </div>
                    </Col>
                </div>
            </Container>
            </section>
            <section className='section_container process_section_container'>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <h2 className='main_title'>A Simple 3-Step Process for Releasing Your Last Wishes</h2>
                            <p className='sub_title mt-2'>
                                The KTMW Personal Affairs Organizer is the perfect place to store your last wishes. If
                                you choose a service plan with us, we’ll release your last wishes to your loved ones  at
                                the time of your choosing.
                            </p>
                        </Col>
                    </Row>
                    <Row className='process_steps_list'>
                        <Col sm={2}>
                            <div className='process_steps text-center'>
                                <h2>01</h2>
                            </div>
                        </Col>
                        <Col sm={10}>
                            <div className='process_steps_content'>
                                <p>
                                    Choose the loved ones who will receive your private information and last wishes.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='process_steps_list'>
                        <Col sm={2}>
                            <div className='process_steps text-center'>
                                <h2>02</h2>
                            </div>
                        </Col>
                        <Col sm={10}>
                            <div className='process_steps_content'>
                                <p>
                                    We’ll send you 3 emergency cards with our contact information. Place the cards around your house.
                                    If someone finds you incapacitated or deceased, they can contact <span className='strong_title'>KeysToMyWishes</span>.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='process_steps_list'>

                        <Col sm={2}>
                            <div className='process_steps text-center'>
                                <h2>03</h2>
                            </div>
                        </Col>
                        <Col sm={10}>
                            <div className='process_steps_content'>
                                <p>
                                    We’ll release your information and last wishes to your designated loved ones upon confirmation of your incapacitation or death.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section_container storagelist_section_container'>
                <Container fluid>
                    <Row>
                        <Col className="text-center">
                            <h2 className='main_title'>KeysToMyWishes Digital Storage</h2>
                            <p className='sub_title mt-2'>
                                Want to store the location of your Personal Affairs Organizer, but doubtful about how
                                your information will be kept private? We’ve got you covered.
                            </p>
                        </Col>
                    </Row>
                    <Row className='storage_box_list'>
                        <Col sm={6} xl={3} className='mb-3'>
                            <div className='storage_box'>
                                <span className='storage_box_icon'>
                                    <img src={infoIcon} alt='infoIcon' className='img-fluid' />
                                </span>
                                <div>
                                    <p>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        256-bit encryption for the latest level of protection of your important information.
                                    </p>
                                    <p className='mb-0'>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        Break-The-Glass functionality which will trigger the release of your information to your designated loved ones.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} xl={3} className='mb-3'>
                            <div className='storage_box'>
                                <span className='storage_box_icon'>
                                    <img src={storageIcon} alt='storageIcon' className='img-fluid' />
                                </span>
                                <div>
                                    <p>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        Optional multi-factor authentication for a secure log in.
                                    </p>
                                    <p className='mb-0'>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        Emergency cards for anyone who finds you incapacitated or deceased, so they can contact KeysToMyWish es.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} xl={3} className='mb-3'>
                            <div className='storage_box'>
                                <span className='storage_box_icon'>
                                    <img src={secureIcon} alt='secureIcon' className='img-fluid' />
                                </span>
                                <div>
                                    <p>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        Easy document and file upload for your convenience.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} xl={3} className='mb-3'>
                            <div className='storage_box'>
                                <span className='storage_box_icon'>
                                    <img src={uploadIcon} alt='uploadIcon' className='img-fluid' />
                                </span>
                                <div>
                                    <p>
                                        <span>
                                            <CircleIcon/>
                                        </span>
                                        2GB of storage – plenty of space for storing more than the location of your Personal Affairs Organizer.
                                    </p>
                                    <p>
                                        <span>
                                            <CircleIcon/>
                                        </span>
                                        Mobile app with fingerprint identification. No more passwords to remember.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section_container asked_questions_section'>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <h2 className='main_title'>Frequently Asked Questions</h2>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col lg={12}>
                            <div className="faq" id="faq-accordion">
                                {faqData1.map((data) => (
                                    <Accordion square expanded={expandedId1 === data.id} onChange={handleChange(data.id)}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                            <Typography className={`accordion-button mb-2 d-flex justify-content-between ${expandedId1 === data.id ? '' : 'collapsed'}`}>
                                                {data.question}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="mx-3">{data.answer}</AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section_container trial_section'>
                <Container fluid>
                    <Row className='justify-content-center align-items-center'>
                        <Col lg={8}>
                            <p>Don't wait until it's <strong>too late.</strong> Take the weight off your shoulders when it comes to your
                                future planning. Secure your last wishes  and have <strong>peace of mind</strong> now.</p>
                        </Col>
                        <Col lg={4}>
                            <button className="button_primary" onClick={() => history.push("/new-signup")}>Start Your 14-day Trial today</button>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
};

export default SafeStorageAndDelivery;