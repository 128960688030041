import React, {useContext, useState, useEffect} from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    InputGroup,
    FormText,
    Modal,
} from "react-bootstrap";
import cards from "./cards.svg"
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements, useStripe
} from "@stripe/react-stripe-js";
import axios from "axios";
import {BooksContext} from "./App";
import { ConfirmationNumber } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PaymentForm = (Props) => {
    const stripe = useStripe();
    const context = useContext(BooksContext);
    const elements = useElements();
    const [nameOnCard, setNameOnCard] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [errors, setErrors] = useState({});
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [shipTo, setShipTo] = useState("");
    const [email, setEmail] = useState("");
    const [states, setStates] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isProcessing,setIsProcessing] = useState(false);
    const [confirmationCode,setConfirmationCode] = useState("");
    let totalCartAmount = parseFloat(Props.totalCartAmount) || 0;
    let discountPercentage = parseFloat(Props.apiData[0]?.DiscountPerc) * 100 || 0;
    let discountAmount = ((totalCartAmount * discountPercentage) / 100).toFixed(2);
    let discountedPrice = (totalCartAmount - discountAmount).toFixed(2);
    const [showCardError, setShowCardError] = useState({
        card: {message:"Card number is empty"},
        expiry:{message:"Card expiry is empty"},
        securityCode:{message:"Card securityCode is empty"},
    })
    const history = useHistory();
    const randomCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let code = '';
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters[randomIndex];
        }
        return code;
    };
    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'nameOnCard':
                if (!value.trim()) {
                    error = 'Name on card is required.';
                }
                break;

            case 'email':
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!value.trim()) {
                    error = 'Email address is required.';
                } else if (!emailPattern.test(value)) {
                    error = 'Please enter a valid email address.';
                }
                break;

            case 'shipTo':
                if (!value.trim()) {
                    error = 'Shipping name is required.';
                }
                break;

            case 'street':
                if (!value.trim()) {
                    error = 'Street address is required.';
                }
                break;

            case 'city':
                if (!value.trim()) {
                    error = 'City is required.';
                }
                break;

            case 'states':
                if (!value.trim()) {
                    error = 'State is required.';
                }
                break;

            case 'zipCode':
                if (!value.trim()) {
                    error = 'Zip code is required.';
                }
                break;

            default:
                break;
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };
    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
    };

    const validateForm = (showCardError.card !== undefined || showCardError.expiry !== undefined || showCardError.securityCode !== undefined ||
        errors.nameOnCard !== "" || errors.email !== "" || errors.shipTo !== "" ||
        errors.street !== "" || errors.city !== "" || errors.states !== "" ||
        errors.zipCode !== "" );
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitted(true);
        setShowToast(true);
        if (!stripe || !elements) {
            return;
        }
        const code = randomCode();
        const cardElement = elements.getElement(CardNumberElement);
        if(showCardError.card === undefined && showCardError.expiry === undefined && showCardError.securityCode === undefined){
            setIsProcessing(true);
            stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: nameOnCard,
                    address: {
                        city: city,
                        line1: street,
                        postal_code: zipCode,
                        state: states,
                    },
                    email: email,
                },
            }).then((response) => {
                const bookShippingCharge = 2.5;
                const binderShippingCharge = 5.61;
                const paymentMethod = response.paymentMethod;
                const data = (JSON.parse(localStorage.getItem("cartItems")) || []);
                const values = data.map((ele) => {
                    const yearlyRate = parseFloat(ele.yearlyrate);
                    const bookCharge = yearlyRate * ele.count + bookShippingCharge;
                    const binderCharge = yearlyRate * ele.count + binderShippingCharge;
                    let priceValue = ele.mediaType === "Book"
                        ? bookCharge
                        : ele.mediaType === "Binder"
                            ? binderCharge
                            : yearlyRate * ele.count;
                    let shiippingValue = ele.mediaType === "Book"
                        ? bookShippingCharge
                        : ele.mediaType === "Binder"
                            ? binderShippingCharge
                            : "";

                    return {
                        name: ele.name,
                        quantity: ele.count,
                        color: ele.color,
                        price: priceValue,
                        shippingCharge: shiippingValue,
                    };
                });
                const payload = {
                    payment_method: paymentMethod ? paymentMethod.id : null,
                    amount: parseFloat(
                        discountAmount
                            ? discountedPrice
                            : Props.totalCartAmount
                    ),
                    items: values,
                    confirmationCode: code
                };
                if (discountPercentage > 0) {
                    payload.promoCode = Props.promoCode;
                }

                axios.post(process.env.REACT_APP_SERVER + 'stripe/payment-intent', payload)
                    .then((r) => {
                        const clientSecret = r.data["client_secret"];
                        const paymentConfirmationPayload = {payment_method: paymentMethod.id}
                        stripe.confirmCardPayment(clientSecret, paymentConfirmationPayload, {
                            handleActions: true
                        }).then((confirm) => {
                            if (confirm.error) {
                                setIsProcessing(false);
                                setIsSuccess(false);
                            } else {
                                if (confirm.paymentIntent.status === "succeeded") {
                                    setConfirmationCode(code);
                                    setIsSubmitted(false)
                                    elements.getElement(CardNumberElement).clear();
                                    elements.getElement(CardExpiryElement).clear();
                                    elements.getElement(CardCvcElement).clear();
                                    setIsProcessing(false);
                                    let receiptData ={
                                        ConfirmationNumber:code,
                                        name:nameOnCard,
                                        email:email,
                                        cartData: context.state.cart,
                                        totalAmount: Props.totalCartAmount,
                                        discountAmount:discountAmount,
                                        payableAmount:discountedPrice,
                                        ship:shipTo ,
                                        line1: street,
                                        city: city,
                                        state: states,
                                        postal_code: zipCode,
                                    };
                                   
                                    setShowToast(false);
                                    setIsSuccess(true);
                                    context.EmptyCard();
                                    localStorage.removeItem("cartItems");
                                    setNameOnCard("");
                                    setZipCode("");
                                    setCity("");
                                    setStreet("");
                                    setShipTo("");
                                    setEmail("");
                                    setStates("");
                                    window.open('/Product_recipt', '_blank');
                                    localStorage.setItem("receiptData",JSON.stringify(receiptData));
                                }
                            }
                        })
                    })
                    .catch((Err) => {
                        console.log(Err);
                        if (Err && Err.response && Err.response.data.code !== 404) {
                            setIsSuccess(false);
                            setIsProcessing(false);
                            setConfirmationCode("");
                        }
                    })
            });
        }
    };

    const styles = {
        Form: {
            boxShadow: "rgba(99, 99, 99, 0.2) 0 2px 8px 0",
            borderRadius: '15px',
        }
    }

    useEffect(() => {
        const savedEmail = localStorage.getItem('email');
        if (savedEmail) {
            setEmail(savedEmail);
        } else {
            setEmail('')
        }
    }, []);

    return (
        <>
            <Container className="">
                <Row className="" style={styles.Form}>
                    <Col className="">
                        <Form className="py-4">
                            <div>
                                <h2 className="text-center mb-4">Pay Invoice</h2>
                                <img src={cards} alt="cards" width="200px" className="mb-2"/>
                            </div>
                            <Form.Group controlId="nameOnCard">
                                <Form.Label>Name on Card</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter name on card"
                                    value={nameOnCard}
                                    name="nameOnCard"
                                    required={true}
                                    onChange={(e) => {
                                        handleBlur(e);
                                        setNameOnCard(e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={isSubmitted && errors.nameOnCard}
                                />
                                {isSubmitted && errors.nameOnCard && (
                                    <FormText className="text-danger">{errors.nameOnCard}</FormText>
                                )}
                            </Form.Group>
                            <Form.Group controlId="shipTo">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter Email address"
                                    value={email}
                                    name="email"
                                    required={true}
                                    onChange={(e) => {
                                        handleBlur(e);
                                        setEmail(e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                />
                                {errors.email && (
                                    <FormText className="text-danger">{errors.email}</FormText>
                                )}
                            </Form.Group>
                            <Form.Group controlId="cardNumber">
                                <Form.Label>Card Number</Form.Label>
                                <CardNumberElement
                                    className="data-card"
                                    onChange={(value) => {
                                        setShowCardError((prevState)=>({...prevState,card: value.error}));
                                    }}
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: "17px",
                                                color: "black",
                                                border: "1px solid red",
                                                "::placeholder": {
                                                    color: "gray",
                                                },
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146'
                                        },
                                        showIcon: true
                                    }}
                                />
                                {((showCardError && showCardError.card) && isSubmitted) && (
                                    <FormText className="text-danger">{showCardError.card.message}</FormText>
                                )}
                            </Form.Group>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="expiryDate">
                                        <Form.Label>Expiry Date</Form.Label>
                                        <CardExpiryElement
                                            onChange={(value) => setShowCardError((prevState)=>({...prevState,expiry: value.error}))}
                                        />
                                        {((showCardError && showCardError.expiry) && isSubmitted) && (
                                            <FormText className="text-danger">{showCardError.expiry.message}</FormText>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="securityCode">
                                        <Form.Label>CVC</Form.Label>
                                        <CardCvcElement onChange={(value) => setShowCardError((prevState)=>({...prevState,securityCode: value.error}))}/>
                                        {((showCardError && showCardError.securityCode) && isSubmitted) && (
                                            <FormText className="text-danger">{showCardError.securityCode.message}</FormText>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="shipping">
                                <h2>Shipping Address</h2>
                            </Form.Group>
                            <Form.Group controlId="shipTo">
                                <Form.Label>Ship To</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Name"
                                    value={shipTo}
                                    name="shipTo"
                                    required={true}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleBlur(e);
                                        setShipTo(e.target.value)
                                    }}
                                    isValid={false}
                                />
                                {errors.shipTo && (
                                    <FormText className="text-danger">{errors.shipTo}</FormText>
                                )}
                            </Form.Group>
                            <Form.Group controlId="street">
                                <Form.Label>Street Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Street Address"
                                    value={street}
                                    name="street"
                                    required={true}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        setStreet(e.target.value)
                                        handleBlur(e);
                                    }}
                                />
                                {errors.street && (
                                    <FormText className="text-danger">{errors.street}</FormText>
                                )}
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group controlId="City">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter City Name"
                                            value={city}
                                            name="city"
                                            onBlur={handleBlur}
                                            required={true}
                                            onChange={(e) => {
                                                setCity(e.target.value)
                                                handleBlur(e);
                                            }}
                                        />
                                        {errors.city && (
                                            <FormText className="text-danger">{errors.city}</FormText>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="states">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Your State"
                                            value={states}
                                            name="states"
                                            onBlur={handleBlur}
                                            required={true}
                                            onChange={(e) => {
                                                setStates(e.target.value)
                                                handleBlur(e);
                                            }}
                                        />
                                        {errors.states && (
                                            <FormText className="text-danger">{errors.states}</FormText>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="zipCode">
                                        <Form.Label>Zipcode</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter Zipcode"
                                                value={zipCode}
                                                name="zipCode"
                                                onBlur={handleBlur}
                                                required={true}
                                                onChange={(e) => {
                                                    setZipCode(e.target.value)
                                                    handleBlur(e);
                                                }}
                                            />
                                        </InputGroup>
                                        {errors.zipCode && (
                                            <FormText className="text-danger">{errors.zipCode}</FormText>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="px-3">
                                {
                                     context.state.cart.length !== 0 && (
                                        <>
                                            <div className="px-3 mb-3 border w-100" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                borderRadius: "5px"}}>
                                                <div className="py-1">
                                                    Total Amount: <strong>${Props.totalCartAmount}</strong>
                                                </div>
                                                <div className="py-1">
                                                    Discount Amount: <strong>{`$${discountAmount} (${Props.apiData[0]?.DiscountPerc ? Props.apiData[0].DiscountPerc * 100 : 0}%)`}
                                                </strong>
                                                </div>
                                                <div className="py-1">
                                                    Payable Amount: <strong>${discountedPrice}</strong>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </Row>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={(e) => handleSubmit(e)}
                                block
                                disabled={Number(Props.totalCartAmount) === 0 || validateForm }
                            >
                                <i className="fas fa-lock"/> {
                                discountAmount ? `$${discountedPrice}` : `$${Props.totalCartAmount}`
                            }
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <div>
                <Modal
                    centered={true}
                    show={showToast}
                    className="custom-modal"
                >
                    {
                        isProcessing ?  (
                           <>
                               <Modal.Body className="d-flex px-3 py-4 align-items-center flex-column row-gap-3"
                                           style={{gap: "10px"}}>
                                   <div className="infinity_loader">
                                       <i className="fas fa-solid fa-spinner text-secondary"></i>
                                   </div>
                                   <div>Processing Payment...</div>
                               </Modal.Body>
                           </>
                        ) : (
                            <>
                                <Modal.Header className="border-0 p-0 mt-2 mr-3 d-flex align-items-center justify-content-end">
                                    <div  className="">
                                        <i onClick={() => setShowToast(false)} className="fas fa-times cursor-pointer text-secondary fa-x"/>
                                    </div>
                                </Modal.Header>
                                <Modal.Body className="border-0 d-flex px-3 py-4 align-items-center flex-column row-gap-3" style={{gap:"10px"}}>
                                    {isSuccess ? (
                                        <i className="fas fa-check-circle fa-3x text-success"/>
                                    ) : (
                                        <i className="fas fa-times text-danger fa-3x mr-3"/>
                                    )}
                                    {isSuccess ? (
                                        <>
                                            <div className="sucess_text">
                                                <div >Thank you for purchasing product(s) with KeysToMyWishes.</div>
                                                <div>Expect to receive an email containing your Invoice shortly.</div>
                                                <div className="text-dark mt-3">Your confirmation # is: {confirmationCode}</div>
                                            </div>
                                        </>
                                    ) : (
                                        <strong>Sorry, your payment failed!</strong>
                                    )}
                                </Modal.Body>
                            </>
                        )
                    }
                </Modal>
            </div>
        </>
    );
};

export default PaymentForm;