import React from 'react';
import '../Introduction/introduction.css'
import {Container, Row} from 'react-bootstrap';
import {Link} from "react-router-dom";

const Introduction = () => {
    return (
        <Container fluid className='introduction_container p-0'>
            <div className='introduction_content'>
                <Row className='justify-content-center m-0'>
                    <h1 className='heading'>
                        Bringing Peace of Mind <br/> to You and Your loved ones
                    </h1>
                </Row>
                <Row className='justify-content-center m-0'>
                    <p className='represent_heading'>
                        Keep your information private. Knowing, should something happen to you, we will <br/>
                        release your information to who you have designated to receive that information.
                    </p>
                </Row>
                <div className='helper_button'>
                    <Row className='justify-content-center d-flex align-items-start gap-2 m-0'>
                        <div className="d-flex flex-column">
                            <Link to="/new-signup" variant='' className='helper_btn mt-0'>Start For Free</Link>
                            <span className={"text-dark pt-1"}>Credit card not required</span>
                        </div>
                        <Link to="/how-to" variant='' className='helper_btn mt-0'>How it Works</Link>
                    </Row>
                </div>
            </div>
        </Container>
    );
};

export default Introduction;