import React, {useState} from 'react';
import '../../PrivatePeople3LandingNew/Pages/Home/Header/header.css';
import './EstatePlanning/estimate-planning.css';
import keysLogoImage from '../../PrivatePeople3LandingNew/assest/images/logo.png';
import {Button, Col, Container, Modal, Navbar, Row} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ktmwPdf from "../../../assets/images/KTMW-PURPLE.pdf";
import axios from "axios";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const Header = () => {
    const history = useHistory();
    const classes = useStyles();
    const [openPdfModel, setOpenPdfModel] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({email: ''});
    const anchor = 'right';
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const validateForm = () => {
        if (!email.trim()) {
            setErrors({ email: 'Email address is required' });
            return false;
        }
        return true;
    };

    const handlePdfPlan = () => {
        setErrors({email: ''});
        setEmail('');
        setOpenPdfModel(!openPdfModel);
    };

    const handlePdf = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                // Create download link for the PDF
                const link = document.createElement('a');
                link.href = ktmwPdf;
                link.download = `printable_personal_affairs_organizer.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Fetch the download link from the server
                const res = await axios.post(`${process.env.REACT_APP_SERVER}getDownLoadEmail`, {
                    sEmail: email,
                });
                if (res.data && res.data.downloadLink) {
                    window.location.href = res.data.downloadLink;
                }
            } catch (error) {
                console.error('Error fetching the PDF:', error);
                setErrors({ email: 'Failed to fetch PDF' });
            } finally {
                setOpenPdfModel(false);
            }
        }
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="mobile_menu">
                <div className='header_logo d-flex gap-2'>
                    <Link to='/'>
                        <img
                            src={keysLogoImage}
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Link>
                    <CloseIcon />
                </div>
                <Link to="/">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/home_webcopy" className="btn">Home</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/shop">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/shop_webcopy" className="btn">Shop</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/about">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/about_webcopy" className="btn">About</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/contact">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/contact_webcopy" className="btn">Contact</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-signup">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-signup" className="btn Landing_signup_btn">Sign Up</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-login">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-login" className="btn Landing_login_btn">Login</ListItemText>
                    </ListItem>
                </Link>
            </List>
        </div>
    );

    return (
        <>
            <div className='introduction_section home_webcopy_section'>
                <Navbar className='main-header webcopy_main_header'>
                    <Navbar.Brand>
                        <Link to='/'>
                            <img
                                src={keysLogoImage}
                                className="d-inline-block align-top"
                                alt="logo"
                                style={{ width: "221px" }}
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse expand="md" className="justify-content-end">
                        <ul className='main_menu_list'>
                            <li className='menu_item active'>
                                <Link to="/">Home</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/shop">Shop</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/about">About</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                        <div className='header_menu_button'>
                            <Button variant="" className='header_menu_outlinebtn' onClick={() => history.push('/new-login')}>Login</Button>
                            <Button variant="" className='header_menu_btn ml-2' onClick={() => history.push('/new-signup')}>
                                Start Your Free 14-Day Trial
                            </Button>
                        </div>
                    </Navbar.Collapse>
                    <div className="menu_bar">
                        <MenuIcon onClick={toggleDrawer(anchor, true)} />
                    </div>
                </Navbar>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                </Drawer>
                <section className='home_section'>
                    <Container fluid >
                        <div className='inroduction_details '>
                            <Row className='align-items-center justify-content-center m-0'>
                                <Col xl={12}>
                                    <div className='inroduction_heading'>
                                        <div>
                                            <button className="btn btn-danger" onClick={handlePdfPlan}>
                                                <span className="mr-2">Download Our Free Printable PDF Version</span>
                                            </button>
                                        </div>
                                        <h1 className="new_heading">Digital Storage Solution</h1>
                                        <h6>
                                            Take control of how your end-of-life information is stored.<br/>
                                            Keep your private information safe with <br/>
                                            <span> Ironclad digital protection  and release of your last wishes.</span>
                                        </h6>
                                    </div>
                                    <div className='d-flex justify-content-xl-center justify-content-center  gap-2'>
                                        <div className='inroduction_details_btn mb-0'>
                                            <Button variant="" className='button_primary' onClick={() => history.push("/new-signup")}>
                                                Start Your Free 14-Day Trial
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </div>
            <Modal show={openPdfModel} onHide={handlePdfPlan} className='activeModel'>
                <Modal.Header
                    closeButton
                    style={{backgroundColor: "#0069d9", color: "white"}}
                >
                    <Modal.Title>Free Printable PDF Download</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This is our Printable PDF version of our Personal Affairs Organizer.
                        You will be able to print this and keep for your personal use.</p>
                    <p> With your Email address we will inform you of any new pages and any page updates.</p>
                    <p>Please check out our Interactive version which does cost. The Digital Interactive PDF version allows
                        you to enter your important information allowing you to digitally store your important information on your computer or your smartphone.</p>
                    <form onSubmit={handlePdf}>
                        <div className="mb-3">
                            <label className='font-weight-bold'>Email:</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </div>
                        <Modal.Footer>
                            <Button type="submit" className="btngenie">
                                Submit
                            </Button>
                            <Button className="btngenie" onClick={handlePdfPlan}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
};
export default Header;