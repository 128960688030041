import React from 'react';
import {TextField} from "@material-ui/core";
import {Col, Row} from "react-bootstrap";

const TrustPage = () => {
    return (
        <div className="personal_affairs_container">
            <div className="info_header">
                <h3>Trust</h3>
            </div>
            <div className="content mt-3 mb-5">
                <div className="d-flex align-items-center gap-2">
                    <span>I have a Trust which was executed on</span>
                    <TextField
                        variant="filled"
                        size="small"
                        className="w-50"
                    />
                    (Date)
                </div>
                <div className="d-flex align-items-center mb-2">
                    <span className="w-50">Trust document location:</span>
                    <TextField
                        variant="filled"
                        size="small"
                        className="w-100"
                    />
                </div>
                <div>
                    Notes about my Trust:
                    <TextField
                        variant="filled"
                        fullWidth={true}
                        size="small"
                        multiline={true}
                        rows={30}
                    />
                </div>
            </div>
        </div>
    );
};

export default TrustPage;