import React, {useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormControl, NativeSelect, InputBase } from '@material-ui/core';
import SharedWithReleasedToGridContainer from './SharedWithReleasedToGridContainer';
import {Button} from "react-bootstrap"
import "./AdditemDiscriptionWizard.css"
import NewContact from "../../../screens/contacts/NewContact";

const BootstrapInput = withStyles((theme) => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		maxHeight: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
		},
	},
}))(InputBase);

const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
}));

const AddItemSharedWithWizard = ({
	isSharedReleaseGridShow,
	handleIsSharedReleaseGridChange,
	contactsList,
	setContactsList,toggle
}) => {
	const classes = useStyles();

	const handleIsShareToChange = (id,value) => {
		const newContactsData = contactsList.map((contact) => {
			return {
				...contact,
				...(contact.contactid === Number(id)
					? {editsharedwith: Number(value)}
					: {}),
			};
		});
		setContactsList(newContactsData);
	};

	return (
		<div>
			<div
				className="d-flex justify-content-center custom-wishMsgBox"
				style={{ padding: '5px 18px' }}
			>
				<div>
					<div style={{ fontSize: '22px' }}>
						<b>This screen allows you to share this Wish with your loved ones.</b>
					</div>
					<div style={{ fontSize: '15px' }}>
						(This means this item will not be Private once you send your loved ones a link for this item. They will be able to see any Wish you have Shared with the person the moment they open the link)
					</div>
				</div>
			</div>
			<Button
				variant="success"
				className="btn mr-2 text-nowrap"
				onClick={toggle}
			>
				+ Add Contact
			</Button>
			{Number(isSharedReleaseGridShow) === 1 ? (
				<SharedWithReleasedToGridContainer
					contactsList={contactsList}
					handleIsShareToChange={handleIsShareToChange}
					isShowShareColumn={true}
					isShowReleaseColumn={false}
				/>
			) : null}
		</div>
	);
};

export default AddItemSharedWithWizard;
