import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import "./multipalReviews.css"
import StarIcon from '@mui/icons-material/Star';

const MultipalReviews = (props) => {
    return (
        <div className={"mt-5"}>
            <div className={`inroduction_details_btn text-center mt-5 ml-0 ${props.buttonNeed && 'd-none'}`} >
                <Link to="/new-signup" variant="" className='btn_primarybtn ml-0'>Discover Peace Of Mind Now</Link>
            </div>
            <div className="d-md-flex justify-content-center flex-wrap gap-2">
                <Card className="reviews_card ">
                    <Card.Body>
                        <div className={"d-flex align-items-center"}>
                            <div className={"profile_photo"} style={{background:"#91add9"}}>GA</div>
                            <div> Greg A.</div>
                        </div>
                        <div className={"mb-3 d-flex justify-content-center"}>
                            <StarIcon color="warning"/>
                            <StarIcon color={"warning"} />
                            <StarIcon color={"warning"}/>
                            <StarIcon color={"warning"}/>
                            <StarIcon color={"warning"}/>
                        </div>
                        <div style={{textAlign:"justify"}}>
                            "I think this is one of the most useful apps a person can have. I am single
                            and somewhat of a private person. Especially as it pertains to my financial
                            affairs.
                            <br/>
                            <br/>
                            I love the fact that I can simply make an entry into Keys with the location
                            of my important documents without having to give this information to
                            someone.
                            <br/>
                            <br/>
                            I do have information I want passed along to my family should something
                            happen to me. I just don't want to share that information while I'm alive."
                            <br/>
                            <br/>
                        </div>
                    </Card.Body>
                </Card>
                <Card className="reviews_card">
                    <Card.Body>
                        <div className={"d-flex align-items-center"}>
                            <div className={"profile_photo"} style={{background:"#1d2c52"}}>MD</div>
                            <div>Maia D.</div>
                        </div>
                        <div className={"mb-3 d-flex justify-content-center"}>
                            <StarIcon color="warning"/>
                            <StarIcon color={"warning"}/>
                            <StarIcon color={"warning"}/>
                            <StarIcon color={"warning"}/>
                        <StarIcon color={"warning"}/>
                        </div>
                        <div style={{textAlign:"justify"}}>
                            "In the wake of  my sister's passing, we  were confronted with the poignant
                            reality of a tribute left unspoken. The absence of her guidance on the pictures
                            for her obituary and the words to narrate her life story left us with an
                            incomplete farewell.
                            <br/>
                            <br/>
                            With KeysToMyWishes, we could have captured her essence and wishes, sparing us the ache of an
                            unsaid tribute and ensuring her memory was celebrated in the way she desired.
                            <br/>
                            <br/>
                            I am so thankful this service exists. I'm letting my family and friends know about this
                            service so that this doesn't happen to anyone else that I know.”
                        </div>
                    </Card.Body>
                </Card>
                <Card className="reviews_card">
                    <Card.Body>
                        <div className={"d-flex align-items-center"}>
                            <div className={"profile_photo"} style={{background:"#2dabd7", color:"black"}}>CB</div>
                            <div> Chester B.</div>
                        </div>
                        <div className={"mb-3 d-flex justify-content-center"}>
                            <StarIcon color="warning"/>
                            <StarIcon color={"warning"}/>
                            <StarIcon color={"warning"}/>
                            <StarIcon color={"warning"}/>
                            <StarIcon color={"warning"}/>
                        </div>
                        <div style={{textAlign:"justify"}}>
                            "Keys has been a game-changer for our family. As a young couple with three
                            small children, we recognize the importance of planning for the unexpected.
                            <br/>
                            <br/>
                            All our crucial information, from financial documents to our safe's combination,
                            is securely stored with KeysToMyWishes.
                            <br/>
                            <br/>
                            In the event that something happens to both of us, this service ensures that
                            our designated contact can access the information needed to open our safe in
                            a legitimate emergency. It provides us with peace of mind, knowing that even
                            if the unforeseen occurs, our family will have the support and resources
                            required. KeysToMyWishes has truly become our trusted guardian, offering a
                            sense of security that every young family deserves."
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default MultipalReviews;