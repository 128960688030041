import React from 'react';
import './findmyplan.css';
import { Col, Container, Row } from 'react-bootstrap';

const FindMyPlan = () => {
    return (
        <>
            <section className='findmyplan_trial bg_grediant'>
                <Container>
                    <Row className='justify-content-center'>
                        <div className='section-title text-center'>
                            <h2 className='text-white'>Not sure which plan to pick?</h2>
                            <p className='text-white'>Let us find the best one for you within two minutes, so you can <br/> focus on things that matter.</p>
                            {/*<button type="button" className="outline-btn btn"><span> Find my plan </span></button>*/}
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default FindMyPlan;