import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {Box, Grid, styled} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from '@material-ui/core/RadioGroup';
import axios from "axios";
import HandleConfirmation from "../../../screens/handleConfirmation";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";

const StyledBox = styled(Box)({
    border: "1px solid #c3c3c3",
    padding: "10px",
    borderRadius: "10px",
    height:"100%"
});

const StyledControlLabel = styled(FormControlLabel)({
    margin: 0
});

const initialValues = {
    nOn: "1",
    sFrequency: "W",
    sReminderMsg: "",
    dDate: ""
}


const AddNewReminderModal = ({
     isOpen,
     toggle,
     currentWish,
     getAllItems
}) => {
    const [formValues, setFormValues] = useState(initialValues);
    const [alertMessage, setConfirmation] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (currentWish) {
            setFormValues(()=>({
                dDate: currentWish && currentWish.reminderDate ? currentWish.reminderDate : "",
                nOn: "1",
                sFrequency :currentWish ? currentWish.reminderfreq  : "W",
                sReminderMsg: currentWish ? (currentWish.reminderComment || "") : ""
            }))
        }
    }, [currentWish])

    const handleChange = (event) => {
        setFormValues((prev) => ({...prev, [event.target.name]: event.target.value}))
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formValues)
        setIsSubmitting(true);
        const token = localStorage.getItem('token');

        const data = {
            nID: currentWish.id,
            nOn: formValues.nOn,
            sFrequency: formValues.sFrequency,
            sReminderMsg: formValues.sReminderMsg,
            nUserID: localStorage.getItem('userid'),
        };
        if (formValues.sFrequency === "D"){
            data.dDate = moment(formValues.dDate).format("YYYY-MM-DD");
        }
        const requestedHeaders = {headers: {Authorization: `Bearer ${token}`}};
        const URL = process.env.REACT_APP_SERVER + 'inventory/addWishReminder';

        axios.post(URL, data, requestedHeaders)
            .then(() => {
                setIsSubmitting(false);
                toggle();
                getAllItems();
                if (data.nOn === "1") {
                    setConfirmation("Reminder Created Successfully");
                }
            })
            .catch(() => {
                setIsSubmitting(false);
                setConfirmation("Error While Creating a New Reminder");
            })
    }

    return (
        <>
            <Modal
                show={isOpen}
                centered
                onHide={() => toggle()}
                size="lg"
            >
                <Modal.Header closeButton className="ShareRelease-Modal-Header justify-content-center">
                    <Modal.Title>Set Reminder On/Off</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <StyledBox mb={2} fontWeight="500">
                            This will Set a Reminder for the selected Wish item. This can be used to remind you of
                            information you may need to add or change at a later date. We will send you a text/email on
                            the
                            day of your reminder.
                        </StyledBox>
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid item xs={12} md={3}>
                                <StyledBox>
                                    <RadioGroup
                                        row
                                        style={{
                                            display: "flex", justifyContent: 'center',
                                            alignItems:"center",
                                            gap: 5,height:"100%"
                                        }}
                                        value={formValues.nOn}
                                        name="nOn"
                                        onChange={handleChange}
                                    >
                                        <StyledControlLabel
                                            value="1"
                                            control={<Radio color="primary"/>}
                                            label="On"
                                            labelPlacement="top"
                                        />
                                        <StyledControlLabel
                                            value="0"
                                            control={<Radio color="primary"/>}
                                            label="Off"
                                            labelPlacement="top"
                                        />
                                    </RadioGroup>
                                </StyledBox>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <StyledBox padding="5px !important">
                                    <RadioGroup
                                        name="sFrequency"
                                        value={formValues.sFrequency}
                                        onChange={handleChange}
                                    >
                                        <Box display="flex" justifyContent="space-between">
                                            <StyledControlLabel
                                                value="D"
                                                control={<Radio color="primary"/>}
                                                label="Remind me on Date:"
                                                labelPlacement="end"
                                            />
                                            {formValues.sFrequency === "D" && (
                                                <Form.Control
                                                    name="dDate"
                                                    type="date"
                                                    style={{maxWidth:"130px"}}
                                                    value={formValues.dDate}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            )}
                                        </Box>
                                        <StyledControlLabel
                                            value="W"
                                            control={<Radio color="primary"/>}
                                            label="Reminder once a week"
                                            labelPlacement="end"
                                        />
                                        <StyledControlLabel
                                            value="M"
                                            control={<Radio color="primary"/>}
                                            label="Reminder once a month"
                                        />
                                    </RadioGroup>
                                </StyledBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <StyledBox>
                                    <Typography variant="h6" gutterBottom>
                                        Reminder Date
                                    </Typography>
                                    <Typography gutterBottom>
                                        {currentWish  && currentWish.reminderDate ? (moment(currentWish.reminderDate).format("MM-DD-YYYY")  || "-") : ""}
                                    </Typography>
                                </StyledBox>
                            </Grid>
                        </Grid>
                        <Form.Control
                            as="textarea"
                            name="sReminderMsg"
                            className="mt-3"
                            placeholder="You can enter up to 400 characters as a Reminder for this Wish Item."
                            maxLength="400"
                            value={formValues.sReminderMsg}
                            onChange={handleChange}
                            rows={3}
                            required={formValues.nOn === "1"}
                        />
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Button color="success" className="btn-secondary" onClick={() => toggle()}>
                            Cancel
                        </Button>
                        <Button className="px-3" color="primary" type="submit" disabled={isSubmitting}>
                            OK {isSubmitting && <Spinner animation="border" className="ml-1" size="sm"/>}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <HandleConfirmation
                isOpen={alertMessage !== null}
                handleClose={() => setConfirmation(null)}
                onConfirm={()=> setConfirmation(null)}
                question={alertMessage}
                yesText="Okay"
                noText=""
            />
        </>
    );
};

export default AddNewReminderModal;