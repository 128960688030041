import {pdfLists} from "./pdfLists";
import {pdfLists2} from "./pdfLists2";

export const wish = {
    text: function (text = "") {
        return text.replace("(s)", "s").replace("*****", "");
    },
    removeHtml: function (html = "") {
        return html.replace(/<\/?[^>]+>/g, "");
    },
    accessApproved: ` 
        Your Access Request has been sent to {username}.<br/>
        The person will now be able to view any Wishes you have Shared with them.`,
    downloadPdfByType: (type = "") => {
        const findByType = pdfLists.find(({file}) => file === type);
        if (findByType) {
            const link = document.createElement('a');
            link.href = findByType.pdf;
            link.download = `${findByType.title}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },
    downloadPdfByType2: (type = "") => {
        const findByType = pdfLists2.find(({file}) => file === type);
        if (findByType) {
            const link = document.createElement('a');
            link.href = findByType.pdf;
            link.download = `${findByType.title}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },
    newTab: (url) => window.open(url, "_blank"),
}